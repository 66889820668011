<template>
  <div class="content widfix">
    <e-breadcrumbs />

    <div id="layerPop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePoup"
          @agreement="agreementPop"
          :parent-info="popupParams"
        />
      </win-layer-pop>
    </div>
    <form
      id="frm"
    >
      <h1 class="page_title">{{ $t('menu.MENU08.010') }}</h1>

      <div class="content_box mt10">
        <!-- content_box -->
        <table class="tbl_row mt20">
          <colgroup>
            <col width="180"><col><col width="160"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                {{ $t('msg.CMBA100.0059') }}
              </th>
              <td>
                <input type="text" id="userId" v-model="params.userId" disabled="disabled">
              </td>
              <th>{{ $t('msg.CMBA100.0061') }}</th><!-- 홈페이지 접속 지역 -->
              <td class="full">
                <!-- {{ selectedCtrList }} -->
                <select
                  disabled
                  id="selectedCtrList"
                  v-model="selectedCtrList"
                  @change="selectedCtr($event)"
                  @keyup="checkValidationCtrList()"
                >
                  <option
                    v-for="ctr in ctrList"
                    :key="ctr.ctrCd"
                    :value="ctr.ctrCd"
                  >
                    {{ ctr.ctrEnm }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.0078') }}</th>
              <td colspan="3">
                <a class="button sm" @click.prevent="openPopup('MyInfoPasswordPop')">{{ $t('msg.CMBA100.0103') }}</a>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.0087') }}<span class="txt_normal">({{ $i18n.locale === 'zh-CN'? $t('msg.CMBA100.0081') : $t('msg.CMBA100.0080') }})</span></th><!-- 회사명-->
              <td>
                <input type="text" id="compName" v-model="params.compName" @keyup="checkCompanyName()">
              </td>
              <th>{{ $t('msg.CMBA100.0087') }}<span class="txt_normal">({{ $t('msg.CMBA100.0083') }})</span></th><!-- 회사명(영문)-->
              <td>
                <input type="text" id="compEName"
                       v-model="params.compEName"
                       @input="formatInput($event)"
                       @keyup="checkCompanyEName()"
                >
                <!-- {{ valid }} -->
              </td>
            </tr>
            <tr v-show="isKor">
              <th>{{ $t('msg.CMBA100.0088') }}</th><!-- 사업자등록번호 -->
              <td>
                <div class="tbl_form">
                  <span class="dv col_3">
                    <input type="text" maxlength="3" id="bizNo1" v-mask="'###'" v-model="params.bizNo1" @keyup="checkbizNo1()">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_3">
                    <input type="text" maxlength="2" id="bizNo2" v-mask="'###'" v-model="params.bizNo2" @keyup="checkbizNo2()">
                  </span>
                  <span class="sp"></span>
                  <span class="dv">
                    <input type="text" maxlength="5" id="bizNo3" v-mask="'#####'" v-model="params.bizNo3" @keyup="checkbizNo3()">
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.MYIN010G010.002') }}</th> <!-- 사업자등록증 첨부 -->
              <td>
                <div class="filebox">
                  <button :class="uploadFileInfo.length > 0 ? 'button sm blue' : 'button sm gray'" @click.prevent="openFileUploader()">{{ $t('msg.MYIN010G010.037') }}</button>
                  <div id="dext5_pop" v-show="isShowDext5Pop">
                    <div class="popup_dim" ref="popup">
                      <div class="popup_wrap" style="width:800px; height:520px;">
                        <button class="layer_close" @click.prevent="closeFileUploadr()">close</button>
                        <div class="popup_cont">
                          <h1 class="page_title">{{ $t('msg.MYIN010G010.037') }}</h1>
                          <div class="content_box">
                            <DEXT5Upload
                              v-if="isShowDext5"
                              ref="dextupload-myinfo"
                              :id="'dext5-uploader-' + dext5Idx"
                              :single="true"
                              :category="false"
                              width="100%"
                              height="200px"
                              @completed="uploadComplete"
                              :file-info="uploadFileInfo"
                              :category-info="[]"
                            />
                          </div>
                        </div>
                        <div class="mt10 text_center">
                          <a class="button blue lg" @click.prevent="closeFileUploadr()">{{ $t('msg.MYIN010G010.022') }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-show="isKor">
              <th>{{ $t('msg.CMBA100.0090') }}</th> <!-- 우편번호 - 회사주소 -->
              <td colspan="3">
                <p class="mb5">
                  <input type="text" maxlength="5" id="postNo" v-model="params.postNo" class="wid100" disabled
                         @keyup="checkPostNo()"
                  ><span class="sp2"></span>
                  <span>
                    <e-search-address class="button sm" @setAddr="customCallback">{{ $t('msg.MYIN010G010.003') }}
                    </e-search-address></span>
                </p>
                <input type="text" v-model="params.compAddr">
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.00108') }}</th> <!-- 전화번호 -->
              <td>
                <div class="tbl_form">
                  <span class="dv col_2">
                    <input type="text" maxlength="4" id="tel1" v-mask="'####'" v-model="params.tel1" @keyup="checkTel1()">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_2">
                    <input type="text" maxlength="3" id="tel2" v-mask="'###'" name="tel2" v-model="params.tel2" @keyup="checkTel2()">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_3">
                    <input type="text" maxlength="4" id="tel3" v-mask="'####'" v-model="params.tel3" @keyup="checkTel3()">
                  </span>
                  <span class="sp"></span>
                  <span class="dv">
                    <input type="text" maxlength="4" id="tel4" v-mask="'####'" v-model="params.tel4" @keyup="checkTel4()">
                  </span>
                </div>
                <span v-if="$i18n.locale === 'en-US'" style="color:#2556c0; font-size:13px;">ex) HK : Non-1234-1234 , SG : Non-1234-1234 , MY : 123-1234-123</span>
              </td>
              <th>{{ $t('msg.CMBA100.00163') }}</th><!-- 이메일 -->
              <td>
                <div>
                  <span class="col_50p wid50">
                    <input type="text" id="email1" v-model="email1" style="width:39%" placeholder="" @keyup="checkEmail1()">
                  </span>
                  <span class="col_7p text_center" style="margin: 0 2px 0 2px;">@</span>
                  <span class="col_43p">
                    <input type="text" id="email2" v-model="email2" style="width:53.8%" placeholder="" @keyup="checkEmail2()">
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.00109') }}<span class="txt_normal">({{ $t('msg.MYIN010G010.004') }})</span></th> <!-- FAX번호 -->
              <td>
                <div class="tbl_form">
                  <span class="dv col_2">
                    <input type="text" maxlength="4" id="fax1" v-mask="'####'" v-model="params.fax1">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_2">
                    <input type="text" maxlength="3" id="fax2" v-mask="'####'" name="fax2" v-model="params.fax2">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_3">
                    <input type="text" maxlength="4" id="fax3" v-mask="'####'" v-model="params.fax3">
                  </span>
                  <span class="sp"></span>
                  <span class="dv">
                    <input type="text" maxlength="4" id="fax4" v-mask="'####'" v-model="params.fax4">
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CMBA100.0091') }}</th> <!-- 회원구분 -->
              <td class="full">
                <select id="selectedCstCatList" v-model="selectedCstCatList" @keyup="checkValidationCstCatList()">
                  <option
                    v-for="cstCat in cstCatList"
                    :key="cstCat.value"
                    :value="cstCat.value"
                  >
                    {{ cstCat.text }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.0097') }}</th> <!-- 부킹담당사무소 -->
              <td class="full">
                <select id="selectedBkgPlc" v-model="params.bkgPlcCd" @keyup="checkValidationBkgPlc()">
                  <option
                    v-for="bkgPlc in selectedBkgPlcList"
                    :key="bkgPlc.plcCd"
                    :value="bkgPlc.plcCd"
                  >
                    {{ bkgPlc.plcEnm }}
                  </option>
                </select>
              </td>
              <th>{{ $t('msg.CMBA100.00104') }}</th> <!-- Pick Up 지 담당자 -->
              <td class="full">
                <input type="radio" id="trad1" v-model="params.eiCatCd" name="trad" value="O" checked="checked" @keyup="checkValidationEiCatCd()" />
                <label for="trad1" class="mr10"><span></span>{{ $t('msg.CMBA100.00105') }}</label>

                <input type="radio" id="trad2" v-model="params.eiCatCd" name="trad" value="I" @keyup="checkValidationEiCatCd()" />
                <label for="trad2" class="mr10"><span></span>{{ $t('msg.CMBA100.00106') }}</label>

                <input type="radio" id="trad3" v-model="params.eiCatCd" name="trad" value="T" @keyup="checkValidationEiCatCd()" />
                <label for="trad3" class="mr10"><span></span>{{ $t('msg.CMBA100.00107') }}</label>
              </td>
            </tr>
            <!-- B/L Copy 수신방법 -->
            <!-- <tr>
              <th scope="row">{{ $t('msg.MYIN010G010.027') }}</th>
              <td colspan="3">
                <input type="radio" id="rdo1" v-model="params.blCpyRecv" name="rdo" value="02" />
                <label for="rdo1" class="mr10"><span></span>FAX</label>
                <input type="radio" id="rdo2" v-model="params.blCpyRecv" name="rdo" value="01" />
                <label for="rdo2" class="mr10"><span></span>E-mail</label>
              </td>
            </tr> -->
            <!-- <tr>
              <th scope="row">{{ $t('msg.MYIN010G010.018') }}<span class="txt_normal">({{ $t('msg.MYIN010G010.017') }})</span></th>
              <td colspan="3">
                <input type="radio" id="rdo3" v-model="params.mdtyPsnlInfCltnCnstYn" name="rdo1" value="Y" />
                <label for="rdo3" class="mr10"><span></span>{{ $t('msg.MYIN010G010.028') }}</label>
                <address-result class="ml30 button sm" @click.prevent="openPopup('MyInfoPersonalAgreePop')">{{ $t('msg.MYIN010G010.030') }}</address-result>
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.MYIN010G010.020') }}<span class="txt_normal">({{ $t('msg.MYIN010G010.019') }})</span></th>
              <td colspan="3">
                <input type="radio" id="rdo5" v-model="params.choPsnlInfCltnCnstYn" name="rdo2" value="Y" />
                <label for="rdo5" class="mr10"><span></span>{{ $t('msg.MYIN010G010.028') }}</label>
                <input type="radio" id="rdo6" v-model="params.choPsnlInfCltnCnstYn" name="rdo2" value="N" />
                <label for="rdo6" class="mr10"><span></span>{{ $t('msg.MYIN010G010.029') }}</label>
                <a class="ml30 button sm" @click.prevent="openPopup('MyInfoPersonalAgreeChoicePop')">{{ $t('msg.MYIN010G010.030') }}</a>
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.MYIN010G010.021') }}<span class="txt_normal">({{ $t('msg.MYIN010G010.019') }})</span></th>
              <td colspan="3">
                <input type="radio" id="rdo7" v-model="params.recvCnstYn" name="rdo3" value="Y" />
                <label for="rdo7" class="mr10"><span></span>{{ $t('msg.MYIN010G010.028') }}</label>
                <input type="radio" id="rdo8" v-model="params.recvCnstYn" name="rdo3" value="N" />
                <label for="rdo8" class="mr10"><span></span>{{ $t('msg.MYIN010G010.029') }}</label>
                <a class="ml30 button sm" @click.prevent="openPopup('MyInfoMarketingAgree')">{{ $t('msg.MYIN010G010.030') }}</a>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div><!-- content_box // -->
      <div v-show="memberDetail.staffFlag !== 'Y'" class="flex_box mt10">
        <button class="button blue lg ml_auto mr5" @click.prevent="memberUpdate()">{{ $t('msg.MYIN010G010.032') }}</button> <!-- 저장 -->
        <!--
        <button class="button gray lg mr5" @click.prevent="clearValidationAll()">
          초기화
        </button>
        -->
        <a class="button gray lg" @click.prevent="openConfirmAlert($t('art.CMATK124'))">{{ $t('msg.MYIN010G010.031') }}</a> <!-- 회원탈퇴 -->
      </div>
    </form>
  </div>
</template>

<script>
import member from '@/api/rest/user/member'
import ESearchAddress from '@/components/common/ESearchAddress'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import MyInfoPasswordPop from '@/pages/user/popup/MyInfoPasswordPop'
import MyInfoPersonalAgreePop from '@/pages/user/popup/MyInfoPersonalAgreePop'
import MyInfoPersonalAgreeChoicePop from '@/pages/user/popup/MyInfoPersonalAgreeChoicePop'
import MyInfoMarketingAgree from '@/pages/user/popup/MyInfoMarketingAgree'
import DownloadPop from '@/pages/trans/popup/SpecialRfDgDownloadPop'
import DEXT5Upload from '@/components/DEXT5Upload'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import api from '@/api/services/auth'

import { rootComputed, rootMethods } from '@/store/helpers'

export default ({
  name: 'MyInfoMember',
  components: {
    WinLayerPop,
    ESearchAddress,
    MyInfoPasswordPop,
    MyInfoPersonalAgreePop,
    MyInfoPersonalAgreeChoicePop,
    MyInfoMarketingAgree,
    DownloadPop,
    DEXT5Upload,
    EBreadcrumbs,
    MyInfoDextUploader: () => import('@/pages/user/MyInfoDextUploader')
  },
  data () {
    return {
      uploadParam: [],
      selectFunc: null,

      ctrList: [],
      cstCatList: [
        { text: this.$t('msg.CMBA100.0092'), value: '01' },
        { text: this.$t('msg.CMBA100.0093'), value: '02' },
        { text: this.$t('msg.CMBA100.0094'), value: '03' },
        { text: this.$t('msg.CMBA100.0095'), value: '04' },
        { text: this.$t('msg.CMBA100.0096'), value: '05' },
        { text: 'Vendor', value: '06' },
        { text: 'CODECO (S.CN)', value: '07' },
        { text: 'OFF-HIRE EOR', value: '08' },
        { text: 'CFS', value: '09' }
      ],
      bkgPlcList: [],
      userInfoList: [],
      selectedCtrList: '',
      selectedBkgPlc: '',
      selectedBkgPlcList: [],
      selectedCstCatList: '',
      email: [],
      email1: '',
      email2: '',

      popupParams: '',

      params:
        {
          userEmail: '',
          compName: '',
          compEName: '',
          bizNo1: '',
          bizNo2: '',
          bizNo3: '',
          compAddr: '',
          cstCatCd: '',
          bkgPlcCd: '',
          tel1: '',
          tel2: '',
          tel3: '',
          tel4: '',
          fax1: '',
          fax2: '',
          fax3: '',
          fax4: '',
          eiCatCd: '',
          postNo: '',
          blCpyRecv: '',
          recvCnstYn: '',
          mdtyPsnlInfCltnCnstYn: '',
          choPsnlInfCltnCnstYn: '',
          userId: ''

          // userPwd: '',
          // userCtrCd: '',
          // userStatus: ' ',
          // bizEntrCd: '',
          // isUpdBizEntrCd: 'Y',
          // fileId: '',
          // socShirYn: ''
        },

        fileList: [],
        // 카테고리가 있는 경우
        catList: [],
        fileOriginalName: '',
        mdtyPsnlInfCltnCnst: '',
        choPsnlInfCltnCnst: '',
        recvCnst: '',

        customComponent: null,
        uploadParams: [],
        uploadFileInfo: [],
        dext5Idx: 0,
        isShowDext5: true,
        isShowDext5Pop: false,

        // 다국어 항목 변수
        isKor: true,

        // 영문입력 체크 변수
        valid: ''
    }
  },
  computed: {
    ...rootComputed
  },
  async mounted () {
    console.log('mounted!')
  },
  created () {
    this.initInfo()

    // 다국어 항목 제어
    if (this.$i18n.locale !== 'ko-KR') {
      this.isKor = false
    }

    console.log('this.loginInfo ::: === ', this.loginInfo)
  },
  methods: {
    ...rootMethods,
    async logout () {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MYIN010G010.036') })) {
        this.delLogin().then(resolve => {
          location.reload()
        })
      }
    },
    initInfo () {
      member.getCtrList().then(response => {
        const resp = response.data

        this.ctrList = resp.commList
        this.bkgPlcList = resp.commList1

        //profile 변경시 아이디도 변경되어 세션에 있는 회원정보로 조회
        // 세션의 login id를 먼저 사용시, 한개의 프로필에 로그인 아이디가 여러개인 경우 비밀번호 변경이 안되는 문제가 있어
        // 로그인 아이디를 먼저 사용하고, 없는 경우 세션의 log ind를 사용하도록 변경함. 2024.04.02 dsyang
        //let loginId = sessionStorage.getItem('loginId').toUpperCase() || this.loginInfo.userId
        let loginId = this.loginInfo.userId || sessionStorage.getItem('loginId').toUpperCase()

        // 직원일때, 정보 조회 안함
        if (this.memberDetail.staffFlag !== 'Y') {
          sessionStorage.getItem('loginId')
          //member.getMemberInfo(this.loginInfo.userId).then(response => {
          member.getMemberInfo(loginId).then(response => {
            // console.log('memberinfo response ::: ', response)
            const resp = response.data

            this.params = resp.adminModList[0]
            this.selectedCtrList = this.params.userCtrCd
            this.selectedBkgPlcList = this.bkgPlcList.filter(temp => temp.ctrCd === this.params.userCtrCd)
            this.selectedBkgPlc = this.params.bkgPlcCd
            this.selectedCstCatList = this.params.cstCatCd
            this.email = this.params.userEmail.split('@')

            this.email1 = this.email[0]
            this.email2 = this.email[1]

            // 홈페이지 접속 국가가 홍콩, 싱가포르 선택시 전화번호, 팩스 VALIDATION
            if (this.selectedCtrList === 'HK' || this.selectedCtrList === 'SG') {
              document.querySelector('input[name="tel2"]').disabled = true
              document.querySelector('input[name="fax2"]').disabled = true
            } else {
              document.querySelector('input[name="tel2"]').disabled = false
              document.querySelector('input[name="fax2"]').disabled = false
            }

            this.uploadParam = []
            member.getFileList(this.loginInfo.userId)
              .then(response => {
                // console.log('getFileList ::: ', response)
                const resp = response.data.fileList
                if (resp.length > 0) {
                  this.uploadFileInfo = [{
                    guid: '1',
                    originalName: resp[0].fileNm,
                    size: resp[0].fileSz,
                    fileId: 'http://user.ekmtc:9000/user/file-download?userId=' + resp[0].userId + '&fileId=' + resp[0].fileId + '&type=BUSINESS_LICENSE',
                    status: 'complete'
                  }]
                }
              })
              .catch(err => {
                console.log(err)
              })
          })
        }
      })
    },
    openFileUploader () {
      $('#dext5_pop').css('display', 'block')

      this.isShowDext5 = true
      this.dext5Idx++
      this.$ekmtcCommon.layerOpen('#dext5_pop > .popup_dim')
    },
    closeFileUploadr () {
      this.isShowDext5 = false
      this.$ekmtcCommon.layerClose('#dext5_pop > .popup_dim')
    },
    uploadComplete (data) {
      // console.log(data)
      this.params.fileId = data[0].fileId
    },
    checkIsPopCheckBox () {
      let isOk = true
      let msg = ''

      if (this.params.mdtyPsnlInfCltnCnstYn === 'N' || this.params.mdtyPsnlInfCltnCnstYn === '') {
        msg = this.$t('msg.MYIN010G010.033')
        isOk = false
      } else if (this.params.choPsnlInfCltnCnstYn === '') {
        msg = this.$t('msg.MYIN010G010.034')
        isOk = false
      } else if (this.params.recvCnstYn === '') {
        msg = this.$t('msg.MYIN010G010.035')
        isOk = false
      }

      if (!isOk) {
        // this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.openAlert(msg)
      }
      return isOk
    },
    closeFun () {
      this.$Simplert.close()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.MYIN010G010.007'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'

      }
      this.$ekmtcCommon.alert(obj)
    },
    agreementPop (chk, gubun) {
      if (gubun === 'MPAP') {
        this.params.mdtyPsnlInfCltnCnstYn = chk
        this.mdtyPsnlInfCltnCnst = chk
      } else if (gubun === 'MPACP') {
        this.params.choPsnlInfCltnCnstYn = chk
        this.choPsnlInfCltnCnst = chk
      } else if (gubun === 'MMA') {
        this.params.recvCnstYn = chk
        this.recvCnst = chk
      }
    },
    selectedCtr (event) {
      // console.log(event.target.value)
      this.selectedBkgPlcList = this.bkgPlcList.filter(temp => temp.ctrEnm === event.target.value)
      // console.log(this.selectedBkgPlcList)
      this.params.bkgPlcCd = this.selectedBkgPlcList[0].ctrCd
    },
    confirmFun () {
    },
    openConfirmAlert (msg, func) {
      this.$t('art.CSBL013', { userId: this.params.userId })

      msg = `${this.$t('art.CSBL013', { userId: this.params.userId })}
             <font color="red">${this.$t('art.CSBL014')}</font> ${this.$t('art.CSBL016')}
             ${this.$t('art.CSBL015')}`

      let obj = {
        onClose: this.closeFun,
        onConfirm: this.memberQuit,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.MYIN010G010.006'),
        customConfirmBtnText: this.$t('msg.MYIN010G010.007'),
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    async memberQuit () {
      const params = this.params

      params.userId = this.auth.userId

      // 회원탈퇴
      await member.memberQuit(params)
        .then(response => {
          if (response.status === 200) {
            // 회원탈퇴 후 자동 로그아웃
            this.logout()
          } else {
            console.log(response.status)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async memberUpdate () {
      const isOk = this.checkValidationAll()

      console.log('memberUpdate >>> ', this.params)
      if (isOk) {
       await member.memberUpdate(this.params)
              .then(response => {
          if (response.data === 1) {
            this.openAlert(this.$t('tem.CMAT013')) // 저장되었습니다.
            this.initInfo()

            // 세션, vuex 정보 재조회
            api.info(this.auth.jwt)
            .then(infoResp => {
              if (infoResp.headers.respcode === 'C0000') {
                const memberDetail = infoResp.data

                const auth = {
                  auth: this.$store.getters['auth/loginResp'],
                  memberDetail: memberDetail,
                  jwt: this.auth.jwt
                }

                sessionStorage.setItem('auth', JSON.stringify(auth))
                this.$store.commit('auth/memberDetail', memberDetail)
                this.$store.commit('memberDetail', memberDetail)
              } else {
                reject(infoResp)
              }
            })
          } else {
            console.log(response.status)
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
    customCallback (addrInfo) {
      this.params.postNo = addrInfo.zipNo
      this.params.compAddr = addrInfo.roadFullAddr
    },
    openPopup (compNm) {
      this.isShowDext5 = false
      $('#dext5_pop').css('display', 'none')

      this.customComponent = compNm

      if (compNm === 'TransCompanyPop') {
        this.popupParams = {
          SrPickUpPlaceData: {
            selectRow: '0',
            cntrSize: '',
            cntrType: ''
          }
        }
      } else if (compNm === 'MyInfoDextUploader') {
/*
        this.uploadParams = [{ guid: '21915747-F71E-930A-D23F-FD1689A1A8E6', originalName: '스크린샷 2021-08-03 오후 3.22.04.png', size: '16958', fileId: '123', status: 'complete' }, { guid: '129C42D8-0F13-AE72-2D32-85D94A8393FC', originalName: '스크린샷 2021-08-04 오후 1.15.45.png', size: '84930', fileId: '124', status: 'complete' }]
 */
        this.uploadParams = []
        this.popupParams = this.uploadParams
      } else if (compNm === 'MyInfoPasswordPop') {
        this.popupParams = {
          userId: this.params.userId
        }
      } else if (compNm === 'MyInfoPersonalAgreePop') {
        this.popupParams = {
          mdtyPsnlInfCltnCnstYn: this.params.mdtyPsnlInfCltnCnstYn
        }
      } else if (compNm === 'MyInfoPersonalAgreeChoicePop') {
        this.popupParams = {
          choPsnlInfCltnCnstYn: this.params.choPsnlInfCltnCnstYn
        }
      } else if (compNm === 'MyInfoMarketingAgree') {
        this.popupParams = {
          recvCnstYn: this.params.recvCnstYn
        }
      } else {
        this.popupParams = {}
      }

      this.$ekmtcCommon.layerOpen('#layerPop > .popup_dim')
    },
    closePoup () {
      this.customComponent = null

      this.$ekmtcCommon.layerClose('#layerPop > .popup_dim')
    },
    clearValidationAll () {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.hideErrorTooltipAll(frm)
    },
    checkValidationAll () {
      const frm = document.querySelector('#frm')
      let isAllOk = true

      if (!this.checkIsPopCheckBox()) {
         isAllOk = false
      }

      if (!this.checkCompanyName(frm)) {
        isAllOk = false
      }

      if (!this.checkCompanyEName(frm)) {
        isAllOk = false
      }
      if (this.isKor) {
        if (!this.checkbizNo1(frm)) {
          isAllOk = false
        }
        if (!this.checkbizNo2(frm)) {
          isAllOk = false
        }

        if (!this.checkbizNo3(frm)) {
          isAllOk = false
        }

        if (!this.checkPostNo(frm)) {
          isAllOk = false
        }
      }
      if (!this.checkTel1()) {
        isAllOk = false
      }

      if (this.params.userCtrCd === 'HK' || this.params.userCtrCd === 'SG') {
        isAllOk = true
      } else {
        if (!this.checkTel2(frm)) {
          isAllOk = false
        }
      }

      if (!this.checkTel3(frm)) {
        isAllOk = false
      }
      if (!this.checkTel4(frm)) {
        isAllOk = false
      }

      if (!this.checkEmail1(frm)) {
        isAllOk = false
      }

      if (!this.checkEmail2(frm)) {
        isAllOk = false
      }

      if (this.$ekmtcCommon.isNotEmpty(this.email1) && this.$ekmtcCommon.isNotEmpty(this.email2)) {
        this.params.userEmail = this.email1 + '@' + this.email2
      }

      if (!this.checkValidationCstCatList(frm)) {
        isAllOk = false
      }

      if (!this.checkValidationBkgPlc(frm)) {
        isAllOk = false
      }
      return isAllOk
    },

    checkValidationId (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#userId')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK022') + this.$t('tem.CMAT005')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationCtrList (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#selectedCtrList')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('msg.CMBA100.0061') + this.$t('tem.CMAT004')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkPassword (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem1 = frm.querySelector('#userPwd')
      const val1 = elem1.value
      const elem2 = frm.querySelector('#userPwd2')
      const val2 = elem2.value
      let isOk = true
      let msg = ''

      if (val1 === '') {
        msg = this.$t('art.CMATK101') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val2 === '') {
        msg = this.$t('art.CMATK101') + this.$t('tem.CMAT006')
        isOk = false
      } else if (val1 !== val2) {
        msg = this.$t('art.CMATK018') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val1.length > 12) {
        msg = this.$t('tem.CMAT034')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem1, msg)
        this.$ekmtcCommon.showErrorTooltip(elem2, msg)
        this.params.userPwd = val1
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem1)
        this.$ekmtcCommon.hideErrorTooltip(elem2)
      }
      return isOk
    },
    checkCompanyName (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#compName')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK072') + this.$t('tem.CMAT001')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkCompanyEName (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#compEName')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = '값을 입력해주세요.'
        isOk = false
      } else if (this.valid === false) {
        msg = this.$t('art.CMATK041') + this.$t('tem.CMAT001')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    formatInput (e) {
     // this.params.compEName
      let message = e.target.value
      let pattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/i
      this.valid = (message.length >= 1 && pattern.test(message) === false)

      console.log(this.valid)

      if (!this.valid) {
        e.target.value = null
      }
    },
    checkbizNo1 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#bizNo1')
      const val = elem.value
      // 추후에 lang코드 받을 것!
      // lang = 'CHN', 'JPN', 'ENG'
      // let lang = 'KOR
      // 기존 jsp 로직상 체크할 필요 없음'
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK019') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (val.length !== 3) {
        msg = this.$t('art.CMATK020')
        isOk = false
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        // this.$ekmtcCommon.hideErrorTooltip(elem)
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkbizNo2 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#bizNo2')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK019') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (val.length !== 2) {
        msg = this.$t('art.CMATK020')
        isOk = false
      }

      if (!isOk) {
        // this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        this.localHideErrorTooltip(elem, 'case_two')
        // this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkbizNo3 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#bizNo3')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK019') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (val.length !== 5) {
        msg = this.$t('art.CMATK020')
        isOk = false
      }

      if (!isOk) {
        // this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        this.localHideErrorTooltip(elem, 'case_two')
        // this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkPostNo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#postNo')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('msg.MYIN010G010.012')
        isOk = false
      }

      if (val.length !== 5 && val.length !== 6) {
        msg = this.$t('msg.MYIN010G010.013')
        isOk = false
      }
      // this.$i18n.locale : 'ko-KR', 'en-US;, 'ja-JP', 'zh-CN'

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkTel1 () {
      let frm = document.querySelector('#frm')

      const elem = frm.querySelector('#tel1')
      const val = elem.value

      // 추후에 lang코드 받을 것!
      // lang = 'CHN', 'JPN', 'ENG'
      // let lang = 'KOR
      // 기존 jsp 로직상 체크할 필요 없음'
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('msg.CMBA100.00108') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        // this.$ekmtcCommon.hideErrorTooltip(elem)
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkTel2 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#tel2')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('msg.CMBA100.00108') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (!isOk) {
        // this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        // this.$ekmtcCommon.hideErrorTooltip(elem)
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkTel3 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#tel3')
      const val = elem.value

      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('msg.CMBA100.00108') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        // this.$ekmtcCommon.hideErrorTooltip(elem)
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkTel4 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#tel4')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('msg.CMBA100.00108') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        // this.$ekmtcCommon.hideErrorTooltip(elem)
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkEmail1 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#email1')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK023') + this.$t('tem.CMAT002')
        isOk = false
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkEmail2 (frm) {
     let isOk = true
     let msg = ''

      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#email2')
      const val = elem.value

      if (val === '') {
        msg = this.$t('art.CMATK023') + this.$t('tem.CMAT002')
        isOk = false
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkFax1 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#fax1')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK091') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkFax2 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#fax2')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK091') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkFax3 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#fax3')
      const val = elem.value

      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK091') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkFax4 (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#fax4')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK091') + this.$t('tem.CMAT002')
        isOk = false
      } else if (val !== '') {
          if (!this.$ekmtcCommon.isOnlyNumber(val)) {
            msg = this.$t('msg.ADMN040G020.017') // 숫자만 입력 가능합니다.
            isOk = false
          }
      }

      if (!isOk) {
        this.localShowErrorTooltip(elem, msg, 'case_two')
      } else {
        this.localHideErrorTooltip(elem, 'case_two')
      }
      return isOk
    },
    checkValidationCstCatList (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#selectedCstCatList')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK079') + this.$t('tem.CMAT003')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
        this.params.cstCatCd = val
      }
      return isOk
    },
    checkValidationBkgPlc (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#selectedBkgPlc')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK084') + this.$t('tem.CMAT004')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationEiCatCd (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#selectedBkgPlc')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK084') + this.$t('tem.CMAT004')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    localShowErrorTooltip (elem, message, flag) {
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)

          let $input = ''
          // let $sParent = ''
          let $sMsgBox = ''
          let $msgBox = $parent.find('.tooltip_essen_box')

          if (flag === 'case_one') {
            $input = $elem.children('div').children('input').eq(0)
            $input.addClass('input_error')
          } else {
            $elem.addClass('input_error')
          }

          if (flag === 'case_two') {
            let $sParent = $parent.parents('td, div').eq(0)
            $sParent.addClass('position_relative')
            let $ctMsgBox = $sParent.find('.tooltip_essen_box')

            $sMsgBox = $sParent.find('.tooltip_essen_box')

            if ($sMsgBox.length === 0) {
             $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
             $msgBox.appendTo($sParent)
            }

            $ctMsgBox.find('.tooltip_essen').eq(0).text(message).show()
          } else {
            $parent.addClass('position_relative')

            if ($msgBox.length === 0) {
              $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
              $msgBox.appendTo($parent)
            }
          }
          $msgBox.find('.tooltip_essen').eq(0).text(message).show()
    },
    localHideErrorTooltip (elem, flag) {
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $input = ''

          if (flag === 'case_one') {
            $input = $elem.children('div').children('input').eq(0)
            $elem.children('div').children('input').eq(0).removeClass('input_error')
          }

          if (flag === 'case_two') {
            $input = $elem.children('div').children('input').eq(0)
            $elem.children('div').children('input').eq(0).removeClass('input_error')

            let $sParent = $parent.parents('td, div').eq(0)
            let $sMsgBox = $sParent.find('.tooltip_essen_box')

            $sParent.find('.tooltip_essen').hide()
          }

          $elem.removeClass('input_error')
          $parent.find('.tooltip_essen').hide()
    }
  }
})
</script>
<style scoped>
.popup_dim .layer_close {z-index: 2998 !important}
.popup_dim2 {z-index: 2999 !important}
</style>
