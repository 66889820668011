//import axios from '@/api/axios-ekmtc'

//const Send = axios.createInstance()
import Send from '../common.client.js'

export default {
  captchaImg (formData) {
    return Send({
      url: '/common/captcha/captchaImg',
      method: 'get',
      params: {
        rand: formData.rand
      }
    })
  },
  captchaAudio (formData) {
    return Send({
      url: '/common/captcha/captchaImg',
      method: 'get',
      params: {
        rand: formData.rand
      }
    })
  },
  chkAnswer (data) {
    return Send({
      url: '/common/captcha/chkAnswer',
      method: 'post',
      withCredentials: true,
      data: { answer: data }
    })
  }
}
