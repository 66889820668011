<template>
  <e-auto-complete class="auto-input"
                   v-model="text"
                   :values="dynamicItems"
                   @update="daynamicSearchCstEnm"
                   :read-only="readOnly"
                   @input="inputEvt"
  >
  </e-auto-complete>
</template>

<script>
import apiCommons from '@/api/services/commons'
import EAutoComplete from '@/components/common/EAutoComplete'

export default {
  name: 'EAutoCompleteCstEnm',
  components: {
    EAutoComplete
  },
  props: {
    ctrCd: { type: String, default: '', required: false },
    cdNm: { type: String, default: '', required: false },
    readOnly: { type: Boolean, default: false, required: false },
    cstCatCd: { type: String, default: '30', required: false },
    type: { type: String, default: '', required: false },
    idx: { type: String, default: '', required: false }
  },
  data () {
    return {
      text: '',
      rtnVo: {},
      items: [],
      dynamicItems: []
    }
  },
  watch: {
    cdNm () {
      if (this.text !== this.$props.cdNm) {
        this.text = this.$props.cdNm
      }
    }
  },
  created () {
    if (this.text !== this.$props.cdNm) {
      this.text = this.$props.cdNm
    }
  },
  methods: {
    daynamicSearchCstEnm (e) {
      const THIS = this
      const keyword = e

      if (keyword === '' || keyword.length < 2) {
        this.items = []
        this.dynamicItems = []
        return
      }

      apiCommons.getCommonsCodesCustomer(keyword, this.ctrCd, this.cstCatCd).then((data) => {
        if (data.headers.respcode === 'C0000') {
          this.items = data.data
          if (this.items === undefined) {
            THIS.dynamicItems = []
          } else {
            THIS.dynamicItems = this.items.map(vo => vo.cdNm)
          }
        }
      })
    },
    inputEvt (val) {
      this.$emit('objData', { idx: this.idx, type: this.type })
      if (val !== '') {
        val = val.toUpperCase()
      }

      if (this.items === undefined || this.items === null || this.items.length === 0) {
        this.rtnVo = {
          cd: '',
          cdNm: val
        }
        this.$emit('change', this.rtnVo)
        return
      }

      const tvo = this.items.find(vo => vo.cdNm === val)

      if (tvo !== undefined) {
        this.rtnVo = tvo
      } else {
        this.rtnVo = {
          cd: '',
          cdNm: val
        }
      }
      this.$emit('change', this.rtnVo)
    }
  }
}

</script>
