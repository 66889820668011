<template>
  <div class="content widfix">
    <e-breadcrumbs />

    <h1 class="page_title">{{ $t('msg.MY_INFO.000') }}</h1>

    <div id="layerPop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePoup"
          :expirelist="exMileageList"
          :totmileamt="mTotalAmt"
          :sum-ex-mile="sumExMileage"
        />
      </win-layer-pop>
    </div>
    <div class="content_box">
      <table class="tbl_search">
        <colgroup>
          <col width="90px"><col><col width="90px">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.ADMN040G010.019') }}</th> <!-- 기간 -->
            <td colspan="2">
              <e-date-range-picker :date-from-to="dateFromTo" @change="changeDateRange" />
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t('msg.ADMN040G010.015') }}</th> <!-- 구분 -->
            <td>
              <CheckBox @checkClick="checkedKind"></CheckBox>
            </td>
            <td>
              <a class="button blue sh" @click.prevent="searchMileageList()">
                <!-- 검색 -->
                {{ $t('msg.ONEX010T010.010') }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="content_box mt10">
      <p class="mile_desc">
        {{ $t('msg.MYIN030T010.000') }} : <span>
          {{ mTotalAmt | comma }}
        </span> {{ $t('msg.MYIN030T010.056') }}
        <a id="ex" class="sm ml10" @click="openPopup('expire')">
          ({{ $t('msg.MYIN030T010.001') }} : <span>{{ sumExMileage | comma }}</span>)
        </a>
      </p>
      <div class="desc_list d_inline_block">
        <ul>
          <li>{{ $t('msg.MYIN030T010.057') }}({{ $t('msg.MYIN030T010.002') }})</li> <!-- 일만 단위로 포인트 전환 가능 -->
        </ul>
      </div>
      <a class="button sm blue ml10" @click="openPopup('switch')">{{ $t('msg.MYIN030T010.003') }}</a> <!-- 포인트 전환 -->
      <div class="line_box">
        <div class="flex_between">
          <div>
            <span class="tot_num">Total : <span class="num">{{ mileageList.length }} </span></span>
          </div>
          <div class="mile_info">
            <span>{{ $t('msg.MYIN030T010.004') }} : <strong class="color_blue">{{ numberFormat(savingMamt) }}</strong></span> <!-- 적립 -->
            <span>{{ $t('msg.MYIN030T010.005') }} : <strong class="color_red">{{ numberFormat(allDeducAmt) }}</strong></span> <!-- 차감 -->
            <span>{{ $t('msg.MYIN030T010.006') }} : <strong class="color_red">{{ numberFormat(refundMamt) }}</strong></span> <!-- 전환 마일리지-->
          </div>
        </div>
      </div>
      <div :style="headerFooterCss">
        <table class="tbl_col">
          <colgroup>
            <col style="width:14%" />
            <col style="width:10%" />
            <col style="width:auto" />
            <col style="width:16%" />
            <col style="width:14%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">{{ $t('msg.MYIN030T010.058') }}</th>
              <th scope="col">{{ $t('msg.MYIN030T010.059') }}</th>
              <th scope="col">{{ $t('msg.MYIN030T010.060') }}</th>
              <th scope="col">{{ $t('msg.MYIN030T010.061') }}</th>
              <th scope="col">{{ $t('msg.MYIN030T010.030') }}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="mlg-body-table-div">
        <table class="tbl_col">
          <colgroup>
            <col style="width:14%" />
            <col style="width:10%" />
            <col style="width:auto" />
            <col style="width:16%" />
            <col style="width:14%" />
          </colgroup>
          <tbody>
            <tr v-for="(m, i) of mileageList" :key="'m-row-' + i">
              <td scope="col">{{ m.occrDt.substr(0,4) }}.{{ m.occrDt.substr(4,2) }}.{{ m.occrDt.substr(6,2) }} </td>
              <td scope="col" :class="m.catCd == '01' ? 'color_blue' : 'color_red'">{{ getCstCdText(m.catCd) }}</td>
              <td scope="col">
                <div>{{ m.content.top }}</div>
                <div>{{ m.content.bottom }}</div>
              </td>
              <td scope="col" :class="m.catCd == '01' ? 'color_blue' : 'color_red'">{{ numberFormat(m.mlgAmt) }}</td>
              <td scope="col">{{ numberFormat(m.mileageamt) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="guide_txt_wrap">
        <div class="tit">[{{ $t('msg.MYIN030T010.050') }}]</div>
        <div class="guide_txt">
          <ul>
            <li>{{ $t('msg.MYIN030T010.051') }}</li>
            <li>{{ $t('msg.MYIN030T010.052') }}</li>
            <!-- &lt;!&ndash; <li>Hot Deal Promotion에 해당하는 선박에 선적 시 1.5배 적립</li> &ndash;&gt; -->
            <li>{{ $t('msg.MYIN030T010.054') }}</li>
            <li>{{ $t('msg.MYIN030T010.008') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- &lt;!&ndash; content // &ndash;&gt; -->
</template>

<script>
import Mileage from '@/api/rest/user/mileage'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'

let layout = [
    'occrDt',
    'gubun',
    'content',
    'mlgAmt',
    'mlgRemainAmt'
    ]

export default {
  name: 'Mileage',
  components: {
    EBreadcrumbs,
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    CheckBox: () => import('@/pages/user/MileageCheckBox'),
    mileagePoint: () => import('@/pages/user/popup/MileagePoint'),
    expireMileage: () => import('@/pages/user/popup/ExpireMileage'),
    EDateRangePicker: () => import('@/pages/user/MileageDateRangePicker')
  },
  filters: {
    comma (val) {
      var num = Number(val)
      return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  data: function () {
    return {
      mileage: 'mileage',
      dateFrom: '',
      dateTo: '',
      customComponent: null,
      isSearch: '',
      isInit: false,
      sumExMileage: 0,

      dateFromTo: {
        dateFrom: '',
        dateTo: ''
      },
      param: {
        userId: '',
        strDt: '',
        endDt: '',
        catCd: '',
        cstNo: '',
        pageNo: 1, // 현재 페이지
        pageSize: 5 // 한 페이지당 게시글 수
      },

      mileageList: [],
      memeberList: [],
      mTotalAmt: '0',
      childCheckValue: ['01', '02', '03', '04'],
      validated: '1',
      radioValues: '',
      totalNum: '',
      listAmt: '',
      calAmtList: [],
      savingMamt: 0,
      deducMamt: '',
      refundMamt: 0,
      deductMamt: '',
      allDeducAmt: 0,

      // props 데이터
      exMileageList: [],

      headerFooterCss: {}

    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.setDate()
    this.searchExpireMileageList()
    this.searchMileageList()
  },
  mounted () {},
  methods: {
    //다국어 적용
    closeFun () {
      this.$Simplert.close()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.MYIN030T010.007'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    setDate (value) {
      console.log('initDate')
      var date = new Date()
      var startDate = new Date(date.getFullYear(), date.getMonth() + 1, Number(date.getDate()))
      var endDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())
      var month = ''
      var day = ''

      if (String(endDate.getMonth()) === '0') {
        month = '12'
      } else {
        if (String(startDate.getMonth()).length === 1) {
          month = '0' + String(startDate.getMonth())
        } else {
          month = String(startDate.getMonth())
        }
      }

      if (String(startDate.getDate()).length === 1) {
        day = '0' + String(startDate.getDate())
      } else {
        day = String(startDate.getDate())
      }

      if (month === '01' && day < 15) {
        this.dateFromTo.dateFrom = date.getFullYear() - 1 + '.' + month + '.' + day
      } else {
        this.dateFromTo.dateFrom = date.getFullYear() - 1 + '.' + month + '.' + day
      }

      if (String(endDate.getMonth()) === '0') {
        month = '12'
      } else {
        if (String(endDate.getMonth()).length === 1) {
          month = '0' + String(endDate.getMonth())
        } else {
          month = String(endDate.getMonth())
        }
      }

      if (String(endDate.getDate()).length === 1) {
        day = '0' + String(endDate.getDate())
      } else {
        day = String(endDate.getDate())
      }

      this.dateFromTo.dateTo = date.getFullYear() + '.' + month + '.' + day
    },
    clickDate (event) {
      this.setDate(event.target.value)
    },
    checkedKind (checkedVal) {
      if (checkedVal.checkAll === undefined) {
        this.childCheckValue = []
        this.childCheckValue = checkedVal
      } else {
        this.childCheckValue = checkedVal.checkedArr
      }
    },
    openPopup (pname) {
      if (pname === 'switch') {
        this.customComponent = 'mileagePoint'
      } else if (pname === 'expire') {
        this.customComponent = 'expireMileage'
        this.searchExpireMileageList()
      }
      // 팝업 후처리
      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePoup () {
      this.customComponent = null

      $('#layerPop > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')

      // 데이터 재조회
      this.searchMileageList('popCloseResearch')
    },
    changeDateRange (s, d) {
      this.dateFromTo.dateFrom = s
      this.dateFromTo.dateTo = d
    },
    searchMileageList (msg) {
      if (msg === 'popCloseResearch') {
        this.childCheckValue = ['01', '02', '03', '04']
      }

      if (this.childCheckValue.length < 1) {
        this.openAlert(this.$t('msg.MYIN030T010.012'))
      } else {
      // 날짜 파라미터
      if (this.dateFromTo.dateFrom !== '' && this.dateFromTo.dateTo !== '') {
        var from = this.dateFromTo.dateFrom.split('.').join('')
        var to = this.dateFromTo.dateTo.split('.').join('')

        this.param.strDt = from
        this.param.endDt = to
      }

      // 체크박스 파라미터
      for (let i = 0; i < this.childCheckValue.length; i++) {
          if (this.childCheckValue.length === 1) {
            this.param.catCd = this.childCheckValue[i]
          } else {
            this.param.catCd += this.childCheckValue[i] + ','
          }
      }

      // 조회 전 데이터 초기화
      this.mileageList = []
      this.totalNum = ''
      this.savingMamt = 0
      this.allDeducAmt = 0
      this.refundMamt = 0

      // 세션데이터 세팅
      this.param.cstNo = this.memberDetail.cstCd
      this.param.userId = this.auth.userId

      Mileage.getMileageList(this.param)
        .then(response => {
          this.mUserName = response.data.member.userName
          this.mNumber = response.data.member.tel1.concat('-', response.data.member.tel2).concat('-', response.data.member.tel3).concat('-', response.data.member.tel4)
          this.mileageList = this.parseMileageList(response.data.mileageList)

          if (this.mileageList.length > 7) {
            this.headerFooterCss = {
                paddingRight: '3px'
            }
          } else {
            this.headerFooterCss = {
                paddingRight: '0px'
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
        this.param.catCd = ''
      }
    },
    parseMileageList (list) {
      console.log('-----------------------')
      console.log(list)
      console.log('-----------------------')
      let mList = []
      for (let m of list) {
        let mlgAmt = this.calcMileageAmt(m)
        let obj = {
          occrDt: m.occrDt,
          catCd: m.catCd,
          content: this.makeContent(m),
          mlgAmt: mlgAmt,
          mileageamt: m.mileageamt
        }
        mList.push(obj)
      }
      return mList
    },
    getCstCdText (catCd) {
      if (catCd === '01') {
        return this.$t('msg.MYIN030T010.013')
      } else if (catCd === '02' || catCd === '04') {
        return this.$t('msg.MYIN030T010.014')
      } else if (catCd === '03') {
        return this.$t('msg.MYIN030T010.015')
      } else {
        return ''
      }
    },
    calcMileageAmt (m) {
      let mlgAmt = m.mlgAmt
      if (m.catCd === '01') { //적립
        this.savingMamt += Number(mlgAmt)
      } else {
        mlgAmt = mlgAmt * -1
        if ((m.catCd === '02' && m.stsCd === '02') || m.catCd === '04') { //차감
          this.allDeducAmt += Number(mlgAmt)
        } else if (m.catCd === '03' && m.stsCd === '03') { //전환요청 전체
          this.refundMamt += Number(mlgAmt)
        }
      }
      return mlgAmt
    },
    makeContent (m) {
      let content = {
        top: '',
        bottom: ''
      }
      if (m.catCd === '01') {
        content = {
          top: m.blNo,
          bottom: '(' + m.polPortCd + '-' + m.podPortCd + '/ TEU : ' + m.teu + ')'
        }
      } else if ((m.catCd === '02' && m.stsCd === '02') || m.catCd === '04') {
        content = {
          top: this.$t('msg.MYIN030T010.016'),
          bottom: ''
        }
      } else if (m.catCd === '03' && m.stsCd !== '02') {
        content = {
          top: this.mUserName,
          bottom: '(' + this.mNumber + ')'
        }
      }
      return content
    },
    numberFormat (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    searchExpireMileageList () {
      this.exMileageList = []

      Mileage.getMileageRemain()
        .then(response => {
          const resp = response.data
          const mlist = resp.mileageList

          if (mlist !== undefined) {
            this.exMileageList = mlist

            // 3개월 이내 소멸 예정 마일리지 총합
            if (this.sumExMileage === 0) {
              for (var i = 0; i < this.exMileageList.length; i++) {
                this.sumExMileage += Number(this.exMileageList[i].mlgAmt)
              }
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
        // console.log('searchData expire mileagelist')
    }
  }
  }
</script>

<style scoped>
a#ex:hover {
  text-decoration: underline;
}

ul.mt10{
  margin-top: 0px !important;
}

span.form_tit {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mlg-body-table-div {
  overflow: auto;
  max-height: 358px;
}

.mlg-body-table-div::-webkit-scrollbar {
  width: 3px;
  background: #fff;
}
.mlg-body-table-div::-webkit-scrollbar-thumb {
  background: #999;
}

.mlg-body-table-div > table > tbody > tr > td {
  height: 51px;
}
</style>>
