<template><!-- 설정 >> 스케쥴구독 -->
  <div id="tab2" class="tab_content_area">
    <div id="myinfo-sch-subs-pop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="parentInfo"
        />
      </win-layer-pop>
    </div>
    <div class="content_box beforetab">
      <div class="flex_box">
        <span class="tot_num">Total : <span class="num">{{ schSubsList.length }}</span></span>
        <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span>  <!-- 엑셀 다운로드 -->
      </div>
      <div class="">
        <table class="tbl_col">
          <colgroup>
            <col style="width:5%">
            <col style="width:15%">
            <col style="width:15%">
            <col style="width:15%">
            <col style="width:15%">
            <col style="width:15%">
            <col style="width:auto">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" class="pr0">
                <input id="All" type="checkbox" name="no" v-model="isCheckAll" @click="checkAll()"><label for="All"><span class="offscreen">All</span></label>
              </th>
              <th scope="col">
                {{ $t('msg.MYIN050T040.001') }}
              </th>
              <th scope="col">
                {{ $t('msg.MYIN050T040.002') }}
              </th>
              <th scope="col">
                {{ $t('msg.MYIN050T040.003') }}
              </th>
              <th scope="col">
                {{ $t('msg.MYIN050T040.004') }}
              </th>
              <th scope="col">
                {{ $t('msg.MYIN050T040.005') }}
              </th>
              <th scope="col">
                {{ $t('msg.MYIN050T040.012') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in schSubsList" :key="'schSubs-' + i">
              <td class="pr0">
                <input :id="'chk-' + i" type="checkbox" v-model="s.isChecked">
                <label :for="'chk-' + i"><span class="offscreen">체크</span></label>
              </td>
              <td>{{ s.polPlcEngNm }}</td>
              <td>{{ s.podPlcEngNm }}</td>
              <td>{{ s.cdNmA }}</td>
              <td>{{ s.cdNmB }}</td>
              <td>{{ parsePeriod(s.period) }}</td>
              <td>{{ s.recptEmlAddr }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex_between">
      <div>
        <a class="button lg" href="javascript:void(0)" @click="deleteAlert()">{{ $t('msg.MYIN050T040.006') }}</a>
      </div>
      <div>
        <a class="button blue lg" href="javascript:void(0)" @click="openPopup('EmailSchedulePop')">{{ $t('msg.MYIN050T040.011') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileApi from '@/api/rest/user/profile'
import EmailSchedulePop from '@/pages/schedule/popup/EmailSchedulePop'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import XLSX from 'xlsx'

export default {
  name: 'Schedule',
  components: {
    EmailSchedulePop,
    WinLayerPop
  },
  props: [
    'memberDetail',
    'auth'
  ],
  data: function () {
  return {
      customComponent: null,
      schSubsList: [],
      isCheckAll: false,
      checkCnt: 0
    }
  },
  watch: {
    schSubsList: {
      deep: true,
      handler () {
        this.checkSingle()
      }
    }
  },
  created () {
   this.getSchSubsList()
  },
  methods: {
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }

      this.parentInfo = {
        // polCtrCd: 'KR',
        // polPlcCd: 'INC',
        // polPlcNm: 'Incheon',

        // podCtrCd: 'HK',
        // podPlcCd: 'HKG',
        // podPlcNm: 'Hong Kong',

        polCtrCd: '',
        polPlcCd: '',
        polPlcNm: '',

        podCtrCd: '',
        podPlcCd: '',
        podPlcNm: '',

        eiCatCd: 'O'
      }
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('#myinfo-sch-subs-pop > .popup_dim')
    },
    closePopup (obj) {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#myinfo-sch-subs-pop > .popup_dim')
      this.getSchSubsList()
    },
    getSchSubsList () {
      ProfileApi.getSchSubsList(this.auth.userId).then(response => {
        this.checkCnt = 0
        this.isCheckAll = false
        this.schSubsList = this.parseSchSubs(response.data)

        this.schSubsList.forEach(element => {
          switch (element.cdNmB) {
            case '일':
              element.cdNmB = this.$t('comp.002')
              break
            case '월':
              element.cdNmB = this.$t('comp.003')
              break
            case '화':
              element.cdNmB = this.$t('comp.004')
              break
            case '수':
              element.cdNmB = this.$t('comp.005')
              break
            case '목':
              element.cdNmB = this.$t('comp.006')
              break
            case '금':
              element.cdNmB = this.$t('comp.007')
              break
            case '토':
              element.cdNmB = this.$t('comp.008')
              break
          }
        })
      }).catch(e => {
        console.log(e)
      })
    },
    deleteAlert () {
        if (this.checkCnt === 0) {
          let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$i18n.t('msg.MYIN050T040.009'),
            useIcon: false,
            customClass: 'simple_alert',
            message: this.$i18n.t('msg.MYIN050T040.007'),
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
        } else {
          let obj = {
            onConfirm: this.deleteSchSubsList,
            customCloseBtnClass: 'button blue lg',
            customCloseBtnText: this.$i18n.t('msg.MYIN050T040.010'),
            customConfirmBtnText: this.$i18n.t('msg.MYIN050T040.009'),
            useConfirmBtn: true,
            customConfirmBtnClass: 'button blue lg',
            message: this.$i18n.t('msg.MYIN050T040.008'),
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
        }
    },
    deleteSchSubsList () {
      let data = {
        schSubsList: []
      }
      for (let schSubs of this.schSubsList) {
        if (schSubs.isChecked) {
          data.schSubsList.push(schSubs)
        }
      }
      ProfileApi.deleteSchSubsList(data).then(response => {
        this.getSchSubsList()
      }).catch(e => {
        console.log(e)
      })
    },
    parseSchSubs (schSubsList) {
      for (let schSubs of schSubsList) {
        schSubs.isChecked = false
      }
      return schSubsList
    },
    parsePeriod (period) {
      let res = ''
      switch (period) {
        case 'msg.VOSD100.026':
          res = '1Week'
          break
        case 'msg.VOSD100.027':
          res = '2Week'
          break
        case 'msg.VOSD100.029':
          res = '1Month'
          break
      }
      return res
    },
    checkSingle () {
      let checkCnt = 0
      for (let schSubs of this.schSubsList) {
        if (schSubs.isChecked) {
          checkCnt++
        }
      }

      if (checkCnt === this.schSubsList.length && checkCnt > 0) {
        this.isCheckAll = true
      } else {
        this.isCheckAll = false
      }
      this.checkCnt = checkCnt
    },
    checkAll () {
      this.isCheckAll = !this.isCheckAll
      for (let i = 0; i < this.schSubsList.length; i++) {
        this.schSubsList[i].isChecked = this.isCheckAll
      }
      if (this.isCheckAll) {
        this.checkCnt = this.schSubsList.length
      } else {
        this.checkCnt = 0
      }
    },
    exlDownload () {
      let excelData = []
      let tempautCatCd
      let tempcstGrpCatCd

      for (const items of this.schSubsList) {
        const excelLogs = {
          출발: items.polPlcEngNm,
          도착: items.podPlcEngNm,
          수출수입: items.cdNmA,
          전송일: items.cdNmB,
          기간: this.parsePeriod(items.period),
          이메일: items.recptEmlAddr
        }
        excelData.push(excelLogs)
      }

      const workSheet = XLSX.utils.json_to_sheet(excelData)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, '스케쥴_구독')
      XLSX.writeFile(workBook, '스케줄_구독.xlsx')
    }
  }
}
</script>
