import Send from '../../common.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //sendCard - 연하장 정송
  sendCard (data) {
    return Send({
      url: '/common/send-card',
      method: 'get',
      params: data
    })
  }
}
