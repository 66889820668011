<template>
  <div class="content resize">
    <!-- content : 화면 리사이징 되는 화면 resize 추가 -->
    <e-breadcrumbs />
    <form id="frm">
      <win-layer-pop class="intergis-cfs-detail-pop">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="popupParams"
        />
      </win-layer-pop>
      <h1 class="page_title">{{ $t('msg.CSCT060T070.001') }}</h1><!-- 인터지스 CFS 입고조회 -->
      <div class="content_box mt10">
        <!-- content_box -->
        <table class="tbl_search">
          <colgroup>
            <col width="110px">
            <col width="150px">
            <col>
            <col width="100px">
            <col width="150px">
            <col>
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSCT060T070.002') }}</th><!-- 입고일 -->
              <td class="pl0" colspan="5">
                <e-date-range-picker
                  id="whsg_dt"
                  :sdate="params.fromDt"
                  :edate="params.toDt"
                  :is-debug="true"
                  @change="changeDateRange"
                />
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSCT060T070.003') }}</th><!-- 도착지 -->
              <td colspan="2">
                <div class="tbl_form">
                  <e-auto-complete-place
                    @change="changePod"
                    :ctr-cd="params.podCtrCd"
                    :plc-cd="params.podPortCd"
                  />
                </div>
              </td>

              <th>{{ $t('msg.CSCT060T070.004') }}</th><!-- Status -->
              <td colspan="2">
                <div class="tbl_form">
                  <span class="dv col_5">
                    <select v-model="params.stsCd">
                      <option value="">{{ $t('msg.CSCT060T070.005') }}</option><!-- 전체 -->
                      <option value="01">{{ $t('msg.CSCT060T070.006') }}</option><!-- 입고 -->
                      <option value="02">{{ $t('msg.CSCT060T070.007') }}</option><!-- 검정 -->
                    </select>
                  </span>
                  <span class="dv">

                  </span>
                </div>
              </td>
              <td class="text_right">
              </td>
            </tr>

            <tr>
              <th>{{ $t('msg.CSCT060T070.008') }}</th><!-- 입고번호 -->
              <td colspan="2">
                <input
                  v-model="params.whsgNo"
                  type="text"
                >
              </td>
              <th>{{ $t('msg.CSCT060T070.009') }}</th><!-- 화주명 -->
              <td colspan="2">
                <e-auto-complete-cst-enm
                  @change="changeAct"
                  :ctr-cd="params.cstCd"
                  :cd-nm="params.cstNm"
                />
              </td>
              <td class="text_right">
              </td>
            </tr>

            <tr>
              <th>{{ $t('msg.CSCT060T070.010') }}</th><!-- Container No. -->
              <td colspan="2">
                <input
                  v-model="params.cntrNo"
                  type="text"
                >
              </td>
              <th>{{ $t('msg.CSCT060T070.011') }}</th><!-- Booking No. -->
              <td colspan="2">
                <input
                  v-model="params.bkgNo"
                  type="text"
                >
              </td>
              <td class="text_right">
                <a
                  class="button blue sh"
                  href="#"
                  @click.prevent="searchCFSInter(1, true)"
                >{{ $t('msg.CSCT060T070.012') }}</a><!-- 검색 -->
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->

      <div class="content_box mt10">
        <!-- content_box -->
        <div class="flex_box">
          <span class="tot_num">{{ $t('msg.CSCT060T070.013') }} : <span class="num">{{ listCountNumber }}</span></span><!-- Total -->
          <span
            v-if="sessionIntergis == '1' || sessionIntergis == '3'"
            class="ml_auto cal_btn"
          >
            <select
              v-model="params.updFlag"
              class="wid160"
            >
              <option value="CN">{{ $t('msg.CSCT060T070.014') }}</option><!-- Container No./SEAL No -->
              <option value="BK">{{ $t('msg.CSCT060T070.015') }}</option>
            </select>
            <input
              v-show="params.updFlag === 'CN'"
              v-model="params.cntrNoUpd"
              type="text"
              class="wid120"
            >
            <input
              v-show="params.updFlag === 'CN'"
              v-model="params.sealNo1Upd"
              type="text"
              class="wid120"
            >
            <input
              v-show="params.updFlag === 'BK'"
              v-model="params.bkgNoUpd"
              type="text"
              class="wid120"
            >
            <a
              class="button sm ml2"
              href="#"
              @click.prevent="updateSelected()"
            >{{ $t('msg.CSCT060T070.016') }}</a><!-- 수정 -->
          </span>
        </div>

        <table class="tbl_list font_sm gridtype">
          <colgroup>
            <col width="60px">
            <col width="6%">
            <col width="15%">
            <col width="13%">
            <col width="26%">
            <col width="8%">

            <col width="8%">
            <col width="12%">
            <col width="12%">
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2">
                <input
                  v-model="checkAll"
                  type="checkbox"
                  name="no"
                  id="All"
                >
                <label for="All"><span class="offscreen">All</span></label>
              </th>
              <th rowspan="2">{{ $t('msg.CSCT060T070.017') }}</th><!-- No -->
              <th>{{ $t('msg.CSCT060T070.018') }}</th><!-- 입고번호 -->
              <th>{{ $t('msg.CSCT060T070.019') }}</th><!-- 도착지 -->
              <th rowspan="2">{{ $t('msg.CSCT060T070.020') }}</th><!-- 화주명 -->
              <th>{{ $t('msg.CSCT060T070.021') }}</th><!-- 수량 -->
              <th>{{ $t('msg.CSCT060T070.022') }}</th><!-- CBM -->
              <th>{{ $t('msg.CSCT060T070.023') }}</th><!-- 컨테이너 번호 -->
              <th>{{ $t('msg.CSCT060T070.024') }}</th><!-- Booking No. -->
            </tr>
            <tr>
              <th class="border_left">{{ $t('msg.CSCT060T070.025') }}</th><!-- 입고시각 -->
              <th>{{ $t('msg.CSCT060T070.026') }}</th><!-- Status -->
              <th>{{ $t('msg.CSCT060T070.027') }}</th><!-- 중량 -->
              <th>{{ $t('msg.CSCT060T070.028') }}</th><!-- 총용적 -->
              <th>{{ $t('msg.CSCT060T070.029') }}</th><!-- Seal 번호 -->
              <th>{{ $t('msg.CSCT060T070.030') }}</th><!-- 비고 -->
            </tr>
          </thead>
          <tbody v-if="intergisCfsList.length > 0">
            <template
              v-for="item in intergisCfsList"
            >
              <tr :key="item.reqRno + item.whsgNo">
                <td rowspan="2">
                  <input
                    v-model="updateList"
                    type="checkbox"
                    name="chk"
                    :id="'chk_' + item.num"
                    :value="item.reqRno"
                  ><label :for="'chk_'+ item.num"><span class="offscreen">No</span></label>
                </td>
                <td rowspan="2">{{ item.num }}</td>
                <td>
                  <a
                    href="#"
                    @click.prevent="fnCfsRequest(item.reqRno, 'UPD')"
                  >{{ item.whsgNo }}</a>
                </td>
                <td>{{ item.podPortNm }}</td>
                <td rowspan="2">{{ item.cstNm }}</td>
                <td :style="{ color: [ $ekmtcCommon.isNotEmpty(item.qtyDtl) && item.qtyDtl !== item.qty ? 'red' : '' ] }">
                  {{ item.qty }}
                </td>
                <td>{{ item.grsCbm }}</td>
                <td>{{ item.cntrNo }}</td>
                <td>{{ item.bkgNo }}</td>
              </tr>
              <tr :key="item.reqRno + item.whsgDtm">
                <td class="border_left">{{ $ekmtcCommon.changeDatePattern(item.whsgDtm, '-') }}</td>
                <td>
                  <span
                    v-if="$ekmtcCommon.isNotEmpty(item.stsCd)"
                    :class="['label', item.stsCd === '입고' ? 'green' : 'red', 'md', 'minwid60']"
                  >{{ item.stsCd }}</span>
                </td>
                <td>{{ item.wt }}</td>
                <td>{{ item.capy }}</td>
                <td>{{ item.sealNo1 }}</td>
                <td>{{ item.rmk }}</td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">{{ $t('msg.CSCT060T070.031') }}</td><!-- 등록된 내용이 없습니다. -->
            </tr>
          </tbody>
        </table>

        <div
          v-if="intergisCfsList.length > 0"
          class="paging"
        >
          <!-- paging -->
          <a
            href="#"
            class="btn start"
            @click.prevent="searchCFSInter(1, false)"
          >Start</a>
          <a
            href="#"
            class="btn prev"
            @click.prevent="searchCFSInter(pagination.prevPage, false)"
          >Prev</a>
          <a
            v-for="idx in pageNumberRange"
            :key="idx"
            :class="[idx === params.curPage ? 'on' : '']"
            href="#"
            @click.prevent="searchCFSInter(idx, false)"
          >{{ idx }}</a>
          <a
            href="#"
            class="btn next"
            @click.prevent="searchCFSInter(pagination.nextPage, false)"
          >Next</a>
          <a
            href="#"
            class="btn end"
            @click.prevent="searchCFSInter(pagination.pageCnt, false)"
          >End</a>
        </div>
      </div><!-- content_box // -->

      <div class="flex_box mt10">
        <a
          class="button lg"
          href="#"
          @click.prevent="excelDownload('CLP_Document.xls')"
        >{{ $t('msg.CSCT060T070.032') }}</a><!-- CLP form Download -->
        <a
          v-if="sessionIntergis == '1'"
          class="button blue lg ml_auto"
          href="#"
          @click.prevent="fnCfsRequest('', 'REG')"
        >{{ $t('msg.CSCT060T070.033') }}</a><!-- 등록 -->
      </div>
    </form>
  </div><!-- content // -->
</template>

<script>

import { rootComputed } from '@/store/helpers'

import intergis from '@/api/rest/common/intergis'

// import moment from 'moment'

import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'IntergisCfs',
  components: {
    EBreadcrumbs,
    'e-date-range-picker': () => import('@/components/common/EDateRangePicker'),
    'e-auto-complete-place': () => import('@/components/common/EAutoCompletePlace'),
    'e-auto-complete-cst-enm': () => import('@/components/common/EAutoCompleteCstEnmV2'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'intergis-cfs-detail-pop': () => import('@/pages/common/popup/IntergisCfsDetailPop')
  },
  data: function () {
    return {
      // 인터지스 권한 코드
      sessionIntergis: '',
      // 인터지스 CFS 입고 등록 팝업 컴포넌트
      customComponent: null,
      // 최초 API(팝업관리 리스트) 호출여부
      isInit: false,

      // 검색조건
      params: {
        // 현재 페이지
        curPage: 1,
        // 한 페이지당 게시글 수
        pageSize: 10,

        // 입고일
        fromDt: '',
        toDt: '',
        // test
        // fromDt: '2020.01.01',
        // toDt: '2020.01.31',
        // 도착지
        podCtrCd: '',
        podPortCd: '',
        // Status
        stsCd: '',
        // 입고번호
        whsgNo: '',
        // 화주
        cstCd: '',
        cstNm: '',
        // Container No.
        cntrNo: '',
        // Booking No.
        bkgNo: '',

        // 일괄수정시 필요한 파라미터
        updFlag: 'CN',
        reqRno: '',
        cntrNoUpd: '',
        sealNo1Upd: '',
        bkgNoUpd: ''

      },
      // 날짜 선택 기준
      dateFlag: '',
      // Total(전체 글 개수)
      listCountNumber: 0,
      // 조회 리스트
      intergisCfsList: [],
      // 페이징 모듈
      pagination: {},
      // 선택한 LIST(일괄 수정)
      updateList: [],

      // 등록/수정 팝업에 필요한 파라미터
      popupParams: {}
    }
  },
  computed: {
    ...rootComputed,
    pageNumberRange: function () {
      return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    },
    // 전체 선택
    checkAll: {
      // getter
      get: function () {
        if ((this.updateList.length !== this.intergisCfsList.length) || this.intergisCfsList.length === 0) {
          return false
        } else {
          return true
        }
      },
      // setter
      set: function (e) {
        if (e) {
          this.intergisCfsList.forEach(element => {
            this.updateList.push(element.reqRno)
          })
        } else {
          this.updateList = []
        }
      }
    }
  },
  created () {
    // 기본 검색 조건 셋팅
    const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: '', endGap: 0 })
    this.params.fromDt = arrMonthInfo.stDt
    this.params.toDt = arrMonthInfo.enDt

    if (this.auth !== undefined) {
      this.params.cstCd = this.auth.cstCd
    }
    if (this.memberDetail !== undefined) {
      this.sessionIntergis = this.memberDetail.intergisLv
    }

    // test
    // this.sessionIntergis = '1'
    // this.sessionIntergis = '2'
    // this.sessionIntergis = '3'

    // this.searchCFSInter(1, true)
  },
  methods: {
    // '도착지' 자동완성 change event
    changePod (vo) {
      this.params.podCtrCd = vo.ctrCd
      this.params.podPortCd = vo.plcCd
    },
    // '화주명' 자동완성 change event
    changeAct (vo) {
      this.params.cstNm = vo.cdNm
    },
    changeDateRange (sdt, edt) {
      this.params.fromDt = sdt
      this.params.toDt = edt
      this.fnValidationWhsgDt()
    },
    /**
     * param
     * |_ index : 이동할 페이지
     * |_ searcgFlag : 검색유무
     *          true  => 검색 시
     *          false => 페이지 이동
     */
    searchCFSInter (index, searchFlag) {
      if (this.fnValidationWhsgDt()) {
        if (this.isInit && index < 1) {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.002')) // 처음페이지입니다.
          return
        }
        if (this.isInit && this.pagination && index > this.pagination.pageCnt) {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.001')) // 마지막페이지입니다.
          return
        }

        // !searchFlag(페이지 이동 시) 현재 페이지와 이동할 페이지 같다면
        if (!searchFlag && this.isInit && this.params.curPage === index) return

        this.params.curPage = index

        const formData = JSON.parse(JSON.stringify(this.params))

        if (formData.fromDt !== '') {
          formData.fromDt = formData.fromDt.replaceAll('.', '')
        }
        if (formData.toDt !== '') {
          formData.toDt = formData.toDt.replaceAll('.', '')
        }

        intergis.searchCFSInter(formData)
          .then((response) => {
            const resp = response.data

            const list = resp.cfsInfoList
            const pagination = resp.pagination

            this.intergisCfsList = []
            this.listCountNumber = 0

            if (list !== undefined && list.length > 0) {
              // api response 값 data에 세팅
              this.pagination = pagination

              //전체 글 개수
              this.listCountNumber = list[0].listCnt

              this.intergisCfsList = list

              if (!this.isInit) this.isInit = true
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    fnCfsRequest (reqRno, flag) {
      this.popupParams = {
        reqRno: reqRno,
        flag: flag
      }

      this.openPopup('intergis-cfs-detail-pop')
    },
    // 팝업 열기
    openPopup (cmpNm) {
      this.customComponent = cmpNm
      this.$ekmtcCommon.layerOpen('.intergis-cfs-detail-pop')
    },
    // 팝업 닫기
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.intergis-cfs-detail-pop')

      this.isInit = false
      this.searchCFSInter(1, true)
    },
    // 선택 수정
    updateSelected () {
      if (this.updateList.length === 0) return

      const msg = this.$t('msg.CSCT060T070.034') // 선택한 데이터를 수정하시겠습니까?

      this.$ekmtcCommon.confirmCallBack(msg, () => { this.onConfirmUpdateSelected() })
    },
    // 선택 수정 Alert => '확인'시 콜백 메소드
    onConfirmUpdateSelected () {
      let formData = {
        reqRno: this.updateList.join(',')
      }

      if (this.params.updFlag === 'CN') {
        formData = { ...formData, ...{ cntrNoUpd: this.params.cntrNoUpd, sealNo1Upd: this.params.sealNo1Upd } }
      } else {
        formData = { ...formData, ...{ bkgNoUpd: this.params.bkgNoUpd } }
      }

      intergis.updateCfsReq(formData)
        .then(response => {
          if (response.headers.respcode === 'C0000') {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060T070.035')) // 수정 성공
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060T070.036')) // 수정 실패
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.updateList = []
          if (this.checkAll) this.checkAll = false

          this.params.cntrNoUpd = ''
          this.params.sealNo1Upd = ''
          this.params.bkgNoUpd = ''

          this.isInit = false
          this.searchCFSInter(1)
        })
    },
    fnValidationWhsgDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elemWhsgDt = frm.querySelector('#whsg_dt')
      let isOk = true

      if (this.$ekmtcCommon.isEmpty(this.params.fromDt) || this.$ekmtcCommon.isEmpty(this.params.toDt)) {
        let msg = this.$t('msg.CSCT060T070.037') // 입고일자를 선택하세요.
        this.$ekmtcCommon.showErrorTooltip(elemWhsgDt, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemWhsgDt)
      }

      return isOk
    },
    //엑셀 템플릿 다운로드
    async excelDownload (fileName) {
      const result = await intergis.excelDownload(fileName)
      console.log('excelDownload')
      console.log(result)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    }
  }
}
</script>
