var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab_content_area" }, [
    _c("div", { staticClass: "content_box profile beforetab" }, [
      _c(
        "ul",
        { staticClass: "profile_list" },
        [
          _vm._l(_vm.profileList, function (p, i) {
            return [
              i < _vm.max
                ? _c(
                    "li",
                    {
                      key: "p-" + i,
                      class: _vm.getMyProfile(i) ? "my" : "",
                      staticStyle: { position: "relative" },
                      on: {
                        click: function ($event) {
                          return _vm.getProfile(i, null, null, null)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tooltip_wrap short position_absolute",
                          staticStyle: { display: "none", right: "-80px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "cont",
                              staticStyle: { padding: "0px" },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(_vm._s(p.preUserId) + " "),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(p.userCtrCd)),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          class:
                            _vm.selectedIdx === i
                              ? p.cls + " selected-profile"
                              : p.cls,
                          on: {
                            mouseover: _vm.showTooltipA,
                            mouseout: _vm.hideTooltipA,
                          },
                        },
                        [
                          !_vm.isEmpty(p.picPotoString)
                            ? _c("img", {
                                attrs: { src: p.picPotoString, alt: "" },
                              })
                            : _vm.isEmpty(p.picPotoString) &&
                              !_vm.isEmpty(p.fileNm) &&
                              _vm.getFilePath(p.fileNm) != null
                            ? _c("img", {
                                attrs: {
                                  src: _vm.getFilePath(p.fileNm),
                                  alt: "",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/images/pers/profile_noimg.png"),
                                  alt: "",
                                },
                              }),
                          p.useYn == "N"
                            ? _c("span", [_vm._v("NO USE")])
                            : _vm._e(),
                        ]
                      ),
                      _c("p", { staticClass: "name" }, [
                        _vm._v(
                          _vm._s(_vm.isEmpty(p.picAlsNm) ? p.picNm : p.picAlsNm)
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
          (_vm.profileList.length == 0 && _vm.isEkmtc) || !_vm.isEkmtc
            ? _c(
                "li",
                {
                  staticClass: "more",
                  attrs: { id: "more" },
                  on: {
                    click: function ($event) {
                      return _vm.resetProfile("ADD")
                    },
                  },
                },
                [_vm._m(0)]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.profileList.length > _vm.max
        ? _c("p", { staticClass: "profile_tot" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.collapseProfile("open")
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("msg.MYIN050T010.013")) + " ("),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("msg.MYIN050T010.014")) +
                      " " +
                      _vm._s(_vm.profileList.length) +
                      _vm._s(_vm.$t("msg.MYIN050T010.015"))
                  ),
                ]),
                _vm._v(")"),
              ]
            ),
            _c("span", { staticClass: "btn_icon icodown" }),
          ])
        : _vm.profileList.length == _vm.max
        ? _c("p", { staticClass: "profile_tot" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.collapseProfile("close")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN050T010.051")))]
            ),
            _c("span", { staticClass: "btn_icon icoup" }),
          ])
        : _vm._e(),
    ]),
    _vm.isSetProfile
      ? _c("div", { staticClass: "content_box mt10" }, [
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(1),
            _c("tbody", [
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEmpty(_vm.profile.userId),
                      expression: "!isEmpty(profile.userId)",
                    },
                  ],
                },
                [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.001"))),
                  ]),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("span", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.useYn,
                            expression: "profile.useYn",
                          },
                        ],
                        attrs: {
                          id: "use1",
                          type: "radio",
                          name: "use",
                          value: "Y",
                        },
                        domProps: {
                          checked: _vm.profile.useYn == "Y",
                          checked: _vm._q(_vm.profile.useYn, "Y"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.profile, "useYn", "Y")
                          },
                        },
                      }),
                      _vm._m(2),
                    ]),
                    _c("span", { staticClass: "ml30" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.useYn,
                            expression: "profile.useYn",
                          },
                        ],
                        attrs: {
                          id: "use2",
                          type: "radio",
                          name: "use",
                          value: "N",
                        },
                        domProps: {
                          checked: _vm.profile.useYn == "X",
                          checked: _vm._q(_vm.profile.useYn, "N"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.profile, "useYn", "N")
                          },
                        },
                      }),
                      _vm._m(3),
                    ]),
                  ]),
                ]
              ),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.003"))),
                ]),
                _c(
                  "td",
                  [
                    _vm.isEkmtc
                      ? [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.profile.picNm,
                                expression: "profile.picNm",
                              },
                            ],
                            ref: "inputPicNm",
                            attrs: {
                              type: "text",
                              maxlength: "50",
                              id: "picNm",
                            },
                            domProps: { value: _vm.profile.picNm },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.profile,
                                  "picNm",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      : [
                          _c("div", { staticStyle: { display: "flex" } }, [
                            _c(
                              "div",
                              { staticStyle: { width: "100%" } },
                              [
                                _c("e-auto-complete-pic-nm", {
                                  ref: "autoComplPicNm",
                                }),
                                _c("input", {
                                  attrs: { type: "hidden", id: "picNm" },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                  ],
                  2
                ),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.075"))),
                ]),
                _c(
                  "td",
                  [
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.picEnm,
                            expression: "profile.picEnm",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id: "picEnm",
                          oninput:
                            "this.value = this.value.replace(/[^a-zA-Z0-9 ]/gi, '');",
                          maxlength: "50",
                        },
                        domProps: { value: _vm.profile.picEnm },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.profile, "picEnm", $event.target.value)
                          },
                        },
                      }),
                    ],
                  ],
                  2
                ),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.005"))),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.profile.picBizCatCd,
                          expression: "profile.picBizCatCd",
                        },
                      ],
                      attrs: { id: "picBizCatCd" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.profile,
                            "picBizCatCd",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.chargeCodeList, function (code, i) {
                      return _c(
                        "option",
                        { key: "charge-" + i, domProps: { value: code.cd } },
                        [_vm._v(_vm._s(code.cdNm))]
                      )
                    }),
                    0
                  ),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.004"))),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.profile.eiCatCd,
                          expression: "profile.eiCatCd",
                        },
                      ],
                      attrs: { id: "eiCatCd" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.profile,
                            "eiCatCd",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.eiCatCdList, function (cd, i) {
                      return _c(
                        "option",
                        { key: "eiCatCd-" + i, domProps: { value: cd.value } },
                        [_vm._v(_vm._s(cd.name))]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.007"))),
                ]),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.faxPlcNo,
                            expression: "profile.faxPlcNo",
                          },
                        ],
                        class: _vm.faxCls,
                        attrs: {
                          type: "text",
                          maxlength: "3",
                          oninput:
                            "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                        },
                        domProps: { value: _vm.profile.faxPlcNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.profile,
                              "faxPlcNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dv col_3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.faxOfcNo,
                            expression: "profile.faxOfcNo",
                          },
                        ],
                        class: _vm.faxCls,
                        attrs: {
                          type: "text",
                          maxlength: "12",
                          oninput:
                            "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                        },
                        domProps: { value: _vm.profile.faxOfcNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.profile,
                              "faxOfcNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("input", { attrs: { type: "hidden", id: "fax" } }),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.006"))),
                ]),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.telPlcNo,
                            expression: "profile.telPlcNo",
                          },
                        ],
                        class: _vm.telCls,
                        attrs: {
                          type: "text",
                          maxlength: "3",
                          oninput:
                            "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                        },
                        domProps: { value: _vm.profile.telPlcNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.profile,
                              "telPlcNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dv col_3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.telOfcNo,
                            expression: "profile.telOfcNo",
                          },
                        ],
                        class: _vm.telCls,
                        attrs: {
                          type: "text",
                          maxlength: "25",
                          oninput:
                            "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                        },
                        domProps: { value: _vm.profile.telOfcNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.profile,
                              "telOfcNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("input", { attrs: { type: "hidden", id: "tel" } }),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [_vm._v("email")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.profile.emlAddr,
                        expression: "profile.emlAddr",
                      },
                    ],
                    attrs: { type: "text", id: "emlAddr" },
                    domProps: { value: _vm.profile.emlAddr },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.profile, "emlAddr", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.008"))),
                ]),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.mbpBsnNo,
                            expression: "profile.mbpBsnNo",
                          },
                        ],
                        class: _vm.mbpCls,
                        attrs: {
                          type: "text",
                          maxlength: "3",
                          oninput:
                            "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                        },
                        domProps: { value: _vm.profile.mbpBsnNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.profile,
                              "mbpBsnNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dv col_3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.mbpOfcNo,
                            expression: "profile.mbpOfcNo",
                          },
                        ],
                        class: _vm.mbpCls,
                        attrs: {
                          type: "text",
                          maxlength: "12",
                          oninput:
                            "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                        },
                        domProps: { value: _vm.profile.mbpOfcNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.profile,
                              "mbpOfcNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("input", { attrs: { type: "hidden", id: "mbp" } }),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.MYIN050T010.066"))),
                ]),
                _c("td", [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticStyle: { width: "100%" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.profile.picAlsNm,
                            expression: "profile.picAlsNm",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id: "picAlsNm",
                          maxlength: "50",
                        },
                        domProps: { value: _vm.profile.picAlsNm },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.profile,
                                "picAlsNm",
                                $event.target.value
                              )
                            },
                            _vm.inputPicAlsNm,
                          ],
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticStyle: { width: "25%", "margin-left": "12px" } },
                      [
                        _c("input", {
                          staticClass: "button blue sm",
                          attrs: {
                            type: "button",
                            value: _vm.$i18n.t("msg.MYIN050T010.071"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.checkDuplicationProfileName()
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.002"))),
                ]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "profile_thumbnail_file",
                      on: {
                        click: function ($event) {
                          _vm.thumbnail_type = "input"
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.thumbnail_type,
                            expression: "thumbnail_type",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "thumb-raido",
                          id: "thumb-input",
                          value: "input",
                        },
                        domProps: {
                          checked: _vm._q(_vm.thumbnail_type, "input"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.thumbnail_type = "input"
                          },
                        },
                      }),
                      _vm._m(4),
                      _c("span", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.uploadFileName,
                              expression: "uploadFileName",
                            },
                          ],
                          staticClass: "upload_name",
                          attrs: {
                            type: "text",
                            readonly: "",
                            id: "uploadFile",
                          },
                          domProps: { value: _vm.uploadFileName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.uploadFileName = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "button sm",
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function ($event) {
                                return _vm.openFileUploader()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.MYIN050T010.017")))]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isShowDext5Pop,
                              expression: "isShowDext5Pop",
                            },
                          ],
                          attrs: { id: "dext5_pop" },
                        },
                        [
                          _c(
                            "div",
                            { ref: "popup", staticClass: "popup_dim" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "popup_wrap",
                                  staticStyle: {
                                    width: "800px",
                                    height: "520px",
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "layer_close",
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeFileUploadr()
                                        },
                                      },
                                    },
                                    [_vm._v("close")]
                                  ),
                                  _c("div", { staticClass: "popup_cont" }, [
                                    _c("h1", { staticClass: "page_title" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.MYIN050T010.017"))
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "content_box" },
                                      [
                                        _vm.isShowDext5
                                          ? _c("DEXT5Upload", {
                                              ref: "dextupload",
                                              attrs: {
                                                id:
                                                  "dext5-uploader-" +
                                                  _vm.dext5Idx,
                                                single: true,
                                                category: false,
                                                width: "100%",
                                                height: "200px",
                                                "file-info": _vm.uploadFileInfo,
                                                "file-size": "50KB",
                                                "category-info": [],
                                              },
                                              on: {
                                                completed:
                                                  _vm.uploadProfileImage,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "mt10 text_center" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button blue lg",
                                          on: {
                                            click: function ($event) {
                                              return _vm.closeFileUploadr()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("msg.MYIN050T010.057")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "profile_thumbnail_wrap" },
                    _vm._l(_vm.defaultThumbnailList, function (thumb, i) {
                      return _c(
                        "span",
                        {
                          key: "thumb-" + i,
                          staticClass: "profile_thumbnail_item",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.thumbnail_type,
                                expression: "thumbnail_type",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "thumb-raido",
                              id: "default-thumb-" + i,
                            },
                            domProps: {
                              value: thumb,
                              checked: _vm._q(_vm.thumbnail_type, thumb),
                            },
                            on: {
                              change: function ($event) {
                                _vm.thumbnail_type = thumb
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "default-thumb-" + i } },
                            [_c("span", { staticClass: "offscreen" })]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "profile_thumbnail",
                              on: {
                                click: function ($event) {
                                  _vm.thumbnail_type = i
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/myin/" + thumb),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.009"))),
                ]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("div", { staticClass: "mt15 mb10" }, [
                    _c("table", { staticClass: "tbl_col t2" }, [
                      _vm._m(5),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("th", { attrs: { scope: "col" } }, [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon plus",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addShj()
                                    },
                                  },
                                },
                                [_vm._v("plus")]
                              ),
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v("FROM"),
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v("TO"),
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v("Actual Shipper"),
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v("Actual Consignee"),
                            ]),
                          ]),
                          _vm.profile.shjList.length == 0
                            ? _c("tr", [_vm._m(6)])
                            : _vm._e(),
                          _vm._l(_vm.profile.shjList, function (shj, i) {
                            return _c("tr", { key: "shj-" + i }, [
                              _c("td", { staticClass: "text_center" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "tbl_icon minus",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteShj(i)
                                      },
                                    },
                                  },
                                  [_vm._v("minus")]
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: shj.fromCtrCd,
                                        expression: "shj.fromCtrCd",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          shj,
                                          "fromCtrCd",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [_vm._v("ALL")]
                                    ),
                                    _vm._l(
                                      _vm.countryCodeList,
                                      function (country, j) {
                                        return _c(
                                          "option",
                                          {
                                            key: "countryCode-" + i + "-" + j,
                                            domProps: { value: country.ctrCd },
                                          },
                                          [_vm._v(_vm._s(country.ctrEnm))]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: shj.toCtrCd,
                                        expression: "shj.toCtrCd",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          shj,
                                          "toCtrCd",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [_vm._v("ALL")]
                                    ),
                                    _vm._l(
                                      _vm.countryCodeList,
                                      function (country, j) {
                                        return _c(
                                          "option",
                                          {
                                            key: "countryCode-" + i + "-" + j,
                                            domProps: { value: country.ctrCd },
                                          },
                                          [_vm._v(_vm._s(country.ctrEnm))]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _c(
                                "td",
                                [
                                  _vm.auth.userCtrCd === "KR"
                                    ? [
                                        _c("e-auto-complete-cst-enm-v2", {
                                          attrs: {
                                            "ctr-cd": _vm.defaultCtrCd,
                                            "cd-nm": shj.shprNm,
                                            type: "shpr",
                                            idx: i.toString(),
                                          },
                                          on: { change: _vm.updateShjV2 },
                                        }),
                                      ]
                                    : [
                                        _c("e-auto-complete-cst-enm", {
                                          attrs: {
                                            "ctr-cd": _vm.defaultCtrCd,
                                            "cd-nm": shj.shprNm,
                                            type: "shpr",
                                            idx: i.toString(),
                                          },
                                          on: {
                                            change: _vm.updateShj,
                                            objData: _vm.getObjData,
                                          },
                                        }),
                                      ],
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      id: "shprCd-" + i,
                                    },
                                  }),
                                ],
                                2
                              ),
                              _c(
                                "td",
                                [
                                  _c("e-auto-complete-cst-enm", {
                                    attrs: {
                                      "ctr-cd": _vm.defaultCtrCd,
                                      "cd-nm": shj.cneNm,
                                      type: "cne",
                                      idx: i.toString(),
                                    },
                                    on: {
                                      change: _vm.updateShj,
                                      objData: _vm.getObjData,
                                    },
                                  }),
                                  _c("input", {
                                    attrs: { type: "hidden", id: "cneCd-" + i },
                                  }),
                                ],
                                1
                              ),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.012"))),
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("div", { staticClass: "tbl_inner" }, [
                    _c("div", { staticClass: "tbl_inner_content" }, [
                      _c("table", { staticClass: "tbl_row t3 mt15 mb15" }, [
                        _vm._m(7),
                        _c(
                          "tbody",
                          [
                            _c("tr", [
                              _c("th", {
                                staticStyle: { "border-left": "none" },
                              }),
                              _c("td", {
                                staticStyle: { "border-left": "none" },
                              }),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-left": "none",
                                    "padding-left": "11px",
                                  },
                                },
                                [
                                  _c("input", {
                                    attrs: { id: "allCheck", type: "checkbox" },
                                    domProps: { checked: _vm.isAllCheck.all },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      attrs: { for: "'allCheck" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.isAllCheckFn("ALL", null)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.MYIN050T010.073"))
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "valign_top",
                                  attrs: { rowspan: _vm.getAlarmLength().ship },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.MYIN050T010.024")))]
                              ),
                              _c("td", { staticClass: "checkbg" }, [
                                _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.073"))),
                              ]),
                              _c("td", { staticClass: "checkbg" }, [
                                _c("ul", { staticClass: "grid form" }, [
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.ship.push,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "SHIP",
                                              "PUSH"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.MYIN050T010.047"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        disabled: !_vm.isKor,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.ship.talk,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "SHIP",
                                              "TALK"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.MYIN050T010.048"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.ship.eml,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "SHIP",
                                              "EML"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.MYIN050T010.049"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        disabled: !_vm.isKor,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.ship.sms,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "SHIP",
                                              "SMS"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("SMS")]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _vm._l(
                              _vm.profile.alarm.auto.ship,
                              function (alarm, i) {
                                return _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showAlarm(alarm),
                                        expression: "showAlarm(alarm)",
                                      },
                                    ],
                                    key: "alarm-ship-" + i,
                                  },
                                  [
                                    _c("td", [_vm._v(_vm._s(alarm.title))]),
                                    _c("td", [
                                      _c("ul", { staticClass: "grid form" }, [
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-ship-push-" + i,
                                              disabled:
                                                alarm.altBizCatCd === "24" ||
                                                alarm.altBizCatCd === "25" ||
                                                alarm.altBizCatCd === "27",
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              value:
                                                alarm.altBizCatCd === "24" ||
                                                alarm.altBizCatCd === "27"
                                                  ? ""
                                                  : "push",
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    alarm.altBizCatCd ===
                                                      "24" ||
                                                      alarm.altBizCatCd === "27"
                                                      ? ""
                                                      : "push"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v =
                                                      alarm.altBizCatCd ===
                                                        "24" ||
                                                      alarm.altBizCatCd === "27"
                                                        ? ""
                                                        : "push",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-ship-push-" + i,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.MYIN050T010.047")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-ship-talk-" + i,
                                              disabled:
                                                !_vm.isKor ||
                                                alarm.altBizCatCd === "25",
                                              type: "checkbox",
                                              value: "talk",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "talk"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "talk",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-ship-talk-" + i,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.MYIN050T010.048")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-ship-eml-" + i,
                                              type: "checkbox",
                                              value: "eml",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "eml"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "eml",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-ship-eml-" + i,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.MYIN050T010.049")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-ship-sms-" + i,
                                              disabled:
                                                !_vm.isKor ||
                                                alarm.altBizCatCd === "25",
                                              type: "checkbox",
                                              value: "sms",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "sms"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "sms",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-ship-sms-" + i,
                                              },
                                            },
                                            [_vm._v("SMS")]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "valign_top",
                                  attrs: { rowspan: _vm.getAlarmLength().req },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("msg.MYIN050T010.025"))
                                  ),
                                ]
                              ),
                              _c("td", { staticClass: "checkbg" }, [
                                _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.073"))),
                              ]),
                              _c("td", { staticClass: "checkbg" }, [
                                _c("ul", { staticClass: "grid form" }, [
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.req.push,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "REQ",
                                              "PUSH"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.MYIN050T010.047"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        disabled: !_vm.isKor,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.req.talk,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "REQ",
                                              "TALK"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.MYIN050T010.048"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.req.eml,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "REQ",
                                              "EML"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.MYIN050T010.049"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        disabled: !_vm.isKor,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.req.sms,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "REQ",
                                              "SMS"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("SMS")]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _vm._l(
                              _vm.profile.alarm.auto.req,
                              function (alarm, i) {
                                return _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showAlarm(alarm),
                                        expression: "showAlarm(alarm)",
                                      },
                                    ],
                                    key: "alarm-req-" + i,
                                  },
                                  [
                                    _c("td", [
                                      _vm._v(" " + _vm._s(alarm.title) + " "),
                                      i == 5
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "position_relative",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showTooltip(true)
                                                },
                                              },
                                            },
                                            [
                                              _c("button", {
                                                staticClass: "tbl_icon help",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.isToolTip,
                                                      expression: "isToolTip",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "tooltip_wrap position_absolute",
                                                  style: _vm.etcTooltipStyle,
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "cont" },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass: "close",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showTooltip(
                                                                false
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "bul_list_sm",
                                                        },
                                                        [
                                                          _c("li", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "msg.MYIN050T010.041"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c("td", [
                                      _c("ul", { staticClass: "grid form" }, [
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-req-push-" + i,
                                              type: "checkbox",
                                              value: "push",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "push"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "push",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-req-push-" + i,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.MYIN050T010.047")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-req-talk-" + i,
                                              disabled: !_vm.isKor,
                                              type: "checkbox",
                                              value: "talk",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "talk"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "talk",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-req-talk-" + i,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.MYIN050T010.048")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-req-eml-" + i,
                                              type: "checkbox",
                                              value: "eml",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "eml"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "eml",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-req-eml-" + i,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.MYIN050T010.049")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-req-sms-" + i,
                                              disabled: !_vm.isKor,
                                              type: "checkbox",
                                              value: "sms",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "sms"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "sms",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-req-sms-" + i,
                                              },
                                            },
                                            [_vm._v("SMS")]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "valign_top",
                                  attrs: { rowspan: _vm.getAlarmLength().anno },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.MYIN050T010.026")))]
                              ),
                              _c("td", { staticClass: "checkbg" }, [
                                _vm._v(_vm._s(_vm.$t("msg.MYIN050T010.073"))),
                              ]),
                              _c("td", { staticClass: "checkbg" }, [
                                _c("ul", { staticClass: "grid form" }, [
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.anno.push,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "ANNO",
                                              "PUSH"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.MYIN050T010.047"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        disabled: !_vm.isKor,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.anno.talk,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "ANNO",
                                              "TALK"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.MYIN050T010.048"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.anno.eml,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "ANNO",
                                              "EML"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.MYIN050T010.049"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      attrs: {
                                        id: "allCheckShipPush",
                                        disabled: !_vm.isKor,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: _vm.isAllCheck.anno.sms,
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "'allCheckShipPush" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.isAllCheckFn(
                                              "ANNO",
                                              "SMS"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("SMS")]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _vm._l(
                              _vm.profile.alarm.auto.anno,
                              function (alarm, i) {
                                return _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showAlarm(alarm),
                                        expression: "showAlarm(alarm)",
                                      },
                                    ],
                                    key: "alarm-anno-" + i,
                                  },
                                  [
                                    _c("td", [_vm._v(_vm._s(alarm.title))]),
                                    _c("td", [
                                      _c("ul", { staticClass: "grid form" }, [
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-anno-push-" + i,
                                              disabled:
                                                alarm.altBizCatCd === "26",
                                              type: "checkbox",
                                              value: "push",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "push"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "push",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-anno-push-" + i,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.MYIN050T010.047")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-anno-talk-" + i,
                                              disabled:
                                                !_vm.isKor ||
                                                alarm.altBizCatCd === "26",
                                              type: "checkbox",
                                              value: "talk",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "talk"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "talk",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-anno-talk-" + i,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.MYIN050T010.048")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-anno-eml-" + i,
                                              type: "checkbox",
                                              value: "eml",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "eml"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "eml",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-anno-eml-" + i,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.MYIN050T010.049")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: alarm.selectArr,
                                                expression: "alarm.selectArr",
                                              },
                                            ],
                                            attrs: {
                                              id: "alarm-anno-sms-" + i,
                                              disabled:
                                                !_vm.isKor ||
                                                alarm.altBizCatCd === "26",
                                              type: "checkbox",
                                              value: "sms",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                alarm.selectArr
                                              )
                                                ? _vm._i(
                                                    alarm.selectArr,
                                                    "sms"
                                                  ) > -1
                                                : alarm.selectArr,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = alarm.selectArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "sms",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        alarm,
                                                        "selectArr",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    alarm,
                                                    "selectArr",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "alarm-anno-sms-" + i,
                                              },
                                            },
                                            [_vm._v("SMS")]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.018"))),
                  _c("span", { staticClass: "txt_normal" }, [
                    _vm._v("(" + _vm._s(_vm.$t("msg.MYIN010G010.017")) + ")"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { attrs: { colspan: "3" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.profile.mdtyPsnlInfCltnCnstYn,
                          expression: "profile.mdtyPsnlInfCltnCnstYn",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "rdo3",
                        name: "rdo1",
                        value: "Y",
                      },
                      domProps: {
                        checked: _vm._q(_vm.profile.mdtyPsnlInfCltnCnstYn, "Y"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.profile,
                            "mdtyPsnlInfCltnCnstYn",
                            "Y"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "mr10", attrs: { for: "rdo3" } },
                      [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.028"))),
                      ]
                    ),
                    _c(
                      "address-result",
                      {
                        staticClass: "ml30 button sm",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPopup("MyInfoPersonalAgreePop")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.030")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.020"))),
                  _c("span", { staticClass: "txt_normal" }, [
                    _vm._v("(" + _vm._s(_vm.$t("msg.MYIN010G010.019")) + ")"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.profile.choPsnlInfCltnCnstYn,
                        expression: "profile.choPsnlInfCltnCnstYn",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "rdo5",
                      name: "rdo2",
                      value: "Y",
                    },
                    domProps: {
                      checked: _vm._q(_vm.profile.choPsnlInfCltnCnstYn, "Y"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.profile,
                          "choPsnlInfCltnCnstYn",
                          "Y"
                        )
                      },
                    },
                  }),
                  _c("label", { staticClass: "mr10", attrs: { for: "rdo5" } }, [
                    _c("span"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.028"))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.profile.choPsnlInfCltnCnstYn,
                        expression: "profile.choPsnlInfCltnCnstYn",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "rdo6",
                      name: "rdo2",
                      value: "N",
                    },
                    domProps: {
                      checked: _vm._q(_vm.profile.choPsnlInfCltnCnstYn, "N"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.profile,
                          "choPsnlInfCltnCnstYn",
                          "N"
                        )
                      },
                    },
                  }),
                  _c("label", { staticClass: "mr10", attrs: { for: "rdo6" } }, [
                    _c("span"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.029"))),
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "ml30 button sm",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup("MyInfoPersonalAgreeChoicePop")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.030")))]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.021"))),
                  _c("span", { staticClass: "txt_normal" }, [
                    _vm._v("(" + _vm._s(_vm.$t("msg.MYIN010G010.019")) + ")"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.profile.recvCnstYn,
                        expression: "profile.recvCnstYn",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "rdo7",
                      name: "rdo3",
                      value: "Y",
                    },
                    domProps: { checked: _vm._q(_vm.profile.recvCnstYn, "Y") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.profile, "recvCnstYn", "Y")
                      },
                    },
                  }),
                  _c("label", { staticClass: "mr10", attrs: { for: "rdo7" } }, [
                    _c("span"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.028"))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.profile.recvCnstYn,
                        expression: "profile.recvCnstYn",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "rdo8",
                      name: "rdo3",
                      value: "N",
                    },
                    domProps: { checked: _vm._q(_vm.profile.recvCnstYn, "N") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.profile, "recvCnstYn", "N")
                      },
                    },
                  }),
                  _c("label", { staticClass: "mr10", attrs: { for: "rdo8" } }, [
                    _c("span"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.029"))),
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "ml30 button sm",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup("MyInfoMarketingAgree")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.030")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "flex_between" }, [
      _c("div"),
      _c("div", [
        _c(
          "a",
          {
            staticClass: "button blue lg mr5",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                return _vm.saveProfileAlert()
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("msg.MYIN050T010.050")))]
        ),
        _c(
          "a",
          {
            staticClass: "button gray lg mr5",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                return _vm.resetProfile("RESET")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("msg.MYIN050T010.065")))]
        ),
      ]),
    ]),
    _c(
      "div",
      { attrs: { id: "layerPop" } },
      [
        _c(
          "win-layer-pop",
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: { "parent-info": _vm.popupParams },
                  on: { close: _vm.closePoup, agreement: _vm.agreementPop },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0)" } }, [
      _c("span", { staticClass: "offscreen" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "17%" } }),
      _c("col", { staticStyle: { width: "33%" } }),
      _c("col", { staticStyle: { width: "17%" } }),
      _c("col", { staticStyle: { width: "33%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "use1" } }, [
      _c("span", [_vm._v("USE")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "use2" } }, [
      _c("span", [_vm._v("NO USE")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "thumb-input" } }, [
      _c("span", { staticClass: "offscreen" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "27%" } }),
      _c("col", { staticStyle: { width: "27%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { colspan: "5" } }, [
      _c("input", { attrs: { type: "hidden", id: "emptyShjList" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "19%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }