<template>
  <div class="content widfix">
    <!-- &lt;!&ndash; popup_cont &ndash;&gt; -->
    <e-breadcrumbs />

    <form
      id="frm"
    >
      <h1 class="page_title">{{ $t('btn.CMBTK026') }}</h1>
      <!-- &lt;!&ndash; ID찾기&ndash;&gt; -->

      <div class="content_box mt10">
        <!-- &lt;!&ndash; content_box &ndash;&gt; -->

        <div class="idpwd_sch">
          <div class="dv">
            <h2 class="content_title">{{ $t('btn.CMBTK025') }}</h2>

            <div v-if="$i18n.locale === 'ko-KR'" class="formbox">
              <span class="tit">{{ $t('art.CMATK019') }}</span>
              <span>
                <input type="text"
                       id="idBizNo"
                       v-model="params.bizNo"
                       :placeholder="$t('art.CMATK019')"
                       maxlength="10"
                       @change="checkValidationIdBizNo"
                />
              </span>
            </div>

            <div v-else class="formbox">
              <span class="tit">{{ $t('msg.MAIN.002') }}</span>
              <span><input type="text" id="idEmail" @blur="checkEmailForm($event, 'idEmail')" v-model="params.userEmail" :placeholder="$t('msg.MAIN.002')" /></span>
            </div>

            <div class="btnarea">
              <a class="button blue sm mr5" @click="findId('BTN')">{{ $t('btn.CMBTK025') }}</a>
              <!-- &lt;!&ndash; ID찾기&ndash;&gt; -->
              <!-- &lt;!&ndash; <a class="button gray sm" href="#">로그인</a> &ndash;&gt; -->
            </div>
          </div>
          <div class="dv">
            <h2 class="content_title">{{ $t('btn.CMBTK033') }}</h2>
            <!-- &lt;!&ndash; PASSWORD 찾기&ndash;&gt; -->
            <div class="formbox">
              <span class="tit">ID</span>
              <span><input type="text" id="pwId" v-model="params.userId" placeholder="ID" /></span>
            </div>

            <div v-if="$i18n.locale === 'ko-KR'" class="formbox">
              <!-- 사업자 등록번호 -->
              <span class="tit">{{ $t('art.CMATK019') }}</span>
              <span><input
                type="text"
                id="pwBizNo"
                v-model="pwBizNo"
                :placeholder="$t('art.CMATK019')"
                maxlength="10"
                @change="checkValidationPwBizNo"
              /></span>
            </div>

            <div v-else class="formbox">
              <span class="tit">{{ $t('msg.MAIN.002') }}</span>
              <span><input type="text" id="pwEmail" @blur="checkEmailForm($event, 'pwEmail')" v-model="pwEmail" :placeholder="$t('msg.MAIN.002')" /></span>
            </div>

            <div v-if="$i18n.locale === 'ko-KR'" class="chk">
              <input type="checkbox" @change="setSyncPwBizNo()" id="regno" v-model="regno" />
              <label for="regno"><span></span>&nbsp;&nbsp;동일 {{ $t('art.CMATK019') }}</label>
            </div>

            <div v-else class="chk">
              <!-- 이메일 -->
              <input type="checkbox" @change="setSyncPwEmail()" id="regemail" v-model="regemail" />
              <label for="regemail"><span></span>&nbsp;&nbsp;{{ $t('msg.MAIN.003') }}</label>
            </div>

            <div class="btnarea">
              <a class="button blue sm mr5" @click="findPassword">{{ $t('btn.CMBTK033') }}</a>
              <!-- &lt;!&ndash; PASSWORD 찾기&ndash;&gt; -->
              <!-- &lt;!&ndash; <a class="button gray sm" href="#">로그인</a> &ndash;&gt; -->
            </div>
          </div>
        </div>
      </div>
      <!-- &lt;!&ndash; content_box // &ndash;&gt; -->
    </form>
  </div>
  <!-- &lt;!&ndash; content // &ndash;&gt; -->
</template>

<script>
import member from '@/api/rest/user/member'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'FindIdPassword',
  components: {
    WinLayerPop,
    EBreadcrumbs
  },
  data: function () {
    return {
     format: 'YYYY-MM-DD HH:mm',

     findIdList: [],
     findPw: '',
     userInfo: [],
     userFindPw: '',

     regno: '',
     regemail: '',
     pwBizNo: '',
     pwEmail: '',

     params: {
        userId: '',
        bizNo: '',
        userEmail: ''
      },

      customComponent: null
    }
  },
  computed: {
    ...rootComputed
  },
  creted () {
  },
  mounted () {
    // console.log('i18n locale:', this.$i18n.locale)
  },
  methods: {
    openAlert (msg, cssFlag) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.SETT010T010.030'), //'확인',
        useIcon: false,
        customClass: 'simple_alert',
        modifyCss: cssFlag,
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    changeFormat (data) {
      let yyyy = data.substring(0, 4)
      let mm = data.substring(4, 6)
      let dd = data.substring(6, 8)
      let hh = data.substring(8, 10)
      let mi = data.substring(10, 12)
      let ss = data.substring(12)

      let yyyymmdd = yyyy + '-' + mm + '-' + dd
      let hhmi = hh + ':' + mi

      let rVal = ''

      if (!this.checkNull(data)) {
        rVal = '(' + yyyymmdd + ' ' + hhmi + ' ' + this.$t('msg.PERS010L020.005') //'최종접속)'
      }

      return rVal
    },
    checkNull (str) {
      if (typeof str === 'undefined' || str === null || str === '') {
        return true
      } else {
        return false
      }
    },
    checkNulltoStr (str) {
      if (typeof str === 'undefined' || str === null || str === '') {
        return ''
      } else {
        return str
      }
    },
    checkEmailForm (event, id) {
     const val = event.target.value

      let isOk = true
      let msg = ''

      if (val === '') {
        // 값이 없을 경우
        msg = this.$t('msg.MAIN100.202') + this.$t('tem.CMAT002')
        isOk = false
      } else if (!this.$ekmtcCommon.checkEmail(val)) {
        // 이메일 형식 체크
        msg = this.$t('art.CMATK196') // 잘못된 형식의 이메일 주소가 있습니다.'
        isOk = false
      } else {
        // 값이 있을 경우, Byte 크기 체크
        if (!this.$ekmtcCommon.checkTextByte(val, 2000)) {
          msg = this.$t('msg.ADMN030G020.023') // 허용된 Byte 크기를 초과하였습니다.'
          isOk = false
        }
      }

      if (!isOk) {
        this.openAlert(msg, false)
        event.target.value = ''
        this.$refs.email.focus()

        if (id === 'idEmail') {
            this.params.userEmail = ''
        } else {
            this.pwEmail = ''
        }
      }
    },
    checkValidationIdAll () {
      const frm = document.querySelector('#frm')
      let isAllOk = true

     if (this.$i18n.locale === 'ko-KR') {
        if (!this.checkValidationIdBizNo()) {
          isAllOk = false
        }
     } else {
        if (!this.checkValidationIdEmail(frm)) {
          isAllOk = false
        }
     }

      return isAllOk
    },
    checkValidationPwAll () {
      const frm = document.querySelector('#frm')
      let isAllOk = true

     if (this.$i18n.locale === 'ko-KR') {
        if (!this.checkValidationPwBizNo()) {
          isAllOk = false
        }

        if (!this.checkValidationId(frm)) {
        isAllOk = false
        }
     } else {
        if (!this.checkValidationPwEmail(frm)) {
          isAllOk = false
        }

        if (!this.checkValidationId(frm)) {
          isAllOk = false
        }
     }

      return isAllOk
    },
    checkValidationId (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#pwId')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK022') + this.$t('tem.CMAT002')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationIdBizNo () {
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#idBizNo')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK019') + this.$t('tem.CMAT002')
        isOk = false
      }

      if (val.length < 10) {
        msg = this.$t('art.CMATK019') + '의 입력 길이는 ' + 10 + '입니다.'
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationPwBizNo () {
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#pwBizNo')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('art.CMATK019') + this.$t('tem.CMAT002')
        isOk = false
      }

      if (val.length < 10) {
        msg = this.$t('art.CMATK019') + '의 입력 길이는 ' + 10 + '입니다.'
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationPwEmail (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#pwEmail')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('msg.CMBA100.00163') + this.$t('tem.CMAT001')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationIdEmail (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#idEmail')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('msg.CMBA100.00163') + this.$t('tem.CMAT001')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    setMasking (str) {
      let originStr = str
      let strLength = ''

      if (this.checkNull(originStr) === true) {
        return originStr
      }

      strLength = originStr.length

      let maskingStr = originStr.split('')

      maskingStr.forEach((name, idx) => {
          if (idx === 0 || idx === maskingStr.length) return

          if (idx > 2) {
            maskingStr[idx] = '*'
          }
      })

      let joinName = maskingStr.join()
      maskingStr = joinName.replace(/,/g, '')

      return maskingStr
    },
    setSyncPwBizNo () {
      if (this.regno === true) {
        if (!this.checkNull(this.params.bizNo)) {
           this.pwBizNo = this.params.bizNo
        } else {
          this.openAlert('입력하신 사업자등록번호가 없습니다', false)
        }
      }
    },
    setSyncPwEmail () {
      if (this.regemail === true) {
        if (!this.checkNull(this.params.userEmail)) {
           this.pwEmail = this.params.userEmail
        } else {
          this.openAlert(this.$t('art.CMATK046'), false) // '입력하신 email이 없습니다.'
        }
      } else {
        this.pwEmail = ''
      }
    },
    async findId (str) {
      if (str === 'BTN') {
        const isOk = this.checkValidationIdAll()

        if (isOk) {
            await member.findMemberId(this.params)
            .then(response => {
              // console.log(response)
              let resp = response.data

              this.findIdList = resp.memberList
              if (this.findIdList.length > 0) {
                let IDList = ''

                for (var i = 0; i < this.findIdList.length; i++) {
                  IDList += '<span style="color:#0A82FF; text-align:left;" >' + this.setMasking(this.findIdList[i].userId)
                  IDList += '<span style="color:black;">' +
                              this.changeFormat(this.checkNulltoStr(this.findIdList[i].entDtm)) +
                            '</span>'
                  IDList += '</span><br>'
                }

                this.openAlert(IDList, true)
              } else {
                this.openAlert(this.$t('art.CMATK045'), false) //  '입력하신 ID가 존재하지 않습니다.'
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      } else {
          await member.findMemberId(this.params)
          .then(response => {
            console.log('findMemberId >>>> ', response)
            let resp = response.data

            this.findIdList = resp.memberList

            // return findIdList
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    async findPassword () {
      const isOkForPw = this.checkValidationPwAll()

      if (isOkForPw) {
        if (this.$ekmtcCommon.isNotEmpty(this.pwBizNo)) {
          this.params.bizNo = this.pwBizNo
        }

        if (this.$ekmtcCommon.isNotEmpty(this.pwEmail)) {
          this.params.userEmail = this.pwEmail
        }

       let bfMsg = ''

       await this.findId('PW')

       if (this.findIdList.length > 0) { // 사업자등록번호에 ID가 있음. 따라서 사업자등록번호는 정상
          bfMsg = this.$t('tem.CMAT036') // '존재하지 않는 ID 입니다.'
       } else {
          if (this.$i18n.locale === 'ko-KR') {
            bfMsg = '사업자 등록번호를 확인해주세요.'
          } else {
            bfMsg = this.$t('art.CMATK397') // 이메일 확인해주세요
          }
       }

       console.log('findPassword findIdList >>>> ', this.findIdList)

       await member.findMemberPw(this.params)
          .then(response => {
            console.log(response)
              if (response.status === 200) {
                let resp = response.data

                this.userInfo = resp.member
                if (this.userInfo !== undefined) {
                  let msg = ''
                    if (this.$i18n.locale === 'ko-KR' || this.$i18n.locale === 'ja-JP') {
                      msg = this.$t('art.CMATK192') + '<span style="color:#0A82FF;">' + this.userInfo.userEmail + '</span>' + this.$t('art.CMATK193')
                    } else {
                      msg = this.$t('art.CMATK192') + '<span style="color:#0A82FF;"> ' + this.userInfo.userEmail + '</span>'
                    }

                    this.openAlert(msg, false)

                    // 성공 후 params 데이터 초기화
                    this.params.userId = ''
                    this.pwBizNo = ''
                    this.pwEmail = ''
                } else {
                  this.openAlert(bfMsg, false) // 조회 데이타가 없습니다.
                }
              }
          })
          .catch(error => {
            console.log(error)
          })

          // 데이터 초기화
          this.params.bizNo = ''
          this.params.userEmail = ''
      }
    },
    openPopup (compNm) {
      console.log('compNm =' + compNm)

      this.customComponent = compNm

      // if (compNm === 'FindPasswordLayerPop') {
      // }
      this.$ekmtcCommon.layerOpen('#openlayerPop > .popup_dim')
    },
    closePoup () {
      this.customComponent = null

      this.$ekmtcCommon.layerClose('#openlayerPop > .popup_dim')
    }
  }
}
</script>

<style scoped>
.e-alert {
  text-align:left;
  width: 350px;
}

.formbox {position:relative; padding-left:120px;}
.formbox>.tit {position:absolute; left:0; top:0;}
</style>
