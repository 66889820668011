var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("e-breadcrumbs"),
      _c("form", { attrs: { id: "frm" } }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.CSCT060G050.001"))),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("table", { staticClass: "tbl_search" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.002")))]),
                _c(
                  "td",
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "podPod",
                        "ctr-cd": _vm.params.ctrCd,
                        "plc-cd": _vm.params.portCd,
                      },
                      on: { change: _vm.changePod },
                    }),
                  ],
                  1
                ),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.003")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.eiCatCd,
                          expression: "params.eiCatCd",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.params,
                            "eiCatCd",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "O" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSCT060G050.004"))),
                      ]),
                      _c("option", { attrs: { value: "I" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSCT060G050.005"))),
                      ]),
                      _c("option", { attrs: { value: "A" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSCT060G050.011"))),
                      ]),
                    ]
                  ),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button blue sh",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fninquiry()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.006")))]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.007")))]),
                _c("td", [
                  _c("span", { staticClass: "ml10" }, [
                    _c("input", {
                      attrs: {
                        disabled: _vm.checkDemCnt === 0,
                        type: "checkbox",
                        id: "dt_chk1",
                        name: "dt_chk",
                        value: "01",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayColumn()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "dt_chk1" } }, [_vm._v("DEM")]),
                    _c("input", {
                      attrs: {
                        disabled: _vm.checkDetCnt === 0,
                        type: "checkbox",
                        id: "dt_chk2",
                        name: "dt_chk",
                        value: "02",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayColumn()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "dt_chk2" } }, [_vm._v("DET")]),
                    _c("input", {
                      attrs: {
                        disabled: _vm.checkDemDetCnt === 0,
                        type: "checkbox",
                        id: "dt_chk3",
                        name: "dt_chk",
                        value: "03",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayColumn()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "dt_chk3" } }, [
                      _vm._v("DEM+DET"),
                    ]),
                    _c("input", {
                      attrs: {
                        disabled: _vm.checkOfcCnt === 0,
                        type: "checkbox",
                        id: "dt_chk4",
                        name: "dt_chk",
                        value: "04",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayColumn()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "dt_chk4" } }, [_vm._v("OFC")]),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.008")))]),
                _c("td", [
                  _c("span", { staticClass: "ml10" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "ct_chk1",
                        name: "ct_chk",
                        value: "GP",
                        checked: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayRow()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "ct_chk1" } }, [_vm._v("GP")]),
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "ct_chk2",
                        name: "ct_chk",
                        value: "HC",
                        checked: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayRow()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "ct_chk2" } }, [_vm._v("HC")]),
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "ct_chk3",
                        name: "ct_chk",
                        value: "RF",
                        checked: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayRow()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "ct_chk3" } }, [_vm._v("RF")]),
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "ct_chk4",
                        name: "ct_chk",
                        value: "TK",
                        checked: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayRow()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "ct_chk4" } }, [_vm._v("TK")]),
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "ct_chk5",
                        name: "ct_chk",
                        value: "OT",
                        checked: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayRow()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "ct_chk5" } }, [_vm._v("OT")]),
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "ct_chk6",
                        name: "ct_chk",
                        value: "FR",
                        checked: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayRow()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "ct_chk6" } }, [_vm._v("FR")]),
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "ct_chk7",
                        name: "ct_chk",
                        value: "SR",
                        checked: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayRow()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "ct_chk7" } }, [_vm._v("SR")]),
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "ct_chk8",
                        name: "ct_chk",
                        value: "DG",
                        checked: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDisplayRow()
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "ct_chk8" } }, [_vm._v("DG")]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("div", { staticClass: "text_right mb8" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "print",
                    rawName: "v-print",
                    value: "#frm",
                    expression: "'#frm'",
                  },
                ],
                staticClass: "button sm",
                attrs: { href: "#" },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.009")))]
            ),
          ]),
          _c("div", {
            style: `width:100%; height:${_vm.gridHeight}px;`,
            attrs: { id: "realgrid" },
          }),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.serviceLang === "KOR",
                  expression: "serviceLang === 'KOR'",
                },
              ],
              staticClass: "txt_desc2",
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.013")))]
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.serviceLang === "KOR",
                  expression: "serviceLang === 'KOR'",
                },
              ],
              staticClass: "txt_desc2",
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.014")))]
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.serviceLang === "KOR",
                  expression: "serviceLang === 'KOR'",
                },
              ],
              staticClass: "txt_desc2",
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.015")))]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "80px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }