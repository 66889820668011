<template>
  <div class="" v-if="captchYn">
    <div>
      <div class="captcha-left">
        <img title="캡차이미지" :src="captchaImg" alt="캡차이미지" style="width: 100px;" />
      </div>
      <div class="captcha-left">
        <img src="../../assets/images/common/icon-headphone.png" @click.prevent="getAudio" style="width: 15px; margin: 5px">
        <img src="../../assets/images/common/icon_refresh.png" @click.prevent="getImage" style="width: 15px; margin: 5px">
      </div>
      <div class="captcha-left">
        <input id="answer" type="text" value="" v-model="answer" style="width: 100px">
      </div>
      <div style="align-content: center; height: 33px">
        <a class="button blue sh" @click="answerCheck">인증</a>
      </div>
    </div>
  </div>
</template>

<script>

import captcha from '@/api/services/captcha'

export default {
  name: 'Captcha',
  props: {
    captchYn: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data: function () {
    return {
      answer: '',
      captchaImg: '',
      items: [],
      dynamicItems: [],
      placeholder: this.$t('msg.MAIN010G030.035')
    }
  },
  created () {
    this.getImage()
  },
  methods: {
    getImage () {
      this.captchaImg = ''
      let rand = Math.random()
      this.captchaImg = process.env.VUE_APP_COMMON_URL + '/common/captcha/captchaImg?rand=' + rand
    },
    getAudio () {
      let rand = Math.random()
      let soundUrl = process.env.VUE_APP_COMMON_URL + '/common/captcha/captchaAudio?rand=' + rand
      if (document.createElement('audio').canPlayType) { /*Chrome 경우 */
        try {
          new Audio(soundUrl).play()
        } catch (e) {
          this.audioPlayer(soundUrl)
        }
      } else {
        window.open(soundUrl, '', 'width=1,height=1')
      }
    },
    answerCheck () {
      const th = this
      captcha.chkAnswer(this.answer).then(res => {
        if (res.data.isHomepage === '200') {
          th.callback('Y')
        } else {
          th.callback('N')
        }
      })
    },
    callback (answer) {
      this.$emit('callback', answer)
    }
  }
}

</script>
<style>
.captcha-left {
  float: left;
  height: 33px;
  align-content: center;
}

</style>
