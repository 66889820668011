<template>
  <div class="popup_wrap" style="width:550px; height:650px;">
    <!-- 팝업사이즈 참고 : 500*235  popup_wrap -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">[{{ $t('msg.ONIM070P020.002') }}] {{ $t('msg.MYIN010G010.020') }}</h1> <!-- 개인정보수집동의서 -->
      <div class="content_box">
        <!-- content_box -->
        <h2 class="content_title"></h2>
        <!-- <textarea class="hei200" v-model="parentInfo.content"></textarea> -->
        <p class="tit">
          {{ $t('approval.013') }}
          <!-- 1. 수집하는 개인정보 항목 -->
        </p>
        <ul>
          <li>
            {{ $t('approval.014') }}
            <!-- 기존 서비스 개선, 신규서비스 개발 및 마케팅·광고 등에 활용하기 위한 정보 (선택) : 성명, 휴대전화번호, 이메일, 회사명, 선적 아이템, 선적국가, 실화주 등 최근 선적 현황, 선적패턴 -->
          </li>
        </ul>
        <p class="tit mt20">
          {{ $t('approval.015') }}
          <!-- 2. 개인정보 수집 및 이용 목적 -->
        </p>
        <ul>
          <li>
            {{ $t('approval.016') }}
            <!-- 고객님에게 기존 서비스 개선, 신규 서비스 개발 및 마케팅·광고 등에 활용하고자 하는 목적으로서, 고객만족 조사, 서비스 개발·연구, 신규 서비스 연구·개발·특화, 이벤트·프로모션 등 마케팅 정보 제공 및 경품 배송, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 고객님의 서비스 이용에 대한 분석 및 통계, 서비스 정보의 추천, 시장 트렌드 유추, 시장조사, 통계분석자료 활용 등을 하고자 합니다. -->
          </li>
        </ul>
        <p class="tit mt20">
          {{ $t('approval.017') }}
          <!-- 3. 개인정보의 보유 및 이용기간 -->
        </p>
        <ul>
          <li>
            {{ $t('approval.018') }}
            <!-- 고객님의 동의 하에 수집된 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면 지체 없이 파기됩니다. 다만, 개인정보의 수집 및 이용 목적이 달성된 경우에도, 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령에 의하여 보존될 필요성이 있는 경우 및 사전에 보유기간을 명시하거나 고지한 경우에는 그에 따라 개인정보를 보관할 수 있습니다. -->
          </li>
        </ul>
        <p class="tit mt20">
          {{ $t('approval.019') }}
          <!-- 4. 동의하지 않았을 경우 불이익 -->
        </p>
        <ul>
          <li>
            {{ $t('approval.020') }}
            <!-- 고객님께서는 개인정보 수집·이용에 대한 동의를 거부하실 수 있으며, 동의를 거부하실 경우 이벤트&nbsp;프로모션 안내 및 혜택 서비스를 받으실 수 없습니다.  -->
          </li>
        </ul>
        <p class="mt20">
          {{ $t('approval.029') }}
          <!-- 위의 개인정보수집에 관한 사항을 모두 읽고 인식하였으며, 이에 동의합니다. (선택) -->
        </p>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <!-- <a class="button lg" href="#">
          <span></span>동의</a> -->
        <!--<p class="button lg">
          <input type="checkbox"
                 @change="$emit('agreement', clickAgreement, gubun)"
                 id="checkAgreement" name="checkAgreement"
                 v-model="clickAgreement"
                 true-value="Y"
                 false-value="N"
          />
          <label for="checkAgreement"><span></span>동의</label>
        </p> -->
        <a class="button gray lg"
           @click="$emit('close')"
        >{{ $t('common.FILE_UPLOAD.011') }}</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import OfficeAgent from '@/api/rest/settle/officeAgent'

export default {
  name: 'PersonalInfoAgree',
  props: {
    parentInfo: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      param: {
        portCd: ''
      },
      contents: '',
      clickAgreement: this.parentInfo.choPsnlInfCltnCnstYn,
      gubun: 'MPACP'
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted: function () {
    const $vm = this
    //조회
    $vm.searchData()
  },
  methods: {
    searchData: function () {
    }
  }
}
</script>

<style scoped>
</style>
