var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("menu.MENU08.020"))),
      ]),
      _c(
        "e-tab",
        { ref: "tab" },
        [
          _c(
            "e-tab-item",
            {
              attrs: {
                title: _vm.$t("menu.MENU08.110"),
                name: "report-dashboard",
                path: "/my-info/report",
                selected: true,
              },
            },
            [
              _vm.routeName === "" || _vm.routeName === "report-dashboard"
                ? _c("report-dashboard")
                : _vm._e(),
            ],
            1
          ),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: _vm.$t("menu.MENU08.090"),
                name: "report-list",
                path: "/my-info/report",
                selected: true,
              },
            },
            [
              _vm.routeName === "" || _vm.routeName === "report-list"
                ? _c("report-list")
                : _vm._e(),
            ],
            1
          ),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: _vm.$t("menu.MENU08.100"),
                name: "report-info",
                path: "/my-info/report",
              },
            },
            [_vm.routeName === "report-info" ? _c("report-info") : _vm._e()],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }