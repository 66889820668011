<template>
  <div class="content resize">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('msg.CART010G010.011') }}</h1><!-- 화물추적 -->
    <form id="frm" @submit.prevent>
      <input type="text" id="_temp" style="display:none;" /><!-- Enter 클릭시 submit 방지용 -->
      <div class="content_box mt10">
        <table class="tbl_search">
          <colgroup>
            <col width="60px">
            <col width="200px">
            <col width="250px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CART010G010.015') }}</th><!-- 조회 -->
              <td>
                <select id="" v-model="params.dtKnd" @change="changeSelectFuc()">
                  <option value="BL">{{ $t('msg.CART010G010.012') }}</option><!-- B/L No. -->
                  <option value="CN">{{ $t('msg.CART010G010.013') }}</option><!-- Container No. -->
                  <option value="BK">{{ $t('msg.CART010G010.014') }}</option><!-- Booking No. -->
                </select>
              </td>
              <td class="pl0">
                <input type="text" v-model="params.blNo" id="blNo" name="" @input="checkValidationAll()">
              </td>
              <td>
                <a class="button blue sh" href="#" @click.prevent="getCargoCntrList()">{{ $t('msg.CART010G010.055') }}</a> <!-- 검색 -->
              </td>
              <td v-if="searchFlag === 'Y'
                    && noDataFlag === 'N'
                    && lang === 'KOR'
                    && bookingInfo.spcShiprYn === 'Y'
                    && (params.dtKnd === 'BL' || params.dtKnd === 'BK')" style="float: right"
                  id="subscriptionBtn"
              >
                <a class="button blue sh" href="#" @click.prevent="openSubscriptionPopup()">구독</a> <!-- 구독 -->
              </td>
              <Captcha
                :captch-yn="isCaptcha"
                @callback="captchaResult"
              />
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="searchFlag === 'Y' && noDataFlag === 'N'" class="content_box mt10">
        <table class="tbl_col mt10">
          <colgroup>
            <col style="width:10%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:10%">
            <col style="width:9%">
            <col style="width:15%">
            <col style="width:16%">
            <col style="width:15%">
            <col style="width:10%">
            <col style="width:10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CART010G010.012') }}</th><!-- B/L No. -->
              <th>{{ $t('msg.CART010G010.014') }}</th><!-- Booking No. -->
              <th>{{ $t('msg.CART010G010.013') }}</th><!-- Container No. -->
              <th>{{ $t('msg.CART010G010.016') }}</th><!-- Size/Type -->
              <th>CGO</th><!-- CGO -->
              <th>{{ $t('msg.CART010G010.017') }}</th><!-- 출발 -->
              <th>{{ $t('msg.CART010G010.018') }}</th><!-- 도착 -->
              <th>{{ $t('msg.CART010G010.019') }}</th><!-- 선명/항차 -->
              <th v-show="registryNo === 'Y'">REGISTRY NO. </th><!-- REGISTRY NO.  -->
              <th>{{ $t('msg.CART010G010.020') }}/<br>{{ $t('msg.CART010G010.021') }}</th><!-- Reserve / Confirm -->
            </tr>
          </thead>
          <tbody v-if="convertList.length > 0">
            <tr v-for="(vo, idx) in convertList" :key="'cntrList_' + idx">
              <td v-if="idx == 0" :rowspan="rowCnt">{{ vo.blNo }}</td>
              <td v-if="idx == 0" :rowspan="rowCnt" v-html="parseBkNo(vo.bkgNo)"></td>
              <td v-if="vo.cntrIdx == 0" :rowspan="rowCnt" class="td_left_line">
                <!-- 컨테이너 갯수가 5개 이하일때 -->
                <p v-for="(cvo, cidx) in cntrList" :key="'cntrNo_' + cidx" v-show="cntrList.length <= 5">
                  <span :id="'cntrNo_' + cvo.cntrNo" class="cntrNo_area" v-if="cvo.cntrNo == vo.cntrNo">{{ cvo.cntrNo }}</span>
                  <a v-else href="#" :id="'cntrNo_' + cvo.cntrNo" class="cntrNo_area link" @click.prevent="setCntrList(cvo.cntrNo, 'Y')">{{ cvo.cntrNo }}</a>
                </p>
                <!-- 컨테이너 갯수가 5개 이상이면 SelectBox로 -->
                <p v-if="cntrList.length > 5">
                  <select v-model="selectCntrNo" @change="setCntrList()">
                    <option v-for="(cvo, cidx) in cntrList" :key="'cntrNo_' + cidx" :value="cvo.cntrNo">{{ cvo.cntrNo }}</option>
                  </select>
                </p>
              </td>
              <td v-if="idx == 0" :rowspan="rowCnt" class="td_left_line">{{ vo.cntrSzCd }} {{ vo.cntrTypCd }}</td>
              <td v-if="idx == 0" :rowspan="rowCnt" class="td_left_line">
                {{ vo.cgoTypNm }}
              </td>
              <td class="td_left_line">{{ vo.polPortNm }} <br />{{ $ekmtcCommon.isNotEmpty(vo.legEtd)? vo.legEtd : vo.etd === '0000.00.00/00:00' ? '' : vo.etd }} </td>
              <td class="td_left_line">{{ vo.podPortNm }}{{ vo.podTrmlNm }}<br /> {{ $ekmtcCommon.isNotEmpty(vo.legEta)? vo.legEta : vo.eta === '0000.00.00/00:00' ? '' : vo.eta }}</td>
              <td class="td_left_line">{{ vo.vslNm }}</td>
              <td v-show="registryNo === 'Y'" class="td_left_line">{{ vo.mrnNo || '' }}</td>
              <td class="td_left_line">
                <span v-if="vo.confirmCd === '01'" class="label red md">{{ $t('msg.CART010G010.022') }}</span><!-- 선적미확정 -->
                <span v-if="vo.confirmCd === '02'" class="label green md">{{ $t('msg.CART010G010.023') }}</span><!-- 선적확정 -->
                <span v-if="vo.confirmCd === '03'" class="label gray md">{{ $t('msg.CART010G010.024') }}</span><!-- 선적예정 -->
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="convertList.length === 0">
            <tr v-for="(vo, idx) in cntrList" :key="'cntrList_' + idx">
              <td v-if="idx == 0" :rowspan="rowCnt">{{ vo.blNo }}</td>
              <td v-if="idx == 0" :rowspan="rowCnt">{{ parseBkNo(vo.bkgNo) }}</td>
              <td v-if="vo.cntrIdx == 0" class="td_left_line">
                <!-- 컨테이너 갯수가 5개 이하일때 -->
                <p v-for="(cvo, cidx) in cntrList" :key="'cntrNo_' + cidx" v-show="cntrList.length <= 5">
                  <span :id="'cntrNo_' + cvo.cntrNo" class="cntrNo_area" v-if="cvo.cntrNo == detailParams.cntrNo">{{ cvo.cntrNo }}</span>
                  <a v-else href="#" :id="'cntrNo_' + cvo.cntrNo" class="cntrNo_area link" @click.prevent="setCntrList(cvo.cntrNo, cidx)">{{ cvo.cntrNo }}</a>
                </p>
                <!-- 컨테이너 갯수가 5개 이상이면 SelectBox로 -->
                <p v-if="cntrList.length > 5">
                  <select v-model="selectCntrNo" @change="setCntrList()">
                    <option v-for="(cvo, cidx) in cntrList" :key="'cntrNo_' + cidx" :value="cvo.cntrNo">{{ cvo.cntrNo }}</option>
                  </select>
                </p>
              </td>
              <td v-if="idx == 0" :rowspan="rowCnt" class="td_left_line">{{ cntrList[cIdx] && cntrList[cIdx].cntrSzCd }} {{ cntrList[cIdx] && cntrList[cIdx].cntrTypCd }}</td>
              <td v-if="idx == 0" :rowspan="rowCnt" class="td_left_line">
                <template v-if="cntrList[cIdx].shprVanYn === 'Y'">
                  <template v-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '01'">S'VAN + HZ</template>
                  <template v-else-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '02'">OOG + S'VAN</template>
                  <template v-else-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '03'">ING + S'VAN</template>
                  <template v-else-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '05'">NOR + S'VAN</template>
                  <template v-else-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '06'">FB + S'VAN</template>
                  <template v-else>S'VAN</template>
                </template>
                <template v-else>
                  <template v-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '01'">HZ</template>
                  <template v-else-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '02'">OOG</template>
                  <template v-else-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '03'">ING</template>
                  <template v-else-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '05'">NOR</template>
                  <template v-else-if="cntrList[cIdx] && cntrList[cIdx].cgoTypCd === '06'">FB</template>
                  <template v-else></template>
                </template>
              </td>
              <td v-if="idx == 0" class="td_left_line">{{ vo.polPortNm }} <br /> {{ $ekmtcCommon.isNotEmpty(vo.legEtd)? vo.legEtd : vo.etd === '0000.00.00/00:00' ? '' : vo.etd }}</td>
              <td v-if="idx == 0" class="td_left_line">{{ vo.podPortNm }}{{ vo.podTrmlNm }}<br /> {{ $ekmtcCommon.isNotEmpty(vo.legEta)? vo.legEta : vo.eta === '0000.00.00/00:00' ? '' : vo.eta }}</td>
              <td v-if="idx == 0" class="td_left_line">{{ vo.vslNm }}</td>
              <td v-show="registryNo === 'Y'" v-if="idx == 0" class="td_left_line">{{ vo.mrnNo || '' }}</td>
              <td v-if="idx == 0" class="td_left_line">
                <span v-if="vo.confirmCd === '01'" class="label red md">{{ $t('msg.CART010G010.022') }}</span><!-- 선적미확정 -->
                <span v-if="vo.confirmCd === '02'" class="label green md">{{ $t('msg.CART010G010.023') }}</span><!-- 선적확정 -->
                <span v-if="vo.confirmCd === '03'" class="label gray md">{{ $t('msg.CART010G010.024') }}</span><!-- 선적예정 -->
              </td>
            </tr>
          </tbody>
        </table>

        <p v-if="rsChVesselList.length > 0 && (bookingInfo.polCtrCd === 'JP' || bookingInfo.podCtrCd === 'JP')" class="txt_desc color_black">
          <span v-for="(vo, idx) in rsChVesselList" :key="'rsChvsList' + idx">
            {{ vo.logCont }} <br>
          </span>
        </p>

        <p v-if="searchFlag !== 'N'" class="txt_desc color_black">
          {{ $t('msg.CART010G010.025') }} <br>
          {{ $t('msg.CART010G010.026') }}<br>
          {{ $t('msg.CART010G010.027') }}<br>
          {{ $t('msg.CART010G010.028') }}<br>
          {{ $t('msg.CART010G010.071') }}
        </p>

        <div v-if="bookingInfo.podCtrCd === 'JP'">
          <p class="txt_desc color_black">
            こちらのボタンから、A/Nについての問合せ先、実入りの搬出先、空バンの返却先などの詳細をご確認いただけます。
          </p>
          <a class="button blue sh" style="margin:5px;" href="#" @click.prevent="CargoTrackingJpPodInfoPopCall()">POD INFORMATION( A/N 発行 所 / CY )</a> <!-- 검색 -->
        </div>

        <div v-if="detailFlag === 'Y'" class="position_relative mt25">
          <div style="display: flex; align-items: center; margin-bottom: 8px;">
            <h2 class="content_title" style="display: inline-block; margin-bottom: 0px !important;">{{ $t('msg.CART010G010.029') }}</h2><!-- 현재 위치 -->
            <a style="margin-left: 8px;" class="button blue sh" href="#" @click.prevent="openCargoEye()">{{ $t('msg.CART010G010.060') }}</a> <!-- 검색 -->
            <a v-if="rfTargetYn === 'Y'" style="margin-left: 8px;" class="button blue sh" href="#" @click.prevent="openRfDataInfoPop()">{{ $t('menu.MENU03.040') }}</a> <!-- 냉동 컨테이너 실시간 정보 -->
          </div>

          <span class="location_ico_update">{{ $t('msg.CART010G010.030') }} : {{ updateDate }}</span><!-- UPDATE -->
        </div>
        <!-- 현재 위치 -->
        <cargo-location-detail
          ref="loc"
          v-if="detailFlag === 'Y'"
          :detail-params="detailParams"
          :average-params="averageParams"
          :detail-date-list="detailDateList"
          :detail-list="detailList"
          :detail-inland-list="inlandList"
          :date-flag="dateFlag"
          :rank="rank"
          :lang="lang"
          :dly-ctr-cd="dlyCtrCd"
          :cy-cfs-yn="cyCfsYn"
          :inland-list-in="inlandListIn"
        />
        <!-- 지도 관련 -->
        <cargo-vessel-location
          ref="map"
          v-if="detailFlag === 'Y' && showCargoEye() && isOpenCargoEye"
          :select-cntr="selectCntrList"
          :all-vessel-location-info="allVesselLocationInfo"
          :all-port-location-info="allPortLocationInfo"
          :vsl-location="vslLocation"
        >
        </cargo-vessel-location>

        <!-- <cargo-map-detail
          ref="map"
          v-else
          :detail-params="detailParams"
          :lang="lang"
        /> -->

        <h2 v-if="detailFlag === 'Y' && inlandList.length > 0" class="content_title">{{ $t('msg.CART010G010.031') }}</h2><!-- 내륙 이동 상세 정보 -->
        <table v-if="detailFlag === 'Y' && inlandList.length > 0" class="tbl_col mt10">
          <colgroup>
            <col style="width:20%">
            <col style="width:18%">
            <col style="width:44%">
            <col style="width:18%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CART010G010.032') }}</th><!-- CONTAINER NUMBER -->
              <th>{{ $t('msg.CART010G010.033') }}</th><!-- EVENT NAME -->
              <th>{{ $t('msg.CART010G010.034') }}</th><!-- LOCATION -->
              <th>{{ $t('msg.CART010G010.035') }}</th><!-- TIME(KST) -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in inlandList" :key="item.seq">
              <td>{{ item.cntrNo }}</td>
              <td>{{ item.eventCd }}</td>
              <td class="text_left">{{ item.plcNm }}</td>
              <td>{{ item.plcDtm }}</td>
            </tr>
          </tbody>
        </table>

        <!-- 마감 정보 , 출항이후는 가리도록 설정-->
        <close-info
          ref="closeInfo"
          v-if="detailFlag === 'Y' && ( this.bookingInfo.etd > this.bookingInfo.toDay)"
          :parent-info="closeParams"
        />

        <!-- 팝업관련 -->
        <win-layer-pop>
          <component
            v-if="customComponent"
            :is="customComponent"
            :detail="detail"
            :ok-hidden="isHidden"
            @close="closePoup"
          />
        </win-layer-pop>

        <win-layer-pop class="ongoing-rf-list-popup">
          <component
            v-if="popupComponent"
            :is="popupComponent"
            @close="closeRfPopup"
            @callback="popupCallback"
            :parent-info="popupParams"
          />
        </win-layer-pop>

        <win-layer-pop class="subscription-pop">
          <component
            v-if="subscriptionPopupComponent"
            :is="subscriptionPopupComponent"
            @close="closeSubscriptionPopup"
            @callback="subscriptionPopupCallback"
            :parent-info="subscriptionPopupParams"
          />
        </win-layer-pop>
      </div>
      <div v-if="searchFlag === 'Y' && noDataFlag === 'Y'">
        <table class="tbl_col mt10">
          <colgroup>
            <col style="width:10%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:10%">
            <col style="width:15%">
            <col style="width:16%">
            <col style="width:15%">
            <col style="width:10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CART010G010.012') }}</th><!-- B/L No. -->
              <th>{{ $t('msg.CART010G010.014') }}</th><!-- Booking No. -->
              <th>{{ $t('msg.CART010G010.013') }}</th><!-- Container No. -->
              <th>{{ $t('msg.CART010G010.016') }}</th><!-- Size/Type -->
              <th>{{ $t('msg.CART010G010.017') }}</th><!-- 출발 -->
              <th>{{ $t('msg.CART010G010.018') }}</th><!-- 도착 -->
              <th>{{ $t('msg.CART010G010.019') }}</th><!-- 선명/항차 -->
              <th>{{ $t('msg.CART010G010.020') }}/<br>{{ $t('msg.CART010G010.021') }}</th><!-- Reserve / Confirm -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="8">{{ $t('msg.CART010G010.056') }}</td><!-- No Data. -->
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</template>

<script>
import cargo from '@/api/rest/trans/cargo'
import commons from '@/api/services/commons'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'
import rfDg from '@/api/rest/trans/rfDg'
import specialCgo from '@/api/rest/trans/specialCgo'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo2'
import Captcha from '@/components/common/Captcha.vue'
//import bookings from '@/api/rest/trans/bookings'

export default {
  name: 'CargoTracking',
  components: {
    Captcha,
    EBreadcrumbs,
    EDateRangePicker: () => import('@/components/common/EDateRangePickerForOog'),
    EDatePicker: () => import('@/components/common/EDatePicker'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    RfDataNotiPop: () => import('@/pages/trans/popup/RfDataNotiPop'),
    RfDataInfoPop: () => import('@/pages/trans/popup/RfDataInfoPop'),
    CargoTrackingSubscriptionPop: () => import('@/pages/trans/popup/CargoTrackingSubscriptionPop'),
    'close-info': () => import('@/components/trans/CloseInfo.vue'),
    'cargo-location-detail': () => import('@/pages/trans/CargoLocationDetail'),
    'cargo-map-detail': () => import('@/pages/trans/CargoMapDetail'),
    'cargo-vessel-location': () => import('@/pages/trans/CargoVesselLocation'),
    CargoTrackingJpPodInfoPop: () => import('@/pages/trans/popup/CargoTrackingJpPodInfoPop') /* JP pod tracking 정보 */
  },
  data: function () {
    return {
      customComponent: null,
      popupComponent: null,
      subscriptionPopupComponent: null,
      popupCallback: null,
      subscriptionPopupCallback: null,
      popupParams: {},
      subscriptionPopupParams: {},
      commonCodes: [],
      //검색여부 플래그
      searchFlag: 'N',
      //상세정보 플래그
      detailFlag: 'N',
      //로그인 안했을때 특정 Bl 검색시 No Data플래그
      noDataFlag: 'N',
      isLoginYn: 'N',
      //사이트별 언어설정
      lang: '',
      //검색조건
      params: {
        dtKnd: 'BL',
        blNo: ''
      },
      //상세로 넘기는 정보
      detailParams: {
        blNo: '',
        bkgNo: '',
        cntrNo: '',
        cntrTypCd: '',
        tsYn: '',
        rail: '',
        railCnt: 0,
        //상세에서 처음에 보여줄 내륙정보 셋팅 (마지막 LEG ETD/ETA 기준)
        inlandEta: '',
        inlandEtd: '',
        dlyCtrCd: ''
      },
      //상세 평균 이동거리 정보
      averageParams: {
        podPortNm: '',
        podPortCd: '',
        dlyPlcNm: '',
        icdCd: '',
        avgTnsDrtm: ''
      },
      //컨테이너 관련 정보
      totalRows: '',
      convertList: [],
      selectCntrNo: '',
      tsYn: '',
      //컨테이너 detail 관련 정보
      detailList: [],
      detailDateList: [],
      //로그인 없이 비엘 임시로 조회 시 imsi 제한 BL체크
      imsiblChk: 'N',
      //검색한 containerList
      cntrList: [],
      selectCntrList: [],
      //마감 정보, 내륙 정보
      inlandList: [],
      inlandListIn: [],
      closeParams: {
        vslCd: '',
        voyNo: '',
        polPortCd: '',
        podPortCd: '',
        polCtrCd: '',
        polTrmlCd: '',
        menuCd: ''
      },
      vesselInfoDetail: {},
      //상세에서 rank 처리
      rank: 0,
      rowCnt: 0,
      size: 0,
      dateFlag: '',
      bookingInfo: {},
      scriptEl: null,
      isOpenCargoEye: false,
      //
      cIdx: 0,
      relYn: true, // JamSin
      prdYn: process.env.VUE_APP_MODE !== 'PRD',

      // 냉동 컨테이너 버튼 대상 정보
      rfTarget: [],
      rfTargetYn: 'N',
      formData: {
        blNo: '',
        flag: 'HZ'
      },
      dgItems: [],
      allPortLocationInfo: [],
      allVesselLocationInfo: [],
      vslLocation: [],
      dlyCtrCd: '',
      cyCfsYn: '',
      rsChVesselList: [],
      nineNum: '',
      inputNine: '',
      captchaYn: '',
      isCaptcha: false,
      registryNo: 'N'
    }
  },
  computed: {
    ...rootComputed,
    updateDate: function () {
      let date = ''

      if (this.detailList.length > 0) {
        for (let i = 0; i < this.detailList.length; i++) {
          if (this.$ekmtcCommon.isEmpty(this.detailList[i].rkNext)) {
            date = this.$ekmtcCommon.changeDatePattern(this.detailList[i].mvntDt, '-')
          }
        }
      }

      return date
    },
    isEkmtc () {
      return sessionStorage.getItem('loginId').indexOf('EKMTC_') !== -1
    }
  },
  async created () {
    this.init()
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  destroyed () {
    console.log('@@@@ destroyed CargoTracking')
    if (this.scriptEl !== null) {
      document.body.removeChild(this.scriptEl)
    }
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    async openRfDataInfoPop (e, page, gridIndex) {
      const params = {
        cntrList: this.cntrList,
        convertList: this.convertList,
        detailParams: this.detailParams,
        selectCntrList: this.selectCntrList,
        detailList: this.detailList,
        rank: this.rank
      }

      this.callRfDataNotiPop(params)
    },
    async callRfDataNotiPop (params) {
      const THIS = this

      this.popupCallback = (p) => {
        THIS.closeRfPopup()
        THIS.callRfDataInfoPop(p)
      }

      this.openRfPopup('RfDataNotiPop', params)
    },
    async callRfDataInfoPop (params) {
      this.openRfPopup('RfDataInfoPop', params)
    },
    closeRfPopup () {
      this.popupParams = {}
      this.popupComponent = null
      this.popupCallback = ''
      this.$ekmtcCommon.layerClose('.ongoing-rf-list-popup')
    },
    closeSubscriptionPopup () {
      this.subscriptionPopupParams = {}
      this.subscriptionPopupComponent = null
      this.subscriptionPopupCallback = ''
      this.$ekmtcCommon.layerClose('.subscription-pop')
    },
    openRfPopup (compNm, params) {
      this.popupParams = params

      this.popupComponent = compNm
      this.$ekmtcCommon.layerOpen('.ongoing-rf-list-popup')
    },
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.getCargoCntrList()
        }
      }
    },
    parseBkNo (s) {
      // console.log(s.replace(/(<br>)/g, '\n'))
      return s.replace(/(<br>)/g, '\n')
    },
    showCargoEye () {
      let cd = localStorage.getItem('service_ctrcd')
      if (cd !== null && cd !== 'IN') {
        return true
      } else {
        return false
      }
    },
    async openCargoEye () {
      setTimeout(() => {
        this.isOpenCargoEye = !this.isOpenCargoEye
      }, 500)
    },
    async init () {
      // 로그인여부 가져오기
      if (this.auth.userId) {
        this.isLoginYn = 'Y'

        //냉동컨테이너 대상정보 가져오기
        const cdId = ['WB024']
        await rfDg.getRfTarget()
          .then(res => {
            // console.log('res @@@ ', res)
            if (res.data.rfTarget.length > 0) {
              this.rfTarget = res.data.rfTarget
            }
          })
      }

      // 언어 가져오기
      await this.settingLang()

      if (Object.keys(this.$route.query).length > 0) {
        const vo = this.$route.query

        if (vo != null) {
          this.params.blNo = vo.keyword

          if (vo.searchType === 'BOOKING') {
            this.params.dtKnd = 'BK'
          }
          if (vo.searchType === 'BL') {
            this.params.dtKnd = 'BL'
          }
          if (vo.searchType === 'CONTAINER') {
            this.params.dtKnd = 'CN'
          }
        }

        await this.getCargoCntrList()
      }

      //Event Cd 가져오기
      const arrCdId = ['WB014']
      this.commonCodes = await commons.getCommonsCodesCommon(arrCdId)

      // 운행중인 모든 선박 조회 : 지도 컴포넌트로 데이터 전달
      await vslScheduleInfo.getAllVslLocationInfo()
        .then(response => {
          if (response.status === 200) {
            const resData = response.data
            this.allVesselLocationInfo = resData.allVesselLocationInfo
          }
        }).catch(error => {
          console.log(error)
        })
    },
    async settingLang () {
      const TH = this

      //유저 serviceLang정보 가져오기
      if (this.auth.userId) {
        TH.lang = this.serviceLang
      } else {
        TH.lang = this.auth.serviceLang
      }
    },
    async getCargoCntrList () {
      console.log('@@@@ enter getCargoCntrList')

      if (this.isCaptcha && this.captchaYn !== 'Y') {
        alert('인증을 해주세요.')
        return
      }

      this.isOpenCargoEye = false

      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: {} })
      }

      const TH = this
      const params = TH.params

      //플래그 초기화
      TH.detailFlag = 'N'
      TH.noDataFlag = 'N'
      TH.searchFlag = 'N'
      TH.rfTargetYn = 'N'

      // 본사직원이면 버튼 오픈
      if (this.auth.userId && this.isEkmtc) {
        this.rfTargetYn = 'Y'
      }

      if (this.checkValidationAll()) {
        //로그인 없이 특정 Bl 넘버 조회시 No Data.
        if (this.isLoginYn === 'N' && (params.blNo === 'PNC2838803' || params.blNo === 'PNC2840780' || params.blNo === 'PNC2890557' || params.blNo === 'PNC2899521' || params.blNo === 'PNC2939180' || params.blNo === 'PNC2944001')) {
          TH.noDataFlag = 'Y'
          TH.searchFlag = 'Y'
          TH.imsiblChk = 'Y'

          return
        }

        await cargo.getContainerList(this.params).then(res => {
          console.log('getContainerList res @@@@@ ', res)

          const data = res.data
          const cntrList = data.cntrList
          const rsforChVessel = data.rsforChVessel

          TH.cntrList = []
          TH.cntrList = cntrList
          TH.rsChVesselList = rsforChVessel

          if (cntrList.length > 0) {
            //cntr가공
            TH.selectCntrNo = cntrList[0].cntrNo
            this.setCntrList(cntrList[0].cntrNo)

            if (cntrList[0].polCtrCd === 'PH' || cntrList[0].podCtrCd === 'PH') {
              this.registryNo = 'Y'
            } else {
              this.registryNo = 'N'
            }

            // console.log('cstNo @@@@@ ', this.selectedProfile.cstNo)
            // console.log('bkgShprCstNo @@@@@ ', cntrList[0].bkgShprCstNo)
            // console.log('hcneCstNo @@@@@ ', cntrList[0].hcneCstNo)
          } else {
            TH.cntrList = []
            TH.convertList = []
            if (data.useYn === 'N') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CART010G010.072')) //현재 검색 불가능 합니다. 잠시후 다시 시도해주세요.
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CART010G010.051')) //검색 결과가 없습니다.
            }

            this.registryNo = 'N'
          }

          TH.searchFlag = 'Y'
        })
      }
    },
    async setCntrList (cntrNo, cidx) {
      console.log('@@@@ cidx:', cidx)
      const TH = this
      this.cIdx = cidx || 0

      TH.convertList = []
      TH.detailParams = {}

      //select를 골라서 들어왔을경우
      if (this.$ekmtcCommon.isEmpty(cntrNo)) {
        cntrNo = TH.selectCntrNo
      }

      const target = this.rfTarget
      const profileCstNo = this.selectedProfile.cstNo
      const cntrList = this.cntrList
      const profileCtrCd = this.selectedProfile.userCtrCd
      // 냉동 컨테이너 조회 버튼 조건 확인
      // 검색 조건의 blNo로 DG 정보 가져오기
      this.formData.blNo = cntrList[0].blNo
      await specialCgo.findSpecialCgoInfoList(this.formData).then(res => {
        // console.log('res @@@@ ', res)
        this.dgItems = res.data
      })

      /* NEW IOT RF CHECK */
      cntrList.forEach(cntrItem => {
        if (cntrItem.iotCntrCnt > 0) {
          if (cntrItem.bkgShprCstNo === this.auth.cstCd || cntrItem.hcneCstNo === this.auth.cstCd) {
            this.rfTargetYn = 'Y'
          }
        }
      })
      /*
      const params = {
        bkgCtrCd: profileCtrCd, //'AE'
        bkgShprCstNo: profileCstNo, //'GDA002'
        polCtrCd: cntrList[0].polCtrCd || ' ', //'AE'
        polPortCd: cntrList[0].polPortCd || ' ', //'AJM'
        podCtrCd: cntrList[0].podCtrCd || ' ', //'AE'
        podPortCd: cntrList[0].podPortCd || ' ', //'AUH'
        cntrSzCd: cntrList[0].cntrSzCd || ' ', //'20'
        cntrTypCd: cntrList[0].cntrTypCd || ' ', //'GP'
        cgoTypCd: cntrList[0].cgoTypCd || ' ' //'04'
      }

      await rfDg.getIotRfCount(params).then(res => {
        if (res.data.iotCnt > 0) {
          // DG 존재 유무 확인(DG_YN 확인)
          if (this.dgItems.length > 0) {
            // console.log('this.dgItems.length > 0 @@@')

            // 검색 B/l의 컨테이너와 DG정보의 컨테이너 비교 확인
            cntrList.forEach(cntrItem => {
              this.dgItems.forEach(dgItem => {
                if (cntrItem.cntrNo === dgItem.cntrNo) {
                  // console.log('cntrItem.cntrNo === dgItem.cntrNo @@@')

                  // 현재 선택한 컨테이너가 DG 요건 부합여부 확인
                  if (cntrItem.cntrNo === cntrNo) {
                    // console.log('cntrItem.cntrNo === this.selectCntrNo @@@')

                    if (item.unno === dgItem.imdunNo) {
                      // console.log('item.unno === dgItem.imdunNo @@@')

                      this.rfTargetYn = 'Y'
                    }
                  }
                }
              })
            })
          }
        }
      })
      */
      /* */
      //await rfDg.getIotRfCount().then(res => {
      //  console.log('getIotRfCount =================>', res.data)
      //  if (res.data.iotCnt > 0) {
      //    console.log('IOT 가 존재함.!!')
      //  }
      //})
      // 로그인한 프로필이 공통코드에 지정한 대상인지 확인
      // 권한 체크
      // 1. 로그인 유저 cstNo = 공통코드 booking shipper
      // 2. 로그인유저 cstNo = 검색한 B/L 의 Handling con.

      /* 기존 코드
      this.rfTarget.forEach((item, idx) => {
        if (profileCstNo === item.bkgShpr || profileCstNo === cntrList[0].hcneCstNo) {
          // console.log('profileCstNo === item.hcne @@@')

          if (item.pol === cntrList[0].polPortCd && item.pod === cntrList[0].podPortCd &&
              item.cntrSz === cntrList[0].cntrSzCd && item.cntrTyp === cntrList[0].cntrTypCd) {
              // console.log('item.pol === cntrList[0].polPortCd')

              // DG 존재 유무 확인(DG_YN 확인)
              if (this.dgItems.length > 0) {
                // console.log('this.dgItems.length > 0 @@@')

                // 검색 B/l의 컨테이너와 DG정보의 컨테이너 비교 확인
                cntrList.forEach(cntrItem => {
                  this.dgItems.forEach(dgItem => {
                    if (cntrItem.cntrNo === dgItem.cntrNo) {
                      // console.log('cntrItem.cntrNo === dgItem.cntrNo @@@')

                      // 현재 선택한 컨테이너가 DG 요건 부합여부 확인
                      if (cntrItem.cntrNo === cntrNo) {
                        // console.log('cntrItem.cntrNo === this.selectCntrNo @@@')

                        if (item.unno === dgItem.imdunNo) {
                          // console.log('item.unno === dgItem.imdunNo @@@')

                          this.rfTargetYn = 'Y'
                        }
                      }
                    }
                  })
                })
              }
              // console.log('333333')
          }
        }
      })
      */
      //[AS-IS] 1. 최초 하단에 보여줄 Detail처리는 컨테이너의 첫번째 정보
      //        2. %%로 묶여있는 여러개의 컨테이너 정보(TS로 묶여있는 정보)는 가공처리
      //          2-1. 컨테이너 리스트가 다섯개 이상이면 셀렉트로 보여준다.
      //          2-2. eta, etd에 대한 데이터의 가공이 필요하다 1. YYYY.MM.DD 2. 날짜 영문표기

      for (let i = 0; i < TH.cntrList.length; i++) {
        if (TH.cntrList[i].polPortNm !== undefined && (TH.cntrList[i].polPortNm).indexOf('%%') > -1) {
          // if (TH.cntrList[i].polPortNm.indexOf('%%') > -1) {

          TH.convertList = TH.cntrList.reduce((arr, vo, idx) => {
            if (arr === undefined) {
              arr = []
            }

            if (cntrNo === '' && idx !== 0) {
              return arr
            } else if (cntrNo !== '' && cntrNo !== vo.cntrNo) {
              return arr
            }

            const arrPolPortNm = vo.polPortNm.split('%%')
            const arrPodPortNm = vo.podPortNm.split('%%')
            const arrPodSchSeq = vo.podSchSeq.split('%%')
            const arrVslNm = vo.vslNm.split('%%')
            const arrEtd = vo.etd.split('%%')
            const arrEta = vo.eta.split('%%')
            const arrConfirmCd = vo.confirmCd.split('%%')
            const cnntDds = vo.cnntDds.split('%%')
            const trstDds = vo.trstDds.split('%%')
            const len = arrPolPortNm.length

            let etdImsi = ''
            let etaImsi = ''

            for (let i = 0; i < len; i++) {
              //2-2 eta, etd를 arr에 넣기전에 가공 => 000000000000일때와 ENG일때 가공 처리 - ENG일때는 홈페이지에서 바뀌는게 있는지 여부
              //    railCnt처리
              //    inland Eta, Etd 처리
              if (arrEtd[i] === '000000000000') {
                if (vo.polSchChk === 'A') {
                  etdImsi = this.$ekmtcCommon.getAddDayForTracking(arrEta[i - 1].substring(0, 8), parseInt(cnntDds[i]))
                  arrEtd[i] = this.$ekmtcCommon.getAddDayForTracking(arrEta[i - 1].substring(0, 8), parseInt(cnntDds[i])) + '1200'
                  arrEtd[i] = arrEtd[i].substring(0, 4) + '.' + arrEtd[i].substring(4, 6) + '.' + arrEtd[i].substring(6, 8) + '/' + arrEtd[i].substring(8, 10) + ':' + arrEtd[i].substring(10, 12)
                } else {
                  etdImsi = arrEtd[i].substring(0, 8)
                  arrEtd[i] = arrEtd[i].substring(0, 4) + '.' + arrEtd[i].substring(4, 6) + '.' + arrEtd[i].substring(6, 8) + '/' + arrEtd[i].substring(8, 10) + ':' + arrEtd[i].substring(10, 12)
                }
              }
              if (arrEta[i] === '000000000000') {
                if (vo.polSchChk === 'A') {
                  arrEta[i] = this.$ekmtcCommon.getAddDayForTracking(etdImsi.substring(0, 8), parseInt(trstDds[i])) + '1200'
                  arrEta[i] = arrEta[i].substring(0, 4) + '.' + arrEta[i].substring(4, 6) + '.' + arrEta[i].substring(6, 8) + '/' + arrEta[i].substring(8, 10) + ':' + arrEta[i].substring(10, 12)
                } else {
                  etaImsi = arrEta[i].substring(0, 8)
                  arrEta[i] = arrEta[i].substring(0, 4) + '.' + arrEta[i].substring(4, 6) + '.' + arrEta[i].substring(6, 8) + '/' + arrEta[i].substring(8, 10) + ':' + arrEta[i].substring(10, 12)
                }
              }

              if (arrVslNm[i].indexOf('Rail') > -1) {
                TH.detailParams.rail = 'Y'
                TH.detailParams.railCnt = i
              }

              if (arrVslNm[len - 1].indexOf('Rail') > -1) {
                TH.detailParams.icdRail = 'Y'
              }

              if (arrVslNm[len - 1].indexOf('Truckage') > -1) {
                TH.detailParams.icdTruckage = 'Y'
              }

              //inland에 넣을 마지막값 넣기
              if (i === len - 1) {
                TH.detailParams.inlandEta = arrEta[i]
                TH.detailParams.inlandEtd = arrEtd[i]
              }

              let cgoTypNm = ''

              if (vo.shprVanYn === 'Y') {
                if (vo.cgoTypCd === '01') {
                  cgoTypNm = 'S\'VAN + HZ'
                } else if (vo.cgoTypCd === '02') {
                  cgoTypNm = 'OOG + S\'VAN'
                } else if (vo.cgoTypCd === '03') {
                  cgoTypNm = 'ING + S\'VAN'
                } else if (vo.cgoTypCd === '05') {
                  cgoTypNm = 'NOR + S\'VAN'
                } else if (vo.cgoTypCd === '06') {
                  cgoTypNm = 'FB + S\'VAN'
                } else {
                  cgoTypNm = 'S\'VAN'
                }
              } else {
                if (vo.cgoTypCd === '01') {
                  cgoTypNm = 'HZ'
                } else if (vo.cgoTypCd === '02') {
                  cgoTypNm = 'OOG'
                } else if (vo.cgoTypCd === '03') {
                  cgoTypNm = 'ING'
                } else if (vo.cgoTypCd === '05') {
                  cgoTypNm = 'NOR'
                } else if (vo.cgoTypCd === '06') {
                  cgoTypNm = 'FB'
                }
              }

              arr.push({
                blNo: vo.blNo,
                bkgNo: vo.bkgNo,
                cntrNo: vo.cntrNo,
                cntrSzCd: vo.cntrSzCd,
                cntrTypCd: vo.cntrTypCd,
                cgoTypNm: cgoTypNm,
                cntrIdx: i,
                rowCnt: len,
                polPortNm: arrPolPortNm[i],
                podPortNm: arrPodPortNm[i],
                podSchSeq: arrPodSchSeq[i],
                vslNm: arrVslNm[i],
                etd: arrEtd[i].indexOf('/') > -1 ? arrEtd[i] : TH.$ekmtcCommon.changeDatePattern(arrEtd[i]),
                eta: arrEta[i].indexOf('/') > -1 ? arrEta[i] : TH.$ekmtcCommon.changeDatePattern(arrEta[i]),
                confirmCd: arrConfirmCd[i],
                vslCd: vo.vslCd,
                voyNo: vo.voyNo,
                polTrmlCd: vo.polTrmlCd,
                polPortCd: vo.polPortCd,
                podPortCd: vo.podPortCd,
                polCtrCd: vo.polCtrCd,
                mapVslNm: arrVslNm[i].substring(2),
                mapEtd: arrEtd[i],
                mapEta: arrEta[i],
                cyCfsYn: vo.cyCfsYn
              })
            }
            TH.dlyCtrCd = vo.dlyCtrCd
            return arr
          }, [])

          TH.rowCnt = TH.convertList.length
        } else {
          //%%가 없는 경우도 있으니 날짜형식 바꿔주기
          if (TH.cntrList[i].eta !== undefined) {
            if (TH.cntrList[i].eta.indexOf('-') < 0) {
              TH.cntrList[i].eta = this.$ekmtcCommon.changeDatePattern(TH.cntrList[i].eta, '-')
              TH.cntrList[i].etd = this.$ekmtcCommon.changeDatePattern(TH.cntrList[i].etd, '-')
            }
          }
          TH.cntrList[i].cntrIdx = i

          // 지도 위치 검색 데이터
          TH.cntrList[i].mapVslNm = TH.cntrList[i].vslNm.substring(2)
          TH.cntrList[i].mapEtd = TH.cntrList[i].etd.replaceAll('-', '').replaceAll(':', '').split(' ').join('')
          TH.cntrList[i].mapEta = TH.cntrList[i].eta.replaceAll('-', '').replaceAll(':', '').split(' ').join('')

          TH.dlyCtrCd = TH.cntrList[i].dlyCtrCd
        }
      }

      this.cyCfsYn = this.cntrList[0].cyCfsYn

      let convertList = this.convertList
      let convertListYn = 'Y'
      let cntrLegInfo = []

      // 컨테이너 개수에 따른 List 변수 구분
      if (this.convertList.length === 0) {
        convertList = this.cntrList
        convertListYn = 'N'
      }

      // leg 데이터 기준으로 출발,도착시간 세팅, registy no. 값세팅
      await cargo.getCntrEtdEtaInfo(TH.cntrList[0].blNo).then(res => {
        cntrLegInfo = res.data.cntrEtdEtaList

        for (var cnv of convertList) {
          for (var leg of cntrLegInfo) {
            if (Number(cnv.cntrIdx + 1) === Number(leg.legSeq)) {
              cnv.legEtd = TH.$ekmtcCommon.changeDatePattern(leg.polEtd)
              cnv.legEta = TH.$ekmtcCommon.changeDatePattern(leg.podEta)
              cnv.mrnNo = leg.mrnNo
            }
          }
        }
      })

      // 도착지 터미널 이름 검색
      for (var item of convertList) {
        let podTrmlNm = ''

        await cargo.getPodTrmlNm(item.podSchSeq).then(res => {
          podTrmlNm = res.data.podTrmlNm

          if (this.$ekmtcCommon.isNotEmpty(podTrmlNm)) {
            podTrmlNm = `(${podTrmlNm})`
          } else {
            podTrmlNm = ''
          }

          item.podTrmlNm = podTrmlNm
        }).finally(() => {
          if (convertListYn === 'Y') {
            this.convertList = convertList
          } else {
            this.cntrList = convertList
          }
        })
      }

      let covParams = ''
      let vslLocation = []
      let propVslLocation = []
      let cntrDataList = this.convertList.length > 0 ? this.convertList : Array(this.cntrList[0])
      let cntrBlNo = this.convertList.length > 0 ? this.convertList[0].blNo : this.cntrList[0].blNo

      for (let vsl of cntrDataList) {
        let chkAis = true
        let vslNm = vsl.mapVslNm.split('/')[0].trim()

        if (vslNm === 'Truckage' || vslNm === 'Truck' || vslNm === 'Rail' || vslNm === 'XIANGFU2') {
          chkAis = false
        }

        if (chkAis) {
          covParams += vsl.mapVslNm + ':'
        }
      }

      let vslParams = {
        covParams: covParams,
        blNo: cntrBlNo,
        cntrDataList: cntrDataList
      }

      await rfDg.getRfVslLocation(vslParams)
        .then(res => {
          // console.log('getRfVslLocation res @@@@@@ ', res)
          if (res.data.rfVslLocationList.length > 0) {
            vslLocation = res.data.rfVslLocationList
          }

          // 데이터 검색전 리셋
          this.vslLocation = []

          vslLocation.forEach((vitem, idx) => {
            cntrDataList.forEach((citem, idx) => {
              if (vitem.vslNm.trim() === citem.mapVslNm.split('/')[0].trim()) { // vslNm
                propVslLocation.push({
                  rteCd: vitem.rteCd,
                  podRteCd: vitem.podRteCd,
                  voyNo: citem.mapVslNm.split('/')[1], // voyNo
                  vslCd: vitem.vslCd,
                  vslNm: citem.mapVslNm.split('/')[0],
                  polPortCd: vitem.polPortCd,
                  polTrmlCd: vitem.polTrmlCd,
                  podPortCd: vitem.podPortCd,
                  podTrmlCd: vitem.podTrmlCd,
                  eta: citem.mapEta,
                  etd: citem.mapEtd,
                  idx: citem.cntrIdx ? (citem.cntrIdx + 1) : 1
                })
              }
            })
          })

          this.vslLocation = propVslLocation
        })

      let data = {
        vslCd: '',
        rteCd: '',
        voyNo: '',
        portCd: '',
        drctn: ''
      }
      // 화물추적 정보로 AIS 데이터 가공
      for (let item of this.vslLocation) {
        // eta, etd 시간 변환
        let etaMvntDt = moment(item.eta.substring(0, 8)).format('YYYY-MM-DD')
        let etaMvntTm = item.eta.substring(8, 10) + ':' + item.eta.substring(10, 12) + ':' + '00'
        let etaDtm = etaMvntDt + ' ' + etaMvntTm

        let etdMvntDt = moment(item.etd.substring(0, 8)).format('YYYY-MM-DD')
        let etdMvntTm = item.etd.substring(8, 10) + ':' + item.etd.substring(10, 12) + ':' + '00'
        let etdDtm = etdMvntDt + ' ' + etdMvntTm

        let eta = new Date(etaDtm)
        let etd = new Date(etdDtm)

        eta = eta.getTime() + (16 * 60 * 60 * 1000)
        etd = etd.getTime() + -(16 * 60 * 60 * 1000)

        eta = new Date(eta)
        etd = new Date(etd)

        eta = moment(eta).format('YYYYMMDDHHMMSS')
        etd = moment(etd).format('YYYYMMDDHHMMSS')

        data = {
          vslCd: item.vslCd,
          rteCd: item.rteCd,
          podRteCd: item.podRteCd,
          voyNo: item.voyNo,
          portCd: item.polPortCd,
          etd: etd,
          eta: eta,
          vslCnt: this.vslLocation.length
        }

        await rfDg.getCargoVesselPreiousRoute(data)
          .then(async response => {
            // console.log('getVesselPreiousRoute response @@@@@ ', response)
            if (response.status === 200) {
              const resData = response.data
              const vesselPreiousRoute = resData.vesselPreiousRoute

              item.vesselPreiousRoute = vesselPreiousRoute
            }
          }) // previous data API end
          .catch(error => {
            console.log(error)
          })
      }

      if (this.$ekmtcCommon.isEmpty(cidx)) {
        let filtered = this.cntrList.filter((item) => item.cntrNo === TH.selectCntrNo)

        if (filtered.length > 0) {
          this.cIdx = filtered[0].cntrIdx
          this.selectCntrList = filtered
        }
      }

      if (TH.cntrList.length > 0) {
        //부킹정보 가져오기
        let tmpBkgNo = TH.cntrList[0].bkgNo
        let paramBkg = ''
        if (!tmpBkgNo.includes('<br>')) {
          paramBkg = tmpBkgNo
        } else {
          paramBkg = tmpBkgNo.split('<br>')[0]
        }

        this.getBookingInfo(paramBkg)
      }
      //마감정보 가져오기
      //this.getCloseInfo()

      // 선택한 컨테이너의 상세정보를 가져온다.
      if (this.$ekmtcCommon.isNotEmpty(cntrNo)) {
        this.getCargoCntrDetail(cntrNo)
      }
    },
    async getCargoCntrDetail (cntrNo) {
      console.log('########' + cntrNo + '#########')

      this.detailFlag = 'N'
      const TH = this
      const detailParams = TH.detailParams
      let tmpBlNo = ''
      let tmpBkgNo = ''
      let tmpTsYn = ''
      let tmpCntrTypCd = ''
      let list = this.cntrList

      await list.forEach(item => {
        if (item.cntrNo === cntrNo) {
          tmpBlNo = item.blNo
          tmpBkgNo = item.bkgNo
          tmpTsYn = item.tsYn
          tmpCntrTypCd = item.cntrTypCd
        }
      })
      // console.log('list @@@@@', list)

      detailParams.dtKnd = this.params.dtKnd
      // 상세정보 할당하기
      detailParams.blNo = tmpBlNo
      if (!tmpBkgNo.includes('<br>')) {
        detailParams.bkgNo = tmpBkgNo
        detailParams.strBkgNo = tmpBkgNo
      } else {
        detailParams.bkgNo = tmpBkgNo.split('<br>')[0]
        detailParams.strBkgNo = tmpBkgNo.split('<br>').join(';')
      }
      detailParams.cntrNo = cntrNo
      detailParams.tsYn = tmpTsYn
      detailParams.cntrTypCd = tmpCntrTypCd

      // console.log('detaillParams @@@@@', detailParams)
      await cargo.getContainerDetail(detailParams).then(res => {
        // console.log(res)

        // 초기화
        this.detailList = []
        this.inlandListIn = []
        this.detailDateList = []
        this.inlandList = []
        this.averageParams = {}
        this.dateFlag = ''
        this.rank = ''
        this.inlandEta = ''
        this.inlandEtd = ''

        // 할당
        this.detailList = [...res.data.trackingList, ...res.data.inlandListIn]
        this.inlandListIn = res.data.inlandListIn

        this.detailList.sort((a, b) => {
          return b.rk - a.rk
        })

        this.detailDateList = res.data.dtVo
        this.dateFlag = res.data.dateFlag

        // trackingList의 가장 최신 rk 값으로 변경
        this.rank = this.detailList.length > 0 && this.$ekmtcCommon.isNotEmpty(this.detailList[0].rk) ? this.detailList[0].rk : res.data.rank

        if (res.data.inlandList.length > 0) {
          this.inlandList = res.data.inlandList

          // 내륙정보는 초기 5개만 보여주기 (5개부터는 Junction Crossed(21)와 ICD Crossed(20) 보여주기)
          if (this.inlandList.length > 5) {
            let tmpInlandList = this.inlandList

            tmpInlandList = this.inlandList.splice(5)

            this.inlandList.splice(5)

            tmpInlandList.forEach(item => {
              if (item.eventCd === '20' || item.eventCd === '21') {
                this.inlandList.push(item)
              }
            })
          }

          //내륙시간 가공
          const tmpCommEventList = this.commonCodes.WB014

          if (tmpCommEventList !== undefined && tmpCommEventList !== null) {
            for (let j = 0; j < this.inlandList.length; j++) {
              this.inlandList[j].plcDtm = this.$ekmtcCommon.changeDatePattern(this.inlandList[j].plcDtm, '-')
              this.inlandList[j].eventCd = this.getEventName(this.inlandList[j].eventCd)
            }
          }

          this.inlandList = this.inlandList.reverse()
        }

        if (this.$ekmtcCommon.isNotEmpty(res.data.averageInfo)) {
          this.averageParams = res.data.averageInfo

          // 평균시간 가공
          this.averageParams.avgTnsDrtm = this.$ekmtcCommon.makeDateForm(this.averageParams.avgTnsDrtm)
        }
      })

      this.detailFlag = 'Y'
    },
    //이벤트 명 가져오기
    getEventName (cd) {
      let catNm = 'X'
      if (this.$ekmtcCommon.isNotEmpty(cd)) {
        this.commonCodes.WB014.forEach((item) => {
          if (item.cd === cd) {
            catNm = item.cdNm
          }
        })
      }
      return catNm
    },
    async getBookingInfo (bkgNo) {
      await cargo.getBookingClose(bkgNo).then(res => {
        this.bookingInfo = res.data
        // console.log('getBookingInfo res @@@@ ', res)

        this.getCloseInfo()
      }).catch(err => {
        console.log(err)
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005')) // 조회에 실패했습니다. 관리자에게 문의해주세요.
      })
    },
    //마감정보에 필요한 정보 가져오기
    async getCloseInfo () {
      const TH = this

      //podCtrCd, podPlcCd, polCtrCd, polPlcCd, menuCd("03" 고정),
      //vslCd, voyNo, rteCd, ctrCd, trmlCd, optrCd, closInfCd("01" 고정)
      //closeInfo에 넘길 parentInfo정보

      if (TH.convertList.length > 0) {
        TH.closeParams.vslCd = TH.convertList[0].vslCd
        TH.closeParams.voyNo = TH.convertList[0].voyNo
        TH.closeParams.polPortCd = TH.convertList[0].polPortCd
        TH.closeParams.podPortCd = TH.convertList[0].podPortCd
        TH.closeParams.polCtrCd = TH.convertList[0].polCtrCd
        TH.closeParams.polTrmlCd = TH.convertList[0].polTrmlCd
        TH.closeParams.bound = 'O'
        TH.closeParams.menuCd = '03'
      } else {
        TH.closeParams.vslCd = TH.cntrList[0].vslCd
        TH.closeParams.voyNo = TH.cntrList[0].voyNo
        TH.closeParams.polPortCd = TH.cntrList[0].polPortCd
        TH.closeParams.podPortCd = TH.cntrList[0].podPortCd
        TH.closeParams.polCtrCd = TH.cntrList[0].polCtrCd
        TH.closeParams.polTrmlCd = TH.cntrList[0].polTrmlCd
        TH.closeParams.bound = 'O'
        TH.closeParams.menuCd = '03'
      }

      TH.closeParams.bkgDocCls = this.$ekmtcCommon.changeDatePattern(TH.bookingInfo.docCls, '.')
      TH.closeParams.bkgCgoCls = this.$ekmtcCommon.changeDatePattern(TH.bookingInfo.cgoCls, '.')
      TH.closeParams.bkgMfCls = this.$ekmtcCommon.changeDatePattern(TH.bookingInfo.mfCls, '.')
      TH.closeParams.cfsCls = this.$ekmtcCommon.changeDatePattern(TH.bookingInfo.cfsCls, '.')
      TH.closeParams.callSign = TH.bookingInfo.callSign || ''
      TH.closeParams.mrnNo = TH.bookingInfo.mrnNo || ''
      //TH.closeParams = { ...TH.bookingInfo }

      //rte코드 가져오기
      await cargo.getRtnCode(TH.closeParams).then(res => {
        if (res.status === 200) {
          TH.closeParams.rteCd = res.data
        }
      })
    },
    checkValidationAll () {
      this.params.blNo = this.params.blNo.toUpperCase().trim()
      const TH = this
      let isAllOk = true
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#blNo')

      this.$ekmtcCommon.hideErrorTooltip(selector)

      // 검색어 체크
      // if (this.$ekmtcCommon.isEmpty(selector.value)) {
      if (this.$ekmtcCommon.isEmpty(this.params.blNo)) {
        if (TH.params.dtKnd === 'BL') {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CART010G010.052')) // Bl No.를 확인하세요.
          isAllOk = false
        } else if (TH.params.dtKnd === 'BK') {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CART010G010.053')) // Booking No.를 확인하세요.
          isAllOk = false
        } else {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CART010G010.054')) // Container No.를 확인하세요.
          isAllOk = false
        }
      }

      return isAllOk
    },
    changeSelectFuc () {
      this.params.blNo = ''
    },
    CargoTrackingJpPodInfoPopCall () {
      let vslLocation = this.vslLocation

      vslLocation.sort((a, b) => {
        return b.idx - a.idx
      })

      const params = {
        podPortCd: this.bookingInfo.podPortCd,
        podTrmlCd: vslLocation[0] ? vslLocation[0].podTrmlCd : '',
        podCtrCd: this.bookingInfo.podCtrCd,
        vslCd: vslLocation[0] ? vslLocation[0].vslCd : '',
        blNo: this.bookingInfo.blNo
      }

      this.openRfPopup('CargoTrackingJpPodInfoPop', params)
    },
    captchaResult (yn) {
      if (yn === 'Y') {
        this.$ekmtcCommon.alertDefault('인증성공')
        this.captchaYn = 'Y'
      } else {
        this.$ekmtcCommon.alertDefault('인증실패')
        this.captchaYn = 'N'
      }
    },
    openSubscriptionPopup () {
      //로그인 체크
      if (!this.auth.login) {
        this.$parent.$parent.$children[0].$children[0].changeLoginBoxStatus()
        return
      } else {
        if (this.bookingInfo.bkgShprCstNo !== this.memberDetail.cstCd) {
          this.$ekmtcCommon.alertDefault('해당 BOOKING의 BOOKING SHIPPER가 아닙니다.')
          return
        }
      }

      let vslLocation = this.vslLocation

      vslLocation.sort((a, b) => {
        return b.idx - a.idx
      })

      const params = {
        podPortCd: this.bookingInfo.podPortCd,
        podTrmlCd: vslLocation[0] ? vslLocation[0].podTrmlCd : '',
        podCtrCd: this.bookingInfo.podCtrCd,
        polPortCd: this.bookingInfo.polPortCd,
        polTrmlCd: vslLocation[0] ? vslLocation[0].polTrmlCd : '',
        polCtrCd: this.bookingInfo.polCtrCd,
        vslCd: vslLocation[0] ? vslLocation[0].vslCd : '',
        bkgNo: this.bookingInfo.bkgNo,
        cntrList: this.cntrList
      }

      this.subscriptionPopupParams = params

      this.subscriptionPopupComponent = 'CargoTrackingSubscriptionPop'
      this.$ekmtcCommon.layerOpen('.subscription-pop')
    }
  }
}
</script>

<style scoped>
.td_left_line {
  border-left: 1px solid #e1e3eb !important;
}

.nodrag {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: #adadad;
  border-color: #000;
  border: 1px;
  padding: 5px 8px 5px 8px;
}
</style>
