<template>
  <div class="popup_wrap" style="width:1000px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.MYIN040G010.014') }}</h1>
      <div class="content_box mt10">
        <h2 class="content_title">
          {{ $t('msg.MYIN040G010.014') }}
          <span class="d_inline_block valign_top" @click="showTooltip(true)"><!-- 도움말 -->
            <div style="position:relative">
              <button class="tbl_icon help">help</button>
              <div v-show="isToolTip" class="tooltip_wrap" style="width:300px; position:absolute; left:0; top:24px;"><!-- tooltip_wrap   -->
                <div class="cont"><!-- cont -->
                  <button class="close" @click="showTooltip(false)">close</button>
                  <p class="title">{{ $t('msg.MYIN040G010.052') }}</p>
                  <ul class="bul_list_sm t2">
                    <li>
                      1. {{ $t('msg.MYIN040G010.053') }}<br>
                      2. {{ $t('msg.MYIN040G010.054') }}<br>
                      3. {{ $t('msg.MYIN040G010.055') }}
                    </li>
                  </ul>
                </div><!-- cont -->
              </div><!-- tooltip_wrap // -->

            </div>

          </span><!-- 도움말 // -->
        </h2>
        <table class="tbl_row">
          <colgroup>
            <col style="width:18%">
            <col style="width:32%">
            <col style="width:18%">
            <col style="width:32%">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">{{ $t('msg.MYIN040G010.012') }}</th>
              <td colspan="3">
                <div style="width: 100%; display: flex;">
                  <div style="width: 30%;">
                    <select v-model="agencyInfo.autCatCd" :disabled="!isAbleInput()">
                      <option value="02">{{ $t('msg.MYIN040G010.058') }}</option>
                      <option value="01">{{ $t('msg.MYIN040G010.057') }}</option>
                    </select>
                  </div>
                  <div style="width: 70%; padding-top: 2px;">
                    <span style="font-size: 12px; padding-left: 10px;">
                      <template v-if="agencyInfo.autCatCd === '02'">
                        {{ $t('msg.MYIN040G010.060') }}
                      </template>
                      <template v-else-if="agencyInfo.autCatCd === '01'">
                        {{ $t('msg.MYIN040G010.059') }}
                      </template>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.MYIN040G010.013') }}</th> <!-- 업무 구분 -->
              <td colspan="3">
                <div v-if="memberDetail.userCtrCd !== 'KR'" class="work_sort_wrap">
                  <div class="tbl_form">
                    <span class="dv col_1 valign_top">
                      <input type="radio" id="sort_rdo" value="O" v-model="agencyInfo.ioType" :disabled="!isAbleInput()">
                      <label for="sort_rdo">{{ $t('msg.MYIN040G010.050') }}</label>
                    </span>
                    <span class="dv">
                      <ul class="grid ws form">
                        <li v-for="(obj, i) in eicat.eList" :key="'oKey-' + i">
                          <input type="checkbox" :id="'o-' + i" v-model="agencyInfo.cstGrpCatCdArr" :value="obj.cd" :disabled="agencyInfo.ioType != 'O' || !isAbleInput()" @click="cstGrpCatCdControl('O' ,obj.cd, isAbleInput())">
                          <label :for="'o-' + i">{{ obj.cdNm }}</label>
                        </li>
                      </ul>
                    </span>
                  </div>
                  <input type="hidden" id="ioType-o" />
                </div>

                <div class="work_sort_wrap">
                  <div class="tbl_form">
                    <span class="dv col_1 valign_top">
                      <input type="radio" id="sort_rdo1" value="I" v-model="agencyInfo.ioType" :disabled="!isAbleInput()" @click="choiceIm()">
                      <label for="sort_rdo1">{{ $t('msg.MYIN040G010.051') }}</label>
                    </span>

                    <span class="dv">
                      <ul class="grid ws form disable">
                        <li v-for="(obj, i) in eicat.iList" :key="'iKey-' + i">
                          <template v-if="obj.cd === '10'">
                            <input type="checkbox" :id="'i-' + i" v-model="agencyInfo.cstGrpCatCdArr" :value="obj.cd" :disabled="agencyInfo.ioType != 'I' || !isAbleInput()" @click="cstGrpCatCdControl('I' ,obj.cd, isAbleInput())">
                            <label v-if="obj.cd === '10'" :for="'i-' + i">{{ obj.cdNm }}({{ remainImCstGrpCatCdNmStr }})</label>
                          </template>
                          <template v-else>
                            <input type="checkbox" :id="'i-' + i" v-model="agencyInfo.cstGrpCatCdArr" :value="obj.cd" :disabled="agencyInfo.ioType != 'I' || !isAbleInput()" style="visibility: hidden; width: 0px;" @click="cstGrpCatCdControl('I' ,obj.cd, isAbleInput())">
                            <label :for="'i-' + i" style="visibility: hidden; width: 0px; height: 0px;">{{ obj.cdNm }}</label>
                          </template>
                        </li>
                      </ul>
                    </span>
                  </div>
                  <input type="hidden" id="ioType-i" />
                </div>
                <input type="hidden" id="ioType" />
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.MYIN040G010.022') }}</th> <!-- 사업자등록번호 -->
              <td>
                <div class="tbl_form">
                  <span class="dv">
                    <input type="text" v-model="agencyInfo.bzrgNo" :disabled="!isAbleInput()" id="bzrgNo">
                  </span>
                  <span class="dv">
                    <a :class="isAbleInput() ? 'button sm' : 'button sm disabled' " href="javascript:void(0)" @click.prevent="openPopup('BusinessNumberPop', null, null)">{{ $t('msg.MYIN040G010.009') }}</a>
                  </span>
                </div>
              </td>
              <th scope="row">{{ $t('msg.MYIN040G010.023') }}</th>
              <td>
                <input type="text" v-model="agencyInfo.bsnNm" :disabled="!isAbleInput()" id="bsnNm">
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.MYIN040G010.024') }}</th>
              <td>
                <input type="text" v-model="agencyInfo.cstPicTelNo" :disabled="!isAbleInput()" id="cstPicTelNo">
              </td>
              <th scope="row">{{ $t('msg.MY_INFO.001') }}</th>
              <td>
                <input type="text" v-model="agencyInfo.cstPicFaxNo" :disabled="!isAbleInput()" id="cstPicFaxNo">
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.MYIN040G010.025') }}</th>
              <td>
                <div class="input_calendar">
                  <e-date-picker @input="changeStrDt" :value="agencyInfo.strDt" disabled="disabled" />
                  <input type="hidden" id="strDt" />
                </div>
              </td>
              <th scope="row">{{ $t('msg.MYIN040G010.026') }}</th>
              <td>
                <div class="input_calendar">
                  <e-date-picker @input="changeEndDt" :value="agencyInfo.endDt" />
                  <input type="hidden" id="endDt" />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.MYIN040G010.028') }}</th>
              <td colspan="3">
                <a class="button sm" href="javascript:void(0)" @click="openPopup('ImpBusAgePdfPop', null, null)">{{ $t('msg.MYIN040G010.029') }}</a>
                <a :class="uploadFileInfo.length > 0 && isAbleInput() ? 'button sm blue ml-1' : 'button sm gray file ml-1'" href="javascript:void(0)" @click="openFileUploader()">{{ $t('msg.MYIN040G010.030') }}</a>
                <input type="hidden" id="uploadFile" />
                <p class="txt_desc">{{ $t('msg.MYIN040G010.031') }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex_between mt10" style="padding-bottom: 15px;">
      <div><!-- 왼쪽 버튼 자리 --></div>
      <div>
        <a href="javascript:void(0)" class="button blue lg" @click="saveAlert('req')" v-if="isAbleInput()">{{ $t('msg.MYIN040G010.032') }}</a>
        <a href="javascript:void(0)" class="button gray lg ml5" @click="saveAlert('save')" v-if="isAbleInput()">{{ $t('msg.MYIN040G010.033') }}</a>
        <a href="javascript:void(0)" class="button gray lg ml5" @click="reset()" v-if="isAbleInput()">{{ $t('msg.MYIN040G010.034') }}</a>
      </div>
    </div>
    <div id="business-pop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :business-info="businessInfo"
          :parent-info="parentInfo"
        />
      </win-layer-pop>
    </div>
    <div id="dext5_pop" v-show="isShowDext5Pop">
      <div class="popup_dim" ref="popup">
        <div class="popup_wrap" style="width:800px; height:520px;">
          <button class="layer_close" @click="closeFileUploadr()">close</button>
          <div class="popup_cont">
            <h1 class="page_title">{{ $t('msg.MYIN040G010.030') }}</h1>
            <div class="content_box">
              <DEXT5Upload
                v-if="isShowDext5"
                ref="dextupload-proxy"
                :id="'dext5-uploader-' + dext5Idx"
                :single="true"
                :category="false"
                width="100%"
                height="200px"
                @completed="uploadComplete"
                :file-info="uploadFileInfo"
                :category-info="[]"
              />
            </div>
          </div>
          <div class="mt10 text_center">
            <a class="button blue lg" @click="closeFileUploadr()">{{ $t('msg.MYIN040G010.045') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import BusinessNumberPop from '@/pages/user/popup/BusinessNumberPop'/* 선박정보 및 스케줄 팝업 */
import ImpBusAgePdfPop from '@/pages/user/popup/ImpBusAgePdfPop'
import ImptBsnesAgencInfo from '@/api/rest/user/imptBsnesAgencInfo'
import EDatePicker from '@/components/common/EDatePicker'
import DEXT5Upload from '@/components/DEXT5Upload'
import { rootComputed } from '@/store/helpers'
import moment from 'moment'

export default {
  name: 'ImpBusDetailPop',
  components: {
   WinLayerPop,
    BusinessNumberPop,
    ImpBusAgePdfPop,
    EDatePicker,
    DEXT5Upload,
    EFileUploaderEmbed: () => import('@/components/common/EFileUploaderEmbed')
  },
  props: [
    'agencyInfo',
    'statusCd',
    'eicat'
  ],
  data () {
    return {
      customComponent: null,
      businessInfo: {
        bzrgNo: '',
        bsnNm: ''
      },
      isWatch: true,
      req: {},
      uploadFileInfo: [],
      dext5Idx: 0,
      isShowDext5: true,
      isShowDext5Pop: false,
      isToolTip: false,
      isToolTipWait: false,
      parentInfo: {},
      remainImCstGrpCatCdNmStr: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    agencyInfo: {
      deep: true,
      handler () {
        if (this.isWatch) {
          this.updateValidationErrMsg()
        }
      }
    },
    uploadFileInfo () {
      if (!this.isEmpty(this.uploadFileInfo)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#uploadFile'))
      }
    }
  },
  created () {
    this.openFileUploader()
    this.closeFileUploadr()
    this.isShowDext5Pop = true

    if (!this.$ekmtcCommon.isEmpty(this.agencyInfo.uploadFileInfo)) {
      // let proxyFile = {
      //   fileId: this.agencyInfo.proxyFile.fileId,
      //   originalName: this.agencyInfo.proxyFile.fileNm,
      //   size: this.agencyInfo.proxyFile.fileSz,
      //   fileCont: this.agencyInfo.proxyFile.fileCont,
      //   bizVeReqNo: this.agencyInfo.proxyFile.bizVeReqNo
      // }

      this.uploadFileInfo.push(this.agencyInfo.uploadFileInfo[0])
    }

    if (this.eicat.iList !== null && this.eicat.iList.length > 0) {
      for (let i = 0; i < this.eicat.iList.length; i++) {
        if (this.eicat.iList[i].cd !== '10') {
          if (this.remainImCstGrpCatCdNmStr !== '') {
            this.remainImCstGrpCatCdNmStr += ','
          }
          this.remainImCstGrpCatCdNmStr += this.eicat.iList[i].cdNm
        }
      }
    }

    // 신규 등록시 수입인 경우 라디오버튼 Click 함수 호출
    if (this.agencyInfo.mode === 'New' && this.agencyInfo.ioType === 'I') {
      this.choiceIm()
    }
  },
  methods: {
    openPopup (compNm, bizVeReqNo, idx) {
      let isOpen = true

      switch (compNm) {
        case 'BusinessNumberPop':
          this.businessInfo.bzrgNo = this.agencyInfo.bzrgNo
          this.businessInfo.bsnNm = ''
          break
        case 'ImpBusAgePdfPop':
          if (this.isEmpty(this.agencyInfo.bizVeReqNo)) {
            isOpen = false

            // 신규등록일때, 출력 불가능 메시지 추가
            let obj = {
              alertType: 'simple',
              customCloseBtnClass: 'button blue lg simplert-test1',
              customCloseBtnText: this.$t('msg.CSBK100.137'),
              useIcon: false,
              customClass: 'simple_alert',
              message: this.$t('msg.MYIN040G010.063'), //저장 후 출력 가능합니다.
              type: 'info'
            }
            this.$ekmtcCommon.alert(obj)
            return
          } else {
            this.parentInfo.type = this.agencyInfo.ioType
            this.parentInfo.reqNo = this.agencyInfo.bizVeReqNo
            this.parentInfo.grpCatCd = this.agencyInfo.autCatCd
          }
          break
      }
      if (isOpen) {
        this.customComponent = compNm
        this.$ekmtcCommon.layerOpen('#business-pop > .popup_dim')
      }
    },
    closePopup (obj) {
      if (obj !== undefined) {
        this.agencyInfo.bsnNm = obj.bsnNm
        this.agencyInfo.bzrgNo = obj.bzrgNo
        //this.agencyInfo.fstEntUno = obj.cstNo
        this.agencyInfo.autCstNo = obj.cstNo
        this.agencyInfo.cstPicFaxNo = obj.faxNo
        this.agencyInfo.cstPicTelNo = obj.telNo
      }
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#business-pop > .popup_dim')
    },
    openFileUploader () {
      if (this.isAbleInput() === false) return
      this.isShowDext5 = true
      this.dext5Idx++
      this.$ekmtcCommon.layerOpen('#dext5_pop > .popup_dim')
    },
    closeFileUploadr () {
      this.isShowDext5 = false
      this.$ekmtcCommon.layerClose('#dext5_pop > .popup_dim')
    },
    uploadComplete (data) {
      this.uploadFileInfo[0].isNew = true
    },
    saveAlert (type) {
      let req = JSON.parse(JSON.stringify(this.agencyInfo))
      req.saveType = type
      if (type === 'req') {
        req.apvYn = 'N'
      }
      req.uploadFile = this.isEmpty(this.uploadFileInfo) ? null : this.uploadFileInfo[0]
      if (this.validation(req)) {
        this.req = req
        let obj = {
          onConfirm: this.save,
          customCloseBtnClass: 'button blue lg',
          customCloseBtnText: this.$i18n.t('msg.MYIN040G010.045'),
          customConfirmBtnText: this.$i18n.t('msg.MYIN040G010.044'),
          useConfirmBtn: true,
          customConfirmBtnClass: 'button blue lg',
          message: type === 'req' ? this.$i18n.t('msg.MYIN040G010.043') : this.$i18n.t('msg.MYIN040G010.061'),
          type: 'info'
        }
        this.$ekmtcCommon.alert(obj)
      }
    },
    save () {
      ImptBsnesAgencInfo.saveAgencyInfo(this.parseAgencyInfoReq(this.req)).then(response => {
        this.$emit('child', { type: 'saveAgencyInfo', payload: null })
        console.log(response)
      }).catch(err => {
        console.log(err)
      })
    },
    validation (req) {
      let flag = true
      let err_msg = ''

      if (this.isEmpty(req.autCatCd)) {
        err_msg = '위수임 구분을 선택해주세요.'
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#autCatCd'), err_msg)
        flag = false
      }

      if (this.isEmpty(req.ioType)) {
        err_msg = '업무 구분을 선택해주세요.'
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#ioType'), err_msg)
        flag = false
      } else {
        let id = req.ioType === 'O' ? 'ioType-o' : 'ioType-i'
        if (this.isEmpty(req.cstGrpCatCdArr)) {
          err_msg = this.$i18n.t('msg.MYIN040G010.035')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#' + id), err_msg)
          flag = false
        }
      }

      if (this.isEmpty(req.bzrgNo)) {
        err_msg = this.$i18n.t('msg.MYIN040G010.036')
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#bzrgNo'), err_msg)
        flag = false
      }

      if (this.isEmpty(req.bsnNm)) {
        err_msg = this.$i18n.t('msg.MYIN040G010.037')
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#bsnNm'), err_msg)
        flag = false
      }

      if (this.isEmpty(req.cstPicTelNo)) {
        err_msg = this.$i18n.t('msg.MYIN040G010.038')
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#cstPicTelNo'), err_msg)
        flag = false
      }

      if (this.isEmpty(req.cstPicFaxNo)) {
        err_msg = this.$i18n.t('msg.MYIN040G010.039')
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#cstPicFaxNo'), err_msg)
        flag = false
      }

      if (this.isEmpty(req.strDt)) {
        err_msg = this.$i18n.t('msg.MYIN040G010.040')
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#strDt'), err_msg)
        flag = false
      }

      if (this.isEmpty(req.endDt)) {
        err_msg = this.$i18n.t('msg.MYIN040G010.041')
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#endDt'), err_msg)
        flag = false
      }

      // if (!this.isEmpty(req.strDt)) {
      //   let now = moment()
      //   let today = now.format('YYYYMMDD')
      //   let isOk = req.strDt >= today

      //   err_msg = this.$i18n.t('msg.ONEX010T010.155') // 오늘 이전 날짜는 선택할 수 없습니다.

      //   let obj = {
      //     customCloseBtnClass: 'button blue lg',
      //     customCloseBtnText: this.$i18n.t('msg.MYIN040G010.045'),
      //     customConfirmBtnText: this.$i18n.t('msg.MYIN040G010.044'),
      //     customConfirmBtnClass: 'button blue lg',
      //     message: err_msg,
      //     type: 'info'
      //   }

      //   if (!isOk) {
      //     this.agencyInfo.strDt = today
      //     this.$ekmtcCommon.alert(obj)
      //     flag = false
      //   }
      // }

      if (!this.isEmpty(req.strDt) && !this.isEmpty(req.endDt)) {
        let isOk = moment(req.strDt).isBefore(req.endDt)

        err_msg = this.$i18n.t('msg.ONEX010T010.128')

        let obj = {
          customCloseBtnClass: 'button blue lg',
          customCloseBtnText: this.$i18n.t('msg.MYIN040G010.045'),
          customConfirmBtnText: this.$i18n.t('msg.MYIN040G010.044'),
          customConfirmBtnClass: 'button blue lg',
          message: err_msg,
          type: 'info'
        }

        if (!isOk) {
          this.$ekmtcCommon.alert(obj)
          flag = false
        }
      }

      if (this.isEmpty(req.uploadFile) && req.saveType === 'req') {
        err_msg = this.$i18n.t('msg.MYIN040G010.042')
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#uploadFile'), err_msg)
        flag = false
      }

      return flag
    },
    updateValidationErrMsg () {
      this.changeIoType()
      let req = JSON.parse(JSON.stringify(this.agencyInfo))

      if (!this.isEmpty(req.autCatCd)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#autCatCd'))
      }

      if (!this.isEmpty(req.ioType)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#ioType'))
        if (!this.isEmpty(req.cstGrpCatCdArr)) {
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#ioType-o'))
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#ioType-i'))
        }
      }

      if (!this.isEmpty(req.bzrgNo)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#bzrgNo'))
      }

      if (!this.isEmpty(req.bsnNm)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#bsnNm'))
      }

      if (!this.isEmpty(req.cstPicTelNo)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#cstPicTelNo'))
      }

      if (!this.isEmpty(req.cstPicFaxNo)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#cstPicFaxNo'))
      }

      if (!this.isEmpty(req.strDt)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#strDt'))
      }

      if (!this.isEmpty(req.endDt)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#endDt'))
      }
    },
    cstGrpCatCdControl (type, cd, isAbleInput) {
      if (isAbleInput) {
        let allCd = type === 'O' ? '30' : '10'
        let tempArr = JSON.parse(JSON.stringify(this.agencyInfo.cstGrpCatCdArr))
        let cstGrpCatCdArr = []
        let isExist = false
        let delIdx = null
        for (let i = 0; i < tempArr.length; i++) {
          if (tempArr[i] === cd) {
            delIdx = i
            isExist = true
            break
          }
        }

        if (!isExist) { //새롭게 체크된 경우
          if (cd === allCd) {
            this.agencyInfo.cstGrpCatCdArr = []
            cstGrpCatCdArr.push(allCd)
          } else {
             for (let c of tempArr) {
               if (c !== allCd) {
                  cstGrpCatCdArr.push(c)
               }
            }
          }
          this.agencyInfo.cstGrpCatCdArr = cstGrpCatCdArr
        } else { //이미 존재하는 경우
          tempArr.splice(delIdx, 1)
          this.agencyInfo.cstGrpCatCdArr = tempArr
        }
      }
    },
    changeIoType () {
      this.isWatch = false
      let tempArr = []
      let arr = []
      if (this.agencyInfo.ioType === 'O') {
        arr = this.eicat.eList
      } else if (this.agencyInfo.ioType === 'I') {
        arr = this.eicat.iList
      }
      for (let grpCatCd of this.agencyInfo.cstGrpCatCdArr) {
        for (let obj of arr) {
          if (obj.cd === grpCatCd) {
            tempArr.push(grpCatCd)
            break
          }
        }
      }
      this.agencyInfo.cstGrpCatCdArr = tempArr
      setTimeout(() => {
        this.isWatch = true
      }, 100)
    },
    parseAgencyInfoReq (req) {
      req.cstNo = this.memberDetail.cstCd

      if (req.autCatCd === '01') {
        if (req.ioType === 'O') {
          req.catCd = '20'
        } else if (req.ioType === 'I') {
          req.catCd = '21'
        }
      } else if (req.autCatCd === '02') {
        if (req.ioType === 'O') {
          req.catCd = '18'
        } else if (req.ioType === 'I') {
          req.catCd = '19'
        }
      }

      // this.selectedProfile에는 telCtrNo, telNo key가 없음
      let telNo = this.selectedProfile.telPlcNo + this.selectedProfile.telOfcNo
      //let telNo = this.selectedProfile.telCtrNo + this.selectedProfile.telPlcNo + this.selectedProfile.telOfcNo + this.selectedProfile.telNo
      req.profile = {
        picNo: this.selectedProfile.picNo,
        picNm: this.selectedProfile.picNm,
        telNo: telNo,
        emlAddr: this.selectedProfile.emlAddr
      }

      return req
    },
    changeStrDt (date) {
      // let now = moment()
      // let today = now.format('YYYYMMDD')

      // if (date < today) {
      //   this.agencyInfo.strDt = today
      //   this.openAlert(this.$t('msg.ONEX010T010.155')) // 오늘 이전 날짜는 선택할 수 없습니다.
      // }
      // this.agencyInfo.strDt = date
    },
    changeEndDt (date) {
      let now = moment()
      let today = now.format('YYYYMMDD')

      if (this.agencyInfo.strDt !== '' && date <= this.agencyInfo.strDt) {
        // date = ''
        this.openAlert(this.$t('msg.ONEX010T010.128')) // 시작일이 종료일 이후 입니다.
      } else if (this.agencyInfo.strDt === '') {
        if (date < today) {
          // date = ''
          this.openAlert(this.$t('msg.ONEX010T010.155')) // 오늘 이전 날짜는 선택할 수 없습니다.
        }
      }

      this.agencyInfo.endDt = date
    },
    reset () {
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#autCatCd'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#ioType'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#ioType-o'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#ioType-i'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#bzrgNo'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#bsnNm'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#cstPicTelNo'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#cstPicFaxNo'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#strDt'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#endDt'))
      this.$emit('child', { type: 'reset', payload: null })
    },
    isAbleInput () {
      if (this.statusCd !== '00') {
        return false
      } else {
        return true
      }
    },
    showTooltip (flag) {
      if (!this.isToolTipWait) {
        this.isToolTip = flag
        this.isToolTipWait = true
      }
      setTimeout(() => {
       this.isToolTipWait = false
      }, 100)
    },
     isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    choiceIm () {
      setTimeout(() => {
        let cstGrpCatCdArr = []
        cstGrpCatCdArr.push('10')
        this.agencyInfo.cstGrpCatCdArr = cstGrpCatCdArr
      }, 100)
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    }
  }
}
</script>

<style>

</style>
