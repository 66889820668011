<template>
  <div class="content">
    <form id="frm">
      <win-layer-pop class="report_sub_pop2">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          @selectFunc="selectFunc"
        />
      </win-layer-pop>

      <div id="tab1" class="tab_content_area">
        <div class="content_box beforetab">
          <table class="tbl_search">
            <colgroup>
              <col width="20px"><col width="210px"><col width="20px"><col width="210px"><col width="100px">
            </colgroup>
            <tbody>
              <tr>
                <td colspan="5" v-html="$t('msg.MYIN020T010.064')"></td>
              </tr>
              <tr>
                <th>{{ $t('msg.MYIN020T010.063') }}<!-- 기간  --></th>
                <td colspan="4">
                  <ul class="grid t3 mt10">
                    <li>
                      <div class="form_wrap t2">
                        <!-- 기간 -->
                        <select v-model="formData.kind" class="wid120" name="" id="">
                          <option value="01" selected>{{ $t('msg.MYIN020T010.003') }}</option>
                          <option value="02">{{ $t('msg.MYIN020T010.004') }}</option>
                          <option value="03">{{ $t('msg.MYIN020T010.005') }}</option>
                          <option value="04">{{ $t('msg.MYIN020T010.006') }}</option>
                        </select>
                      </div>
                    </li>
                    <li class="btn_rdo ml20">
                      <!-- Date Picker -->
                      <e-date-range-picker
                        id="rvsdDt"
                        class="ul_ml0"
                        @change="changeDateRange"
                        :sdate="from"
                        :edate="to"
                        :is-debug="true"
                      />
                    </li>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('msg.MYIN020T010.000') }}</th>
                    <td colspan="4">
                      <input type="radio" v-model="formData.inout" id="report1" name="report" value="O">
                      <label for="report1" class="mb10 mr20"><span></span>{{ $t('msg.MYIN020T010.001') }}</label>
                      <input type="radio" v-model="formData.inout" id="report2" name="report" value="I">
                      <label for="report2" class="mb10 mr20"><span></span>{{ $t('msg.MYIN020T010.002') }}</label>
                    </td>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row">{{ $t('msg.MYIN020T010.012') }}</th>
                <td>
                  <e-auto-complete-place id="polPol" @change="changePol" :ctr-cd="pol.ctrCd" :plc-cd="pol.plcCd" />
                </td>
                <th scope="row">{{ $t('msg.MYIN020T010.013') }}</th>
                <td>
                  <e-auto-complete-place id="podPod" @change="changePod" :ctr-cd="pod.ctrCd" :plc-cd="pod.plcCd" />
                </td>
                <td class="text_right">
                  <a href="#none" class="button sm blue" @click.prevent="getReportList()">{{ $t('msg.MYIN020T010.014') }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->

        <div class="content_box mt10">
          <div class="flex_box">
            <span class="tot_num">Total : <span class="num">{{ total }}</span></span>
            <span class="input_box">
              <input class="wid240" type="text" :placeholder="$t('msg.SETT010T010.011')" v-model="templateStr" @keyup.prevent="changeFilter()">
            </span>
            <span class="ml_auto cal_btn">
              <a class="button sm" href="#" @click.prevent="openPopup('ReportSubPop')">{{ $t('msg.MYIN020T010.015') }}</a>
              <a class="button sm ml10" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a>
            </span>
          </div>
          <div v-if="reportList">
            <div id="realgrid" style="width:100%; height:600px;" />
          </div>
          <div v-else>
            <span>{{ $t('msg.CMBA100.00131') }}</span>
          </div>
          <!-- paging
          <div v-if="reportList.length > 0" class="paging">
            <a v-if="isPaging1" class="btn prev" @click.prevent="setPrevPage()">Prev</a>
            <a>{{ currentPage + 1 }}/{{ totalPage }}</a>
            <a class="btn next" @click.prevent="setNextPage()">Next</a>
          </div> -->
        </div><!-- content_box // -->
      </div>
    </form>
  </div><!-- content // -->
</template>

<script>
//그리드 관련 import
import { GridView, LocalDataProvider } from 'realgrid'
import { rootComputed } from '@/store/helpers'
import report from '@/api/rest/user/report'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ReportSubPop from '@/pages/user/popup/ReportSubPop'
import commons from '@/api/services/commons'
import JSZip from 'jszip'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'bkgDt',
    dataType: 'text'
  },
  {
    fieldName: 'obrdDt',
    dataType: 'text'
  },
  {
    fieldName: 'polEtd',
    dataType: 'text'
  },
  {
    fieldName: 'podEta',
    dataType: 'text'
  },
  {
    fieldName: 'inout',
    dataType: 'text'
  },
  {
    fieldName: 'bkgNo',
    dataType: 'text'
  },
  {
    fieldName: 'bkgprofile',
    dataType: 'text'
  },
  {
    fieldName: 'blNo',
    dataType: 'text'
  },
  {
    fieldName: 'blprofile',
    dataType: 'text'
  },
  {
    fieldName: 'term',
    dataType: 'text'
  },
  {
    fieldName: 'actShprCstEnm',
    dataType: 'text'
  },
  {
    fieldName: 'cneCstEnm',
    dataType: 'text'
  },
  {
    fieldName: 'ntifCstEnm',
    dataType: 'text'
  },
  {
    fieldName: 'porPlcNm',
    dataType: 'text'
  },
  {
    fieldName: 'polPortNm',
    dataType: 'text'
  },
  {
    fieldName: 'podPortNm',
    dataType: 'text'
  },
  {
    fieldName: 'dlyPlcNm',
    dataType: 'text'
  },
  {
    fieldName: 'vslCd',
    dataType: 'text'
  },
  {
    fieldName: 'voyNo',
    dataType: 'text'
  },
  {
    fieldName: 'cntr20',
    dataType: 'text'
  },
  {
    fieldName: 'cntr40',
    dataType: 'text'
  },
  {
    fieldName: 'cntrHc',
    dataType: 'text'
  },
  {
    fieldName: 'cntr45',
    dataType: 'text'
  },
  {
    fieldName: 'cgo',
    dataType: 'text'
  },
  {
    fieldName: 'commodity',
    dataType: 'text'
  },
  {
    fieldName: 'description',
    dataType: 'text'
  },
  {
    fieldName: 'gwt',
    dataType: 'text'
  },
  {
    fieldName: 'cbm',
    dataType: 'text'
  },
  {
    fieldName: 'scNo',
    dataType: 'text'
  },
  {
    fieldName: 'locCur',
    dataType: 'text'
  },
  {
    fieldName: 'ofLocAmt',
    dataType: 'text'
  },
  {
    fieldName: 'ofUsdAmt',
    dataType: 'text'
  },
  {
    fieldName: 'thcLocAmt',
    dataType: 'text'
  },
  {
    fieldName: 'thcUsdAmt',
    dataType: 'text'
  },
  {
    fieldName: 'dcfLocAmt',
    dataType: 'text'
  },
  {
    fieldName: 'dcfUsdAmt',
    dataType: 'text'
  },
  {
    fieldName: 'ccfLocAmt',
    dataType: 'text'
  },
  {
    fieldName: 'ccfUsdAmt',
    dataType: 'text'
  },
  {
    fieldName: 'wfgLocAmt',
    dataType: 'text'
  },
  {
    fieldName: 'wfgUsdAmt',
    dataType: 'text'
  },
  {
    fieldName: 'pfsLocAmt',
    dataType: 'text'
  },
  {
    fieldName: 'pfsUsdAmt',
    dataType: 'text'
  },
  {
    fieldName: 'afaLocAmt',
    dataType: 'text'
  },
  {
    fieldName: 'afaUsdAmt',
    dataType: 'text'
  },
  {
    fieldName: 'othLocAmt',
    dataType: 'text'
  },
  {
    fieldName: 'othUsdAmt',
    dataType: 'text'
  },
  {
    fieldName: 'receiptYn',
    dataType: 'text'
  },
  {
    fieldName: 'receiptDt',
    dataType: 'text'
  },
  {
    fieldName: 'blType',
    dataType: 'text'
  },
  {
    fieldName: 'issueDate',
    dataType: 'text'
  }
]

export const columns = [
  {
    name: 'bkgDt',
    fieldName: 'bkgDt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.017') // Booking Date
    }
  },
  {
    name: 'obrdDt',
    fieldName: 'obrdDt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.018') // 'On board Date'
    }
  },
  {
    name: 'polEtd',
    fieldName: 'polEtd',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.019') // '출항일'
    }
  },
  {
    name: 'podEta',
    fieldName: 'podEta',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.020') // '입항일'
    }
  },
  {
    name: 'inout',
    fieldName: 'inout',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.021') // '수출/수입'
    },
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const inout = vmApp.reportList[idx].inout || ''
        let rtn = ''
        if (inout === 'O') {
          rtn = app.$t('msg.ONEX010T010.002')
        } else if (inout === 'I') {
          rtn = app.$t('msg.ONEX010T010.003')
        } else {
          rtn = inout
        }
        return rtn
      }
    }
  },
  {
    name: 'bkgNo',
    fieldName: 'bkgNo',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.022') // 'Booking No.'
    }
  },
  {
    name: 'bkgprofile',
    fieldName: 'bkgprofile',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.065') // 'BKG 프로필'
    }
  },
  {
    name: 'blNo',
    fieldName: 'blNo',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.023') // 'B/L No.'
    }
  },
  {
    name: 'blprofile',
    fieldName: 'blprofile',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.062') // 'B/L 프로필'
    }
  },
  {
    name: 'term',
    fieldName: 'term',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.024') // 'Term'
    }
  },
  {
    name: 'actShprCstEnm',
    fieldName: 'actShprCstEnm',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.025') // 'B/L Shipper'
    }
  },
  {
    name: 'cneCstEnm',
    fieldName: 'cneCstEnm',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.026') // 'B/L Consignee'
    }
  },
  {
    name: 'ntifCstEnm',
    fieldName: 'ntifCstEnm',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.027') // 'Notify'
    }
  },
  {
    name: 'porPlcNm',
    fieldName: 'porPlcNm',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.028') // 'POR'
    }
  },
  {
    name: 'polPortNm',
    fieldName: 'polPortNm',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.029') // 'POL'
    }
  },
  {
    name: 'podPortNm',
    fieldName: 'podPortNm',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.030') // 'POD'
    }
  },
  {
    name: 'dlyPlcNm',
    fieldName: 'dlyPlcNm',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.031') // 'DLY'
    }
  },
  {
    name: 'vslCd',
    fieldName: 'vslCd',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.032') // 'VSL'
    }
  },
  {
    name: 'voyNo',
    fieldName: 'voyNo',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.033') // 'VOY'
    }
  },
  {
    name: 'cntr20',
    fieldName: 'cntr20',
    type: 'text',
    header: {
      text: '20"'
    }
  },
  {
    name: 'cntr40',
    fieldName: 'cntr40',
    type: 'text',
    header: {
      text: '40"'
    }
  },
  {
    name: 'cntrHc',
    fieldName: 'cntrHc',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.034') // 'HC'
    }
  },
  {
    name: 'cntr45',
    fieldName: 'cntr45',
    type: 'text',
    header: {
      text: '45"'
    }
  },
  {
    name: 'cgo',
    fieldName: 'cgo',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.035') // 'CGO'
    }
  },
  {
    name: 'commodity',
    fieldName: 'commodity',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.036') // 'Commodity'
    }
  },
  {
    name: 'description',
    fieldName: 'description',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.037') // 'Description'
    }
  },
  {
    name: 'gwt',
    fieldName: 'gwt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.038') // 'Gross Weight (KG)'
    }
  },
  {
    name: 'cbm',
    fieldName: 'cbm',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.039') // 'CBM'
    }
  },
  {
    name: 'scNo',
    fieldName: 'scNo',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.040') // 'S/C No.'
    }
  },
  {
    name: 'locCur',
    fieldName: 'locCur',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.041') // 'Local Currency'
    }
  },
  {
    name: 'ofLocAmt',
    fieldName: 'ofLocAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.042') // 'O/F'
    }
  },
  {
    name: 'ofUsdAmt',
    fieldName: 'ofUsdAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.043') // 'O/F (USD)'
    }
  },
  {
    name: 'thcLocAmt',
    fieldName: 'thcLocAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.044') // 'THC'
    }
  },
  {
    name: 'thcUsdAmt',
    fieldName: 'thcUsdAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.045') // 'THC (USD)'
    }
  },
  {
    name: 'dcfLocAmt',
    fieldName: 'dcfLocAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.046') // 'DOCUMENT FEE'
    }
  },
  {
    name: 'dcfUsdAmt',
    fieldName: 'dcfUsdAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.047') // 'DOCUMENT FEE(USD)'
    }
  },
  {
    name: 'ccfLocAmt',
    fieldName: 'ccfLocAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.048') // 'CNTR CLEANING FEE'
    }
  },
  {
    name: 'ccfUsdAmt',
    fieldName: 'ccfUsdAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.049') // 'CNTR CLEANING FEE(USD)'
    }
  },
  {
    name: 'wfgLocAmt',
    fieldName: 'wfgLocAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.050') // 'WHARFAGE'
    }
  },
  {
    name: 'wfgUsdAmt',
    fieldName: 'wfgUsdAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.051') // 'WHARFAGE (USD)'
    }
  },
  {
    name: 'pfsLocAmt',
    fieldName: 'pfsLocAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.052') // 'PORT FACILITY SECURITY'
    }
  },
  {
    name: 'pfsUsdAmt',
    fieldName: 'pfsUsdAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.053') // 'PORT FACILITY SECURITY(USD)'
    }
  },
  {
    name: 'afaLocAmt',
    fieldName: 'afaLocAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.054') // 'ADVANCED FILING AMENDMENT FEE'
    }
  },
  {
    name: 'afaUsdAmt',
    fieldName: 'afaUsdAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.055') // 'ADVANCED FILING AMENDMENT FEE(USD)'
    }
  },
  {
    name: 'othLocAmt',
    fieldName: 'othLocAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.056') // 'Other'
    }
  },
  {
    name: 'othUsdAmt',
    fieldName: 'othUsdAmt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.057') // 'Other(USD)'
    }
  },
  {
    name: 'receiptYn',
    fieldName: 'receiptYn',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.058') // '운임입금여부'
    }
  },
  {
    name: 'receiptDt',
    fieldName: 'receiptDt',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.059') // '입금일'
    }
  },
  {
    name: 'blType',
    fieldName: 'blType',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.060') // 'B/L 타입'
    }
  },
  {
    name: 'issueDate',
    fieldName: 'issueDate',
    type: 'text',
    header: {
      text: app.$t('msg.MYIN020T010.061') // 'B/L 발행일'
    }
  }
]

export default {
  name: 'ReportList',
  components: {
    WinLayerPop,
    EAutoCompletePlace,
    ReportSubPop,
    'e-date-range-picker': () => import('@/components/common/EDateRangePicker')
  },
  data () {
    return {
      customComponent: null,
      selectFunc: null,
      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      formData: {
        inout: 'O',
        kind: '01',
        startPlcCd: '',
        startCtrCd: '',
        destPlcCd: '',
        destCtrCd: ''
      },
      reportList: [],
      isPaging1: true,
      currentPage: 0,
      totalPage: 0,
      total: 0,
      templateStr: '',
      rstList: [],
      rstFilter: [],
      //초기화면 flag
      initFlag: 'Y',
      //유입 메뉴 구분
      menuCd: '',

      //시작일, 종료일 초기값
      from: '',
      to: '',

      format: 'YYYY.MM.DD'
    }
  },
  watch: {
  },
  computed: {
    ...rootComputed
  },
  mounted () {
    window.vmApp = this

    // 로그인 여부
    if (!this.auth.userId) {
      this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
      return
    }

    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.displayOptions.fitStyle = 'fill'
    gridView.header.height = 50
    gridView.displayOptions.rowHeight = 30
    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //리얼그리드 팝업 HEIGHT 조절
    $('#realgrid').height('500px')

    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //푸터 없애기
    gridView.setFooter({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })

    const $vm = this
    //API : Report List 가져오기
    $vm.getReportList()

    //페이징
    //gridView.setPaging(true, 10)
  },
  methods: {
    // param
    // compNm : Import한 컴포넌트 명
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.report_sub_pop2')
    },
    closePopup () {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.report_sub_pop2')
    },
    changeDateRange (s, d) {
      this.from = s
      this.to = d

      this.cond = 'DT'
    },
    changePol (vo) {
      this.pol = vo
      // console.log('######## changePol')
      // console.log(vo)
      this.formData.startPolName = vo.plcEnm
      this.formData.startCtrCd = vo.ctrCd
      this.formData.startPlcCd = vo.plcCd
      this.formData.startPlcName = vo.plcEnm
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.pod = vo
      // console.log('######## changePod')
      // console.log(vo)
      this.formData.destPodName = vo.plcEnm
      this.formData.destCtrCd = vo.ctrCd
      this.formData.destPlcCd = vo.plcCd
      this.formData.destPlcName = vo.plcEnm
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    selectedRow: function (index) {
      //console.log(provider.getValue(index))
    },
    setPickUpInfo: function (clickData) {
      //this.$emit('selectFunc', clickData)
      // console.log(clickData)
    },
    async getReportList () {
      //초기화면이라면
      if (this.initFlag === 'Y') {
        await commons.getServerTime().then(res => {
          const serverYear = res.data.substring(0, 4)
          const serverMonth = res.data.substring(4, 6)
          const serverDay = res.data.substring(6, 8)

          const edate = new Date(serverYear, serverMonth - 1, serverDay)

          // console.log('@@@@serverYear = ' + serverYear + '@@@@@@@@')
          // console.log('@@@@serverMonth = ' + serverMonth + '@@@@@@@@')
          // console.log('@@@@serverDay = ' + serverDay + '@@@@@@@@')

          // console.log('@@@@beforeedate = ' + edate + '@@@@@@@@')
          this.to = this.$ekmtcCommon.getDateToStrDelim(edate, '.')

          //30일 이전으로 날짜 설정
          edate.setMonth(edate.getMonth() - 1)

          // console.log('@@@@afteredate = ' + edate + '@@@@@@@@')
          this.from = this.$ekmtcCommon.getDateToStrDelim(edate, '.')
        })
      }
/*
      // 메인화면 가로배너 유입 체크
      if (Object.keys(this.$route.query).length > 0) {
        this.menuCd = this.$route.query.menuCd
      }
*/
      // 메인화면 가로배너 유입 체크
      if (Object.keys(this.$route.params).length > 0) {
        this.menuCd = this.$route.params.menuCd
      } else {
        this.menuCd = null
      }

      const formData = {
        inout: this.formData.inout,
        from: this.from.replaceAll('.', ''),
        to: this.to.replaceAll('.', ''),
        kind: this.formData.kind,
        startPlcCd: this.pol.plcCd,
        startCtrCd: this.pol.ctrCd,
        destPlcCd: this.pod.plcCd,
        destCtrCd: this.pod.ctrCd,
        menuCd: this.menuCd
      }

      // console.log(formData)

      await report.getReportList(formData).then((data) => {
        // console.log(data)
        const list = data.data.reportList
        this.total = list.length

        this.rstList = []
        this.rstFilter = []
        this.reportList = []

        if (data.status === 200) {
          //셋팅할 데이터 to array
          list.forEach((item) => {
            item.bkgDt = this.getMonthDay(item.bkgDt)
            item.obrdDt = this.getMonthDay(item.obrdDt)
            item.polEtd = this.getMonthDay(item.polEtd)
            item.podEta = this.getMonthDay(item.podEta)
            item.receiptDt = this.getMonthDay(item.receiptDt)
            item.issueDate = this.getMonthDay(item.issueDate)

            this.reportList.push(item)
          })

          this.rstList = this.$_.sortBy(list, ['blNo', 'bkgNo'])
          this.rstFilter = this.$_.sortBy(list, ['blNo', 'bkgNo'])
          //그리드 데이터 셋팅
          provider.setRows(this.reportList)

          //초기화면 Flag N
          this.initFlag = 'N'

          //페이징(axios는 비동기 처리기 때문에 promise 처리된 then() 안에서 사용)
          //this.currentPage = gridView.getPage() //현재 페이지 반환
          //this.totalPage = gridView.getPageCount() //전체페이지 개수 반환
        }
      })
    },
    checkValidationPol (frm) {
      const elem = frm.querySelector('#polPol')
      let msg = this.$t('art.CMATK068') + this.$t('tem.CMAT003') // '출발지역을 선택하세요.'
      this.$ekmtcCommon.showErrorTooltip(elem, msg)
    },
    checkValidationPod (frm) {
      const elem = frm.querySelector('#podPod')
      let msg = this.$t('art.CMATK010') + this.$t('tem.CMAT003') // '도착지역을 선택하세요.'
      this.$ekmtcCommon.showErrorTooltip(elem, msg)
    },
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
    exlDownload () {
      // 엑셀 다운로드 로그 코드
      this.$route.params.menuCd = '02'
      const $vm = this
      // 로그 기록을 위해 조회 호출
      $vm.getReportList()

      gridView.exportGrid({
          type: 'excel',
          target: 'local',
          fileName: 'ReportExcel.xlsx'
      })
    },
    getMonthDay (date) {
      let result = date

      if (date !== undefined && date !== null && date !== '') {
        if (date === '00000000000000') {
          result = 'No data'
        } else {
          result = date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)
        }
      }

      return result
    },
    // bkgNO, blNO 검색시 사용
    changeFilter () {
      const gridList = this.reportList

      let arrFilter

      if (this.templateStr.indexOf(',') !== -1) {
        arrFilter = this.templateStr.split(',')
      } else {
        arrFilter = [this.templateStr]
      }

      if (this.templateStr === '') {
        this.rstFilter = gridList
      } else {
        this.rstFilter = gridList.filter(vo => {
          const blNo = vo.blNo === undefined ? '' : vo.blNo.toLowerCase()
          const bkgNo = vo.bkgNo === undefined ? '' : vo.bkgNo.toLowerCase()

          let txtFilter

          for (let i in arrFilter) {
            txtFilter = arrFilter[i].toLowerCase().replace(/^\s+|\s+$/g, '')
            if (txtFilter === '') {
              continue
            }
            if (blNo.indexOf(txtFilter) !== -1 || bkgNo.indexOf(txtFilter) !== -1) {
              return true
            }
          }
          return false
        })
      }
      provider.setRows(this.rstFilter)
      this.total = this.getTotalCnt(this.rstFilter)
      gridView.setTopItem(0) //결과재검색후 스크롤을 무조건 최상단으로 올린다
    },
    getTotalCnt (arr) {
      let blList = []
      for (let obj of arr) {
        if (blList.length === 0) {
          blList.push(obj)
        } else {
          let isExist = false
          for (let bl of blList) {
            if (obj.blNo === bl.blNo) {
              isExist = true
              break
            }
          }
          if (!isExist) {
            blList.push(obj)
          }
        }
      }
      return blList.length
    }

  }
}
</script>
