<template>
  <div id="report_sub_pop" class="content">
    <win-layer-pop>
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
    <div id="tab2" class="tab_content_area">
      <div class="content_box beforetab">
        <div class="flex_box">
          <span class="tot_num">
            {{ $t('msg.CMBA100.530') }} : <span class="num">{{ listCountNumber }}</span></span>
        </div>
        <div class="">
          <table class="tbl_col">
            <colgroup>
              <col style="width:40px">
              <col style="width:8%">
              <col style="width:10%">
              <col style="width:8%">
              <col style="width:12%">
              <col style="width:12%">
              <col style="width:auto">
              <col style="width:18%">
              <col style="width:9%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="pr0">
                  <input
                    v-model="checkAll"
                    type="checkbox"
                    name="all"
                    id="all"
                  >
                  <label for="all"><span class="offscreen">전체 체크</span></label>
                </th>
                <th scope="col">{{ $t('msg.MYIN020T020.008') }}</th><!-- 신청자 -->
                <th scope="col">{{ $t('msg.MYIN020T020.009') }}</th><!-- 신청일자 -->
                <th scope="col">{{ $t('msg.MYIN020T020.010') }}</th><!-- 수출/수입 -->
                <th scope="col">{{ $t('msg.MYIN020T020.011') }}</th><!-- 구분 -->
                <th scope="col">{{ $t('msg.MYIN020T020.012') }}</th><!-- 구독일 -->
                <th scope="col">{{ $t('msg.MYIN020T020.013') }}</th><!-- 구독기간 -->
                <th scope="col">{{ $t('msg.MYIN020T020.014') }}</th><!-- E-mail -->
                <th scope="col">{{ $t('msg.MYIN020T020.015') }}</th><!-- 구독상태 -->
              </tr>
            </thead>
            <tbody v-if="reportInfoList.length > 0">
              <tr
                v-for="vo in reportInfoList"
                :key="vo.seq + '/' + vo.rptSts"
              >
                <td class="pr0">
                  <input
                    v-model="cancelList"
                    type="checkbox"
                    name="chk"
                    :id="vo.seq + '/' + vo.rptSts"
                    :value="vo.seq + '/' + vo.rptSts"
                  >
                  <label :for="vo.seq + '/' + vo.rptSts"><span class="offscreen">{{ $t('msg.MYIN020T020.056') }}</span></label>
                </td>
                <td>{{ vo.userNm === '' ? vo.rptId : vo.userNm }}</td>
                <td>{{ vo.fstEntDtm }}</td>
                <td>
                  <template v-if="vo.elCatCd === 'O'">
                    {{ $t('msg.ONEX010T010.002') }}<!-- 수출 -->
                  </template>
                  <template v-else-if="vo.elCatCd === 'I'">
                    {{ $t('msg.ONEX010T010.003') }}<!-- 수입 -->
                  </template>
                </td>
                <td>{{ vo.rptType }}</td>
                <td>{{ vo.rptDateText }}</td>
                <td>
                  {{ vo.rptTrmText }}
                  <template v-if="vo.rptTrm === '1'"><br>({{ vo.rptClosDtm }})</template>
                  <template v-else><br>({{ vo.rptStrdtm }}~{{ vo.rptClosDtm }})</template>
                </td>
                <td>{{ vo.rptEmlAddr }}</td>
                <td>
                  <template v-if="vo.rptSts === '01'">
                    {{ $t('msg.MYIN020T020.061') }}<!-- 구독중 -->
                  </template>
                  <template v-else-if="vo.rptSts === '02'">
                    {{ $t('msg.MYIN020T020.062') }}<!-- 구독종료 -->
                  </template>
                  <template v-else>
                    {{ $t('msg.MYIN020T020.063') }}<!-- 구독취소 -->
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9">{{ $t('msg.ONIM010P080.010') }}</td> <!-- No Data. -->
              </tr>
            </tbody>
          </table>
          <!-- paging -->
          <div
            v-if="reportInfoList.length > 0"
            class="paging"
          >
            <a
              class="btn start"
              @click.prevent="getReportSubList(1, false)"
            >Start</a>
            <a
              class="btn prev"
              @click.prevent="getReportSubList(pagination.prevPage, false)"
            >Prev</a>
            <a
              v-for="idx in pageNumberRange"
              :key="idx"
              :class="[idx === param.curPage ? 'on' : '']"
              href="#"
              @click.prevent="getReportSubList(idx, false)"
            >{{ idx }}</a>
            <a
              class="btn next"
              @click.prevent="getReportSubList(pagination.nextPage, false)"
            >Next</a>
            <a
              class="btn end"
              @click.prevent="getReportSubList(pagination.pageCnt, false)"
            >End</a>
          </div>
          <!-- paging // -->
        </div>
      </div>
      <div class="flex_between">
        <div>
          <a class="button sm t2" href="#" @click="deleteSelected()">{{ $t('msg.MYIN020T020.054') }}</a>
        </div>
        <div>
          <a class="button blue lg" @click.prevent="openPopup('ReportSubPop')">{{ $t('msg.MYIN020T020.055') }}</a>
        </div>
      </div>
    </div>
  </div><!-- content // -->
</template>

<script>
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ReportSubPop from '@/pages/user/popup/ReportSubPop'
import { rootComputed } from '@/store/helpers'
import report from '@/api/rest/user/report'

export default {
  name: 'ReportInfo',
  components: {
    WinLayerPop,
    ReportSubPop,
    report
  },
  data () {
    return {
      isInit: false,
      param: {
        curPage: 1, // 현재 페이지
        pageSize: 15 // 한 페이지당 게시글 수
      },
      customComponent: null,
      selectFunc: null,
      reportInfoList: [],
      cancelList: [],
      listCountNumber: 0,
      pagination: {},
      //userName
      userNm: ''
    }
  },
  computed: {
    ...rootComputed,
    pageNumberRange: function () {
        return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    },
    checkAll: {
      // getter
      get: function () {
        if ((this.cancelList.length !== this.reportInfoList.length) || this.reportInfoList.length === 0) {
          return false
        } else {
          return true
        }
      },
      // setter
      set: function (e) {
        if (e) {
          this.reportInfoList.forEach(element => {
            this.cancelList.push(element.seq + '/' + element.rptSts)
          })
        } else {
          this.cancelList = []
        }
      }
    }
  },
  created () {
    this.getReportSubList(1)
  },
  methods: {
    async getReportSubList (index, searchFlag) {
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        return
      }
      if (this.isInit && index < 1) {
        // 처음페이지입니다.
        this.$ekmtcCommon.alertDefault(this.$t('js.common.002'))
        return
      }
      if (this.isInit && this.pagination && index > this.pagination.pageCnt) {
        // 마지막페이지입니다.
        this.$ekmtcCommon.alertDefault(this.$t('js.common.001'))
        return
      }

      // !searchFlag(페이지 이동 시) 현재 페이지와 이동할 페이지 같다면
      if (!searchFlag && this.isInit && this.param.curPage === index) return

      this.param.curPage = index

      await report.getReportSubList(this.param).then((res) => {
        const TH = this
        const resp = res.data
        const pagination = resp.pagination
        const list = resp.reportInfoList

        this.reportInfoList = []

        this.pagination = pagination

        //전체 글 개수
        this.listCountNumber = list.length === 0 ? 0 : list[0].listCnt

        list.forEach((item) => {
          item.rptStrdtm = this.getMonthDay(item.fstEntDtm)
          item.rptClosDtm = this.getMonthDay(item.rptClosDtm)
          item.rptDateText = this.rptChgDate(item.rptDate)
          item.rptTrmText = this.rptChgTrm(item.rptTrm)
          item.rptType = this.rptChgType(item.rptType)
          item.userNm = item.rptId
//          item.userNm = item.rptId === TH.auth.cstCd ? TH.auth.userName : item.rptId
          item.fstEntDtm = String(item.fstEntDtm).substring(0, 4) + '-' + String(item.fstEntDtm).substring(4, 6) + '-' + String(item.fstEntDtm).substring(6, 8)

          this.reportInfoList.push(item)
        })
        if (!this.isInit) this.isInit = true
      })
      .catch(err => {
        console.log(err)
      })
    },
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('#report_sub_pop > .popup_dim')
    },
    closePopup () {
      this.customComponent = null
      this.isInit = false
      this.$ekmtcCommon.layerClose('#report_sub_pop > .popup_dim')
      this.getReportSubList(1)
    },
    deleteSelected () {
      const TH = this
      let tmpFlag = 'N'

       if (this.cancelList.length === 0) {
        // 취소할 데이터를 선택해주십시오
        this.$ekmtcCommon.alertDefault(this.$t('msg.MYIN020T020.057'))
        return
      }

      // 구독 취소 여부
      this.cancelList.seq = []

      for (let i = 0; i < this.cancelList.length; i++) {
        this.cancelList.seq.push(this.cancelList[i].split('/')[0])

        //구독 취소 플래그 바꾸기
        if (this.cancelList[i].split('/')[1] !== '01' && this.cancelList[i].split('/')[1] !== '02') {
          tmpFlag = 'Y'
        }
      }

      if (tmpFlag === 'Y') {
        // 이미 구독취소된 건이 있습니다.
          TH.$ekmtcCommon.alertDefault(TH.$t('msg.MYIN020T020.000'))
          return
      }

      //선택한 데이터를 구독취소하시겠습니까?
      this.$ekmtcCommon.confirmCallBack(this.$t('msg.MYIN020T020.053'), () => {
        this.onConfirm()
      })
    },
    async onConfirm () {
      const formData = {
        seq: this.cancelList.seq.join()
      }

      await report.cancelMultiSubs(formData).then(response => {
        if (response.headers.respcode === 'C0000') {
          // 선택한 항목의 구독 취소를 성공하였습니다.
          this.$ekmtcCommon.alertDefault(this.$t('msg.MYIN020T020.001'))
        } else {
          // 선택한 항목의 구독 취소를 실패하였습니다.
          this.$ekmtcCommon.alertDefault(this.$t('msg.MYIN020T020.002'))
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.cancelList = []
        if (this.checkAll) this.checkAll = false

        this.isInit = false
        this.getReportSubList(1)
      })
    },
    rptChgTrm (rptTrm) {
      let rtn = ''
      if (rptTrm === '1') {
        rtn = this.$t('msg.MYIN020T020.064')
      } else {
        rtn = this.$t('msg.MYIN020T020.065', { rptTrm: rptTrm })
      }
      return rtn
    },
    rptChgDate: function (date) {
      let result = ''

      if (date !== undefined) {
        if (date === '01') {
          result = this.$t('msg.MYIN020T020.058') // 매월 1일
        } else if (date === '02') {
          result = this.$t('msg.MYIN020T020.059') // 매월 15일
        } else if (date === '03') {
          result = this.$t('msg.MYIN020T020.060') // 매월 말일
        }
      }

      return result
    },
    rptChgType: function (type) {
      let result = ''

      if (type !== undefined) {
        if (type === '01') {
          result = this.$t('msg.MYIN020T020.066') // On Board Date
        } else if (type === '02') {
          result = this.$t('msg.MYIN020T020.067') // 출항일
        } else if (type === '03') {
          result = this.$t('msg.MYIN020T020.068') // 입항일
        } else if (type === '04') {
          result = this.$t('msg.MYIN020T020.069') // 입금일
        }
      }

      return result
    },
    getMonthDay (val) {
      const tempDay = val.substring(0, 4)
      let month = ''
      let temp = val.substring(4, 6)

      if (temp === '01') {
        month = 'Jan'
      } else if (temp === '02') {
        month = 'Feb'
      } else if (temp === '03') {
        month = 'Mar'
      } else if (temp === '04') {
        month = 'Apr'
      } else if (temp === '05') {
        month = 'May'
      } else if (temp === '06') {
        month = 'Jun'
      } else if (temp === '07') {
        month = 'Jul'
      } else if (temp === '08') {
        month = 'Aug'
      } else if (temp === '09') {
        month = 'Sep'
      } else if (temp === '10') {
        month = 'Oct'
      } else if (temp === '11') {
        month = 'Nov'
      } else {
        month = 'Dec'
      }
      return month + ' ' + tempDay
    }
  }
}
</script>
