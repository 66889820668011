<template>
  <div class="popup_wrap" style="width:550px; height:650px;">
    <!-- 팝업사이즈 참고 : 500*235  popup_wrap -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title"><span class="color_red">[{{ $t('msg.MYIN010G010.017') }}] </span>{{ $t('msg.MYIN010G010.018') }} <!-- [필수] 개인정보수집동의--></h1>
      <div class="content_box">
        <!-- content_box -->
        <h2 class="content_title"></h2>
        <!-- <textarea class="hei200" v-model="parentInfo.content"></textarea> -->
        <p>
          {{ $t('approval.001') }}
          <!-- 고려해운 주식회사(이하 ‘고려해운’이라 합니다)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 회원가입, 상담, 서비스 제공 등을 위하여 아래와 같은 고객님의 정보를 수집하고자 하며, 이에 다음과 같은 사항에 동의하시는 고객 분들에 한하여 당사의 회원 가입을 받고 있습니다. -->
        </p>
        <p class="tit mt20">
          {{ $t('approval.002') }}
          <!-- 1. 수집하는 개인정보 항목 -->
        </p>
        <ul>
          <li>
            {{ $t('approval.003') }}
          <!-- 회원가입을 위하여 필요한 개인정보 (필수) : 아이디, 비밀번호, 홈페이지 접속지역, 성명, 휴대전화번호, 이메일, 회사명, 사업자등록번호, 회사주소, 회원구분, 처리업무, 전화번호, FAX번호, 자동알림 서비스 신청 -->
          </li>
          <li>
            {{ $t('approval.004') }}
            <!-- 서비스 이용과정이나 정보처리 과정에서 자동으로 생성되는 정보 (자동) : 고객님의 브라우저 종류, 접속로그, 접속 IP정보, 서비스 이용기록, 쿠키 등 -->
          </li>
        </ul>
        <p class="tit mt20">
          {{ $t('approval.005') }}
          <!-- 2. 개인정보 수집 및 이용 목적 -->
        </p>
        <ul>
          <li>
            {{ $t('approval.006') }}
            <!-- 고객님을 고려해운의 회원으로서 관리하려는 목적으로서, 회원제 서비스의 제공, 본인 확인, 당사 서비스의 부정한 이용과 비인가 이용의 방지, 고객님의 가입의사 확인, 서비스 이용에 따른 기록보존, 불만사항 처리, 고지사항 전달을 하고자 합니다. -->
          </li>
          <li>
            {{ $t('approval.007') }}
            <!-- 고객님과 관련된 서비스 제공 및 그에 따른 정산을 위한 목적으로서, 서비스 및 콘텐츠 제공, 대금 결제, 고객님께 최적화된 서비스 제공 및 기능 개선 등에 활용하고자 합니다. -->
          </li>
        </ul>
        <p class="tit mt20">
          {{ $t('approval.008') }}
          <!-- 3. 개인정보의 보유 및 이용기간 -->
        </p>
        <ul>
          <li>
            {{ $t('approval.009') }}
            <!-- 고객님의 동의 하에 수집된 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면 지체 없이 파기됩니다. 다만, 개인정보의 수집 및 이용 목적이 달성된 경우에도, 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령에 의하여 보존될 필요성이 있는 경우 및 사전에 보유기간을 명시하거나 고지한 경우에는 그에 따라 개인정보를 보관할 수 있습니다. -->
          </li>
        </ul>
        <p class="tit mt20">
          {{ $t('approval.010') }}
          <!-- 4. 동의하지 않았을 경우 불이익 -->
        </p>
        <ul>
          <li>
            {{ $t('approval.011') }}
            <!-- 고객님께서는 개인정보 수집·이용에 대한 동의를 거부하실 수 있으나, 위 정보는 서비스 제공에 필수적인 정보이므로 동의를 거부하실 경우 회원가입, 서비스 이용 등이 불가합니다. -->
          </li>
        </ul>
        <p class="mt20">
          {{ $t('approval.032') }}
          <!-- 위의 개인정보수집에 관한 사항을 모두 읽고 인식하였으며, 이에 동의합니다. -->
        </p>
      </div><!-- content_box // -->

      <div class="mt10 text_center">
        <!-- <p class="button lg">
          <input type="checkbox"
                 @change="sendCheckValue"
                 id="checkAgreement" name="checkAgreement"
                 v-model="checkVal"
                 true-value="Y"
                 false-value="N"
          />
          <label for="checkAgreement"><span></span>동의</label>

        </p>-->

        <!-- <a class="button lg" href="#">
          <span></span>동의</a> -->
        <a class="button gray lg"
           @click="$emit('close')"
        >{{ $t('common.FILE_UPLOAD.011') }}</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
export default {
  name: 'PersonalInfoAgree',
  props: {
    parentInfo: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      param: {
        portCd: ''
      },
      contents: '',
      clickAgreement: '',
      checkVal: this.parentInfo.mdtyPsnlInfCltnCnstYn,
      gubun: 'MPAP'
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
    console.log('this.parentInfo :: ', this.parentInfo.mdtyPsnlInfCltnCnstYn)
  },
  mounted: function () {
    const $vm = this
    //조회
    // $vm.searchData()
  },
  methods: {
     sendCheckValue () {
      // if (this.checkVal === 'Y') {
      //   this.checkVal = 'N'
      // }
      console.log('checkVal ::: ', this.checkVal)
      this.$emit('agreement', this.checkVal, this.gubun)
    }
  }
}
</script>

<style scoped>
</style>
