<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('msg.MAIN100.172') }}</h1>

    <div class="content_box mt10">
      <!-- content_box -->
      <table class="tbl_row">
        <colgroup>
          <col width="130"><col><col width="130"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.MAIN100.173') }}</th>
            <td>{{ param.reqUno }}</td>
            <th>{{ $t('msg.MAIN100.174') }}</th>
            <td>{{ param.usrCtrCd }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.MAIN100.175') }}</th>
            <td>{{ userTelNo }}</td>
            <th>{{ $t('msg.MAIN100.202') }}</th>
            <td>{{ param.usrEmlAddr }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.MAIN100.176') }}</th>
            <td>{{ param.contKndCd }}</td>
            <th>{{ $t('msg.MAIN100.206') }}</th>
            <td v-if="param.repYn !== 'Y'">
              <span class="label red md">{{ $t('msg.MAIN100.207') }}</span>
            </td>
            <td v-else>
              <span class="label green md">{{ $t('msg.MAIN100.208') }}</span>
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.MAIN100.210') }}</th>
            <td colspan="3">
              <div style="min-height:200px; word-break: break-all;">
                {{ param.rmk }}
              </div>
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.MAIN100.211') }}</th>
            <td v-if="param.cfmRmk" colspan="3">
              <div style="min-height:200px; word-break: break-all;">
                {{ param.cfmRmk }}
              </div>
            </td>
            <td v-if="!param.cfmRmk && param.repYn === 'Y'" colspan="3">
              <div style="min-height:200px; word-break: break-all;">
                {{ $t('msg.MAIN100.212') }}
              </div>
            </td>
            <td v-else colspan="3"></td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
    <div class="flex_box mt10">
      <a class="button blue lg" href="#" @click.prevent="$router.push({ name: 'Voc' })">{{ $t('msg.MAIN100.213') }}</a> <!-- 우축 정렬시  ml_auto 추가 -->
    </div>
  </div>
</template>

<script>
import vocs from '@/api/services/vocs'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'VocDetail',
  components: {
    EBreadcrumbs
  },
  props: {
    cstNo: {
      type: String,
      default: ''
    },
    cstUno: {
      type: String,
      default: ''
    },
    seq: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      param: {
        //개인번호
        cstNo: this.cstNo,
        //작성자
        cstUno: this.cstUno,
        //순서
        seq: this.seq,
        //작성자
        reqUno: '',
        //작성 지역
        usrCtrCd: '',
        //전화번호
        picTelOfcNo: '',
        picTelNo: '',
        //E-mail
        usrEmlAddr: '',
        //제안 및 불만
        contKndCd: '',
        //내용
        rmk: '',
        //답변내용
        cfmRmk: '',
        //답변여부
        repYn: ''
      },
      //카테고리 리스트
      categoryList: [],
      //작성지역 리스트
      ctrList: []
    }
  },
  computed: {
    userTelNo: function () {
      return this.param.picTelOfcNo + '-' + this.param.picTelNo
    }
  },
  created () {
    this.initCommCodes()
    this.getVocsDetail()
  },
  methods: {
    async initCommCodes () {
      const cdId = 'SA023'
      await Promise.all([
        vocs.getCtrCode().then(response => {
          if (response.status === 200) {
            this.ctrList = [...this.ctrList, ...response.data]
          }
        }),

        vocs.getCode({ cdId: cdId }).then(response => {
            this.categoryList = [{ cd: '', cdNm: '선택' }]

            if (response.status === 200) {
            this.categoryList = [...this.categoryList, ...response.data]
          }
        })
        .catch(err => {
          console.log(err)
        })
      ])
    },
    // 카테고리 명 가져오기
    getCategoryName (cd) {
      let catNm = '카테고리 X'
      if (this.$ekmtcCommon.isNotEmpty(cd)) {
          this.categoryList.forEach((item) => {
            if (item.cd === cd) {
                catNm = item.cdNm
            }
        })
      }
      return catNm
    },
    // 국가 명 가져오기
    getCountryName (ctrCd) {
      let ctrEnm = '국가명 X'
      if (this.$ekmtcCommon.isNotEmpty(ctrCd)) {
          this.ctrList.forEach((item) => {
            if (item.ctrCd === ctrCd) {
                ctrEnm = item.ctrEnm
            }
        })
      }
      return ctrEnm
    },
    getVocsDetail () {
      if (this.cstNo !== '') {
        const data = {
          cstNo: this.cstNo,
          seq: this.seq
        }
        vocs.getVocsDetail(data).then(res => {
          if (res.status === 200) {
            this.param = { ...this.param, ...res.data.detail }
            this.param.contKndCd = this.getCategoryName(res.data.detail.contKndCd)
            this.param.usrCtrCd = this.getCountryName(res.data.detail.usrCtrCd)
            this.param.picTelNo = res.data.detail.picTelNo.substring(0, 3) + '-' + res.data.detail.picTelNo.substring(3)
          }
        })
      }
    }
  }
}
</script>
