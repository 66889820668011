<template><!-- 업무 대행정보 >> 업무 대행정보 리스트 -->
  <form id="frm">
    <div class="content widfix">
      <!-- <e-breadcrumbs />
      <h1 class="page_title">{{ $t('msg.CMBA100.544') }}</h1> -->
      <div class="content_box beforetab">
        <!-- content_box -->
        <table class="tbl_search">
          <colgroup>
            <col width="80px"><col><col width="80px"><col><col width="120px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.MYIN040G010.001') }}</th>
              <td>
                <select v-model="params.autCatCd">
                  <option value="00">{{ $t('msg.MYIN040G010.006') }}</option>
                  <option value="02">{{ $t('msg.MYIN040G010.046') }}</option>
                  <option value="01">{{ $t('msg.MYIN040G010.047') }}</option>
                </select>
              </td>
              <th>{{ $t('msg.MYIN040G010.004') }}</th>
              <td>
                <input type="text" v-model="params.bsnNm">
              </td>
              <td></td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA320.005') }}</th>
              <td colspan="3">
                <!-- <e-date-range-picker-for-settle
                  :term-type="termType"
                  :show-term-type="['01', '02', '03', '04']"
                  :sdate="range.sdate"
                  :edate="range.edate"
                  @change="changeDateRange"
                /> -->
                <e-date-range-picker-multi
                  :id="'agencyInfoListDatePicker'"
                  @change="changeDateRange"
                  :sdate="range.sdate"
                  :edate="range.edate"
                  :is-exist-form-tit="false"
                />
              </td>
              <td class="text_right"><a class="button blue sh" @click="searchData()">{{ $t('msg.MYIN040G010.009') }}</a></td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
      <div class="content_box mt10">
        <div class="flex_box">
          <span class="tot_num">Total : <span class="num">{{ total }}</span></span>
          <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span><!--  엑셀 다운로드 -->
        </div>
        <div id="agent-second-tab-list-realgrid" style="width: 100%; height: 450px">
        </div>
        <!--
        <div class="flex_box">
          <span class="tot_num">Total : <span class="num">{{ agencyInfoList.length }}</span></span>
        </div>
        <div :style="headerFooterCss">
          <table class="tbl_col">
            <colgroup>
              <col style="width:12%">
              <col style="width:22%">
              <col style="width:32%">
              <col style="width:12%">
              <col style="width:11%">
              <col style="width:11%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{{ $t('msg.MYIN040G010.012') }}</th>
                <th scope="col">{{ $t('msg.MYIN040G010.013') }}</th>
                <th scope="col">{{ $t('msg.MYIN040G010.056') }}</th>
                <th scope="col">{{ $t('msg.MYIN040G010.015') }}<br />{{ $t('msg.MYIN040G010.016') }}</th>
                <th scope="col">{{ $t('msg.MYIN040G010.017') }}</th>
                <th scope="col">{{ $t('msg.MYIN040G010.018') }}</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="imp-body-table-div">
          <table class="tbl_col" id="imp-body-table">
            <colgroup>
              <col style="width:12%">
              <col style="width:22%">
              <col style="width:32%">
              <col style="width:12%">
              <col style="width:11%">
              <col style="width:11%">
            </colgroup>
            <tbody>
              <tr :class="row.cls" v-for="(row, i) in agencyInfoList" :key="'agency-' + i">
                <td>
                  <template v-if="row.autCatCd == '01'">{{ $t('msg.MYIN040G010.047') }}</template>
                  <template v-else>{{ $t('msg.MYIN040G010.046') }}</template>
                </td>
                <td class="txt_line">
                  {{ row.cstGrpCatNm }}
                </td>
                <td class="txt_line">
                  {{ row.bsnNm }}
                </td>
                <td>{{ row.bzrgNo }}</td>
                <td>{{ row.strDt }}</td>
                <td>{{ row.endDt }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        -->
      </div>
    </div>
  </form>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid' // realgrid Component
import ImptBsnesAgencInfo from '@/api/rest/user/imptBsnesAgencInfo'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import EDateRangePickerForSettle from '@/components/common/EDateRangePickerForSettle' // 달력 Component
import { rootComputed } from '@/store/helpers'
import _lodash from 'lodash'
import store from '@/store/index'
import moment from 'moment'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'autCatCd', dataType: 'text' }, // 위수임 구분
  { fieldName: 'autCatNm', dataType: 'text' }, // 위수임 구분명
  { fieldName: 'cstNo', dataType: 'text' }, // 업체번호
  { fieldName: 'seqAgencySeq', dataType: 'number' }, // 업체 시퀀스
  { fieldName: 'bsnNm', dataType: 'text' }, // 업체명
  { fieldName: 'autCstNo', dataType: 'text' }, // 업체코드
  { fieldName: 'bzrgNo', dataType: 'text' }, // 사업자등록번호
  { fieldName: 'cstGrpCatCd', dataType: 'text' }, // 업무 구분
  { fieldName: 'cstGrpCatNm', dataType: 'text' }, // 업무 구분명
  { fieldName: 'apvYn', dataType: 'text' }, // 승인여부
  { fieldName: 'strDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 시작일
  { fieldName: 'endDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 종료일
  { fieldName: 'fstEntUno', dataType: 'text' }, // 작성자 사번
  { fieldName: 'fileSeq', dataType: 'number' }, // 파일 시퀀스
  { fieldName: 'fileId', dataType: 'text' }, // 파일 아이디
  { fieldName: 'fileSz', dataType: 'number' }, // 파일 사이즈
  { fieldName: 'fileNm', dataType: 'text' }, // 파일명
  { fieldName: 'autEntrTyp', dataType: 'text' }, // AUT_ENTR_TYP
  { fieldName: 'cstPicEmlAddr', dataType: 'text' } // CST_PIC_EML_ADDR
]

export const columns = [
  {
    name: 'autCatNm',
    fieldName: 'autCatNm',
    type: 'text',
    header: { text: app.$t('msg.MYIN040G010.012') },
    editable: false,
    width: 60,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const autCatCdVal = grid.getValue(idx, 'autCatCd')
        let str = ''
        if (autCatCdVal === '01') {
          str = app.$t('msg.MYIN040G010.047')
        } else {
          str = app.$t('msg.MYIN040G010.046')
        }
        return str
      }
    }
  }, // 업무 구분
  { name: 'cstGrpCatNm', fieldName: 'cstGrpCatNm', type: 'text', header: { text: app.$t('msg.MYIN040G010.013') }, editable: false, width: 60 }, // 업무 구분명
  { name: 'bsnNm', fieldName: 'bsnNm', type: 'text', header: { text: app.$t('msg.MYIN040G010.056') }, editable: false, width: 210 }, // 업체명
  { name: 'bzrgNo', fieldName: 'bzrgNo', type: 'text', header: { text: app.$t('msg.MYIN040G010.015') + '\n' + app.$t('msg.MYIN040G010.016'), styleName: 'multi-line-css' }, editable: false, width: 50 }, // 사업자등록번호
  { name: 'strDt', fieldName: 'strDt', type: 'text', header: { text: app.$t('msg.MYIN040G010.017') }, editable: false, datetimeFormat: 'yyyy.MM.dd', width: 50 }, // 시작일
  { name: 'endDt', fieldName: 'endDt', type: 'text', header: { text: app.$t('msg.MYIN040G010.018') }, editable: false, datetimeFormat: 'yyyy.MM.dd', width: 50 } // 종료일
]

const gridLayout = [
  'autCatNm', // 위수임 구분명
  'cstGrpCatNm', // 업무 구분명
  'bsnNm', // 업체명
  'bzrgNo', // 사업자등록번호
  'strDt', // 시작일
  'endDt' // 종료일
]

export default {
  name: 'ImptBsnesAgencInfoList',
  components: {
    ImptBsnesAgencInfo,
    EBreadcrumbs,
    // EDateRangePickerForSettle,
    EDateRangePickerMulti: () => import('@/components/common/EDateRangePickerMulti')
  },
  props: {
    paramData: {
      type: Object,
      default () {
        return {
          tab: '' // 탭
        }
      }
    }
  },
  data: function () {
    return {
      agencyInfoList: [],
      termType: '02',
      gridLayout: [],
      range: {
        sdate: '',
        edate: ''
      },
      params: {
        autCatCd: '00',
        bsnNm: '',
        fromDt: '',
        toDt: ''
      },
      beforeParams: {},
      rstList: [],
      searchedList: [],
      total: 0,
      currentPage: 0,
      totalPage: 0,
      agencyInfo: {
        autCatCd: '02',
        autCatNm: '',
        agencySeq: 0,
        bsnNm: null,
        autCstCd: null,
        bzrgNo: null,
        cstGrpCatCd: null,
        cstGrpCatNm: null,
        apvYn: null,
        strDt: '',
        endDt: '',
        fstEntUno: null,
        cstNo: null,
        fileSeq: 0,
        fileSz: 0,
        fileNm: null,
        autEntrTyp: null,
        cstPicEmlAddr: null
      },
      headerFooterCss: {},
      eicat: {
        iList: [],
        eList: []
      },
      statusCd: '00',
      isReqView: false,
      unformattedFrom: '',
      unformattedTo: '',

      validationDt: false,
      relYn: true
    }
  },
  computed: {

  },
  watch: {
  },
  created () {
    window.functionOutsideVue(this)
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted: function () {
    this.setDateRange()
    this.getEiCatList()

    provider = new LocalDataProvider(true)
    gridView = new GridView('agent-second-tab-list-realgrid')

    // 그리드 필드 생성
    provider.setFields(fields)

    // columns 명
    gridView.setColumnLayout(gridLayout)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)

    // 그리고 헤더 높이 조정
    gridView.header.height = [60]
    gridView.displayOptions.rowHeight = 40
    gridView.displayOptions.fitStyle = 'evenFill'
    // gridView.displayOptions.selectionStyle = 'block'
    gridView.setFooter({ visible: false })
    gridView.setRowIndicator({ visible: false })
    gridView.setStateBar({ visible: false })

    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    // gridView.setCheckBar({
    //  visible: true,
    //  exclusive: false,
    //  mergeRule: "values['acshIssDt'] + values['blNo']"
    // })
    gridView.setCheckBar({
     visible: false
    })
    // gridView.columnByName('acshIssDt').mergeRule = { criteria: "value + values['blNo']" }
    // gridView.columnByName('accoDt').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('acshRno').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('blNo').mergeRule = { criteria: "values['acshIssDt'] + value" }
    // gridView.columnByName('usdFrtSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('frtSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('wfgSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('pfsSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('othSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('othTaxSum').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }
    // gridView.columnByName('total').mergeRule = { criteria: "values['acshIssDt'] + values['blNo'] + value" }

    this.gridInit()
    this.requestView()
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  methods: {
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.searchData()
        }
      }
    },
    setDateRange () {
      this.range = {
        //현업 요청으로 조회기간 1년으로 변경함 (엑셀 104번.진혜정님)
        sdate: this.getWeekDt(-364),
        edate: this.getWeekDt(0)
      }
      this.params.fromDt = this.range.sdate
      this.params.toDt = this.range.edate
    },
    gridInit () {
      // 그리드 초기화
      this.rstList = []
      provider.setRows(this.rstList)
      this.total = this.rstList.length
      this.currentPage = gridView.getPage()
      this.totalPage = gridView.getPageCount()
    },
    // 입력 데이터 기준 yyyyMMdd 반환
    getDateStr (myDate) {
      let year = myDate.getFullYear()
      let month = (myDate.getMonth() + 1)
      let day = myDate.getDate()

      month = (month < 10) ? '0' + String(month) : month
      day = (day < 10) ? '0' + String(day) : day

      return year.toString() + month.toString() + day.toString()
    },
    // n일 후의 yyyyMMdd 반환
    getWeekDt (n) {
      let d = new Date()
      let dayOfMonth = d.getDate()
      d.setDate(dayOfMonth + n)
      return this.getDateStr(d)
    },
    changeDateRange (s, d) {
      this.params.fromDt = s
      this.params.toDt = d

      this.unformattedFrom = moment(s).format('YYYY-MM-DD')
      this.unformattedTo = moment(d).format('YYYY-MM-DD')

      this.checkSearchValidation('agencyInfoListDatePicker')
    },
    checkSearchValidation (id) {
      const searchParams = this.param
      const frm = document.querySelector('#frm')
      let selector = frm.querySelector('#' + id)
      let isOk = true

      if (selector === null || selector === undefined) {
        return true
      }

      // this.$ekmtcCommon.hideErrorTooltip(selector)
      this.localHideErrorTooltip(selector)

      switch (id) {
        case 'agencyInfoListDatePicker':
          var fromDt = new Date(this.unformattedFrom)
          var toDt = new Date(this.unformattedTo)

          if (fromDt > toDt) {
            this.LocalShowErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            // this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            isOk = false
          }
          break
      }
      return isOk
    },
    LocalShowErrorTooltip (elem, message) {
          this.validationDt = true

          let $elem = $(elem)
          let $parent = $elem.parents('td, div').eq(0)
          let $parentTd = $parent.parents('td,div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.addClass('input_error')
          let $sMsgBox = ''
          let $msgBox = ''

          // $parentTd.addClass('position_relative')
          $msgBox = $parent.find('.tooltip_essen_box')

          if ($sMsgBox.length === 0) {
            $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
            $msgBox.appendTo($parentLi)
          }
          $msgBox.find('.tooltip_essen').eq(0).text(message).show()
    },
    localHideErrorTooltip (elem) {
          this.validationDt = false
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.removeClass('input_error')
          $parentLi.find('.tooltip_essen').hide()
    },
    // 리스트 조회
    requestView () {
      this.isReqView = true
      if (this.paramData.tab === '2') {
        this.searchData()
      }
    },
    // 유효성 확인 후 외상매출금 api 호출
    searchData () {
      if (this.optionChk()) {
        this.rstList = []

        ImptBsnesAgencInfo.agencySecondTabList(this.params).then(response => {
          console.log(response)
          this.isReqView = false
          this.beforeParams = JSON.parse(JSON.stringify(this.params))
          let resp = []
          resp = response.data.rstList
          this.searchedList = this.$_.cloneDeep(resp)

          this.total = this.getTotalCnt(resp)
          this.rstList = resp
          provider.setRows(this.rstList)
          gridView.setPage(-1)

          // console.log(this.rstList)
          this.currentPage = gridView.getPage() //현재 페이지 반환
          this.totalPage = gridView.getPageCount() //전체페이지 개수 반환

          if (this.total === 0) {
            this.openAlert(this.$t('msg.SETT010T010.129'))
          }
        }).catch(err => {
          this.isReqView = false
          console.log(err)
        })
      }
    },
    // 리스트 총 카운트 반환
    getTotalCnt (arr) {
      return arr.length
    },
    // 외상매출금 조회 전 파라미터 체크
    optionChk: function () {
      const reg = /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/

      if (this.params.fromDt === '') {
        this.openAlert(this.$t('msg.SETT010T010.152'))
        return false
      }
      if (this.params.toDt === '') {
        this.openAlert(this.$t('msg.SETT010T010.153'))
        return false
      }

      if (!reg.test(this.params.fromDt)) {
        this.openAlert(this.$t('msg.SETT010T010.151'))
        return false
      }
      if (!reg.test(this.params.toDt)) {
        this.openAlert(this.$t('msg.SETT010T010.151'))
        return false
      }

      return true
    },
    getEiCatList () {
      ImptBsnesAgencInfo.eicatList().then(response => {
        this.eicat.iList = response.data.ilist
        this.eicat.eList = response.data.elist
        this.eicat.iList.forEach(element => {
          element.cdNm = this.$t(`msg.MY_INFO.${element.cdId}.${element.cd}`)
        })
        this.eicat.eList.forEach(element => {
          element.cdNm = this.$t(`msg.MY_INFO.${element.cdId}.${element.cd}`)
        })
        //this.getAgencyInfoList()
      }).catch(err => {
        console.log(err)
      })
    },
    /*
    getAgencyInfoList () {
      ImptBsnesAgencInfo.agencySecondTabList(this.params).then(response => {
        console.log(response)
        this.agencyInfoList = this.parseAgencyInfoList(response.data.rstList)
        if (this.agencyInfoList.length > 10) {
          this.headerFooterCss = {
              paddingRight: '3px'
          }
        } else {
          this.headerFooterCss = {
              paddingRight: '0px'
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    */
   /*
    parseAgencyInfoList (data) {
      for (let i = 0; i < data.length; i++) {
        data[i].cls = ''
        data[i].strDt = this.parseDateFormat(data[i].strDt, '.')
        data[i].endDt = this.parseDateFormat(data[i].endDt, '.')
      }
      return data
    },
    */
    parseDateFormat (date, separator) {
      return date.substring(0, 4) + separator + date.substring(4, 6) + separator + date.substring(6, 8)
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    // 레이어 팝업 호출
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    // 레이어 팝업 종료
    closeFun () {
      this.$Simplert.close()
    },
    // 이전 페이지 호출
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    // 다음 페이지 호출
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
    exlDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: this.$t('msg.CMBA100.548'), // 업무 대행정보 리스트
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
        applyDynamicStyles: true,
        exportTemplate: true,
        allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('msg.CMBA100.544') + '_' + this.$t('msg.CMBA100.548')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    }
 /*
    exlDownload () { // 그리드 엑셀 다운로드
      let title = `${this.$t('msg.CMBA100.544')}_${this.$t('msg.CMBA100.548')}` // 업무 대행정보_업무 대행정보 리스트
      this.$ekmtcCommon.gridExportExcel(gridView, title)
    }
  */
  }
}
</script>

 <style>
#agent-second-tab-list-realgrid .left-column {
  text-align: left;
}

#agent-second-tab-list-realgrid .imp-body-table-div {
  overflow: auto;
  max-height: 340px;
}

#agent-second-tab-list-realgrid #imp-body-table {
  table-layout: fixed;
}

#agent-second-tab-list-realgrid #imp-body-table > tbody > tr {
  cursor: pointer;
}

#agent-second-tab-list-realgrid #imp-body-table > tbody > tr:hover td {
  background: #ebf1ff ;
}

#agent-second-tab-list-realgrid #imp-body-table > tbody > .selected td {
  background: #ebf1ff ;
}

#agent-second-tab-list-realgrid .imp-body-table-div::-webkit-scrollbar {
  width: 3px;
  background: #fff;
}
#agent-second-tab-list-realgrid .imp-body-table-div::-webkit-scrollbar-thumb {
  background: #999;
}

#agent-second-tab-list-realgrid .txt_line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px 10px;
}

#agent-second-tab-list-realgrid .multi-line-css {
  white-space: pre !important;
}
 </style>
