import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  findLocalCharge (formData) {
    return Send({
      method: 'get',
      url: '/trans/local-charge',
      params: {
        polCn: formData.polCtrCd,
        podCn: formData.podCtrCd,
        polCd: formData.polPortCd,
        podCd: formData.podPortCd,
        cntrTypeList: formData.cntrTypeList.join(',')
      }
    })
  }
}
