import Send from '../../user.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
    //마일리지 조회 - GET /trans/oog/list
    getMileageList (param) {
        return Send({
            // url: 'http://localhost:9002/user/mileage',
            url: '/user/mileage',
            method: 'get',
            // params: param
            params: {
                catCd: param.catCd,
                strDt: param.strDt,
                endDt: param.endDt,
                pageNo: param.pageNo,
                pageSize: param.pageSize,
                cstNo: param.cstNo,
                userId: param.userId
            }
        })
    },
    getMileageRemain (param) {
        return Send({
            // url: 'http://localhost:9002/user/mileage/remain',
            url: '/user/mileage/remain',
            method: 'get'
        })
    },
    // 마일리지 -> 포인트 요청 등록
    refundMileage (data) {
        return new Promise((resolve, reject) => {
            Send({
                // url: 'http://localhost:9002/user/mileage',
                url: '/user/mileage',
                method: 'put',
                data: {
                    mlgAmt: data.reqMileage,
                    cstPicNm: data.reqName,
                    cstPicMbpNo: data.reqPhone,
                    cstPicEmlAddr: data.reqEmail
                }
            }).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}
