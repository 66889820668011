<template>
  <form id="frm">
    <div class="popup_wrap" style="width:800px;">
      <button class="layer_close" @click="layerClose()">close</button>
      <div class="popup_cont">
        <h1 class="page_title">{{ $t('msg.MYIN020T020.016') }}</h1><!-- Report 구독 -->
        <!-- content_box -->
        <form id="frm">
          <div class="content_box">
            <table class="tbl_row mt10">
              <colgroup>
                <col style="width:20%">
                <col style="width:auto">
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">{{ $t('msg.MYIN020T020.010') }}</th><!-- 수출/수입 -->
                  <td>
                    <input
                      v-model="param.inout"
                      type="radio"
                      id="radio1"
                      name="radioGubun"
                      value="O"
                    >
                    <label for="radio1" class="mr20">{{ $t('msg.MYIN020T020.017') }}</label><!-- 수출 -->
                    <input
                      v-model="param.inout"
                      type="radio"
                      id="radio2"
                      name="radioGubun"
                      value="I"
                    >
                    <label for="radio2" class="mr20">{{ $t('msg.MYIN020T020.018') }}</label><!-- 수입 -->
                  </td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('msg.MYIN020T020.019') }}</th><!-- 구분항목 -->
                  <td>
                    <ul class="grid">
                      <li>
                        <input type="radio" v-model="param.kind" value="01" id="rdoType1" name="radioType">
                        <label for="rdoType1">{{ $t('msg.MYIN020T020.042') }}</label><!-- On board Date -->
                      </li>
                      <li class="ml20">
                        <input type="radio" v-model="param.kind" value="02" id="rdoType2" name="radioType">
                        <label for="rdoType2">{{ $t('msg.MYIN020T020.020') }}</label><!-- 출항일 -->
                      </li>
                      <li class="ml20">
                        <input type="radio" v-model="param.kind" value="03" id="rdoType3" name="radioType">
                        <label for="rdoType3">{{ $t('msg.MYIN020T020.021') }}</label><!-- 입항일 -->
                      </li>
                      <li class="ml20">
                        <input type="radio" v-model="param.kind" value="04" id="rdoType4" name="radioType">
                        <label for="rdoType4">{{ $t('msg.MYIN020T020.022') }}</label><!-- 입금일 -->
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('msg.MYIN020T020.023') }}</th><!-- 구독일 -->
                  <td>
                    <div class="d_inline_block">
                      <div class="d_inline_block wid90">
                        <span>{{ $t('msg.MYIN020T020.024') }}</span>
                      </div>
                      <select v-model="param.rptDate" class="wid80" id="selectDate">
                        <option v-for="day in dayList" :value="day.value" :key="day.value">{{ day.day }}</option>
                      </select>
                    </div>
                    <div class="d_inline_block">
                      <span class="">&nbsp;{{ $t('msg.MYIN020T020.051') }}</span><!-- (전월 1일 ~ 말일 기준 ) -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('msg.MYIN020T020.013') }}</th><!-- 구독기간 -->
                  <td>
                    <select v-model="param.rptTrm" class="wid120">
                      <option value="1">{{ $t('msg.MYIN020T020.030') }}</option><!-- 1개월 -->
                      <option value="2">{{ $t('msg.MYIN020T020.031') }}</option><!-- 2개월 -->
                      <option value="3">{{ $t('msg.MYIN020T020.032') }}</option><!-- 3개월 -->
                      <option value="4">{{ $t('msg.MYIN020T020.033') }}</option><!-- 4개월 -->
                      <option value="5">{{ $t('msg.MYIN020T020.034') }}</option><!-- 5개월 -->
                      <option value="6">{{ $t('msg.MYIN020T020.035') }}</option><!-- 6개월 -->
                      <option value="7">{{ $t('msg.MYIN020T020.036') }}</option><!-- 7개월 -->
                      <option value="8">{{ $t('msg.MYIN020T020.037') }}</option><!-- 8개월 -->
                      <option value="9">{{ $t('msg.MYIN020T020.038') }}</option><!-- 9개월 -->
                      <option value="10">{{ $t('msg.MYIN020T020.039') }}</option><!-- 10개월 -->
                      <option value="11">{{ $t('msg.MYIN020T020.040') }}</option><!-- 11개월 -->
                      <option value="12">{{ $t('msg.MYIN020T020.041') }}</option><!-- 12개월 -->
                    </select>
                  </td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('msg.MYIN020T020.014') }}</th><!-- E-mail -->
                  <td>
                    <input type="text" v-model="email1" class="wid120" id="inputEmail" @input="checkValiEmail()">
                    <span class="space">@</span>
                    <input type="text" v-model="email2" class="wid150" id="inputEmail2" @input="checkValiEmail()">
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="serviceCtrCd === 'KR'" class="mt10">
              <!-- {{ rptDateType }} {{ totalDate }}, {{ totalKind }} 기준으로 {{ totalDateType }} 1일 ~ 말일까지의 Report를 {{ param.rptTrm }}개월 동안 {{ email }}으로 구독신청 합니다. -->
              {{ rptDateType }} {{ totalDate }}, {{ totalKind }} {{ $t('msg.MYIN020T020.070') }} {{ totalDateType }} {{ $t('msg.MYIN020T020.071') }} {{ param.rptTrm }}{{ $t('msg.MYIN020T020.072') }} {{ email }}{{ $t('msg.MYIN020T020.073') }}
            </div>
          </div>
        </form>
        <div class="mt10 text_center">
          <a class="button blue lg" href="#" @click.prevent="onConfirm()">{{ $t('msg.MYIN020T020.052') }}</a><!-- 저장 -->
          <a class="button gray lg" href="#" @click.prevent="layerClose()">{{ $t('msg.MYIN020T020.046') }}</a><!-- 닫기 -->
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import report from '@/api/rest/user/report'

export default {
  name: 'ReportSubPop',
  data () {
    return {
      param: {
        //수출/수입
        inout: 'O',
        //구분항목
        kind: '01',
        //구독일
        rptDate: '01',
        //구독기간
        rptTrm: '1',
        //이메일
        rptEmlAddr: ''
      },
      //구독일 기간 Type
      dayList: [
        { value: '01', day: this.$t('msg.MYIN020T020.075') }, // '1일'
        { value: '02', day: this.$t('msg.MYIN020T020.076') }, // '15일'
        { value: '03', day: this.$t('msg.MYIN020T020.077') } // '말일'
      ],
      //이메일 1,2
      email1: '',
      email2: '',
      //rptDateType '매월'
      rptDateType: this.$t('msg.MYIN020T020.074'),
      //totalDateType '전월'
      totalDateType: this.$t('msg.MYIN020T020.078'),
      serviceCtrCd: ''
   }
  },
  created () {
    this.init()
  },
  computed: {
    ...rootComputed,
    email: function () {
      return this.email1 + '@' + this.email2
    },
    totalKind: function () {
      let result = ''
      let kind = this.param.kind

      if (kind === '01') {
        result = 'On Board Date'
      } else if (kind === '02') {
        result = this.$t('msg.MYIN020T020.020') // '출항일'
      } else if (kind === '03') {
        result = this.$t('msg.MYIN020T020.021') // '입항일'
      } else if (kind === '04') {
        result = this.$t('msg.MYIN020T020.022') // '입금일'
      }

      return result
    },
    totalDate: function () {
      let result = ''
      let rptDate = this.param.rptDate

      if (rptDate === '01') {
        result = this.$t('msg.MYIN020T020.075') // '1일'
      } else if (rptDate === '02') {
        result = this.$t('msg.MYIN020T020.076') // '15일'
      } else if (rptDate === '03') {
        result = this.$t('msg.MYIN020T020.077') // '말일'
      }

      return result
    }
  },
  methods: {
    init () {
      // 1. 로그인 시 로그인한 유저의 국가정보
      // 2. 브라우저 접속한 국가정보
      // 3. 상단 드롭다운의 언어정보 (Default: KOR)
      if (!this.auth.userId) {
        this.serviceCtrCd = this.$ekmtcCommon.isNotEmpty(localStorage.getItem('service_ctrcd')) ? localStorage.getItem('service_ctrcd') : localStorage.getItem('service_lang')
      } else {
        this.serviceCtrCd = this.memberDetail.userCtrCd
      }

      // KOR -> KR
      if (this.serviceCtrCd === 'KOR') {
        this.serviceCtrCd = 'KR'
      }
    },
    //layer_popup
    layerOpen: function () {
      $('.popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
      return false
    },
    layerClose: function () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    // tooltip 전체 제거
    clearValidationAll () {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.hideErrorTooltipAll(frm)
    },
    // 저장 전, 전체 validation 체크
    checkValidationAll () {
      const frm = document.querySelector('#frm')
      let isAllOk = true

      // '이메일' 체크
      if (!this.checkValiEmail()) {
        isAllOk = false
      }

      return isAllOk
    },
    //이메일 체크
    checkValiEmail () {
      const elem = document.querySelector('#inputEmail')
      const elem2 = document.querySelector('#inputEmail2')
      const val = this.email
      let isOk = true
      let msg = ''

      if (val === '@') {
        // 값이 없을 경우
        msg = this.$t('msg.MYIN020T020.045') // 이메일을 입력해주십시오.'
        isOk = false
      } else if (!this.$ekmtcCommon.checkEmail(val)) {
        // 이메일 형식 체크
        msg = this.$t('art.CMATK196') // 잘못된 형식의 이메일 주소가 있습니다.'
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.$ekmtcCommon.showErrorTooltip(elem2, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
        this.$ekmtcCommon.hideErrorTooltip(elem2)
      }

      return isOk
    },
    async onConfirm () {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MYIN020T020.050'), useConfirmBtn: true })) {
        if (this.checkValidationAll()) {
          this.param.rptEmlAddr = this.email

         await report.insertReportSub(this.param).then(response => {
            if (response.headers.respcode === 'C0000') {
              // 구독신청이 완료되었습니다.
              this.$ekmtcCommon.alertCallback(this.$t('msg.MYIN020T020.048'), () => {
                this.$emit('close')
              })
            } else {
              // 구독신청을 실패하였습니다.
              this.$ekmtcCommon.alertDefault(this.$t('msg.MYIN020T020.049'))
            }
          })
          .catch(error => {
            console.log(error)
          })
        }
      }
    },
    closeFunc () {
      this.$emit('selectFunc', 'ok')
      this.$emit('close')
    }
  }
}
</script>

<style>

</style>
