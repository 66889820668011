var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.flag == "Y"
      ? _c("div", { staticClass: "flex_box" }, [
          _c("span", { staticClass: "tot_num" }, [
            _vm._v("Total : "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.list.length)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.flag == "Y"
      ? _c("div", {
          staticStyle: { width: "100%", height: "600px" },
          attrs: { id: "realgrid1" },
        })
      : _vm._e(),
    _vm.flag == "N"
      ? _c("div", { staticClass: "flex_box" }, [
          _c("span", { staticClass: "tot_num" }, [
            _vm._v("Total : "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.list.length)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.flag == "N"
      ? _c("div", {
          staticStyle: { width: "100%", height: "600px" },
          attrs: { id: "realgrid2" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }