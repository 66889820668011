<template>
  <div class="popup_wrap popup_wrap_pdf" style="width:1000px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">업무 위임장</h1>
      <div class="content_box">
        <div class="clearfix">
          <div class="float_left text_center col_10 pl20" style="max-height:500px;overflow-y:auto">
            <!-- <div>{{ pdf.currentPage }} / {{ pdf.pageCount }}</div> -->
            <vue-pdf-app
              style="height: 300px;"
              :pdf="pdf.pdfUrl"
              :config="pdfConfig.config"
              :id-config="pdfConfig.idConfig"
              theme="light"
            ></vue-pdf-app>
            <div class="paging print">
              <span class="num">
                <input type="number" :id="pdfConfig.idConfig.pageNumber" class="pageNumber" maxlength="2">
                <span :id="pdfConfig.idConfig.numPages" class="pageNumber">-</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="fnDownlaod();">{{ $t('msg.REPORT_PDF.101') }}<!-- 다운 --></a>
        <a class="button blue lg" :id="pdfConfig.idConfig.print">{{ $t('msg.REPORT_PDF.102') }}<!-- 인쇄 --></a>
        <a class="button blue lg" href="#" @click.prevent="openSendEmailPopup();">{{ $t('msg.REPORT_PDF.103') }}<!-- 공유 --></a>
      </div>
    </div>

    <win-layer-pop class="pdf_send_email">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closeSendEmailPopup"
        :parent-info="sendEmailParam"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commonPdf from '@/plugins/commonPdf'
import 'vue-pdf-app/dist/icons/main.css'

const REPORT_URL = process.env.VUE_APP_REPORT_URL
const SETTLE_URL = process.env.VUE_APP_SETTLE_URL

export default {
  name: 'DemInvoicePop',
  components: {
    vuePdfApp: () => import('vue-pdf-app'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    PdfSendEmailPop: () => import('@/pages/pdf/popup/PdfSendEmailPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      customComponent: null,
      sendEmailParam: {},
      items: [],
      userInfo: {},
      pdf: {
        pdfUrl: '',
        emailUrl: '',
        emailFiles: [],
        pg: 1,
        pageCount: 0,
        currentPage: 0
      },
      pdfConfig: {
        config: {},
        idConfig: {}
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      if (this.$props.parentInfo.blNo !== undefined) {
        this.setItems(this.$props.parentInfo)
      }
    }
  },
  created () {
    this.init()
    console.log('# auth info : ', this.auth)
    console.log('# auth memberDetail : ', this.memberDetail)
  },
  methods: {
    async init () {
      this.pdfConfig = commonPdf.getPdfConfig()
      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      if (this.$props.parentInfo.reqNo !== undefined) {
        this.setItems(this.$props.parentInfo)
      }
      console.log(this.$props.parentInfo)
      commonPdf.commonInit()
    },
    async setItems (items) {
      const logInfo = {
          logUserId: this.auth.userId,
          logCtrCd: this.memberDetail.userCtrCd,
          logPlcCd: this.memberDetail.bkgPlcCd
      }

      this.pdf = await commonPdf.setImpBusAgePdfPop(items, logInfo)
      console.log(this.pdf)
    },
    fnDownlaod () {
      console.log('fnDownlaod')
      console.log(this.pdf.downloadUrl)
      commonPdf.download(this.pdf.downloadUrl, this.parentInfo.reqNo + '.pdf')
    },
    openSendEmailPopup () {
      this.sendEmailParam = {
        emailUrl: this.pdf.emailUrl,
        emailFiles: this.pdf.emailFiles,
        type: 'AN'
      }
      this.customComponent = 'PdfSendEmailPop'
      commonPdf.layerOpen('.pdf_send_email')
    },
    closeSendEmailPopup () {
      this.customComponent = null
      commonPdf.layerClose('.pdf_send_email')
    }
  }
}
</script>

<style>

body {
  min-width:100% !important;
  overflow: auto !important;
}

</style>
