var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1000px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.014"))),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.MYIN040G010.014")) + " "),
            _c(
              "span",
              {
                staticClass: "d_inline_block valign_top",
                on: {
                  click: function ($event) {
                    return _vm.showTooltip(true)
                  },
                },
              },
              [
                _c("div", { staticStyle: { position: "relative" } }, [
                  _c("button", { staticClass: "tbl_icon help" }, [
                    _vm._v("help"),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isToolTip,
                          expression: "isToolTip",
                        },
                      ],
                      staticClass: "tooltip_wrap",
                      staticStyle: {
                        width: "300px",
                        position: "absolute",
                        left: "0",
                        top: "24px",
                      },
                    },
                    [
                      _c("div", { staticClass: "cont" }, [
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            on: {
                              click: function ($event) {
                                return _vm.showTooltip(false)
                              },
                            },
                          },
                          [_vm._v("close")]
                        ),
                        _c("p", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.052"))),
                        ]),
                        _c("ul", { staticClass: "bul_list_sm t2" }, [
                          _c("li", [
                            _vm._v(
                              " 1. " + _vm._s(_vm.$t("msg.MYIN040G010.053"))
                            ),
                            _c("br"),
                            _vm._v(
                              " 2. " + _vm._s(_vm.$t("msg.MYIN040G010.054"))
                            ),
                            _c("br"),
                            _vm._v(
                              " 3. " +
                                _vm._s(_vm.$t("msg.MYIN040G010.055")) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.012"))),
                ]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", display: "flex" } },
                    [
                      _c("div", { staticStyle: { width: "30%" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.agencyInfo.autCatCd,
                                expression: "agencyInfo.autCatCd",
                              },
                            ],
                            attrs: { disabled: !_vm.isAbleInput() },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.agencyInfo,
                                  "autCatCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "02" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.058"))),
                            ]),
                            _c("option", { attrs: { value: "01" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.057"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { width: "70%", "padding-top": "2px" } },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "padding-left": "10px",
                              },
                            },
                            [
                              _vm.agencyInfo.autCatCd === "02"
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.MYIN040G010.060")) +
                                        " "
                                    ),
                                  ]
                                : _vm.agencyInfo.autCatCd === "01"
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.MYIN040G010.059")) +
                                        " "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.013"))),
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.memberDetail.userCtrCd !== "KR"
                    ? _c("div", { staticClass: "work_sort_wrap" }, [
                        _c("div", { staticClass: "tbl_form" }, [
                          _c("span", { staticClass: "dv col_1 valign_top" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.agencyInfo.ioType,
                                  expression: "agencyInfo.ioType",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                id: "sort_rdo",
                                value: "O",
                                disabled: !_vm.isAbleInput(),
                              },
                              domProps: {
                                checked: _vm._q(_vm.agencyInfo.ioType, "O"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.agencyInfo, "ioType", "O")
                                },
                              },
                            }),
                            _c("label", { attrs: { for: "sort_rdo" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.050"))),
                            ]),
                          ]),
                          _c("span", { staticClass: "dv" }, [
                            _c(
                              "ul",
                              { staticClass: "grid ws form" },
                              _vm._l(_vm.eicat.eList, function (obj, i) {
                                return _c("li", { key: "oKey-" + i }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.agencyInfo.cstGrpCatCdArr,
                                        expression: "agencyInfo.cstGrpCatCdArr",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "o-" + i,
                                      disabled:
                                        _vm.agencyInfo.ioType != "O" ||
                                        !_vm.isAbleInput(),
                                    },
                                    domProps: {
                                      value: obj.cd,
                                      checked: Array.isArray(
                                        _vm.agencyInfo.cstGrpCatCdArr
                                      )
                                        ? _vm._i(
                                            _vm.agencyInfo.cstGrpCatCdArr,
                                            obj.cd
                                          ) > -1
                                        : _vm.agencyInfo.cstGrpCatCdArr,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.cstGrpCatCdControl(
                                          "O",
                                          obj.cd,
                                          _vm.isAbleInput()
                                        )
                                      },
                                      change: function ($event) {
                                        var $$a = _vm.agencyInfo.cstGrpCatCdArr,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = obj.cd,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.agencyInfo,
                                                "cstGrpCatCdArr",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.agencyInfo,
                                                "cstGrpCatCdArr",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.agencyInfo,
                                            "cstGrpCatCdArr",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c("label", { attrs: { for: "o-" + i } }, [
                                    _vm._v(_vm._s(obj.cdNm)),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]),
                        _c("input", {
                          attrs: { type: "hidden", id: "ioType-o" },
                        }),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "work_sort_wrap" }, [
                    _c("div", { staticClass: "tbl_form" }, [
                      _c("span", { staticClass: "dv col_1 valign_top" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.agencyInfo.ioType,
                              expression: "agencyInfo.ioType",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "sort_rdo1",
                            value: "I",
                            disabled: !_vm.isAbleInput(),
                          },
                          domProps: {
                            checked: _vm._q(_vm.agencyInfo.ioType, "I"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.choiceIm()
                            },
                            change: function ($event) {
                              return _vm.$set(_vm.agencyInfo, "ioType", "I")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "sort_rdo1" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.051"))),
                        ]),
                      ]),
                      _c("span", { staticClass: "dv" }, [
                        _c(
                          "ul",
                          { staticClass: "grid ws form disable" },
                          _vm._l(_vm.eicat.iList, function (obj, i) {
                            return _c(
                              "li",
                              { key: "iKey-" + i },
                              [
                                obj.cd === "10"
                                  ? [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.agencyInfo.cstGrpCatCdArr,
                                            expression:
                                              "agencyInfo.cstGrpCatCdArr",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "i-" + i,
                                          disabled:
                                            _vm.agencyInfo.ioType != "I" ||
                                            !_vm.isAbleInput(),
                                        },
                                        domProps: {
                                          value: obj.cd,
                                          checked: Array.isArray(
                                            _vm.agencyInfo.cstGrpCatCdArr
                                          )
                                            ? _vm._i(
                                                _vm.agencyInfo.cstGrpCatCdArr,
                                                obj.cd
                                              ) > -1
                                            : _vm.agencyInfo.cstGrpCatCdArr,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.cstGrpCatCdControl(
                                              "I",
                                              obj.cd,
                                              _vm.isAbleInput()
                                            )
                                          },
                                          change: function ($event) {
                                            var $$a =
                                                _vm.agencyInfo.cstGrpCatCdArr,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = obj.cd,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.agencyInfo,
                                                    "cstGrpCatCdArr",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.agencyInfo,
                                                    "cstGrpCatCdArr",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.agencyInfo,
                                                "cstGrpCatCdArr",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      obj.cd === "10"
                                        ? _c(
                                            "label",
                                            { attrs: { for: "i-" + i } },
                                            [
                                              _vm._v(
                                                _vm._s(obj.cdNm) +
                                                  "(" +
                                                  _vm._s(
                                                    _vm.remainImCstGrpCatCdNmStr
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.agencyInfo.cstGrpCatCdArr,
                                            expression:
                                              "agencyInfo.cstGrpCatCdArr",
                                          },
                                        ],
                                        staticStyle: {
                                          visibility: "hidden",
                                          width: "0px",
                                        },
                                        attrs: {
                                          type: "checkbox",
                                          id: "i-" + i,
                                          disabled:
                                            _vm.agencyInfo.ioType != "I" ||
                                            !_vm.isAbleInput(),
                                        },
                                        domProps: {
                                          value: obj.cd,
                                          checked: Array.isArray(
                                            _vm.agencyInfo.cstGrpCatCdArr
                                          )
                                            ? _vm._i(
                                                _vm.agencyInfo.cstGrpCatCdArr,
                                                obj.cd
                                              ) > -1
                                            : _vm.agencyInfo.cstGrpCatCdArr,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.cstGrpCatCdControl(
                                              "I",
                                              obj.cd,
                                              _vm.isAbleInput()
                                            )
                                          },
                                          change: function ($event) {
                                            var $$a =
                                                _vm.agencyInfo.cstGrpCatCdArr,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = obj.cd,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.agencyInfo,
                                                    "cstGrpCatCdArr",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.agencyInfo,
                                                    "cstGrpCatCdArr",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.agencyInfo,
                                                "cstGrpCatCdArr",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticStyle: {
                                            visibility: "hidden",
                                            width: "0px",
                                            height: "0px",
                                          },
                                          attrs: { for: "i-" + i },
                                        },
                                        [_vm._v(_vm._s(obj.cdNm))]
                                      ),
                                    ],
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("input", { attrs: { type: "hidden", id: "ioType-i" } }),
                  ]),
                  _c("input", { attrs: { type: "hidden", id: "ioType" } }),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.022"))),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.agencyInfo.bzrgNo,
                            expression: "agencyInfo.bzrgNo",
                          },
                        ],
                        attrs: {
                          type: "text",
                          disabled: !_vm.isAbleInput(),
                          id: "bzrgNo",
                        },
                        domProps: { value: _vm.agencyInfo.bzrgNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.agencyInfo,
                              "bzrgNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dv" }, [
                      _c(
                        "a",
                        {
                          class: _vm.isAbleInput()
                            ? "button sm"
                            : "button sm disabled",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openPopup(
                                "BusinessNumberPop",
                                null,
                                null
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.009")))]
                      ),
                    ]),
                  ]),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.023"))),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agencyInfo.bsnNm,
                        expression: "agencyInfo.bsnNm",
                      },
                    ],
                    attrs: {
                      type: "text",
                      disabled: !_vm.isAbleInput(),
                      id: "bsnNm",
                    },
                    domProps: { value: _vm.agencyInfo.bsnNm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.agencyInfo, "bsnNm", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.024"))),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agencyInfo.cstPicTelNo,
                        expression: "agencyInfo.cstPicTelNo",
                      },
                    ],
                    attrs: {
                      type: "text",
                      disabled: !_vm.isAbleInput(),
                      id: "cstPicTelNo",
                    },
                    domProps: { value: _vm.agencyInfo.cstPicTelNo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.agencyInfo,
                          "cstPicTelNo",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MY_INFO.001"))),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agencyInfo.cstPicFaxNo,
                        expression: "agencyInfo.cstPicFaxNo",
                      },
                    ],
                    attrs: {
                      type: "text",
                      disabled: !_vm.isAbleInput(),
                      id: "cstPicFaxNo",
                    },
                    domProps: { value: _vm.agencyInfo.cstPicFaxNo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.agencyInfo,
                          "cstPicFaxNo",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.025"))),
                ]),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "input_calendar" },
                    [
                      _c("e-date-picker", {
                        attrs: {
                          value: _vm.agencyInfo.strDt,
                          disabled: "disabled",
                        },
                        on: { input: _vm.changeStrDt },
                      }),
                      _c("input", { attrs: { type: "hidden", id: "strDt" } }),
                    ],
                    1
                  ),
                ]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.026"))),
                ]),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "input_calendar" },
                    [
                      _c("e-date-picker", {
                        attrs: { value: _vm.agencyInfo.endDt },
                        on: { input: _vm.changeEndDt },
                      }),
                      _c("input", { attrs: { type: "hidden", id: "endDt" } }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.028"))),
                ]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ImpBusAgePdfPop", null, null)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.029")))]
                  ),
                  _c(
                    "a",
                    {
                      class:
                        _vm.uploadFileInfo.length > 0 && _vm.isAbleInput()
                          ? "button sm blue ml-1"
                          : "button sm gray file ml-1",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.openFileUploader()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.030")))]
                  ),
                  _c("input", { attrs: { type: "hidden", id: "uploadFile" } }),
                  _c("p", { staticClass: "txt_desc" }, [
                    _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.031"))),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex_between mt10",
          staticStyle: { "padding-bottom": "15px" },
        },
        [
          _c("div"),
          _c("div", [
            _vm.isAbleInput()
              ? _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.saveAlert("req")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.032")))]
                )
              : _vm._e(),
            _vm.isAbleInput()
              ? _c(
                  "a",
                  {
                    staticClass: "button gray lg ml5",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.saveAlert("save")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.033")))]
                )
              : _vm._e(),
            _vm.isAbleInput()
              ? _c(
                  "a",
                  {
                    staticClass: "button gray lg ml5",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.reset()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.034")))]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "div",
        { attrs: { id: "business-pop" } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "business-info": _vm.businessInfo,
                      "parent-info": _vm.parentInfo,
                    },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowDext5Pop,
              expression: "isShowDext5Pop",
            },
          ],
          attrs: { id: "dext5_pop" },
        },
        [
          _c("div", { ref: "popup", staticClass: "popup_dim" }, [
            _c(
              "div",
              {
                staticClass: "popup_wrap",
                staticStyle: { width: "800px", height: "520px" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "layer_close",
                    on: {
                      click: function ($event) {
                        return _vm.closeFileUploadr()
                      },
                    },
                  },
                  [_vm._v("close")]
                ),
                _c("div", { staticClass: "popup_cont" }, [
                  _c("h1", { staticClass: "page_title" }, [
                    _vm._v(_vm._s(_vm.$t("msg.MYIN040G010.030"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "content_box" },
                    [
                      _vm.isShowDext5
                        ? _c("DEXT5Upload", {
                            ref: "dextupload-proxy",
                            attrs: {
                              id: "dext5-uploader-" + _vm.dext5Idx,
                              single: true,
                              category: false,
                              width: "100%",
                              height: "200px",
                              "file-info": _vm.uploadFileInfo,
                              "category-info": [],
                            },
                            on: { completed: _vm.uploadComplete },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "mt10 text_center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button blue lg",
                      on: {
                        click: function ($event) {
                          return _vm.closeFileUploadr()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.045")))]
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "18%" } }),
      _c("col", { staticStyle: { width: "32%" } }),
      _c("col", { staticStyle: { width: "18%" } }),
      _c("col", { staticStyle: { width: "32%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }