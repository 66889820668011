<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('msg.CSCT060G030.001') }}</h1><!-- MRN/MSN/Call Sign -->
    <div class="content_box mt10">
      <!-- search_box -->
      <table class="tbl_search">
        <colgroup>
          <col width="100px">
          <col width="150px">
          <col>
          <col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.CSCT060G030.002') }}</th><!-- 선명/항차 -->
            <td class="pr5">
              <input type="text" v-model="param.vslCd" :readonly="isReadOnly" @click.prevent="searchSchedule()">
            </td>
            <td class="pl0">
              <input type="text" class="wid150" v-model="param.voyNo" :readonly="isReadOnly" @click.prevent="searchSchedule()">
            </td>
            <td class="text_right">
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.CSCT060G030.003') }}</th><!-- 번호/입력 -->
            <td class="pr5">
              <select v-model="cond" @change="changeSelect($event)">
                <option value="BL">B/L No.</option>
                <option value="BK">Booking No.</option>
              </select>
            </td>
            <td class="pl0">
              <div class="tbl_form">
                <span class="dv wid70" v-if="cond !== 'BK'">
                  <input type="text" value="KMTC" readonly>
                </span>
                <span class="dv wid150">
                  <input type="text" v-model="param.blNo" @input="param.blNo=param.blNo.toUpperCase()">
                </span>
                <span class="dv pl20">
                  <input type="radio" v-model="param.eiCatCd" id="ex" name="exim" value="O">
                  <label for="ex"><span></span>{{ $t('msg.CSCT060G030.004') }}</label><!-- 수출 -->
                  <input type="radio" v-model="param.eiCatCd" id="im" name="exim" value="I">
                  <label for="im"><span></span>{{ $t('msg.CSCT060G030.005') }}</label><!-- 수입 -->
                </span>
              </div>
            </td>
            <td class="text_right">
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.CSCT060G030.006') }}</th><!-- 입항일/포트 -->
            <td class="pr5">
              <select v-model="param.portcond" @change="changeSelect($event)">
                <option value="PUS">{{ $t('msg.CSCT060G030.007') }}</option><!-- BUSAN -->
                <option value="PNC">{{ $t('msg.CSCT060G030.008') }}</option><!-- BUSAN NEW PORT -->
                <option value="DSN">{{ $t('msg.CSCT060G030.009') }}</option><!-- DAESAN, KOREA -->
                <option value="INC">{{ $t('msg.CSCT060G030.010') }}</option><!-- INCHEON -->
                <option value="KAN">{{ $t('msg.CSCT060G030.011') }}</option><!-- KWANGYANG -->
                <option value="KPO">{{ $t('msg.CSCT060G030.012') }}</option><!-- POHANG.KOREA -->
                <option value="PTK">{{ $t('msg.CSCT060G030.013') }}</option><!-- PYEONGTAEK, KOREA -->
                <option value="USN">{{ $t('msg.CSCT060G030.014') }}</option><!-- ULSAN -->
              </select>
            </td>
            <td class="pl0">
              <e-date-range-picker
                id="rvsdDt"
                class="ul_ml0"
                @change="changeDateRange"
                :sdate="param.startDt"
                :edate="param.endDt"
                :is-debug="true"
              />
            </td>
            <td class="text_right">
              <a class="button blue sh" @click.prevent="getMrnMsnCallInfo()">{{ $t('msg.CSCT060G030.015') }}</a><!-- 검색 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- search_box // -->

    <div class="content_box mt10">
      <!-- content_box -->
      <div class="flex_box">
        <h2 class="content_title">&nbsp;</h2>
        <span class="ml_auto cal_btn">
          <a class="button sm" @click.prevent="moveTo('ulogis')">{{ $t('msg.CSCT060G030.016') }}</a><!-- U LOGIS HUB -->
          <a class="button sm ml2" @click.prevent="moveTo('customs')">{{ $t('msg.CSCT060G030.017') }}</a><!-- 세관통관진행 -->
          <a class="button sm ml2" @click.prevent="moveTo('unipass')">{{ 'UNI-PASS' }}</a><!-- 세관통관진행 -->
        </span>
      </div>
      <table v-if="param.dtKnd !== 'DT'" class="tbl_col">
        <colgroup>
          <col width="26%">
          <col width="16%">
          <col width="14%">
          <col width="16%">
          <col width="16%">
          <col width="12%">
        </colgroup>
        <thead v-if="initFlag === 'N' && mrnMsnDetail.length > 0">
          <tr>
            <th>{{ $t('msg.CSCT060G030.002') }}</th><!-- 선명/항차 -->
            <th>{{ $t('msg.CSCT060G030.018') }}</th><!-- RN No. -->
            <th>Call Sign</th><!-- Call Sign -->
            <th>{{ mrnMsnDetail[0].polPortNm }} {{ $t('msg.CSCT060G030.020') }}</th><!-- 출항일 -->
            <th>{{ mrnMsnDetail[0].podPortNm }} {{ $t('msg.CSCT060G030.021') }}</th><!-- 입항일 -->
            <th>{{ $t('msg.CSCT060G030.035') }}</th><!-- 입항횟수 -->
          </tr>
        </thead>
        <thead v-else>
          <tr>
          </tr>
        </thead>
        <tbody v-if="initFlag === 'N' && mrnMsnDetail.length > 0">
          <tr v-for="mrnMsnDetail in mrnMsnDetail" :key="mrnMsnDetail.mrnNo">
            <td>{{ mrnMsnDetail.vslNm }} / {{ mrnMsnDetail.voyNo }}</td>
            <td>{{ mrnMsnDetail.mrnNo }}</td>
            <td>{{ mrnMsnDetail.clsgNo }}</td>
            <td>{{ mrnMsnDetail.etd }}</td>
            <td>{{ mrnMsnDetail.eta }}</td>
            <td>{{ mrnMsnDetail.apoTcnt }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">{{ $t('msg.CSCT060G030.022') }}</td><!-- No Data. -->
          </tr>
        </tbody>
      </table>
      <!-- List -->
      <table v-if="param.dtKnd !== 'DT'" class="tbl_col mt10">
        <colgroup>
          <col width="16%">
          <col width="16%">
          <col width="16%">
          <col width="14%">
          <col width="14%">
          <col width="12%">
          <col width="12%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.CSCT060G030.023') }}</th><!-- B/L No. -->
            <th>{{ $t('msg.CSCT060G030.024') }}</th><!-- Booking No. -->
            <th>{{ $t('msg.CSCT060G030.025') }}</th><!-- Container No. -->
            <th>{{ $t('msg.CSCT060G030.026') }}</th><!-- MSN No. -->
            <th>{{ $t('msg.CSCT060G030.027') }}</th><!-- On Board Date -->
            <th>{{ $t('msg.CSCT060G030.028') }}</th><!-- P’Kgs -->
            <th>{{ $t('msg.CSCT060G030.029') }}</th><!-- G Weight -->
          </tr>
        </thead>
        <tbody v-if="initFlag === 'N' && mrnMsnList.length > 0">
          <tr v-for="mrnMsnList in mrnMsnList" :key="mrnMsnList.blNo">
            <td>{{ mrnMsnList.blNo }}</td>
            <td>{{ mrnMsnList.bkgNo }}</td>
            <td>{{ mrnMsnList.cntrNo }}</td>
            <td>{{ mrnMsnList.msnNo }}</td>
            <td>{{ mrnMsnList.obrdDt }}</td>
            <td>{{ mrnMsnList.pkgQty }}</td>
            <td>{{ mrnMsnList.grsWt }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">{{ $t('msg.CSCT060G030.022') }}</td><!-- No Data. -->
          </tr>
        </tbody>
      </table>

      <!-- flag == DT -->
      <table v-if="param.dtKnd === 'DT'" class="tbl_col">
        <colgroup>
          <col style="width:18%">
          <col style="width:20%">
          <col style="width:18%">
          <col style="width:12%">
          <col style="width:20%">
          <col style="width:12%">
        </colgroup>
        <thead v-if="initFlag === 'N' && mrnMsnDetail.length > 0">
          <tr>
            <th>{{ $t('msg.CSCT060G030.002') }}</th><!-- 선명/항차 -->
            <th>{{ columnDtNm }}</th>
            <th>{{ $t('msg.CSCT060G030.018') }}</th><!-- MRN No. -->
            <th>{{ $t('msg.CSCT060G030.019') }}</th><!-- Call Sign -->
            <th>{{ $t('msg.CSCT060G030.036') }}</th><!-- 접안부두 -->
            <th>{{ $t('msg.CSCT060G030.035') }}</th><!-- 입항횟수 -->
          </tr>
        </thead>
        <thead v-else>
          <tr>
          </tr>
        </thead>
        <tbody v-if="initFlag === 'N' && mrnMsnDetail.length > 0">
          <tr v-for="mrnMsnDetail in mrnMsnDetail" :key="mrnMsnDetail.mrnNo">
            <td>{{ mrnMsnDetail.vslNm }} / {{ mrnMsnDetail.voyNo }}</td>
            <td>{{ mrnMsnDetail.eta }}</td>
            <td>{{ mrnMsnDetail.mrnNo }}</td>
            <td>{{ mrnMsnDetail.clsgNo }}</td>
            <td>{{ mrnMsnDetail.cyEnm }}</td>
            <td>{{ mrnMsnDetail.apoTcnt }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">{{ $t('msg.CSCT060G030.022') }}</td><!-- No Data. -->
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
    <win-layer-pop class="schedule_leg_pop">
      <component
        :is="customComponent"
        v-if="customComponent"
        @close="closePoup"
        :parent-info="parentInfo"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import VoyVslSearchPop from '@/pages/schedule/popup/VoyVslSearchPop'/* 선명/항차 조회 팝업 */
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import mrnmsn from '@/api/rest/trans/mrnmsn'

export default {
  name: 'MrnMsn',
  components: {
    EBreadcrumbs,
    'e-date-range-picker': () => import('@/components/common/EDateRangePicker'),
    VoyVslSearchPop,
    WinLayerPop
  },
  //as-is 소스 반영 - 수출/수입에서 접근할시 eiCatCd 및 dtKnd 적용
  props: {
    eiCatCd: {
      type: String,
      default: 'O'
    }
  },
  data: function () {
    return {
      parentInfo: {},
      customComponent: null,
      param: {
        //BK, BL 번호
        blNo: '',
        //ownYn(N)
        ownYn: 'N',
        //입항일 Port 관련 select
        kind: '',
        //DT, BK, VL 여부
        dtKnd: '',
        //시작일
        startDt: '',
        //종료일
        endDt: '',
        portcond: 'PUS',
        //출항포트 코드(dtKnd=VL)
        polPortCd: '',
        //수출수입구분(O/I)
        eiCatCd: this.eiCatCd,
        //항차(dtKnd=VL)
        voyNo: '',
        //선명(dtKnd=VL)
        vslCd: '',
        //선명
        vslNm: '',
        //입항포트 코드(dtKnd=VL)
        podPortCd: ''
      },
      vslInfo: {
        vslCd: '',
        vslNm: '',
        voyNo: ''
      },
      //번호, 입항일 flag처리
      cond: 'BL',
      //선박, 항차 팝업 정보
      selectFunc: null,
      //BL정보
      mrnMsnList: [],
      //선박정보
      mrnMsnDetail: [],
      //초기화면 여부
      initFlag: 'Y',
      //readyOnly 여부
      isReadOnly: false,
      usrCtrCd: 'KR',

      columnDtNm: 'ETA DT'
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.memberDetail !== undefined) {
      this.usrCtrCd = this.memberDetail.userCtrCd
    }

    // 컬럼 DT 이름 세팅
    this.setDtNm()
  },
  methods: {
    setDtNm () {
      if (this.param.eiCatCd === 'O') {
        this.columnDtNm = 'ETA DT'
      } else {
        this.columnDtNm = 'ETD DT'
      }
    },
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.schedule_leg_pop')
    },
    closePoup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.schedule_leg_pop')
    },
    moveTo (flag) {
      const newWindow = '_blank'
      let url = ''

      if (flag === 'customs') {
        url = 'https://www.plism.com/websquare/websquare.html?w2xPath=/sq5/com/uat/Index.xml'
      } else if (flag === 'unipass') {
        url = 'https://unipass.customs.go.kr/csp/index.do'
      } else {
        url = 'https://www.ulogishub.com'
      }

      window.open(url, newWindow)
    },
    searchSchedule () {
      this.selectFunc = this.callbackSearchSchedule

      this.popParams = {
        bound: this.param.eiCatCd
      }

      this.parentInfo = this.popParams

      this.openPopup('VoyVslSearchPop')
    },
    callbackSearchSchedule (obj) {
      console.log('@@@@@ callbackSearchVslVoy @@@@@')

      if (obj !== undefined) {
        //해당 선명, 항차 정보를 넣고 flag를 VL로 바꾼다.
        this.vslInfo.vslNm = obj.vslNm
        this.param.vslCd = obj.vslCd
        this.param.voyNo = obj.voyNo
        this.param.podPortCd = obj.pod
        this.param.polPortCd = obj.pol

        //선명/항차에 자동으로 들어간 값을 param값에 넣는다.
        //this.param.vslNo = this.vslInfo.vslNo
        //this.param.vslCd = this.vslInfo.vslCd
        //this.param.voyNo = this.vslInfo.voyNo
        //this.cond = 'VL'

        //VL로 검색하니 blNo 지워주기 처리
        this.param.blNo = ''

        this.getMrnMsnCallInfo()
      }
    },
    changeDateRange (s, d) {
      this.param.startDt = s
      this.param.endDt = d

      // this.cond = 'DT'
    },
    changeSelect (e) {
      if (e.target.value !== 'BK' && e.target.value !== 'BL') {
        // this.cond = 'DT'

        this.param.vslCd = ''
        this.param.voyNo = ''
      }
    },
    checkValidationAll () {
      let isAllOk = true

      // // '번호/입력' 체크
      // if ((this.param.blNo === '') && (this.param.dtKnd === 'BK' || this.param.dtKnd === 'BL')) {
      //   this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G030.031'))// B/L,Booking 번호를 확인하세요.
      //   isAllOk = false
      // }
      // // '입항일/포트' 체크
      // if ((this.param.startDt === '' || this.param.endDt === '') && this.param.dtKnd === 'DT') {
      //   this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G030.032'))// 조회 기간을 선택하세요.
      //   isAllOk = false
      // } else if (this.param.portcond === '' && this.param.dtKnd === 'DT') {
      //   this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G030.033'))// 항구 포트를 선택하세요.
      //   isAllOk = false
      // } else if ((this.param.startDt - this.param.endDt > 0) && this.param.dtKnd === 'DT') {
      //   this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G030.034'))// 조회 기간을 확인하세요.
      //   isAllOk = false
      // }

      if (['BK', 'BL'].indexOf(this.param.dtKnd) >= 0 && this.$ekmtcCommon.isEmpty(this.param.blNo)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G030.031'))// B/L,Booking 번호를 확인하세요.
        isAllOk = false
      } else if (this.param.dtKnd === 'DT') {
        if (this.$ekmtcCommon.isEmpty(this.param.portcond)) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G030.033'))// 항구 포트를 선택하세요.
          isAllOk = false
        } else if (this.$ekmtcCommon.isEmpty(this.param.startDt) || this.$ekmtcCommon.isEmpty(this.param.endDt)) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G030.032'))// 조회 기간을 선택하세요.
          isAllOk = false
        } else if (this.param.startDt - this.param.endDt > 0) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G030.032'))// 조회 기간을 선택하세요.
          isAllOk = false
        }
      }

      return isAllOk
    },
    getMrnMsnCallInfo () {
      console.log('####searchMrnMsn######')

      // 컬럼 DT 이름 세팅
      this.setDtNm()

      // //flag에 따라 ''처리
      // if ((this.cond === 'BK' || this.cond === 'BL') && (this.param.blNo !== undefined || this.param.blNo === '')) {
      //   //BL/BKGNO 검색
      //   this.param.dtKnd = this.cond
      //   this.param.vslCd = ''
      //   this.param.voyNo = ''
      //   //this.param.startDt = ''
      //   //this.param.endDt = ''
      //   this.param.portcond = ''
      // } else if ((this.param.vslCd !== '' || this.param.voyNo !== '') && (this.param.blNo === undefined || this.param.blNo === '')) {
      //   //선명/항차 검색
      //   this.param.dtKnd = 'VL'
      //   //this.param.startDt = ''
      //   //this.param.endDt = ''
      //   this.param.portcond = ''
      // } else {
      //   //Date 검색
      //   this.param.dtKnd = 'DT'
      //   this.param.blNo = ''
      // }

      // 검색 값에 따라 검색 유형을 설정
      if (this.$ekmtcCommon.isNotEmpty(this.param.blNo)) {
        this.param.dtKnd = this.cond
      } else if (this.$ekmtcCommon.isNotEmpty(this.param.vslCd) || this.$ekmtcCommon.isNotEmpty(this.param.voyNo)) {
        this.param.dtKnd = 'VL'
      } else if (this.$ekmtcCommon.isNotEmpty(this.param.startDt) || this.$ekmtcCommon.isNotEmpty(this.param.endDt)) {
        this.param.dtKnd = 'DT'
      } else { // 모두 없는 경우 BL,BK를 default로 설정
        this.param.dtKnd = this.cond
      }

      if (this.checkValidationAll()) {
        //flag에 따라 ''처리
        if (this.param.dtKnd === 'VL') {
          this.param.blNo = ''
          this.param.startDt = ''
          this.param.endDt = ''
        } else if (this.param.dtKnd === 'DT') {
          this.param.vslCd = ''
          this.param.voyNo = ''
          this.param.blNo = ''
        } else { // BL, BK
          this.param.vslCd = ''
          this.param.voyNo = ''
          this.param.startDt = ''
          this.param.endDt = ''
        }

        mrnmsn.getMrnMsnCallInfo(this.param).then(res => {
          if (res.headers.respcode === 'C0000') {
            const resp = res.data
            const list = resp.mrnMsnList
            const detail = resp.mrnMsnDetail

            this.mrnMsnDetail = []
            this.mrnMsnList = []

            list.forEach((item) => {
              item.obrdDt = this.$ekmtcCommon.changeDatePattern(item.obrdDt, '.')

              this.mrnMsnList.push(item)
            })

            detail.forEach((item) => {
              item.eta = this.formatEtdEta(item.eta)
              item.etd = this.formatEtdEta(item.etd)

              this.mrnMsnDetail.push(item)
            })

            if (this.param.dtKnd === 'BL' || this.param.dtKnd === 'BK') {
              this.vslInfo.vslCd = ''
              this.vslInfo.voyNo = ''
            } else if (this.param.dtKnd === 'VL') {
              this.param.blNo = ''
            } else {
              this.vslInfo.vslCd = ''
              this.vslInfo.voyNo = ''
              this.param.blNo = ''
            }

            this.initFlag = 'N'
          }
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => {
          console.log(this.mrnMsnList)
          console.log(this.mrnMsnDetail)
        })
      }
    },
    formatEtdEta (val) {
      let result = ''

      if (val !== undefined) {
        result = val.substring(0, 4) + '.' + val.substring(4, 6) + '.' + val.substring(6, 8) + '/' + val.substring(8, 10) + ':' + val.substring(10, 12)
      }

      return result
    }
  }
}
</script>

<style scoped>

</style>
