<template><!-- 업무 대행정보 >> 업무 대행정보 요청 -->
  <div class="content widfix">
    <div id="imp-detail-pop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          @child="getChildData"
          :agency-info="agencyInfo"
          :status-cd="statusCd"
          :eicat="eicat"
        />
      </win-layer-pop>
    </div>
    <div class="content_box beforetab">
      <!-- content_box -->
      <table class="tbl_search">
        <colgroup>
          <col width="80px"><col><col width="80px"><col><col width="120px">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.MYIN040G010.001') }}</th>
            <td>
              <select v-model="params.autCatCd">
                <option value="00">{{ $t('msg.MYIN040G010.006') }}</option>
                <option value="02">{{ $t('msg.MYIN040G010.046') }}</option>
                <option value="01">{{ $t('msg.MYIN040G010.047') }}</option>
              </select>
            </td>
            <th>{{ $t('msg.MYIN040G010.004') }}</th>
            <td>
              <input type="text" v-model="params.bsnNm">
            </td>
            <td></td>
          </tr>
          <tr>
            <th>{{ $t('msg.MYIN040G010.005') }}</th>
            <td>
              <select v-model="params.apvYn">
                <option value="00">{{ $t('msg.MYIN040G010.006') }}</option>
                <option value="Y">{{ $t('msg.MYIN040G010.007') }}</option>
                <option value="N">{{ $t('msg.MYIN040G010.008') }}</option>
              </select>
            </td>
            <td colspan="2">
            </td>
            <td class="text_right"><a href="javascript:void(0)" class="button blue sh" @click="getAgencyInfoList()">{{ $t('msg.MYIN040G010.009') }}</a></td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
    <div class="content_box mt10">
      <div class="flex_box">
        <span class="tot_num">Total : <span class="num">{{ agencyInfoList.length }}</span></span>
        <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span>  <!-- 엑셀 다운로드 -->
      </div>
      <div :style="headerFooterCss">
        <table class="tbl_col">
          <colgroup>
            <!-- <col style="width:12%"> -->
            <col style="width:12%">
            <col style="width:22%">
            <col style="width:22%">
            <col style="width:12%">
            <col style="width:11%">
            <col style="width:11%">
            <col style="width:10%">
          </colgroup>
          <thead>
            <tr>
              <!-- <th scope="col">{{ $t('msg.MYIN040G010.010') }}<br />{{ $t('msg.MYIN040G010.011') }}</th> -->
              <th scope="col">{{ $t('msg.MYIN040G010.012') }}</th>
              <th scope="col">{{ $t('msg.MYIN040G010.013') }}</th>
              <th scope="col">{{ $t('msg.MYIN040G010.056') }}</th>
              <th scope="col">{{ $t('msg.MYIN040G010.015') }}<br />{{ $t('msg.MYIN040G010.016') }}</th>
              <th scope="col">{{ $t('msg.MYIN040G010.017') }}</th>
              <th scope="col">{{ $t('msg.MYIN040G010.018') }}</th>
              <th scope="col">{{ $t('msg.MYIN040G010.019') }}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="imp-body-table-div">
        <table class="tbl_col" id="imp-body-table">
          <colgroup>
            <!-- <col style="width:12%"> -->
            <col style="width:12%">
            <col style="width:22%">
            <col style="width:22%">
            <col style="width:12%">
            <col style="width:11%">
            <col style="width:11%">
            <col style="width:10%">
          </colgroup>
          <tbody>
            <tr :class="row.cls" v-for="(row, i) in agencyInfoList" :key="'agency-' + i">
              <!-- <td>{{ row.bizVeReqNo }}</td> -->
              <td @click="openPopup('ImpBusDetailPop', row.bizVeReqNo, i)">
                <template v-if="row.autCatCd == '01'">{{ $t('msg.MYIN040G010.047') }}</template>
                <template v-else>{{ $t('msg.MYIN040G010.046') }}</template>
              </td>
              <td class="txt_line" @click="openPopup('ImpBusDetailPop', row.bizVeReqNo, i)">
                <template v-if="row.grpCatCdNmList.length > 0">
                  {{ row.grpCatCdNmList[0] }}
                  <template v-if="row.grpCatCdNmList.length > 1">
                    {{ $t('msg.MYIN040G010.048') }} {{ row.grpCatCdNmList.length - 1 }} {{ $t('msg.MYIN040G010.049') }}
                  </template>
                </template>
              </td>
              <td class="txt_line" @click="openPopup('ImpBusDetailPop', row.bizVeReqNo, i)">
                {{ row.bsnNm }}
              </td>
              <td @click="openPopup('ImpBusDetailPop', row.bizVeReqNo, i)">{{ row.bzrgNo }}</td>
              <td @click="openPopup('ImpBusDetailPop', row.bizVeReqNo, i)">{{ row.strDt }}</td>
              <td @click="openPopup('ImpBusDetailPop', row.bizVeReqNo, i)">{{ row.endDt }}</td>
              <td class="txt_line" :class="{underline : row.apvCont}" @click="openRemark(row.apvCont)">
                <template v-if="isEmpty(row.stsCdNm)">{{ $t('msg.MYIN040G010.062') }}</template>
                <template v-else> {{ row.stsCdNm }}</template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex_between">
      <div />
      <div>
        <a class="button blue lg mr5" href="javascript:void(0)" @click="newAgencyInfo()">{{ $t('msg.CMBA100.00177') }}</a> <!-- 신규등록 -->
      </div>
    </div>
  </div>
</template>

<script>
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ImpBusDetailPop from '@/pages/user/popup/ImpBusDetailPop'
import ImptBsnesAgencInfo from '@/api/rest/user/imptBsnesAgencInfo'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'
import XLSX from 'xlsx'

export default {
  name: 'ImptBsnesAgencInfo',
  components: {
    ImpBusDetailPop,
    WinLayerPop,
    EBreadcrumbs
  },
  data: function () {
    return {
      customComponent: null,
      agencyInfoList: [],
      params: {
        autCatCd: '00',
        apvYn: '00',
        bsnNm: ''
      },
      agencyInfo: {
        bizVeReqNo: null,
        autCatCd: '02',
        autCstNo: null,
        cstGrpCatCd: null,
        bsnNm: null,
        bzrgNo: null,
        fstEntUno: null,
        cstPicFaxNo: null,
        cstPicTelNo: null,
        strDt: '',
        endDt: '',
        apvYn: '',
        file: null,
        ioType: 'O',
        cstGrpCatCdArr: []
      },
      headerFooterCss: {},
      eicat: {
        iList: [],
        eList: []
      },
      statusCd: '00',
      apvCont: false,
      relYn: true
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
  },
  created () {
    this.getEiCatList()
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted: function () {
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  methods: {
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.getAgencyInfoList()
        }
      }
    },
    newAgencyInfo () {
      let now = moment()
      let today = now.format('YYYYMMDD')

      this.agencyInfo = {
        bizVeReqNo: null,
        autCatCd: '02',
        autCstNo: null,
        cstGrpCatCd: null,
        bsnNm: null,
        bzrgNo: null,
        fstEntUno: null,
        cstPicFaxNo: null,
        cstPicTelNo: null,
        strDt: today,
        endDt: '',
        apvYn: '',
        file: null,
        ioType: (this.memberDetail.userCtrCd === 'KR' ? 'I' : 'O'), // 220603. 국내는 수입을 default로 선택(국내 팝업에서 수출항목 제거 요건)
        cstGrpCatCdArr: [],
        mode: 'New' // 신규 Flag
      }

      this.statusCd = '00'
      this.customComponent = 'ImpBusDetailPop'
      this.$ekmtcCommon.layerOpen('#imp-detail-pop > .popup_dim')
    },
    getAgencyInfo (bizVeReqNo, idx) {
      ImptBsnesAgencInfo.getAgencyInfo({ bizNo: bizVeReqNo }).then(response => {
        this.agencyInfo = this.parseAgencyInfo(response.data)
        this.statusCd = this.agencyInfoList[idx].stsCd
        for (let i = 0; i < this.agencyInfoList.length; i++) {
          if (idx === i) {
            this.agencyInfoList[i].cls = 'selected'
          } else {
            this.agencyInfoList[i].cls = ''
          }
        }
        this.customComponent = 'ImpBusDetailPop'
        this.$ekmtcCommon.layerOpen('#imp-detail-pop > .popup_dim')
      }).catch(err => {
        console.log(err)
      })
    },
    parseAgencyInfo (data) {
      data.cstGrpCatCdArr = data.grpCatCdList
      let flag = true
      for (let grpCatcd of data.cstGrpCatCdArr) {
        if (flag) {
          for (let e of this.eicat.eList) {
            if (grpCatcd === e.cd) {
              data.ioType = 'O'
              flag = false
              break
            }
          }
          for (let i of this.eicat.iList) {
            if (grpCatcd === i.cd) {
              data.ioType = 'I'
              flag = false
              break
            }
          }
        }
      }

      if (!this.isEmpty(data.proxyFile)) {
        this.uploadFileInfo = [{
            guid: '1',
            originalName: data.proxyFile.fileNm,
            size: data.proxyFile.fileSz,
            // fileId: 'http://user.ekmtc:9000/user/file-download?bizNo=' + data.proxyFile.bizVeReqNo + '&type=PROXY_FILE',
            fileId: data.proxyFile.fileId,
            bizVeReqNo: data.proxyFile.bizVeReqNo,
            status: 'complete',
            contentType: 'image/png',
            category: '',
            isNew: false
          }]

        data.uploadFileInfo = this.uploadFileInfo
      } else {
        this.uploadFileInfo = []
      }

      return data
    },
    getEiCatList () {
      ImptBsnesAgencInfo.eicatList().then(response => {
        this.eicat.iList = response.data.ilist
        this.eicat.eList = response.data.elist
        this.eicat.iList.forEach(element => {
          element.cdNm = this.$t(`msg.MY_INFO.${element.cdId}.${element.cd}`)
        })
        this.eicat.eList.forEach(element => {
          element.cdNm = this.$t(`msg.MY_INFO.${element.cdId}.${element.cd}`)
        })
        this.getAgencyInfoList()
      }).catch(err => {
        console.log(err)
      })
    },
    getAgencyInfoList () {
      ImptBsnesAgencInfo.agencyInfoList(this.params).then(response => {
        this.agencyInfoList = this.parseAgencyInfoList(response.data)
        if (this.agencyInfoList.length > 10) {
          this.headerFooterCss = {
              paddingRight: '3px'
          }
        } else {
          this.headerFooterCss = {
              paddingRight: '0px'
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    parseAgencyInfoList (data) {
      for (let i = 0; i < data.length; i++) {
        data[i].cls = ''
        data[i].strDt = this.parseDateFormat(data[i].strDt, '.')
        data[i].endDt = this.parseDateFormat(data[i].endDt, '.')
        data[i].grpCatCdNmList = []
        data[i].grpCatCdList = this.ascendingGrpCatCdList(data[i].grpCatCdList)
        data[i].stsCdNm = this.getStatus(data[i].stsCd)
        data[i].apvCont = data[i].apvCont !== undefined ? data[i].apvCont : ''

        for (let grpCatCd of data[i].grpCatCdList) {
          data[i].grpCatCdNmList.push(this.getGrpCatCdNm(grpCatCd))
        }
      }
      return data
    },
    ascendingGrpCatCdList (arr) {
      let temp = {}
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (parseInt(arr[i]) > parseInt(arr[j])) {
            temp = arr[i]
            arr[i] = arr[j]
            arr[j] = temp
          }
        }
      }
      return arr
    },
    getGrpCatCdNm (val) {
      for (let e of this.eicat.eList) {
        if (val === e.cd) {
          return e.cdNm
        }
      }
      for (let i of this.eicat.iList) {
        if (val === i.cd) {
          return i.cdNm
        }
      }
      return ''
    },
    parseDateFormat (date, separator) {
      return date.substring(0, 4) + separator + date.substring(4, 6) + separator + date.substring(6, 8)
    },
    openPopup (compNm, bizVeReqNo, idx) {
      let isOpen = true
      switch (compNm) {
        case 'ImpBusDetailPop':
          this.getAgencyInfo(bizVeReqNo, idx)
          isOpen = false
          break
      }
      if (isOpen) {
        this.customComponent = compNm
        this.$ekmtcCommon.layerOpen('#imp-detail-pop > .popup_dim')
      }
    },
    getChildData (data) {
       switch (data.type) {
        case 'saveAgencyInfo':
          this.closePopup()
          this.getAgencyInfoList()
          break
        case 'reset':
          this.reset()
          break
       }
    },
    reset () {
       this.agencyInfo = {
        bizVeReqNo: null,
        autCatCd: '01',
        cstGrpCatCd: null,
        bsnNm: null,
        bzrgNo: null,
        fstEntUno: null,
        cstPicFaxNo: null,
        cstPicTelNo: null,
        strDt: '',
        endDt: '',
        apvYn: '',
        file: null,
        ioType: 'O',
        cstGrpCatCdArr: []
      }
      this.uploadFileInfo = []
      this.statusCd = '00'

      for (let i = 0; i < this.agencyInfoList.length; i++) {
        this.agencyInfoList[i].cls = ''
      }
    },
    closePopup (obj) {
      if (obj !== undefined) {
        this.agencyInfo.bsnNm = obj.bsnNm
        this.agencyInfo.bzrgNo = obj.bzrgNo
        this.agencyInfo.fstEntUno = obj.cstNo
        this.agencyInfo.cstPicFaxNo = obj.faxNo
        this.agencyInfo.cstPicTelNo = obj.telNo
      }
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#imp-detail-pop > .popup_dim')
    },

    getStatus (cd) {
      let res = ''
      switch (cd) {
        case '01':
          res = 'Requested'
          break
        case '02':
          res = 'Accepted'
          break
        case '03':
          res = 'Rejected'
          break
        case '04':
          res = 'Check by Kmtc'
          break
        case '05':
          res = 'Check by shpr'
          break
        case '06':
          res = 'Confirmed by Shpr'
          break
        case '07':
          res = 'Canceled'
          break
      }
      return res
    },

    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    openRemark (data) {
      if (!this.$ekmtcCommon.isEmpty(data)) {
        this.apvCont = true
        this.msgAlert(data)
      }
    },
    msgAlert (msg) {
       let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: '닫기',
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    exlDownload () {
      let excelData = []
      let tempautCatCd
      let tempcstGrpCatCd

      for (const items of this.agencyInfoList) {
        if (items.autCatCd === '01') {
          tempautCatCd = '수임업체'
        } else {
          tempautCatCd = '위임업체'
        }

        const excelLogs = {
          위수임구분: tempautCatCd,
          업무구분: items.grpCatCdNmList[0],
          업체명: items.bsnNm,
          사업자등록번호: items.bzrgNo,
          시작일: items.strDt,
          종료일: items.endDt,
          승인여부: items.stsCdNm === '' ? '임시저장' : items.stsCdNm
        }
        excelData.push(excelLogs)
      }

      const workSheet = XLSX.utils.json_to_sheet(excelData)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, '업무_대행정보_요청')
      XLSX.writeFile(workBook, '업무_대행정보_요청.xlsx')
    }
  }
}
</script>

 <style scoped>
.left-column {
  text-align: left;
}

.imp-body-table-div {
  overflow: auto;
  max-height: 340px;
}

#imp-body-table {
  table-layout: fixed;
}

#imp-body-table > tbody > tr {
  cursor: pointer;
}

#imp-body-table > tbody > tr:hover td {
  background: #ebf1ff ;
}

#imp-body-table > tbody > .selected td {
  background: #ebf1ff ;
}

.imp-body-table-div::-webkit-scrollbar {
  width: 3px;
  background: #fff;
}
.imp-body-table-div::-webkit-scrollbar-thumb {
  background: #999;
}

.txt_line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px 10px;
}

.underline {
    color: #075bb9;
    text-decoration: underline !important;
  }
 </style>
