var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "550px", height: "650px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(
            "[" +
              _vm._s(_vm.$t("msg.ONIM070P020.002")) +
              "] " +
              _vm._s(_vm.$t("msg.MYIN010G010.020"))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("h2", { staticClass: "content_title" }),
          _c("p", { staticClass: "tit" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.013")) + " "),
          ]),
          _c("ul", [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.014")) + " ")]),
          ]),
          _c("p", { staticClass: "tit mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.015")) + " "),
          ]),
          _c("ul", [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.016")) + " ")]),
          ]),
          _c("p", { staticClass: "tit mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.017")) + " "),
          ]),
          _c("ul", [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.018")) + " ")]),
          ]),
          _c("p", { staticClass: "tit mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.019")) + " "),
          ]),
          _c("ul", [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.020")) + " ")]),
          ]),
          _c("p", { staticClass: "mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.029")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.FILE_UPLOAD.011")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }