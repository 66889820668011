<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('msg.MAIN100.209') }}</h1><!-- 문의하기 -->

    <form id="frm">
      <div class="content_box mt10">
        <!-- content_box -->
        <table class="tbl_row">
          <colgroup>
            <col width="130"><col>
            <col width="130"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.MAIN100.173') }}</th>
              <td>
                <input type="text" v-model="params.reqUno" class="" id="inputUno" name="inputUno" value="" @keyup="checkValiUser()">
              </td>
              <th>{{ $t('msg.MAIN100.174') }}</th>
              <td>
                <select v-model="params.usrCtrCd" name="selectCtrCd" id="selectCtrCd" @change="checkValiPlac()">
                  <option v-for="ctr in ctrList" :key="ctr.ctrCd" :value="ctr.ctrCd">{{ ctr.ctrEnm }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.MAIN100.175') }}</th>
              <td>
                <!-- <input type="text" v-model="params.userTelNo" class="" id="inputTel" name="inputTel" value="" @keyup="checkValiTel()"> -->
                <e-input-number
                  v-model="params.userTelNo"
                  :id="'inputTel'"
                  :name="'inputTel'"
                  @keyup="checkValiTel()"
                >
                </e-input-number>
              </td>
              <th>{{ $t('msg.MAIN100.202') }}</th>
              <td>
                <input type="text" v-model="params.usrEmlAddr" class="" id="inputEmail" name="inputEmail" value="" @keyup="checkValiEmail()">
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.MAIN100.176') }}</th>
              <td>
                <select v-model="params.contKndCd" name="selectCate" id="selectCate" @change="checkValiCate()">
                  <option
                    v-for="category in categoryList"
                    :key="category.cd"
                    :value="category.cd"
                  >
                    {{ category.cdNm }}
                  </option>
                </select>
              </td>
              <td colspan="2" class="border_left_none"></td>
            </tr>
            <tr>
              <th>{{ $t('msg.MAIN100.177') }}</th>
              <td colspan="3">
                <textarea v-model="params.rmk" id="inputCont" style="height:200px;" @keyup="checkValiCont()"></textarea>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.MAIN100.214') }}</th><!-- 개인정보 수집에 <br />관한 동의 -->
              <td colspan="3">
                <div class="agree_box mt8">
                  <p class="tit font_medium">{{ $t('msg.MAIN100.215') }}</p><!-- 개인정보의 수집, 이용 목적 -->
                  <ul>
                    <li>{{ $t('msg.MAIN100.216') }}</li><!-- 서비스 이용에 다른 본인 식별 -->
                    <li>{{ $t('msg.MAIN100.217') }}</li><!-- 고지사항 전달, 불만처리 의사소통 경로 확보 -->
                    <li>{{ $t('msg.MAIN100.218') }}</li><!-- 불량회원 부정이용 방지 및 비인가 사용 방지 -->
                    <li>{{ $t('msg.MAIN100.219') }}</li><!-- 기타 원활한 양질의 서비스 제공 등 -->
                  </ul>

                  <p class="tit font_medium mt20">{{ $t('msg.MAIN100.220') }}</p><!-- 수집하는 개인정보의 항목 -->
                  <ul>
                    <li>{{ $t('msg.MAIN100.221') }}</li><!-- 성명, 이메일주소, 연락처 -->
                  </ul>

                  <p class="tit font_medium mt20">{{ $t('msg.MAIN100.222') }}</p><!-- 개인정보의 보유 및 이용기간 -->
                  <ul>
                    <li>{{ $t('msg.MAIN100.223') }}</li><!-- 원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시 지체없이 파기합니다. -->
                    <li>{{ $t('msg.MAIN100.224') }}</li><!-- 전자상거래에서의 소비자보호에 관한 법률 등 타법류에 의해 보존할 필요가 있는 경우에는 일정기간 보존합니다. -->
                  </ul>
                </div>

                <p class="mt10">
                  <input
                    type="checkbox"
                    v-model="acceptYn"
                    id="agreea1"
                    name="agree"
                    true-value="Y"
                    false-value="N"
                    @change="checkValiCheck()"
                  >
                  <label for="agreea1" class="color_black">
                    {{ $t('msg.MAIN100.225') }}
                  </label>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
    </form>
    <div class="flex_box mt10">
      <a class="button blue lg ml_auto" href="#" @click.prevent="submitVoc()">{{ $t('msg.MAIN100.226') }}</a>
    </div>
  </div>
</template>

<script>
import vocs from '@/api/services/vocs'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'
import auth from '@/api/services/auth'
import EInputNumber from '@/components/common/EInputNumber'

export default {
  name: 'VocReg',
  components: {
    rootComputed,
    EBreadcrumbs,
    EInputNumber
  },
  data: function () {
    return {
      params: {
        //작성자
        cstNo: '',
        cstUno: '',
        //등록할때 작성자
        reqUno: '',
        //작성 지역
        usrCtrCd: 'all',
        //전화번호
        userTelNo: '',
        picTelOfcNo: '',
        picTelNo: '',
        //E-mail
        usrEmlAddr: '',
        //제안 및 불만
        contKndCd: '',
        //내용
        rmk: '',
        //중요도
        diptCd: '01',
        //등록부서
        entDiv: '000112',
        //최초 등록 사용자
        fstEntUno: ''
      },
      //개인정보수집 동의 여부
      acceptYn: '',
      //제안 및 불만 카테고리 리스트
      categoryList: [],
      //작성지역 리스트
      ctrList: [],
      //프로필정보
      profileInfo: []
    }
  },
  computed: {
    ...rootComputed,
    pageNumberRange: function () {
        return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    }
  },
  created () {
    this.initCommCodes()
  },
  methods: {
    async initCommCodes () {
      const cdId = 'SA023'

      // 로그인한 정보를 이용하여 프로필 정보 가져오기
      if (this.auth.login) {
        const data = this.selectedProfile

        this.params.cstNo = data.cstNo
        this.params.reqUno = data.picNm
        this.params.fstEntUno = data.cstNo
        this.params.usrEmlAddr = data.emlAddr
        let usrTelNo = data.telPlcNo || ''

        if (this.$ekmtcCommon.isNotEmpty(data.telOfcNo)) {
          usrTelNo += '-' + data.telOfcNo
        }

        if (this.$ekmtcCommon.isNotEmpty(data.telNo)) {
          usrTelNo += '-' + data.telNo
        }

        this.params.userTelNo = usrTelNo

        this.params.picTelOfcNo = data.telPlcNo
        this.params.picTelNo = data.telOfcNo
        if (data.telNo) {
          this.params.picTelNo += '-' + data.telNo
        }
      } else {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
      }

      await Promise.all([

        //0. 유저국가리스트 가져오기
        vocs.getCtrCode().then(response => {
          if (response.status === 200) {
            this.ctrList = [...this.ctrList, ...response.data]
          }
        }),

        //1. 세션유저정보 가져와서 미리 할당하기
        auth.info(this.auth).then(res => {
          const result = res.data

          if (result !== undefined) {
            this.params.fstEntUno = result.userId
            this.params.cstUno = result.userId
            this.params.usrCtrCd = result.userCtrCd
          }
        }),
        //3. 제안 및 불만 카테고리 리스트 담기
        vocs.getCode({ cdId: cdId }).then(response => {
            this.categoryList = [{ cd: '', cdNm: this.$t('msg.ONEX010T010.004') }] // 선택

            if (response.status === 200) {
            this.categoryList = [...this.categoryList, ...response.data]
          }
        })
        .catch(err => {
          console.log(err)
        })
      ])
    },
    // 카테고리 명 가져오기
    getCategoryName (cd) {
      let catNm = '카테고리 X'
      if (this.$ekmtcCommon.isNotEmpty(cd)) {
          this.categoryList.forEach((item) => {
            if (item.cd === cd) {
                catNm = item.cdNm
            }
        })
      }
      return catNm
    },
    // 국가 명 가져오기
    getCountryName (ctrCd) {
      let ctrEnm = '국가명 X'
      if (this.$ekmtcCommon.isNotEmpty(ctrCd)) {
          this.ctrList.forEach((item) => {
            if (item.ctrCd === ctrCd) {
                ctrEnm = item.ctrEnm
            }
        })
      }
      return ctrEnm
    },
    // 작성자 체크
    checkValiUser (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#inputUno')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        // 값이 없을 경우
        msg = this.$t('msg.MAIN100.173') + this.$t('tem.CMAT002')
        isOk = false
      } else {
        // 값이 있을 경우, Byte 크기 체크
        if (!this.$ekmtcCommon.checkTextByte(val, 30)) {
          msg = this.$t('msg.ADMN030G020.023') // 허용된 Byte 크기를 초과하였습니다.'
          isOk = false
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },

    //작성지역항목 체크
    checkValiPlac (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelector('#selectCtrCd')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        // 값이 없을 경우
        msg = this.$t('msg.MAIN100.174') + this.$t('tem.CMAT006')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    //전화번호항목 체크
    checkValiTel (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelector('#inputTel')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        // 값이 없을 경우
        msg = this.$t('msg.MAIN100.175') + this.$t('tem.CMAT002')
        isOk = false
      } else if (!this.$ekmtcCommon.checkTel(val)) {
        // -형태가 아닐경우
        // msg = this.$t('msg.MAIN100.175') + this.$t('tem.CMAT006')
        // isOk = false
      } else {
        // 값이 있을 경우, Byte 크기 체크
        if (!this.$ekmtcCommon.checkTextByte(val, 20)) {
          msg = this.$t('msg.ADMN030G020.023') // 허용된 Byte 크기를 초과하였습니다.'
          isOk = false
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    //E-mail 체크
    checkValiEmail (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelector('#inputEmail')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        // 값이 없을 경우
        msg = this.$t('msg.MAIN100.202') + this.$t('tem.CMAT002')
        isOk = false
      } else if (!this.$ekmtcCommon.checkEmail(val)) {
        // 이메일 형식 체크
        msg = this.$t('art.CMATK196') // 잘못된 형식의 이메일 주소가 있습니다.'
        isOk = false
      } else {
        // 값이 있을 경우, Byte 크기 체크
        if (!this.$ekmtcCommon.checkTextByte(val, 2000)) {
          msg = this.$t('msg.ADMN030G020.023') // 허용된 Byte 크기를 초과하였습니다.'
          isOk = false
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    //카테고리항목 체크
    checkValiCate (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelector('#selectCate')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        // 값이 없을 경우
        msg = this.$t('msg.MAIN100.176') + this.$t('tem.CMAT002')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    //내용항목 체크
    checkValiCont (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelector('#inputCont')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        // 값이 없을 경우
        msg = this.$t('msg.MAIN100.177') + this.$t('tem.CMAT002')
        isOk = false
      } else {
        // 값이 있을 경우, Byte 크기 체크
        if (!this.$ekmtcCommon.checkTextByte(val, 4000)) {
          msg = this.$t('msg.ADMN030G020.023') // 허용된 Byte 크기를 초과하였습니다.'
          isOk = false
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    //체크박스항목 체크
    checkValiCheck (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelector('#agreea1')
      const val = this.acceptYn
      let isOk = true
      let msg = ''

      if (val !== 'Y') {
        // 개인정보 동의하지않았을 경우
        msg = this.$t('msg.MAIN100.203')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // tooltip 전체 제거
    clearValidationAll () {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.hideErrorTooltipAll(frm)
    },
    // 저장 전, 전체 validation 체크
    checkValidationAll () {
      const frm = document.querySelector('#frm')
      let isAllOk = true

      // '작성자' 체크
      if (!this.checkValiUser(frm)) {
        isAllOk = false
      }
      // '작성 지역' 체크
      if (!this.checkValiPlac(frm)) {
        isAllOk = false
      }
      // '전화번호' 체크
      if (!this.checkValiTel(frm)) {
        isAllOk = false
      }
      // 'E-mail' 체크
      if (!this.checkValiEmail(frm)) {
        isAllOk = false
      }
      // '제안 및 불만' 체크
      if (!this.checkValiCate(frm)) {
        isAllOk = false
      }
      // '내용' 체크
      if (!this.checkValiCont(frm)) {
        isAllOk = false
      }
      // '개인정보 수집 동의' 체크
      if (!this.checkValiCheck(frm)) {
        isAllOk = false
      }

      return isAllOk
    },
    //접수하기
    submitVoc () {
      //문의하시겠습니까
      this.$ekmtcCommon.confirmCallBack(this.$t('msg.MAIN100.227'), () => {
        if (this.checkValidationAll()) {
          //as-is 소스 null 처리
          if (this.params.cstUno === null || this.params.cstUno === '') {
            this.params.cstUno = 'ETC001'
          }
          if (this.params.fstEntUno === null || this.params.fstEntUno === '') {
            this.fstEntUno = 'WEBVOC'
          }
          if (this.params.cstNo === null || this.params.cstNo === '') {
            this.params.cstNo = 'ETC001'
          }

          //전화번호 가공처리
          this.params.picTelOfcNo = this.params.userTelNo.split('-')[0]
          this.params.picTelNo = this.params.userTelNo.split('-')[1]
          if (this.params.userTelNo.split('-')[2]) {
            this.params.picTelNo += this.params.userTelNo.split('-')[2]
          }

          // eslint-disable-next-line no-unreachable
          vocs.insertVocs(this.params).then(response => {
            if (response.headers.respcode === 'C0000') {
                //성공적으로 저장하였습니다.
                this.$ekmtcCommon.alertCallback(this.$t('msg.CSBL200.086') + this.$t('msg.MAIN100.201'), () => {
                  this.$router.push({ name: 'Voc' })
                })
              } else {
                //저장하지 못했습니다. 관리자에게 문의하세요.
                this.$ekmtcCommon.alertCallback(this.$t('msg.KMTC413.020') + this.$t('art.CMATK185'), () => {
                  this.$router.push({ name: 'Voc' })
                })
              }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
