<template>
  <div class="content resize">
    <!-- content  -->

    <div class="location">
      <!-- location -->
      <ul>
        <li><a href="#" class="home">home</a></li>
        <li><a href="#" @click.prevent="goHome">Home</a></li>
        <li><a href="#" @click.prevent="selectTab('1')">{{ $t('menu.MENU08.000') }}</a></li>
        <!-- <li>{{ $t('menu.MENU06.020') }}</li> -->
        <li>{{ curTabName }}</li>
      </ul>
    </div><!-- location -->

    <h1 class="page_title">{{ $t('msg.CMBA100.544') }}</h1>

    <div class="tab">
      <!-- tab -->
      <ul>
        <li v-if="isShowTab.tab1" class="on" id="tab1" @click="selectTab('1')"><a href="javascript:void(0)">{{ $t('msg.CMBA100.547') }}</a></li>
        <li v-if="isShowTab.tab2" id="tab2" @click="selectTab('2')"><a href="javascript:void(0)">{{ $t('msg.CMBA100.548') }}</a></li>
      </ul>
    </div><!-- tab // -->
    <div v-if="isShowTab.tab1" id="cnt1" name="cnt">
      <impt-bsnes-agenc-info
        :param-data="paramData"
      ></impt-bsnes-agenc-info>
    </div>
    <div v-if="isShowTab.tab2" id="cnt2" name="cnt" v-show="false">
      <impt-bsnes-agenc-info-list
        :param-data="paramData"
      ></impt-bsnes-agenc-info-list>
    </div>
  </div><!-- content // -->
</template>

<script>

import ImptBsnesAgencInfo from '@/pages/user/ImptBsnesAgencInfo'
import ImptBsnesAgencInfoList from '@/pages/user/ImptBsnesAgencInfoList'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'ImptBsnesAgencInfoMain',
  components: {
    ImptBsnesAgencInfo,
    ImptBsnesAgencInfoList
  },
  data () {
    return {
      isShowTab: {
        tab1: true,
        tab2: true
      },
      // tabNames: ['업무 대행정보 요청', '업무 대행정보 리스트'],
      tabNames: [this.$t('msg.CMBA100.547'), this.$t('msg.CMBA100.548')],
      curTabName: this.$t('msg.CMBA100.544'),
      paramData: {}
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    '$store.state.user.tabIndex' () {
      this.selectTab(this.$store.state.user.tabIndex)
    }
  },
  created () {
  },
  mounted () {
    this.initTab()

    let tabIndex = this.$store.state.user.tabIndex
    if (tabIndex != null) {
        setTimeout(() => {
          const vo = this.$route.query
          if (this.$ekmtcCommon.isEmpty(vo.tab)) {
            this.selectTab(tabIndex)
          }
        }, 300)
    }
    this.$store.commit('user/updateTabIndex', null)
    this.requestView()
  },
  methods: {
    requestView () {
      const vo = this.$route.query
      if (vo.tab === '1') {
        this.paramData = vo
        this.selectTab('1')
      }
    },
    initTab () {
        this.isShowTab.tab1 = true
        this.isShowTab.tab2 = true
    },
    selectTab (tab) {
      this.$store.commit('user/updateTabIndex', tab)
      $('.tab li').removeClass('on')
      $('div[name=cnt]').hide()
      $('#tab' + tab).addClass('on')
      $('#cnt' + tab).show()
      if (parseInt(tab) >= 1 && parseInt(tab) <= 5) {
        this.curTabName = this.tabNames[parseInt(tab) - 1]
      }
    },
    goHome () {
      this.$router.push({ path: '/main' })
    },
    goServiceMain () {
      this.$router.push({ path: '/my-info/import-business-agency-information-main' })
    }
  }
}

</script>
