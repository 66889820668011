<template>
  <div class="content" id="send_card_area">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('menu.MENU09.141') }}</h1>
    <div class="content_box mt10">
         <p align="left"><b><font face="맑은고딕" size="4" color="#004080">카드가 완성되었습니다. 확인하세요.</font></b></p>
        <div id="sendContents">
            <table class="tbl_row">
                <tr>
                    <td style="border: 0;">
                        <img :src="imgUrl()" style="border:#b4b4b4; border-width:1px; border-style: solid; " />
                    </td>
                </tr>
                <tr>
                    <td style="border: 0; text-align: left;"> <b>To  {{ params.toName }}</b></td>
                </tr>
                <tr>
                    <td style="border: 0; text-align: left;">
                        <pre>{{ params.reqCont }}</pre>
                    </td>
                </tr>
                <tr>
                    <td style="border: 0; text-align: left;"><b> From  {{ params.fromName }} </b>  </td>
                </tr>
            </table>
        </div>
        <div class="mt10 flex_box">
            <span class="ml_auto">
                <a v-show="!sendResult" class="button blue lg ml5" @click="goSendCard()">연하장 전송</a>
                <span v-show="sendResult">
                <font color="red" ><b>총 {{ sendCnt }} 건의 {{ year }}년도 연하장 메일을 발송하였습니다. </b></font><br><br>
                <a class="button blue lg ml5"  @click="goNewSendCard()">연하장 새로 보내기</a>
                </span>
            </span>
        </div>
    </div>
  </div>
</template>
<script>
import { rootComputed } from '@/store/helpers'
import auth from '@/api/services/auth'
import commons from '@/api/rest/common/sendCard'

export default {
    name: 'sendCard',
    components: {
        'sample-pop': () => import('@/pages/common/popup/sendCardSamplePop'),
        'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop')
    },
    computed: {
    ...rootComputed
    },
    props: {
        regParams: {
        type: Object,
        default: null
        }
    },
    data () {
     return {
        params: {
            fromName: '',
            fromEmail: '',
            toName: '',
            toEmail: '',
            toTitle: '',
            cardCd: '',
            reqCont: '',
            sendContents: '',
            appMode: process.env.VUE_APP_MODE
        },
        sendCnt: 0,
        sendResult: false,
        year: ''
     }
    },
    created () {
        this.init()
    },
    methods: {
        async init () {
            this.params.fromName = this.regParams.fromName
            this.params.fromEmail = this.regParams.fromEmail
            this.params.toName = this.regParams.toName
            this.params.toEmail = this.regParams.toEmail
            this.params.toTitle = this.regParams.toTitle
            this.params.cardCd = this.regParams.cardCd
            this.params.reqCont = this.regParams.reqCont
        },
        goSendCard () {
            this.params.sendContents = document.querySelector('#sendContents').innerHTML
            commons.sendCard(this.params).then((res) => {
              const result = res.data
              this.sendCnt = result.sendCnt
              if (result.sendCnt > 0) {
                this.sendResult = true
                this.year = result.year
              }
            })
            .finally(() => {
                if (this.sendResult) {
                    this.$ekmtcCommon.alertDefault('전송하였습니다.')
                } else {
                    this.$ekmtcCommon.alertDefault('전송에 실패하였습니다.')
                }
            })
        },
        imgUrl () {
           return `https://www.ekmtc.com/img/${this.params.cardCd}.jpg`
        },
        goNewSendCard () {
          this.$router.push({ name: 'sendNewYearCard' }).catch(() => {})
        },
        goPrecedingStep () {
            this.$router.go(-1)
        }
    }
}
</script>
