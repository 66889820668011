<template>
  <div class="content">
      <e-breadcrumbs />
      <h1 class="page_title">{{ $t('msg.CSCT060G040.001') }}</h1> <!-- Local Charge -->
      <div class="content_box mt10"><!-- content_box -->
        <table class="tbl_search">
          <colgroup>
            <col width="130px">
            <col>
            <col width="150px">
            <col>
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSCT060G040.002') }}</th> <!-- Port of Loading -->
              <td>
                <e-auto-complete-place @change="changePol" v-model="formData.podCtrCd" placeholder="항구, 국가 입력(영어)"/>
              </td>
              <th>{{ $t('msg.CSCT060G040.003') }}</th> <!-- Port of Discharging -->
              <td>
                <e-auto-complete-place @change="changePod" v-model="formData.podCtrCd" placeholder="항구, 국가 입력(영어)"/>
              </td>
              <td class="text_right">
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSCT060G040.004') }}</th> <!-- Container Type -->
              <td colspan="3">
                <template v-for="cntrType in cntrTypeList">
                  <input type="checkbox" :id="cntrType.cd" :name="cntrType.cd" :key="cntrType.cd" @change="fnCntrTypeCheck(cntrType.cd)">
                  <label :for="cntrType.cd" class="mr20" :key="'label_' + cntrType.cd"><span></span>{{cntrType.cd}}</label>
                </template>
              </td>
              <td class="text_right">
                <a class="button blue sh" href="#" @click.prevent="fnSearch">{{ $t('msg.CMBA100.00120') }}</a> <!-- 검색 -->
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->

      <div class="content_box mt10">
        <!--Excel Download -->
        <div class="text_right mb8">
          <a class="button sm" href="#" @click.prevent="fnExcelDownload">{{ $t('msg.CSCT060G040.005') }}</a> <!-- 엑셀 다운로드 -->
        </div>
        <local-charge-sub :list-data="originLocalChargeList" :mount-flag="oFlag" ref="sub"/>
        <br>
        <local-charge-sub :list-data="destinationLocalChargeList" :mount-flag="dFlag"/>
      </div>
      <ul class="bul_list_sm lh_short">
        <li>{{ $t('msg.CSCT060G040.006') }}</li> <!-- 양하지 터미널별로 상기에 기재되지 않은 비용들이 추가 및 변경될 수 있습니다. -->
        <li>{{ $t('msg.CSCT060G040.007') }}</li> <!-- 양하지별로 화물의 중량과 터미널을 기준으로 적용되는 요율이 변경될 수 있습니다. -->
        <li>{{ $t('msg.CSCT060G040.008') }}</li> <!-- 내륙 운송의 IHC(Inland Haulage Charge) 비용은 별도로 부가되며 담당자에게 문의 바랍니다 -->
      </ul>
  </div>
</template>
<script>

import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import localCharge from '@/api/rest/trans/localCharge'
import LocalChargeSub from '@/pages/common/LocalChargeSub'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'LocalCharge',
  components: {
    EBreadcrumbs,
    EAutoCompletePlace,
    LocalChargeSub
  },
  computed: {
    ...rootComputed
  },
  data () {
    return {
      oFlag: 'Y',
      dFlag: 'N',
      totalCount: 0,
      cntrTypeList: [
        { cdNm: 'DRY', cd: 'GP' },
        { cdNm: 'HIGH CUBE', cd: 'HC' },
        { cdNm: 'REEFER', cd: 'RF' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH' },
        { cdNm: 'TANK', cd: 'TK' },
        { cdNm: 'OPEN TOP', cd: 'OT' },
        { cdNm: 'FLAT RACK', cd: 'FR' },
        { cdNm: 'SUPER RACK', cd: 'SR' },
        { cdNm: 'DANGEROUS', cd: 'DG' }
      ],
      formData: {
        cntrTypeList: [],
        dtKnd: '',
        podCtrCd: '',
        polCtrCd: '',
        actShprCstEnm: ''
      },
      originLocalChargeList: [],
      destinationLocalChargeList: []
    }
  },
  mounted () {
    console.log('mounted')
  },
  methods: {
     changePol (vo) {
      const def = {
        ctrCd: '',
        plcCd: ''
      }
      const tvo = { ...def, ...vo }

      this.formData.polCtrCd = tvo.ctrCd
      this.formData.polPortCd = tvo.plcCd
    },
    changePod (vo) {
      const def = {
        ctrCd: '',
        plcCd: ''
      }
      const tvo = { ...def, ...vo }

      this.formData.podCtrCd = tvo.ctrCd
      this.formData.podPortCd = tvo.plcCd
    },
    //컨테이너 체크 로직
    fnCntrTypeCheck (cntrType) {
      //체크한 컨테이너 있는지 필터링
      const filteredType = this.formData.cntrTypeList.filter((item) => {
        return item === cntrType
      })
      //체크한 컨테이너가 있으면(삭제)
      if (filteredType.length > 0) {
        this.formData.cntrTypeList = this.formData.cntrTypeList.filter((item) => {
          return item !== cntrType
        })
      //체크한 컨테이너가 없으면(삽입)
      } else {
        this.formData.cntrTypeList.push(cntrType)
      }
    },
    //검색
    async fnSearch () {
      if (!this.formData.polCtrCd && !this.formData.podCtrCd) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G040.009')) //Port of Loading 또는 Port of Discharging을 입력해주세요.
        return
      }

      if (this.formData.cntrTypeList.length === 0 || (this.formData.cntrTypeList.length === 1 && this.formData.cntrTypeList[0] === 'DG')) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G040.010')) //위험물을 선적할 컨테이너 타입(GP/HC 등)을 선택하세요.
        return
      }

      const result = await localCharge.findLocalCharge(this.formData)

      this.originLocalChargeList = result.data.originLocalChargeList.map((item) => {
        item.term1 = this.$t('msg.CSCT060G040.011') //ORIGIN\n(PREPAID)
        // item.extrRmk = this.auth.userId && (this.memberDetail.userCtrCd === 'KR') ? item.extrRmk : ''
        return item
      })

      this.destinationLocalChargeList = result.data.destinationLocalChargeList.map((item) => {
        item.term2 = this.$t('msg.CSCT060G040.012') //DESTINATION\n(COLLECT)
        // item.extrRmk = this.auth.userId && (this.memberDetail.userCtrCd === 'KR') ? item.extrRmk : ''
        return item
      })

      this.totalCount = Number(result.data.originLocalChargeList.length) + Number(result.data.destinationLocalChargeList.length)
    },
    //엑셀다운로드
    fnExcelDownload () {
      if (!this.formData.polCtrCd && !this.formData.podCtrCd) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK139')) //조회 후 선택하세요.
        return
      }
      this.$refs.sub.fnExcelDownload()
    }
  }
}
</script>
