var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content widfix" },
    [
      _c("e-breadcrumbs"),
      _c(
        "div",
        { attrs: { id: "layerPop" } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.popupParams },
                    on: { close: _vm.closePoup, agreement: _vm.agreementPop },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("form", { attrs: { id: "frm" } }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("menu.MENU08.010"))),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("table", { staticClass: "tbl_row mt20" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [
                  _vm._v(" " + _vm._s(_vm.$t("msg.CMBA100.0059")) + " "),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.userId,
                        expression: "params.userId",
                      },
                    ],
                    attrs: { type: "text", id: "userId", disabled: "disabled" },
                    domProps: { value: _vm.params.userId },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "userId", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0061")))]),
                _c("td", { staticClass: "full" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedCtrList,
                          expression: "selectedCtrList",
                        },
                      ],
                      attrs: { disabled: "", id: "selectedCtrList" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedCtrList = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.selectedCtr($event)
                          },
                        ],
                        keyup: function ($event) {
                          return _vm.checkValidationCtrList()
                        },
                      },
                    },
                    _vm._l(_vm.ctrList, function (ctr) {
                      return _c(
                        "option",
                        { key: ctr.ctrCd, domProps: { value: ctr.ctrCd } },
                        [_vm._v(" " + _vm._s(ctr.ctrEnm) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0078")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup("MyInfoPasswordPop")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0103")))]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA100.0087"))),
                  _c("span", { staticClass: "txt_normal" }, [
                    _vm._v(
                      "(" +
                        _vm._s(
                          _vm.$i18n.locale === "zh-CN"
                            ? _vm.$t("msg.CMBA100.0081")
                            : _vm.$t("msg.CMBA100.0080")
                        ) +
                        ")"
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.compName,
                        expression: "params.compName",
                      },
                    ],
                    attrs: { type: "text", id: "compName" },
                    domProps: { value: _vm.params.compName },
                    on: {
                      keyup: function ($event) {
                        return _vm.checkCompanyName()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "compName", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA100.0087"))),
                  _c("span", { staticClass: "txt_normal" }, [
                    _vm._v("(" + _vm._s(_vm.$t("msg.CMBA100.0083")) + ")"),
                  ]),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.compEName,
                        expression: "params.compEName",
                      },
                    ],
                    attrs: { type: "text", id: "compEName" },
                    domProps: { value: _vm.params.compEName },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "compEName", $event.target.value)
                        },
                        function ($event) {
                          return _vm.formatInput($event)
                        },
                      ],
                      keyup: function ($event) {
                        return _vm.checkCompanyEName()
                      },
                    },
                  }),
                ]),
              ]),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isKor,
                      expression: "isKor",
                    },
                  ],
                },
                [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0088")))]),
                  _c("td", [
                    _c("div", { staticClass: "tbl_form" }, [
                      _c("span", { staticClass: "dv col_3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "###",
                              expression: "'###'",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.bizNo1,
                              expression: "params.bizNo1",
                            },
                          ],
                          attrs: { type: "text", maxlength: "3", id: "bizNo1" },
                          domProps: { value: _vm.params.bizNo1 },
                          on: {
                            keyup: function ($event) {
                              return _vm.checkbizNo1()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params,
                                "bizNo1",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "sp" }),
                      _c("span", { staticClass: "dv col_3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "###",
                              expression: "'###'",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.bizNo2,
                              expression: "params.bizNo2",
                            },
                          ],
                          attrs: { type: "text", maxlength: "2", id: "bizNo2" },
                          domProps: { value: _vm.params.bizNo2 },
                          on: {
                            keyup: function ($event) {
                              return _vm.checkbizNo2()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params,
                                "bizNo2",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "sp" }),
                      _c("span", { staticClass: "dv" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "#####",
                              expression: "'#####'",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.bizNo3,
                              expression: "params.bizNo3",
                            },
                          ],
                          attrs: { type: "text", maxlength: "5", id: "bizNo3" },
                          domProps: { value: _vm.params.bizNo3 },
                          on: {
                            keyup: function ($event) {
                              return _vm.checkbizNo3()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params,
                                "bizNo3",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.002")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "filebox" }, [
                      _c(
                        "button",
                        {
                          class:
                            _vm.uploadFileInfo.length > 0
                              ? "button sm blue"
                              : "button sm gray",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openFileUploader()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.037")))]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isShowDext5Pop,
                              expression: "isShowDext5Pop",
                            },
                          ],
                          attrs: { id: "dext5_pop" },
                        },
                        [
                          _c(
                            "div",
                            { ref: "popup", staticClass: "popup_dim" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "popup_wrap",
                                  staticStyle: {
                                    width: "800px",
                                    height: "520px",
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "layer_close",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.closeFileUploadr()
                                        },
                                      },
                                    },
                                    [_vm._v("close")]
                                  ),
                                  _c("div", { staticClass: "popup_cont" }, [
                                    _c("h1", { staticClass: "page_title" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.MYIN010G010.037"))
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "content_box" },
                                      [
                                        _vm.isShowDext5
                                          ? _c("DEXT5Upload", {
                                              ref: "dextupload-myinfo",
                                              attrs: {
                                                id:
                                                  "dext5-uploader-" +
                                                  _vm.dext5Idx,
                                                single: true,
                                                category: false,
                                                width: "100%",
                                                height: "200px",
                                                "file-info": _vm.uploadFileInfo,
                                                "category-info": [],
                                              },
                                              on: {
                                                completed: _vm.uploadComplete,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "mt10 text_center" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button blue lg",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.closeFileUploadr()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("msg.MYIN010G010.022")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isKor,
                      expression: "isKor",
                    },
                  ],
                },
                [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0090")))]),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("p", { staticClass: "mb5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.postNo,
                            expression: "params.postNo",
                          },
                        ],
                        staticClass: "wid100",
                        attrs: {
                          type: "text",
                          maxlength: "5",
                          id: "postNo",
                          disabled: "",
                        },
                        domProps: { value: _vm.params.postNo },
                        on: {
                          keyup: function ($event) {
                            return _vm.checkPostNo()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "postNo", $event.target.value)
                          },
                        },
                      }),
                      _c("span", { staticClass: "sp2" }),
                      _c(
                        "span",
                        [
                          _c(
                            "e-search-address",
                            {
                              staticClass: "button sm",
                              on: { setAddr: _vm.customCallback },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("msg.MYIN010G010.003")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.compAddr,
                          expression: "params.compAddr",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.params.compAddr },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "compAddr", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00108")))]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.tel1,
                            expression: "params.tel1",
                          },
                        ],
                        attrs: { type: "text", maxlength: "4", id: "tel1" },
                        domProps: { value: _vm.params.tel1 },
                        on: {
                          keyup: function ($event) {
                            return _vm.checkTel1()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "tel1", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "###",
                            expression: "'###'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.tel2,
                            expression: "params.tel2",
                          },
                        ],
                        attrs: {
                          type: "text",
                          maxlength: "3",
                          id: "tel2",
                          name: "tel2",
                        },
                        domProps: { value: _vm.params.tel2 },
                        on: {
                          keyup: function ($event) {
                            return _vm.checkTel2()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "tel2", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv col_3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.tel3,
                            expression: "params.tel3",
                          },
                        ],
                        attrs: { type: "text", maxlength: "4", id: "tel3" },
                        domProps: { value: _vm.params.tel3 },
                        on: {
                          keyup: function ($event) {
                            return _vm.checkTel3()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "tel3", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.tel4,
                            expression: "params.tel4",
                          },
                        ],
                        attrs: { type: "text", maxlength: "4", id: "tel4" },
                        domProps: { value: _vm.params.tel4 },
                        on: {
                          keyup: function ($event) {
                            return _vm.checkTel4()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "tel4", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm.$i18n.locale === "en-US"
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#2556c0",
                            "font-size": "13px",
                          },
                        },
                        [
                          _vm._v(
                            "ex) HK : Non-1234-1234 , SG : Non-1234-1234 , MY : 123-1234-123"
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00163")))]),
                _c("td", [
                  _c("div", [
                    _c("span", { staticClass: "col_50p wid50" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email1,
                            expression: "email1",
                          },
                        ],
                        staticStyle: { width: "39%" },
                        attrs: { type: "text", id: "email1", placeholder: "" },
                        domProps: { value: _vm.email1 },
                        on: {
                          keyup: function ($event) {
                            return _vm.checkEmail1()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email1 = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "col_7p text_center",
                        staticStyle: { margin: "0 2px 0 2px" },
                      },
                      [_vm._v("@")]
                    ),
                    _c("span", { staticClass: "col_43p" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email2,
                            expression: "email2",
                          },
                        ],
                        staticStyle: { width: "53.8%" },
                        attrs: { type: "text", id: "email2", placeholder: "" },
                        domProps: { value: _vm.email2 },
                        on: {
                          keyup: function ($event) {
                            return _vm.checkEmail2()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email2 = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA100.00109"))),
                  _c("span", { staticClass: "txt_normal" }, [
                    _vm._v("(" + _vm._s(_vm.$t("msg.MYIN010G010.004")) + ")"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fax1,
                            expression: "params.fax1",
                          },
                        ],
                        attrs: { type: "text", maxlength: "4", id: "fax1" },
                        domProps: { value: _vm.params.fax1 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "fax1", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fax2,
                            expression: "params.fax2",
                          },
                        ],
                        attrs: {
                          type: "text",
                          maxlength: "3",
                          id: "fax2",
                          name: "fax2",
                        },
                        domProps: { value: _vm.params.fax2 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "fax2", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv col_3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fax3,
                            expression: "params.fax3",
                          },
                        ],
                        attrs: { type: "text", maxlength: "4", id: "fax3" },
                        domProps: { value: _vm.params.fax3 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "fax3", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fax4,
                            expression: "params.fax4",
                          },
                        ],
                        attrs: { type: "text", maxlength: "4", id: "fax4" },
                        domProps: { value: _vm.params.fax4 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "fax4", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0091")))]),
                _vm._v(" "),
                _c("td", { staticClass: "full" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedCstCatList,
                          expression: "selectedCstCatList",
                        },
                      ],
                      attrs: { id: "selectedCstCatList" },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidationCstCatList()
                        },
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedCstCatList = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.cstCatList, function (cstCat) {
                      return _c(
                        "option",
                        {
                          key: cstCat.value,
                          domProps: { value: cstCat.value },
                        },
                        [_vm._v(" " + _vm._s(cstCat.text) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0097")))]),
                _vm._v(" "),
                _c("td", { staticClass: "full" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.bkgPlcCd,
                          expression: "params.bkgPlcCd",
                        },
                      ],
                      attrs: { id: "selectedBkgPlc" },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValidationBkgPlc()
                        },
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.params,
                            "bkgPlcCd",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.selectedBkgPlcList, function (bkgPlc) {
                      return _c(
                        "option",
                        {
                          key: bkgPlc.plcCd,
                          domProps: { value: bkgPlc.plcCd },
                        },
                        [_vm._v(" " + _vm._s(bkgPlc.plcEnm) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00104")))]),
                _vm._v(" "),
                _c("td", { staticClass: "full" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.eiCatCd,
                        expression: "params.eiCatCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "trad1",
                      name: "trad",
                      value: "O",
                      checked: "checked",
                    },
                    domProps: { checked: _vm._q(_vm.params.eiCatCd, "O") },
                    on: {
                      keyup: function ($event) {
                        return _vm.checkValidationEiCatCd()
                      },
                      change: function ($event) {
                        return _vm.$set(_vm.params, "eiCatCd", "O")
                      },
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "mr10", attrs: { for: "trad1" } },
                    [_c("span"), _vm._v(_vm._s(_vm.$t("msg.CMBA100.00105")))]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.eiCatCd,
                        expression: "params.eiCatCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "trad2",
                      name: "trad",
                      value: "I",
                    },
                    domProps: { checked: _vm._q(_vm.params.eiCatCd, "I") },
                    on: {
                      keyup: function ($event) {
                        return _vm.checkValidationEiCatCd()
                      },
                      change: function ($event) {
                        return _vm.$set(_vm.params, "eiCatCd", "I")
                      },
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "mr10", attrs: { for: "trad2" } },
                    [_c("span"), _vm._v(_vm._s(_vm.$t("msg.CMBA100.00106")))]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.eiCatCd,
                        expression: "params.eiCatCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "trad3",
                      name: "trad",
                      value: "T",
                    },
                    domProps: { checked: _vm._q(_vm.params.eiCatCd, "T") },
                    on: {
                      keyup: function ($event) {
                        return _vm.checkValidationEiCatCd()
                      },
                      change: function ($event) {
                        return _vm.$set(_vm.params, "eiCatCd", "T")
                      },
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "mr10", attrs: { for: "trad3" } },
                    [_c("span"), _vm._v(_vm._s(_vm.$t("msg.CMBA100.00107")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.memberDetail.staffFlag !== "Y",
                expression: "memberDetail.staffFlag !== 'Y'",
              },
            ],
            staticClass: "flex_box mt10",
          },
          [
            _c(
              "button",
              {
                staticClass: "button blue lg ml_auto mr5",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.memberUpdate()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.032")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.openConfirmAlert(_vm.$t("art.CMATK124"))
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.031")))]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col"),
      _c("col", { attrs: { width: "160" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }