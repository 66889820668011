import Send from '../../user.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //나의 정보 > Report 조회/생성 리스트 조회
  getReportList (formData) {
    return Send({
        url: '/user/report',
        method: 'get',
        params: {
        selectRow: formData.selectRow || '0',
        cstNo: formData.cstNo,
        from: formData.from,
        to: formData.to,
        kind: formData.kind,
        inout: formData.inout,
        startPlcCd: formData.startPlcCd,
        startCtrCd: formData.startCtrCd,
        destPlcCd: formData.destPlcCd,
        destCtrCd: formData.destCtrCd,
        menuCd: formData.menuCd
      }
    })
  },
  getReportSubList (formData) {
    return Send({
      url: '/user/report/sub',
      method: 'get',
      params: {
        curPage: formData.curPage,
        pageSize: formData.pageSize
      }
    })
  },
  cancelMultiSubs (data) {
    return Send({
      url: '/user/report/multi-cancel',
      method: 'put',
      data: {
        seq: data.seq
      }
    })
  },
  insertReportSub (data) {
    return Send({
      url: '/user/report/report-insert',
      method: 'post',
      data: data
    })
  }
}
