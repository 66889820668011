<template>
  <div class="autocomplete-form"
       @keydown.down="onKeydown($event)"
       @keydown.up="onKeyup($event)"
       @keydown.enter="onClicked"
       @focusout="onFocusout($event)"
  >
    <div class="autocomplete-form-input-elements">
      <input id="autocomplete-form-input"
             autocomplete="off"
             class="w-100"
             :value="keyword"
             @input="daynamicSearchPicNm"
             @click="displaySelectBox(true)"
             type="text"
      >
    </div>
    <div id="autoComplPicNm" class="pic-nm-select-div list-group list-group-flush autocomplete-form-items" v-show="dynamicItems.length > 0"
         style="width: 378.13px;"
    >
      <template v-for="(p, i) in dynamicItems">
        <button
          :key="'picNm-' + i"
          :id="'picNm-' + i"
          class="list-group-item list-group-item-action"
          :class="{active: p.active}"
          @mouseover="setActiveRow(i)"
          @click="onClicked()"
          type="button"
        >
          {{ p.picNm }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import apiCommons from '@/api/services/commons'

export default {
  name: 'EAutoCompletePicNm',
  components: {
  },
  props: {
    picNm: { type: String, default: '', required: false },
    readOnly: { type: Boolean, default: false, required: false },
    simpleProfileList: { type: Array, default: null, required: false }
  },
  data () {
    return {
      keyword: '',
      rtnVo: {},
      items: [],
      dynamicItems: [],
      selectedIndex: null,
      disabled: false,
      visible: false,
      activeIdx: null
    }
  },
  watch: {
    picNm () {
      //
    }

  },
  created () {
  },
  methods: {
    clear () {
      this.keyword = ''
      this.activeIdx = null
      this.displaySelectBox(false)
    },
    onFocusout (e) {
      if (e.relatedTarget == null) {
        this.activeIdx = null
        this.displaySelectBox(false)
      } else {
        this.onClicked()
      }
    },
    onKeydown () {
      let activeRow = this.activeIdx
      if (this.dynamicItems.length === 0) {
        return
      }
      if (this.activeIdx === null) {
        activeRow = 0
      } else {
        if (this.activeIdx !== this.dynamicItems.length) {
          activeRow = this.activeIdx + 1
        }
      }

      this.setActiveRow(activeRow)

      // 스크롤 처리
      const elem = document.querySelector('#autoComplPicNm')
      const btn = elem.querySelectorAll('.list-group-item')[activeRow]
      const bb = btn.offsetTop + btn.offsetHeight
      const h = elem.offsetHeight

      if (this.activeIdx === 0) {
        elem.scrollTop = 0
      } else if (h < bb) {
        elem.scrollTop = bb - h
      }
    },
    onKeyup () {
      let activeRow = this.activeIdx
      if (this.dynamicItems.length === 0) {
        return
      }
      if (this.activeIdx === null) {
        activeRow = 0
      } else {
        if (this.activeIdx !== 0) {
          activeRow = this.activeIdx - 1
        }
      }

      this.setActiveRow(activeRow)
      // 스크롤 처리
      const elem = document.querySelector('#autoComplPicNm')
      const btn = elem.querySelectorAll('.list-group-item')[activeRow]
      const bt = btn.offsetTop

      if (bt < elem.scrollTop) {
        elem.scrollTop = bt
      }
    },
    onClicked () {
      if (this.activeIdx !== null) {
        this.selectPicNm(this.dynamicItems[this.activeIdx].idx)
      }
    },

    displaySelectBox (flag) {
      this.visible = flag
      if (flag) {
         $('#autoComplPicNm').css('display', '')
      } else {
         $('#autoComplPicNm').css('display', 'none')
      }
    },
    setActiveRow (idx) {
      let arr = JSON.parse(JSON.stringify(this.dynamicItems))
      for (let i = 0; i < arr.length; i++) {
        arr[i].active = false
      }
      arr[idx].active = true
      this.activeIdx = idx
      this.dynamicItems = arr
    },
    selectPicNm (idx) {
      let el = $('#picNm-' + idx)
      this.keyword = this.simpleProfileList[idx].picNm
      this.selectedIndex = idx
      this.$emit('change', this.simpleProfileList[idx])
      this.displaySelectBox(false)
      el.blur()
    },
    daynamicSearchPicNm (e) {
      this.activeIdx = null
      this.keyword = e.target.value
      const keyword = this.keyword
      const picArr = JSON.parse(JSON.stringify(this.simpleProfileList))
      let resArr = []
      if (keyword === '' || keyword.length < 1) {
        this.items = []
        this.dynamicItems = []
      } else {
        for (let pic of picArr) {
          if (!this.isEmpty(pic.picNm)) {
            if (pic.picNm.indexOf(keyword) !== -1) {
              resArr.push(pic)
            }
          }
        }
        this.dynamicItems = resArr
      }
      //태국어 혹은 한글은 3바이트로 계산될 수도 있어서 50byte로 제한
      this.keyword = this.getLimitedByteText(keyword, 50)
    },
    getLimitedByteText (inputText, maxByte) {
      const characters = inputText.split('')
      let validText = ''
      let totalByte = 0

      for (let i = 0; i < characters.length; i += 1) {
        const character = characters[i]
        const decimal = character.charCodeAt(0)
        const byte = (decimal >> 7) ? 2 : 1

        if (totalByte + byte <= maxByte) {
          totalByte += byte
          validText += character
        } else {
          break
        }
      }
      return validText
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }
  }
}

</script>

<style scope>
  #autoComplPicNm {
    position: absolute;
    min-width: 300px;
    max-height: 300px;
    border: 1px solid #d8d8d8;
    z-index: 999;
    overflow: hidden;
    overflow-y: auto;
  }
</style>
