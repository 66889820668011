var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("e-breadcrumbs"),
      _c(
        "form",
        { attrs: { id: "frm" } },
        [
          _c(
            "win-layer-pop",
            { staticClass: "intergis-cfs-detail-pop" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.popupParams },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.001"))),
          ]),
          _c("div", { staticClass: "content_box mt10" }, [
            _c("table", { staticClass: "tbl_search" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.002")))]),
                  _c(
                    "td",
                    { staticClass: "pl0", attrs: { colspan: "5" } },
                    [
                      _c("e-date-range-picker", {
                        attrs: {
                          id: "whsg_dt",
                          sdate: _vm.params.fromDt,
                          edate: _vm.params.toDt,
                          "is-debug": true,
                        },
                        on: { change: _vm.changeDateRange },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.003")))]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "tbl_form" },
                      [
                        _c("e-auto-complete-place", {
                          attrs: {
                            "ctr-cd": _vm.params.podCtrCd,
                            "plc-cd": _vm.params.podPortCd,
                          },
                          on: { change: _vm.changePod },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.004")))]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("div", { staticClass: "tbl_form" }, [
                      _c("span", { staticClass: "dv col_5" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.params.stsCd,
                                expression: "params.stsCd",
                              },
                            ],
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.params,
                                  "stsCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.005"))),
                            ]),
                            _c("option", { attrs: { value: "01" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.006"))),
                            ]),
                            _c("option", { attrs: { value: "02" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.007"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("span", { staticClass: "dv" }),
                    ]),
                  ]),
                  _c("td", { staticClass: "text_right" }),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.008")))]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.whsgNo,
                          expression: "params.whsgNo",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.params.whsgNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "whsgNo", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.009")))]),
                  _c(
                    "td",
                    { attrs: { colspan: "2" } },
                    [
                      _c("e-auto-complete-cst-enm", {
                        attrs: {
                          "ctr-cd": _vm.params.cstCd,
                          "cd-nm": _vm.params.cstNm,
                        },
                        on: { change: _vm.changeAct },
                      }),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "text_right" }),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.010")))]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.cntrNo,
                          expression: "params.cntrNo",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.params.cntrNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "cntrNo", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.011")))]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.bkgNo,
                          expression: "params.bkgNo",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.params.bkgNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "bkgNo", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("td", { staticClass: "text_right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button blue sh",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.searchCFSInter(1, true)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.012")))]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "content_box mt10" }, [
            _c("div", { staticClass: "flex_box" }, [
              _c("span", { staticClass: "tot_num" }, [
                _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.013")) + " : "),
                _c("span", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.listCountNumber)),
                ]),
              ]),
              _vm.sessionIntergis == "1" || _vm.sessionIntergis == "3"
                ? _c("span", { staticClass: "ml_auto cal_btn" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.updFlag,
                            expression: "params.updFlag",
                          },
                        ],
                        staticClass: "wid160",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "updFlag",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "CN" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.014"))),
                        ]),
                        _c("option", { attrs: { value: "BK" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.015"))),
                        ]),
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.params.updFlag === "CN",
                          expression: "params.updFlag === 'CN'",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.cntrNoUpd,
                          expression: "params.cntrNoUpd",
                        },
                      ],
                      staticClass: "wid120",
                      attrs: { type: "text" },
                      domProps: { value: _vm.params.cntrNoUpd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "cntrNoUpd", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.params.updFlag === "CN",
                          expression: "params.updFlag === 'CN'",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.sealNo1Upd,
                          expression: "params.sealNo1Upd",
                        },
                      ],
                      staticClass: "wid120",
                      attrs: { type: "text" },
                      domProps: { value: _vm.params.sealNo1Upd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.params,
                            "sealNo1Upd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.params.updFlag === "BK",
                          expression: "params.updFlag === 'BK'",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.bkgNoUpd,
                          expression: "params.bkgNoUpd",
                        },
                      ],
                      staticClass: "wid120",
                      attrs: { type: "text" },
                      domProps: { value: _vm.params.bkgNoUpd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "bkgNoUpd", $event.target.value)
                        },
                      },
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "button sm ml2",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.updateSelected()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.016")))]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("table", { staticClass: "tbl_list font_sm gridtype" }, [
              _vm._m(1),
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { rowspan: "2" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkAll,
                          expression: "checkAll",
                        },
                      ],
                      attrs: { type: "checkbox", name: "no", id: "All" },
                      domProps: {
                        checked: Array.isArray(_vm.checkAll)
                          ? _vm._i(_vm.checkAll, null) > -1
                          : _vm.checkAll,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.checkAll,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.checkAll = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checkAll = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checkAll = $$c
                          }
                        },
                      },
                    }),
                    _vm._m(2),
                  ]),
                  _c("th", { attrs: { rowspan: "2" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.017"))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.018")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.019")))]),
                  _c("th", { attrs: { rowspan: "2" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.020"))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.021")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.022")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.023")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.024")))]),
                ]),
                _c("tr", [
                  _c("th", { staticClass: "border_left" }, [
                    _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.025"))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.026")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.027")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.028")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.029")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.030")))]),
                ]),
              ]),
              _vm.intergisCfsList.length > 0
                ? _c(
                    "tbody",
                    [
                      _vm._l(_vm.intergisCfsList, function (item) {
                        return [
                          _c("tr", { key: item.reqRno + item.whsgNo }, [
                            _c("td", { attrs: { rowspan: "2" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.updateList,
                                    expression: "updateList",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  name: "chk",
                                  id: "chk_" + item.num,
                                },
                                domProps: {
                                  value: item.reqRno,
                                  checked: Array.isArray(_vm.updateList)
                                    ? _vm._i(_vm.updateList, item.reqRno) > -1
                                    : _vm.updateList,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.updateList,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = item.reqRno,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.updateList = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.updateList = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.updateList = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "chk_" + item.num } },
                                [
                                  _c("span", { staticClass: "offscreen" }, [
                                    _vm._v("No"),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("td", { attrs: { rowspan: "2" } }, [
                              _vm._v(_vm._s(item.num)),
                            ]),
                            _c("td", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.fnCfsRequest(
                                        item.reqRno,
                                        "UPD"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.whsgNo))]
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.podPortNm))]),
                            _c("td", { attrs: { rowspan: "2" } }, [
                              _vm._v(_vm._s(item.cstNm)),
                            ]),
                            _c(
                              "td",
                              {
                                style: {
                                  color: [
                                    _vm.$ekmtcCommon.isNotEmpty(item.qtyDtl) &&
                                    item.qtyDtl !== item.qty
                                      ? "red"
                                      : "",
                                  ],
                                },
                              },
                              [_vm._v(" " + _vm._s(item.qty) + " ")]
                            ),
                            _c("td", [_vm._v(_vm._s(item.grsCbm))]),
                            _c("td", [_vm._v(_vm._s(item.cntrNo))]),
                            _c("td", [_vm._v(_vm._s(item.bkgNo))]),
                          ]),
                          _c("tr", { key: item.reqRno + item.whsgDtm }, [
                            _c("td", { staticClass: "border_left" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeDatePattern(
                                    item.whsgDtm,
                                    "-"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm.$ekmtcCommon.isNotEmpty(item.stsCd)
                                ? _c(
                                    "span",
                                    {
                                      class: [
                                        "label",
                                        item.stsCd === "입고" ? "green" : "red",
                                        "md",
                                        "minwid60",
                                      ],
                                    },
                                    [_vm._v(_vm._s(item.stsCd))]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.wt))]),
                            _c("td", [_vm._v(_vm._s(item.capy))]),
                            _c("td", [_vm._v(_vm._s(item.sealNo1))]),
                            _c("td", [_vm._v(_vm._s(item.rmk))]),
                          ]),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "9" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSCT060T070.031"))),
                      ]),
                    ]),
                  ]),
            ]),
            _vm.intergisCfsList.length > 0
              ? _c(
                  "div",
                  { staticClass: "paging" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn start",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.searchCFSInter(1, false)
                          },
                        },
                      },
                      [_vm._v("Start")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "btn prev",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.searchCFSInter(
                              _vm.pagination.prevPage,
                              false
                            )
                          },
                        },
                      },
                      [_vm._v("Prev")]
                    ),
                    _vm._l(_vm.pageNumberRange, function (idx) {
                      return _c(
                        "a",
                        {
                          key: idx,
                          class: [idx === _vm.params.curPage ? "on" : ""],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.searchCFSInter(idx, false)
                            },
                          },
                        },
                        [_vm._v(_vm._s(idx))]
                      )
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "btn next",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.searchCFSInter(
                              _vm.pagination.nextPage,
                              false
                            )
                          },
                        },
                      },
                      [_vm._v("Next")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "btn end",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.searchCFSInter(
                              _vm.pagination.pageCnt,
                              false
                            )
                          },
                        },
                      },
                      [_vm._v("End")]
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "flex_box mt10" }, [
            _c(
              "a",
              {
                staticClass: "button lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.excelDownload("CLP_Document.xls")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.032")))]
            ),
            _vm.sessionIntergis == "1"
              ? _c(
                  "a",
                  {
                    staticClass: "button blue lg ml_auto",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnCfsRequest("", "REG")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.033")))]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "110px" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "60px" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "13%" } }),
      _c("col", { attrs: { width: "26%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "All" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("All")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }