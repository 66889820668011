var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content", attrs: { id: "send_card_area" } },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("menu.MENU09.141"))),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("p", { attrs: { align: "left" } }, [
          _c(
            "b",
            [
              _c(
                "font",
                { attrs: { face: "맑은고딕", size: "4", color: "#004080" } },
                [_vm._v("카드가 완성되었습니다. 확인하세요.")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { attrs: { id: "sendContents" } }, [
          _c("table", { staticClass: "tbl_row" }, [
            _c("tr", [
              _c("td", { staticStyle: { border: "0" } }, [
                _c("img", {
                  staticStyle: {
                    border: "#b4b4b4",
                    "border-width": "1px",
                    "border-style": "solid",
                  },
                  attrs: { src: _vm.imgUrl() },
                }),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticStyle: { border: "0", "text-align": "left" } }, [
                _c("b", [_vm._v("To " + _vm._s(_vm.params.toName))]),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticStyle: { border: "0", "text-align": "left" } }, [
                _c("pre", [_vm._v(_vm._s(_vm.params.reqCont))]),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticStyle: { border: "0", "text-align": "left" } }, [
                _c("b", [_vm._v(" From " + _vm._s(_vm.params.fromName) + " ")]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 flex_box" }, [
          _c("span", { staticClass: "ml_auto" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.sendResult,
                    expression: "!sendResult",
                  },
                ],
                staticClass: "button blue lg ml5",
                on: {
                  click: function ($event) {
                    return _vm.goSendCard()
                  },
                },
              },
              [_vm._v("연하장 전송")]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.sendResult,
                    expression: "sendResult",
                  },
                ],
              },
              [
                _c("font", { attrs: { color: "red" } }, [
                  _c("b", [
                    _vm._v(
                      "총 " +
                        _vm._s(_vm.sendCnt) +
                        " 건의 " +
                        _vm._s(_vm.year) +
                        "년도 연하장 메일을 발송하였습니다. "
                    ),
                  ]),
                ]),
                _c("br"),
                _c("br"),
                _c(
                  "a",
                  {
                    staticClass: "button blue lg ml5",
                    on: {
                      click: function ($event) {
                        return _vm.goNewSendCard()
                      },
                    },
                  },
                  [_vm._v("연하장 새로 보내기")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }