<template>
  <div class="content widfix">
    <e-breadcrumbs />
    <h1 class="page_title">
      {{ $t('menu.MENU08.050') }}
    </h1>
    <div class="tab mt15">
      <ul class="tab_list">
        <li :class="tab == 'profile' ? 'on' : ''">
          <a @click="selectTab('profile')" href="javascript:void(0)">{{ $t('menu.MENU08.060') }}</a>
        </li>
        <li :class="tab == 'sch-subs' ? 'on' : ''">
          <a @click="selectTab('sch-subs')" href="javascript:void(0)">{{ $t('menu.MENU08.080') }}</a>
        </li>
      </ul>
    </div>
    <div class="tab_content_area">
      <Profile v-if="tab == 'profile'" :auth="auth" :member-detail="memberDetail" :selected-profile="selectedProfile" />
      <Schedule v-else-if="tab == 'sch-subs'" :auth="auth" :member-detail="memberDetail" />
    </div>
  </div>
</template>

<script>
import store from '@/store/index'
import Profile from './Profile'
import Schedule from './Schedule'
import { rootComputed } from '@/store/helpers'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'Setting',
  components: {
    Profile,
    Schedule,
    EBreadcrumbs
  },
  computed: {
    ...rootComputed,
    path () {
      return this.$route.path
    }
  },
  data: function () {
    return {
      tab: ''
    }
  },
  watch: {
    path: function () {
      this.selectTab(this.path.split('/')[3])
    }
  },
  created () {
    this.getTab()
  },
  methods: {
    getTab () {
      let url = window.location.href
      let urlArr = url.split('/')
      this.tab = urlArr[urlArr.length - 1]
    },
    selectTab (type) {
      this.tab = type
      this.$router.push({
        name: type
      })
    }
  }
}
</script>
