var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "550px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(
            "[" +
              _vm._s(_vm.$t("msg.ONIM070P020.002")) +
              "] " +
              _vm._s(_vm.$t("msg.MYIN010G010.021"))
          ),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("h2", { staticClass: "content_title" }),
          _c("p", { staticClass: "tit" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.022")) + " "),
          ]),
          _c("ul", [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.023")) + " ")]),
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.024")) + " ")]),
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.025")) + " ")]),
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.026")) + " ")]),
          ]),
          _c("p", { staticClass: "tit mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.027")) + " "),
          ]),
          _c("p", { staticClass: "mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.030")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.FILE_UPLOAD.011")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }