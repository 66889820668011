var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content", attrs: { id: "send_card_area" } },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("menu.MENU09.141"))),
      ]),
      _c(
        "div",
        { staticClass: "content_box mt10" },
        [
          _c("p", { attrs: { align: "left" } }, [
            _c(
              "b",
              [
                _c(
                  "font",
                  { attrs: { face: "맑은고딕", size: "4", color: "#004080" } },
                  [_vm._v("카드보내기에 필요한 정보들을 입력해주세요.")]
                ),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("font", { attrs: { color: "#CD4275" } }, [
            _vm._v(
              "* 다중전송을 하실경우에 공백없이 메일주소 뒤에 콤마( , ) 를 붙여주세요."
            ),
          ]),
          _c("br"),
          _c("font", { attrs: { color: "#CD4275" } }, [
            _vm._v(
              '* Hotmail.com 또는 한글이 지원되지 않는 해외로 메일을 전송하실경우 "보낸 이(이름)"을 영문으로 써주세요.'
            ),
          ]),
          _c("br"),
          _c("font", { attrs: { color: "#004080" } }, [
            _vm._v("* 메일 주소 기재시 소문자로 적어주세요. (예) .com .net"),
          ]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("보낸 이 (이름)")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.fromName,
                        expression: "params.fromName",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { type: "text", id: "fromName" },
                    domProps: { value: _vm.params.fromName },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "fromName", $event.target.value)
                        },
                        function ($event) {
                          return _vm.inputTextChk("fromName")
                        },
                      ],
                    },
                  }),
                ]),
                _c("th", [_vm._v("보낸 이 E-mail")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.fromEmail,
                        expression: "params.fromEmail",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { type: "text", id: "fromEmail" },
                    domProps: { value: _vm.params.fromEmail },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "fromEmail", $event.target.value)
                        },
                        function ($event) {
                          return _vm.inputTextChk("fromEmail")
                        },
                      ],
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("받을 이 (이름)")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.toName,
                        expression: "params.toName",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { type: "text", id: "toName" },
                    domProps: { value: _vm.params.toName },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "toName", $event.target.value)
                        },
                        function ($event) {
                          return _vm.inputTextChk("toName")
                        },
                      ],
                    },
                  }),
                ]),
                _c("th", [_vm._v("받을 이 E-mail")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.toEmail,
                        expression: "params.toEmail",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { type: "text", id: "toEmail" },
                    domProps: { value: _vm.params.toEmail },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "toEmail", $event.target.value)
                        },
                        function ($event) {
                          return _vm.inputTextChk("toEmail")
                        },
                      ],
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("메일제목")]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.toTitle,
                        expression: "params.toTitle",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { type: "text", id: "toTitle" },
                    domProps: { value: _vm.params.toTitle },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "toTitle", $event.target.value)
                        },
                        function ($event) {
                          return _vm.inputTextChk("toTitle")
                        },
                      ],
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "tbl_row" }, [
            _c("col", { attrs: { "aria-colspan": "4" } }),
            _c("tbody", [
              _vm._m(1),
              _c("tr", { attrs: { "aria-colspan": "4" } }, [
                _c("td", [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.reqCont,
                        expression: "params.reqCont",
                      },
                    ],
                    staticStyle: { width: "100%", height: "400px" },
                    attrs: { id: "reqCont" },
                    domProps: { value: _vm.params.reqCont },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "reqCont", $event.target.value)
                        },
                        function ($event) {
                          return _vm.inputTextChk("reqCont")
                        },
                      ],
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10 flex_box" }, [
            _c("span", { staticClass: "ml_auto" }, [
              _c(
                "a",
                {
                  staticClass: "button blue lg ml5",
                  on: {
                    click: function ($event) {
                      return _vm.samplePop()
                    },
                  },
                },
                [_vm._v("샘플 문구보기")]
              ),
              _c(
                "a",
                {
                  staticClass: "button blue lg ml5",
                  on: {
                    click: function ($event) {
                      return _vm.goNextStep()
                    },
                  },
                },
                [_vm._v("다음")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { attrs: { "aria-colspan": "4" } }, [
      _c("th", { staticStyle: { "text-align": "center" } }, [
        _vm._v("전달할 글내용"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }