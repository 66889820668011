import Send from '../common.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //vocs - 고객센터 > 고객의소리 리스트 - GET /common/vocs
  getVocList (formData) {
    return Send({
      url: '/common/vocs',
      method: 'get',
      params: {
        curPage: formData.curPage,
        pageSize: formData.pageSize,
        select: formData.select,
        searchInput: formData.searchInput
      }
    })
  },
  //vocs - 고객센터 > 고객의소리 상세 - GET /common/vocs/{cstNo}/{seq}
  getVocsDetail (data) {
    return Send({
      url: '/common/vocs/' + data.cstNo + '/' + data.seq,
      method: 'get',
      params: {
      }
    })
  },
  //vocs - 고객센터 > 고객의소리 문의등록 - POST /common/vocs/voc
  insertVocs (data) {
    return Send({
      url: '/common/vocs/insert-voc',
      method: 'post',
      data: data
    }).catch((err) => {
      console.log(err)
    })
  },
  //vocs - 고객의소리 코드 가져오기 - GET /common/vocs/code -- 미사용
  getVocCode () {
    return Send({
      url: '/common/vocs/code',
      method: 'get',
      params: {
      }
    })
  },
  //findCommonCode - 공통코드 가져오기 - GET /commons/codes/common
  getCode (formData) {
    return Send({
      url: '/commons/codes/common',
      method: 'get',
      params: {
        cdId: formData.cdId,
        arrCdId: formData.arrCdId || ''
      }
    })
  },
  //ctrCode - 유저국가코드 가져오기 - GET /common/vocs/ctrCdCode
  getCtrCode () {
    return Send({
      url: '/common/vocs/ctrCdCode',
      method: 'get',
      params: {
      }
    })
  }
}
