<template>
  <div id="report_sub_pop" class="content">
    <div id="tab3" class="tab_content_area">
      <div class="content_box beforetab">
        <h2 class="content_title">{{ $t("msg.MYIN020T010.066") }}</h2>
        <tableau-viz id="tableauViz" :src="url" token="" height="800" width="1200">
          <viz-parameter name="CST_NO" :value="cstCd"></viz-parameter>
          <viz-parameter name="POR_CTR_CD_PARAMETER" :value="ctrCd"></viz-parameter>
          <custom-parameter name=":tooltip" value="no"></custom-parameter>
        </tableau-viz>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import jwt from 'jsonwebtoken'

export default {
  name: 'ReportDashboard',
  data () {
    return {
      customComponent: null,
      selectFunc: null,
      url: 'https://tableau.ekmtc.com/views/231213_/_',
      cstCd: this.getCstCd(),
      ctrCd: this.getCtrCd()
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.setToken()
  },
  mounted () {
    let viz = document.getElementById('tableauViz')
    viz.token = this.$store.state.user.tableauToken
    viz.toolbar = 'hidden'
  },
  methods: {
    getCstCd () {
      return this.$store.state.auth.loginInfo.cstCd
    },
    getCtrCd () {
      return this.$store.state.auth.loginInfo.userCtrCd
    },
    setToken () {
      var token = this.$store.state.user.tableauToken
      if (this.$ekmtcCommon.isEmpty(token)) {
        var newToken = this.getToken()
        this.$store.commit('user/updateToken', newToken)
      } else {
        let flag = this.verifyJWT(token)
        if (!flag) {
          newToken = this.getToken()
          this.$store.commit('user/updateToken', newToken)
        }
      }
    },
    getToken () {
      const appClientId = this.$store.state.user.appClientId
      const appSecretKey = this.$store.state.user.appSecretKey
      const appSecretId = this.$store.state.user.appSecretId

      const token = jwt.sign({
        iss: appClientId,
        exp: Math.round(new Date().getTime() / 1000) + (10 * 60),
        jti: new Date().getTime().toString(),
        aud: 'tableau',
        sub: 'admin',
        scp: ['tableau:views:embed']
      },
      appSecretKey,
      {
        algorithm: 'HS256',
        header: {
          kid: appSecretId,
          iss: appClientId
        }
      })

      return token
    },
    // JWT 유효성 검증 함수
    verifyJWT (token) {
      try {
        const decoded = jwt.verify(token, this.$store.state.user.appSecretKey)
        // console.log('Decoded JWT:', decoded)
        return true
      } catch (error) {
        // console.error('JWT verification failed:', error.message)
        return false
      }
    }
  }
}
</script>
