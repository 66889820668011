<template>
  <div class="content resize">
    <!-- content : 화면 리사이징 되는 화면 resize 추가 -->
    <e-breadcrumbs />
    <form id="frm" @submit.prevent>
      <win-layer-pop class="vessel-inquiry-pop">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="params"
        />
      </win-layer-pop>
      <h1 class="page_title">{{ $t('msg.CSCT060G060.001') }}</h1><!-- 환율 -->
      <div class="content_box mt10">
        <!-- content_box -->
        <table class="tbl_search">
          <colgroup>
            <col width="120px">
            <col width="150px">
            <col>
            <col width="80px">
            <col width="150px">
            <col>
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSCT060G060.002') }}</th><!-- 지역/항구 -->
              <td colspan="2">
                <div class="tbl_form">
                  <e-auto-complete-place
                    id="podPod"
                    @change="changePod"
                    :ctr-cd="params.polCtrCd"
                    :plc-cd="params.polPlcCd"
                  />
                </div>
              </td>
              <td colspan="2">
                <span>
                  <input
                    v-model="params.eiCatCd"
                    type="radio"
                    id="ex"
                    name="exim"
                    value="O"
                  />
                  <label for="ex"><span></span>{{ $t('msg.CSCT060G060.003') }}</label><!-- 수출 -->
                  <input
                    v-model="params.eiCatCd"
                    type="radio"
                    id="im"
                    name="exim"
                    value="I"
                  />
                  <label for="im"><span></span>{{ $t('msg.CSCT060G060.004') }}</label><!-- 수입 -->
                </span>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSCT060G060.005') }}</th><!-- 조회옵션 -->
              <td class="pr5">
                <select
                  v-model="params.optCd"
                  @change="changeOpt()"
                >
                  <option value="VL">{{ $t('msg.CSCT060G060.006') }}</option><!-- 선박명 -->
                  <option value="DT">{{ $t('msg.MAIN100.010') }}</option><!-- 입항일 -->
                  <option value="BL">{{ $t('msg.CSCT060G060.008') }}</option><!-- B/L No. -->
                  <option value="BK">{{ $t('msg.CSCT060G060.009') }}</option><!-- Booking No. -->
                </select>
              </td>
              <td class="pl0" colspan="4">
                <!-- 선박명(VL) 선택일때 s -->
                <div v-show="params.optCd === 'VL'">
                  <input
                    v-model="params.vslNm"
                    id="vessel"
                    type="text"
                    class="wid150"
                    @click.prevent="openPopup('vessel-inquiry-pop')"
                    :placeholder="$t('msg.CSCT060G060.028')"
                  >
                  <button
                    type="button"
                    class="tbl_icon search ml2"
                    @click="openPopup('vessel-inquiry-pop')"
                  >
                    검색
                  </button>
                  <input
                    v-model="params.voyNo"
                    id="voyNo"
                    type="text"
                    class="wid150 ml2"
                    :placeholder="$t('msg.CSCT060G060.029')"
                    @keyup="fnValidationVoyNo()"
                  />
                  <input type="text" id="_temp" style="display:none;" /><!-- Enter 클릭시 submit 방지용 -->
                </div>
                <!-- 선박명(VL) 선택일때 e// -->
                <!-- 출항일 선택일때 s -->
                <div v-show="params.optCd === 'DT'">
                  <e-date-range-picker
                    id="rvsdDt"
                    class="ul_ml0"
                    :sdate="params.startdate"
                    :edate="params.enddate"
                    :is-debug="true"
                    @change="changeDateRange"
                  />
                </div>
                <!-- 출항일 선택일때 e// -->
                <!-- B/L No.(BL) 선택일때 s -->
                <div v-show="params.optCd === 'BL'">
                  <input
                    id="blNo"
                    v-model="params.blNo"
                    type="text"
                    class="wid150"
                    placeholder="B/L No."
                    @keyup="fnValidationBlNo()"
                  />
                </div>
                <!-- B/L No.(BL) 선택일때 e// -->
                <!-- Booking No.(BK) 선택일때 s -->
                <div v-show="params.optCd === 'BK'">
                  <input
                    id="bkgNo"
                    v-model="params.bkgNo"
                    type="text"
                    class="wid150"
                    placeholder="Booking No."
                    @keyup="fnValidationBkgNo()"
                  />
                </div>
                <!-- Booking No.(BK) 선택일때 e// -->
              </td>
              <td class="text_right">
                <a
                  class="button blue sh"
                  href="#"
                  @click.prevent="getExchangeRateList(1, true)"
                >{{ $t('msg.CSCT060G060.010') }}</a><!-- 검색 -->
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->

      <div class="content_box mt10">
        <!-- content_box -->
        <div class="flex_box">
          <span class="tot_num">{{ $t('msg.CSCT060G060.011') }} : <span class="num">{{ listCountNumber }}</span></span>
          <span class="ml_auto cal_btn">
            <a
              v-print="'#frm'"
              class="button sm ml2"
              href="#"
            >{{ $t('msg.CSCT060G060.012') }}</a><!-- Print -->
          </span>
        </div>

        <table class="tbl_col">
          <colgroup>
            <col width="30%">
            <col width="10%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <!-- <col width="14%"> -->
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSCT060G060.013') }}</th><!-- 선명 -->
              <th>{{ $t('msg.CSCT060G060.031') }}</th><!-- 항차 -->
              <th>{{ $t('msg.CSCT060G060.015') }}</th><!-- 현지 화폐단위 -->
              <th>{{ $t('msg.CSCT060G060.016') }}</th><!-- 미화 환율 -->
              <th>{{ $t('msg.CSCT060G060.030') }}</th><!-- 환율 적용일 -->
              <!-- <th>{{ $t('msg.CSCT060G060.018') }}</th>입항 예정일 -->
            </tr>
          </thead>
          <tbody v-if="exchangeRateList.length > 0">
            <tr
              v-for="(item, idx) in exchangeRateList"
              :key="item.voyNo + '_' + item.ttSllRt + '_' + idx"
            >
              <td>{{ item.vslNm }}</td>
              <td>{{ item.voyNo }}</td>
              <td>{{ item.locCur }}</td>
              <td>{{ item.ttSllRt }}</td>
              <td>{{ $ekmtcCommon.changeDatePattern(item.rvsdEndpDt, '-') }}</td>
              <!-- <td>test</td> -->
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">{{ $t('msg.CSCT060G060.019') }}</td><!-- 등록된 내용이 없습니다. -->
            </tr>
          </tbody>
        </table>
        <p class="txt_desc" v-html="$t('msg.CSCT060G060.020') "></p><!-- 입항일 기준으로 환율이 달라질 수 있습니다. -->

        <div
          v-if="exchangeRateList.length > 0"
          class="paging"
        >
          <!-- paging -->
          <a
            href="#"
            class="btn start"
            @click.prevent="getExchangeRateList(1, false)"
          >Start</a>
          <a
            href="#"
            class="btn prev"
            @click.prevent="getExchangeRateList(pagination.prevPage, false)"
          >Prev</a>
          <a
            v-for="idx in pageNumberRange"
            :key="idx"
            :class="[idx === params.curPage ? 'on' : '']"
            href="#"
            @click.prevent="getExchangeRateList(idx, false)"
          >{{ idx }}</a>
          <a
            href="#"
            class="btn next"
            @click.prevent="getExchangeRateList(pagination.nextPage, false)"
          >Next</a>
          <a
            href="#"
            class="btn end"
            @click.prevent="getExchangeRateList(pagination.pageCnt, false)"
          >End</a>
        </div>
      </div><!-- content_box // -->
    </form>
  </div><!-- content // -->
</template>

<script>

import ExchangeRate from '@/api/rest/common/exchangeRate'
import print from 'vue-print-nb'

import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'ExchangeRate',
  directives: {
    print
  },
  components: {
    EBreadcrumbs,
    'e-date-range-picker': () => import('@/components/common/EDateRangePicker'),
    'e-auto-complete-place': () => import('@/components/common/EAutoCompletePlace'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'vessel-inquiry-pop': () => import('@/pages/common/popup/VesselInquiryPop')
  },
  data () {
    return {
      // Vessel Inquiry 조회 팝업 컴포넌트
      customComponent: null,
      // 최초 API(환율 리스트) 호출여부
      isInit: false,
      params: {
        // 현재 페이지
        curPage: 1,
        // 한 페이지당 게시글 수
        pageSize: 10,

        optCd: 'VL',
        eiCatCd: 'O',
        vslCd: '',
        voyNo: '',
        startdate: '',
        enddate: '',
        polCtrCd: '',
        polPlcCd: '',
        blNo: '',
        bkgNo: ''

        // test1
        // optCd: 'VL',
        // eiCatCd: 'O',
        // vslCd: 'AEP1',
        // vslNm: 'ASIA EXPRESS',
        // voyNo: '221E',
        // startdate: '',
        // enddate: '',
        // polCtrCd: 'JP',
        // polPlcCd: 'THS',
        // blNo: '',
        // bkgNo: ''

        // test2
        // optCd: 'VL',
        // eiCatCd: 'O',
        // vslCd: 'JERD',
        // vslNm: 'EVER RADIANT',
        // voyNo: '055W',
        // startdate: '',
        // enddate: '',
        // polCtrCd: 'LK',
        // polPlcCd: 'CMB',
        // blNo: '',
        // bkgNo: ''
      },
      // Total(전체 글 개수)
      listCountNumber: 0,
      // 조회 리스트
      exchangeRateList: [],
      // 페이징 모듈
      pagination: {}
    }
  },
  computed: {
    pageNumberRange: function () {
      return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    }
  },
  created () {},
  methods: {
    changePod (vo) {
      this.params.polCtrCd = vo.ctrCd
      this.params.polPlcCd = vo.plcCd
      this.fnValidationPod()
    },
    changeVessel (vo) {
      this.params.vslCd = vo.cdNm
      this.params.vslNm = vo.cd
      this.fnValidationVessel()
    },
    changeDateRange (sdt, edt) {
      this.params.startdate = sdt
      this.params.enddate = edt
      this.fnValidationRvsdDt()
    },
    /**
     * param
     * |_ index : 이동할 페이지
     * |_ searcgFlag : 검색유무
     *          true  => 검색 시
     *          false => 페이지 이동
     */
    getExchangeRateList (index, searchFlag) {
      if (this.fnValidationAll()) {
        if (this.isInit && index < 1) {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.002')) // 처음페이지입니다.
          return
        }
        if (this.isInit && this.pagination && index > this.pagination.pageCnt) {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.001')) // 마지막페이지입니다.
          return
        }

        // !searchFlag(페이지 이동 시) 현재 페이지와 이동할 페이지 같다면
        if (!searchFlag && this.isInit && this.params.curPage === index) return

        this.params.curPage = index

        const formData = JSON.parse(JSON.stringify(this.params))

        ExchangeRate.exchangeRateList(formData)
          .then((response) => {
            const resp = response.data

            const list = resp.exchangeRateList
            const pagination = resp.pagination

            this.exchangeRateList = []
            this.listCountNumber = 0

            if (list !== undefined && list.length > 0) {
              // api response 값 data에 세팅
              this.pagination = pagination

              //전체 글 개수
              this.listCountNumber = list[0].listCnt

              this.exchangeRateList = list

              if (!this.isInit) this.isInit = true
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    // 팝업 열기
    openPopup (cmpNm) {
      this.customComponent = cmpNm
      this.$ekmtcCommon.layerOpen('.vessel-inquiry-pop')
    },
    // 팝업 닫기
    closePopup (vo) {
      console.log('vo')
      console.log(vo)
      if (vo !== undefined) {
        this.params.vslCd = vo.vslCd
        this.params.vslNm = vo.vslNm
      }

      this.customComponent = null
      this.$ekmtcCommon.layerClose('.vessel-inquiry-pop')

      this.fnValidationVessel()
    },
    // '지역/항구' validation check
    fnValidationPod (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelectorAll('#podPod')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.polCtrCd) || this.$ekmtcCommon.isEmpty(this.params.polPlcCd)) {
        msg = this.$t('msg.CSCT060G060.021') // 지역/항구를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 선택옵션-선박명(VL) > '선박명' validation check
    fnValidationVessel (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelectorAll('#vessel')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.vslNm)) {
        msg = this.$t('msg.CSCT060G060.022') // 선박명을 선택해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 선택옵션-선박명(VL) > '항차번호' validation check
    fnValidationVoyNo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelectorAll('#voyNo')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.voyNo)) {
        msg = this.$t('msg.CSCT060G060.023') // 항차 번호를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 선택옵션-출항일(DT) > '출항일' validation check
    fnValidationRvsdDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelectorAll('#rvsdDt')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.startdate) || this.$ekmtcCommon.isEmpty(this.params.enddate)) {
        if (this.params.eiCatCd === 'O') {
          msg = this.$t('msg.CSCT060G060.024') // 출항일을 선택해 주세요.
        } else {
          msg = this.$t('msg.CSCT060G060.027') // 입항일을 선택해 주세요.
        }
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 선택옵션-B/L No.(BL) > 'B/L No.' validation check
    fnValidationBlNo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelectorAll('#blNo')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.blNo)) {
        msg = this.$t('msg.CSCT060G060.025') // B/L No.를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 선택옵션-Booking No.(BK) > 'Booking No.' validation check
    fnValidationBkgNo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elem = frm.querySelectorAll('#bkgNo')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.bkgNo)) {
        msg = this.$t('msg.CSCT060G060.026') // Booking No.를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    fnValidationAll (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      let isAllOk = true
      const optCd = this.params.optCd

      if (!this.fnValidationPod(frm)) {
        isAllOk = false
      }

      if (optCd === 'VL') {
        if (!this.fnValidationVessel(frm)) {
          isAllOk = false
        } else {
          if (!this.fnValidationVoyNo(frm)) {
            isAllOk = false
          }
        }
      } else if (optCd === 'DT') {
        if (!this.fnValidationRvsdDt(frm)) {
          isAllOk = false
        }
      } else if (optCd === 'BL') {
        if (!this.fnValidationBlNo(frm)) {
          isAllOk = false
        }
      } else if (optCd === 'BK') {
        if (!this.fnValidationBkgNo(frm)) {
          isAllOk = false
        }
      } else {
        isAllOk = false
      }

      return isAllOk
    },
    changeOpt () {
      const frm = document.querySelector('#frm')

      const elemPod = frm.querySelectorAll('#podPod')
      const elemVsl = frm.querySelectorAll('#vessel')
      // const elemVoy = frm.querySelectorAll('#voyNo')
      const elemRvsdDt = frm.querySelectorAll('#rvsdDt')
      const elemBlNo = frm.querySelectorAll('#blNo')
      const elemBkgNo = frm.querySelectorAll('#bkgNo')

      this.$ekmtcCommon.hideErrorTooltip(elemPod)
      this.$ekmtcCommon.hideErrorTooltip(elemVsl)
      // this.$ekmtcCommon.hideErrorTooltip(elemVoy)
      this.$ekmtcCommon.hideErrorTooltip(elemRvsdDt)
      this.$ekmtcCommon.hideErrorTooltip(elemBlNo)
      this.$ekmtcCommon.hideErrorTooltip(elemBkgNo)
    }
  }
}
</script>
