var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.MAIN100.172"))),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("table", { staticClass: "tbl_row" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.173")))]),
              _c("td", [_vm._v(_vm._s(_vm.param.reqUno))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.174")))]),
              _c("td", [_vm._v(_vm._s(_vm.param.usrCtrCd))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.175")))]),
              _c("td", [_vm._v(_vm._s(_vm.userTelNo))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.202")))]),
              _c("td", [_vm._v(_vm._s(_vm.param.usrEmlAddr))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.176")))]),
              _c("td", [_vm._v(_vm._s(_vm.param.contKndCd))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.206")))]),
              _vm.param.repYn !== "Y"
                ? _c("td", [
                    _c("span", { staticClass: "label red md" }, [
                      _vm._v(_vm._s(_vm.$t("msg.MAIN100.207"))),
                    ]),
                  ])
                : _c("td", [
                    _c("span", { staticClass: "label green md" }, [
                      _vm._v(_vm._s(_vm.$t("msg.MAIN100.208"))),
                    ]),
                  ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.210")))]),
              _c("td", { attrs: { colspan: "3" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "min-height": "200px",
                      "word-break": "break-all",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.param.rmk) + " ")]
                ),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.211")))]),
              _vm.param.cfmRmk
                ? _c("td", { attrs: { colspan: "3" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "min-height": "200px",
                          "word-break": "break-all",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.param.cfmRmk) + " ")]
                    ),
                  ])
                : _vm._e(),
              !_vm.param.cfmRmk && _vm.param.repYn === "Y"
                ? _c("td", { attrs: { colspan: "3" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "min-height": "200px",
                          "word-break": "break-all",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("msg.MAIN100.212")) + " ")]
                    ),
                  ])
                : _c("td", { attrs: { colspan: "3" } }),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex_box mt10" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$router.push({ name: "Voc" })
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("msg.MAIN100.213")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }