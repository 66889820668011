<template>
  <div class="content">
    <div class="location">
      <e-breadcrumbs />
    </div>
    <h1 class="page_title">{{ $t('menu.MENU09.020') }}</h1> <!-- Network -->
    <div class="content_box mt10">
      <div class="network_menu">
        <ul>
          <li v-for="item in ctrList" :v-model="ctrFlag" :key="item.cdNm" :value="item.seq" :id="item.seq" class="list_area">
            <a href="#" @click.prevent="[getNetworkInfo(item.seq),btnChgImg(item.seq)]">{{ item.cdNm }}</a>
          </li>
        </ul>
      </div>
      <div class="map_wrap network_photo mt20">
        <p v-if="ctrFlag === '01'" id="tab1" class="state_map"><img src="../../assets/images/csct/map_01.jpg" alt="" /></p>
        <p v-if="ctrFlag === '02'" id="tab2" class="state_map"><img src="../../assets/images/csct/map_02.jpg" alt="" /></p>
        <p v-if="ctrFlag === '03'" id="tab3" class="state_map"><img src="../../assets/images/csct/map_03.jpg" alt="" /></p>
        <p v-if="ctrFlag === '04'" id="tab4" class="state_map"><img src="../../assets/images/csct/map_05_02.png" alt="" /></p>
        <p v-if="ctrFlag === '05'" id="tab5" class="state_map"><img src="../../assets/images/csct/map_06.jpg" alt="" /></p>
        <p v-if="ctrFlag === '06'" id="tab6" class="state_map"><img src="../../assets/images/csct/map_07.jpg" alt="" /></p>
        <p v-if="ctrFlag === '07'" id="tab7" class="state_map"><img src="../../assets/images/csct/map_08.jpg" alt="" /></p>
        <p v-if="ctrFlag === '08'" id="tab8" class="state_map"><img src="../../assets/images/csct/map_09.jpg" alt="" /></p>
        <p v-if="ctrFlag === '09'" id="tab9" class="state_map"><img src="../../assets/images/csct/map_10.jpg" alt="" /></p>
        <p v-if="ctrFlag === '10'" id="tab10" class="state_map"><img src="../../assets/images/csct/map_11.jpg" alt="" /></p>
        <p v-if="ctrFlag === '11'" id="tab11" class="state_map"><img src="../../assets/images/csct/map_12.jpg" alt="" /></p>
        <p v-if="ctrFlag === '12'" id="tab12" class="state_map"><img src="../../assets/images/csct/map_13.jpg" alt="" /></p>
        <p v-if="ctrFlag === '13'" id="tab13" class="state_map"><img src="../../assets/images/csct/map_14.jpg" alt="" /></p>
        <p v-if="ctrFlag === '14'" id="tab14" class="state_map"><img src="../../assets/images/csct/map_15.jpg" alt="" /></p>
        <p v-if="ctrFlag === '15'" id="tab15" class="state_map"><img src="../../assets/images/csct/map_16.jpg" alt="" /></p>
        <p v-if="ctrFlag === '16'" id="tab16" class="state_map"><img src="../../assets/images/csct/map_17.jpg" alt="" /></p>
        <p v-if="ctrFlag === '17'" id="tab17" class="state_map"><img src="../../assets/images/csct/map_18_01.jpg" alt="" /></p>
        <p v-if="ctrFlag === '18'" id="tab18" class="state_map"><img src="../../assets/images/csct/map_19.jpg" alt="" /></p>
        <p v-if="ctrFlag === '19'" id="tab19" class="state_map"><img src="../../assets/images/csct/map_20.jpg" alt="" /></p>
        <p v-if="ctrFlag === '20'" id="tab20" class="state_map"><img src="../../assets/images/csct/map_21.jpg" alt="" /></p>
        <p v-if="ctrFlag === '21'" id="tab21" class="state_map"><img src="../../assets/images/csct/map_22.jpg" alt="" /></p>
        <p v-if="ctrFlag === '22'" id="tab22" class="state_map"><img src="../../assets/images/csct/map_23.jpg" alt="" /></p>
        <p v-if="ctrFlag === '23'" id="tab23" class="state_map"><img src="../../assets/images/csct/map_24.jpg" alt="" /></p>
        <p v-if="ctrFlag === '24'" id="tab24" class="state_map"><img src="../../assets/images/csct/map_25.png" alt="" /></p>
      </div>
      <table v-if="ctrFlag === '08'" class="tbl_col mt20">
        <colgroup>
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="20%">
        </colgroup>
        <thead>
          <tr>
            <th>AREA</th>
            <th>TEAM/NAME</th>
            <th>JOB</th>
            <th>OFFICE TEL.</th>
            <th>EMAIL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(vo, idx) in ctrInfoList" :key="idx" :v-if="vo.area !== null && vo.area !== undefined">
            <td v-if="vo.area !== null && vo.area !== undefined"
                :rowspan="[vo.area !== undefined && vo.area !== null && vo.area == 'UJUNG PANDANG'? 2 : vo.cnt]"
            >
              {{ vo.area }}
            </td>
            <td v-if="vo.area !== null && vo.area !== undefined && vo.area === 'PALEMBANG'"
                :colspan="$ekmtcCommon.isNotEmpty(vo.address) ? 5 : false"
                :rowspan="vo.area === 'PALEMBANG' ? '10' : '1'"
                v-show="$ekmtcCommon.isEmpty(vo.address) && !$ekmtcCommon.isEmpty(vo.team)"
            >
              {{ vo.team }}
            </td>
            <td v-else class="border_left" :colspan="$ekmtcCommon.isNotEmpty(vo.address) ? 5 : false">
              {{ vo.team }}
              <textarea v-show="$ekmtcCommon.isNotEmpty(vo.address)"
                        :value="vo.address"
                        name="p_descr"
                        readonly
                        :class="textAreaHtcontrol(vo.rows)"
                        style="border:0;"
              >
              </textarea>
            </td>
            <td :rowspan="vo.area === 'PALEMBANG' ? '10' : '1'" v-show="$ekmtcCommon.isEmpty(vo.address) && !$ekmtcCommon.isEmpty(vo.team)">{{ vo.job }}</td>
            <td :rowspan="vo.area === 'PALEMBANG' ? '10' : '1'" v-show="$ekmtcCommon.isEmpty(vo.address) && !$ekmtcCommon.isEmpty(vo.team)">{{ vo.officeTel }}</td>
            <td :rowspan="vo.area === 'PALEMBANG' ? '10' : '1'" v-show="$ekmtcCommon.isEmpty(vo.address) && !$ekmtcCommon.isEmpty(vo.team)">{{ vo.email }}</td>
          </tr>
        </tbody>
      </table>
      <table v-else class="tbl_col mt20">
        <colgroup>
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="20%">
        </colgroup>
        <thead>
          <tr>
            <th>AREA</th>
            <th>TEAM/NAME</th>
            <th>JOB</th>
            <th>OFFICE TEL.</th>
            <th>EMAIL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(vo, idx) in ctrInfoList" :key="idx" :v-if="vo.area !== null && vo.area !== undefined">
            <td v-if="vo.area !== null && vo.area !== undefined" :rowspan="[vo.area !== undefined && vo.area !== null ? vo.cnt : 1]">
              {{ vo.area }}
            </td>
            <td v-if="vo.area !== null && vo.area !== undefined" :colspan="$ekmtcCommon.isNotEmpty(vo.address) ? 5 : false">{{ vo.team }}</td>
            <td v-else class="border_left" :colspan="$ekmtcCommon.isNotEmpty(vo.address) ? 5 : false">
              {{ vo.team }}
              <!-- <pre class="pre" v-show="$ekmtcCommon.isNotEmpty(vo.address) ">
                {{ vo.address }}
              </pre> -->
              <textarea v-show="$ekmtcCommon.isNotEmpty(vo.address)"
                        :value="vo.address"
                        name="p_descr"
                        readonly
                        :class="textAreaHtcontrol(vo.rows)"
                        style="border:0;"
              >
              </textarea>
            </td>
            <td v-show="$ekmtcCommon.isEmpty(vo.address)">{{ vo.job }}</td>
            <td v-show="$ekmtcCommon.isEmpty(vo.address)">{{ vo.officeTel }}</td>
            <td v-show="$ekmtcCommon.isEmpty(vo.address)">{{ vo.email }}</td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
  </div>
</template>

<script>
import { network } from '@/api/network-ctrList'
import { rootComputed } from '@/store/helpers'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'Network',
  components: {
    EBreadcrumbs,
    rootComputed
  },
  data: function () {
    return {
      params: {
        ctrNm: '',
        ctrCd: ''
      },
      //국가 리스트
      ctrList: [],
      //연락처 정보 리스트
      ctrInfoKey: [],
      ctrInfoList: [],
      ctrFlag: '01',
      //홈페이지 여부 :: as-is 에서 title : 홈페이지일 경우 이미지로, e-svc일 경우 텍스트로 표시
      isHomePage: 'Y',
      showHKaddr: false
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    window.scrollTo(0, 0)
    this.init()
  },
  methods: {
    textAreaHtcontrol (data) {
      let ht = ''

      switch (data) {
        case '2':
         ht = 'height50px'
         break
        case '3':
         ht = 'height70px'
         break
        case '4':
         ht = 'height90px'
         break
        case '8':
         ht = 'height170px'
         break
      }

      return ht
    },
    async init () {
      const TH = this
      let tmpCtrCd = ''
      let tmpKey = ''

      //현재 로그인한 유저의 국가코드를 가져오기
      if (this.auth.userId) {
        const data = this.memberDetail
        tmpCtrCd = data.userCtrCd
      }

      //국가별 리스트
      TH.ctrList = await network.ctrList

      //ctrInfoList 가져오기
      TH.ctrInfoList = await network.ctrInfoList

      //연락처정보 가져오기
      if (TH.ctrInfoList && TH.ctrInfoList.length > 0) {
        for (let i = 0; i < TH.ctrInfoList.length; i++) {
          if (tmpCtrCd === TH.ctrInfoList[i].keyNm) {
            tmpKey = TH.ctrInfoList[i].key
            break
          } else {
            tmpKey = '01'
          }
        }
      }
      this.getNetworkInfo(tmpKey)
    },
    async getNetworkInfo (flag) {
      const TH = this

      //초기화
      TH.ctrInfoList = []

      //연락처 리스트
      let ctrInfoList = await network.ctrInfoList
      let length = ctrInfoList.length

      if (length > 0) {
        ctrInfoList.forEach(i => {
          if (i.key === flag) {
            TH.ctrInfoList = i.info
          }
        })
      }

      setTimeout(this.btnChgImg(flag), 1000)
    },
    btnChgImg (flag) {
      //class 바꾸기
      $('.list_area').removeClass('on')
      $('#' + flag).addClass('on')

      //img 바꾸기
      this.ctrFlag = flag
    }
  }
}
</script>

<style scoped>
.pre {
  font-family: 'Noto Sans KR', sans-serif !important;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    color: #000;
}

textarea {
  /* min-height: auto !important; */
  overflow:hidden;
}

.height50px {
  min-height: 50px !important;
}

.height70px {
  min-height: 70px !important;
}

.height90px {
  min-height: 90px !important;
}

.height170px {
  min-height: 170px !important;
}
</style>
