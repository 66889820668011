<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('menu.MENU08.020') }}</h1><!-- Report -->
    <e-tab ref="tab">
      <e-tab-item
        :title="$t('menu.MENU08.110')"
        name="report-dashboard"
        path="/my-info/report"
        :selected="true"
      >
        <report-dashboard v-if="routeName === '' || routeName === 'report-dashboard'" />
      </e-tab-item>
      <e-tab-item
        :title="$t('menu.MENU08.090')"
        name="report-list"
        path="/my-info/report"
        :selected="true"
      >
        <report-list v-if="routeName === '' || routeName === 'report-list'" />
      </e-tab-item>
      <e-tab-item
        :title="$t('menu.MENU08.100')"
        name="report-info"
        path="/my-info/report"
      >
        <report-info v-if="routeName === 'report-info'" />
      </e-tab-item>
    </e-tab>
  </div><!-- content // -->
</template>

<script>
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import ETabItem from '@/components/common/ETabItem'
import ETab from '@/components/common/ETab'

import ReportList from '@/pages/user/ReportList'
import ReportInfo from '@/pages/user/ReportInfo'
import ReportDashboard from '@/pages/user/ReportDashboard'

export default {
  name: 'ReportMain',
  components: {
    ETabItem,
    ETab,
    ReportList,
    ReportInfo,
    ReportDashboard,
    EBreadcrumbs
  },
  data () {
    return {
      routeName: ''
    }
  },
  watch: {
    $route (to, from) {
      this.routeName = to.name
      this.$refs.tab.selectTab({ name: this.$route.name })
    }
  },
  created () {
    this.routeName = this.$route.name || ''
  },
  mounted () {
    this.$refs.tab.selectTab({ name: this.$route.name })
  },
  methods: {
  }
}
</script>
