<template>
  <div>
    <!-- Original -->
    <div v-if="flag == 'Y'" class="flex_box">
      <span class="tot_num">Total : <span class="num">{{ list.length }}</span></span>
    </div>
    <div v-if="flag == 'Y'" id="realgrid1" style="width: 100%; height: 600px" />

    <!-- Destination -->
    <div v-if="flag == 'N'" class="flex_box">
      <span class="tot_num">Total : <span class="num">{{ list.length }}</span></span>
    </div>
    <div v-if="flag == 'N'" id="realgrid2" style="width: 100%; height: 600px" />
    <!-- <div
      v-if="flag == 'N' && list.length > 0"
      class="paging"
    >
      <a
        v-if="flag == 'N' && isPaging1"
        class="btn prev"
        @click.prevent="setPrevPage('D')"
      >Prev</a>
      <a>{{ currentPage2 + 1 }}/{{ totalPage2 }}</a>
      <a
        class="btn next"
        @click.prevent="setNextPage('D')"
      >Next</a>
      </div> -->
    </div>
</template>

<script>

import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'

let gridView1 = GridView
let provider1 = LocalDataProvider
let gridView2 = GridView
let provider2 = LocalDataProvider

//필드
const dynamicField1 = [
  { fieldName: 'term1', dataType: 'text' }
]
const dynamicField2 = [
  { fieldName: 'term2', dataType: 'text' }
]

export const fields1 = [
  ...dynamicField1,
  { fieldName: 'frtCdNm', dataType: 'text' },
  { fieldName: 'curCd', dataType: 'text' },
  { fieldName: 'cntrTypCd', dataType: 'text' },
  { fieldName: 'rate20', dataType: 'text' },
  { fieldName: 'rate40', dataType: 'text' },
  { fieldName: 'rateHc', dataType: 'text' },
  { fieldName: 'rate45hc', dataType: 'text' },
  { fieldName: 'blRate', dataType: 'text' },
  { fieldName: 'extrRmk', dataType: 'text' }
]

export const fields2 = [
  ...dynamicField2,
  { fieldName: 'frtCdNm', dataType: 'text' },
  { fieldName: 'curCd', dataType: 'text' },
  { fieldName: 'cntrTypCd', dataType: 'text' },
  { fieldName: 'rate20', dataType: 'text' },
  { fieldName: 'rate40', dataType: 'text' },
  { fieldName: 'rateHc', dataType: 'text' },
  { fieldName: 'rate45hc', dataType: 'text' },
  { fieldName: 'blRate', dataType: 'text' },
  { fieldName: 'extrRmk', dataType: 'text' }
]

//컬럼
const dynamicColumn1 = [
  {
    name: 'term1',
    fieldName: 'term1',
    header: { text: 'TERM' },
    width: '90'
  }
]

const dynamicColumn2 = [
  {
    name: 'term2',
    fieldName: 'term2',
    header: { text: 'TERM' },
    width: '90',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return 'DESTINATION<br>(COLLECT)'
      }
    }
  }
]

export const columns = [
  { name: 'frtCdNm', fieldName: 'frtCdNm', header: { text: 'CODE' }, width: '150' },
  { name: 'curCd', fieldName: 'curCd', header: { text: 'CURRENCY' }, width: '90' },
  { name: 'cntrTypCd', fieldName: 'cntrTypCd', header: { text: 'Container Type' }, width: '90' },
  {
    name: 'rate20',
    fieldName: 'rate20',
    header: { text: '20\'' },
    width: '80',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const value = grid.getValue(idx, 'rate20')
        return app.$ekmtcCommon.changeNumberFormat(value, { isComma: true })
      }
    }
  },
  {
    name: 'rate40',
    fieldName: 'rate40',
    header: { text: '40\'' },
    width: '80',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const value = grid.getValue(idx, 'rate40')
        return app.$ekmtcCommon.changeNumberFormat(value, { isComma: true })
      }
    }
  },
  {
    name: 'rateHc',
    fieldName: 'rateHc',
    header: { text: 'HC\'' },
    width: '80',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const value = grid.getValue(idx, 'rateHc')
        return app.$ekmtcCommon.changeNumberFormat(value, { isComma: true })
      }
    }
  },
  {
    name: 'rate45hc',
    fieldName: 'rate45hc',
    header: { text: '45\'' },
    width: '80',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const value = grid.getValue(idx, 'rate45hc')
        return app.$ekmtcCommon.changeNumberFormat(value, { isComma: true })
      }
    }
  },
  {
    name: 'blRate',
    fieldName: 'blRate',
    header: { text: 'B/L' },
    width: '80',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const value = grid.getValue(idx, 'blRate')
        return app.$ekmtcCommon.changeNumberFormat(value, { isComma: true })
      }
    }
  },
  { name: 'extrRmk', fieldName: 'extrRmk', header: { text: 'Remark' }, width: '100' }
]

const resultColumns1 = [
  ...dynamicColumn1,
  ...columns
]

const resultColumns2 = [
  ...dynamicColumn2,
  ...columns
]

//레이아웃
const dynamicLayout = [
  'term1',
  'term2'
]

export const layout = [
  'frtCdNm',
  'curCd',
  'cntrTypCd',
  {
    name: 'AMOUNT',
    direction: 'horizontal',
    items: [
      'rate20',
      'rate40',
      'rateHc',
      'rate45hc',
      'blRate'
    ],
    header: {
      text: 'AMOUNT'
    }
  },
  'extrRmk'
]

const resultLayout1 = [
  dynamicLayout[0],
  ...layout
]

const resultLayout2 = [
  dynamicLayout[1],
  ...layout
]

export default {
  props: {
    listData: {
      type: Array,
      required: false
    },
    mountFlag: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      list: this.listData,
      flag: this.mountFlag
      //isPaging1: true,
      //currentPage1: 0,
      //totalPage1: 0,
      //isPaging2: true,
      //currentPage2: 0,
      //totalPage2: 0
    }
  },
  mounted () {
    console.log('sub mounted')
    if (this.flag === 'Y') {
      //엑셀 다운로드용 JSZip 셋팅
      window.JSZip = window.JSZip || JSZip
      provider1 = new LocalDataProvider(true)
      gridView1 = new GridView('realgrid1')
      gridView1.setDataSource(provider1)

      provider1.setFields(fields1)
      gridView1.setColumnLayout(resultLayout1)
      gridView1.setColumns(resultColumns1)

      gridView1.displayOptions.fitStyle = 'fill'

      //인디케이터 없애기
      gridView1.setRowIndicator({
        visible: false
      })
      //상태바 없애기
      gridView1.setStateBar({
        visible: false
      })
      //체크바 없애기
      gridView1.setCheckBar({
        visible: false
      })
      gridView1.setEditOptions({
        editable: false,
        updatable: false
      })

      //리얼그리드 팝업 HEIGHT 조절
      $('#realgrid1').height('300px')

      //gridView1.setPaging(true, 10)
    } else {
      provider2 = new LocalDataProvider(true)
      gridView2 = new GridView('realgrid2')
      gridView2.setDataSource(provider2)

      provider2.setFields(fields2)
      gridView2.setColumnLayout(resultLayout2)
      gridView2.setColumns(resultColumns2)

      gridView2.displayOptions.fitStyle = 'fill'

      //인디케이터 없애기
      gridView2.setRowIndicator({
        visible: false
      })
      //상태바 없애기
      gridView2.setStateBar({
        visible: false
      })
      //체크바 없애기
      gridView2.setCheckBar({
        visible: false
      })
      gridView2.setEditOptions({
        editable: false,
        updatable: false
      })

      //리얼그리드 팝업 HEIGHT 조절
      $('#realgrid2').height('300px')

      //gridView2.setPaging(true, 10)
    }
  },
  methods: {
    setPrevPage: function (flag) {
      let gridView = ''
      let currentPage = 0
      if (flag === 'O') {
        gridView = gridView1
        currentPage = this.currentPage1
      } else {
        gridView = gridView2
        currentPage = this.currentPage2
      }

      gridView.setPage(currentPage - 1)

      if (flag === 'O') {
        this.currentPage1 = gridView.getPage()
      } else {
        this.currentPage2 = gridView.getPage()
      }
    },
    setNextPage: function (flag) {
      let gridView = ''
      let currentPage = 0

      if (flag === 'O') {
        gridView = gridView1
        currentPage = this.currentPage1
      } else {
        gridView = gridView2
        currentPage = this.currentPage2
      }

      gridView.setPage(currentPage + 1)

      if (flag === 'O') {
        this.currentPage1 = gridView.getPage()
      } else {
        this.currentPage2 = gridView.getPage()
      }
    },
    //엑셀 다운로드
    fnExcelDownload () {
      gridView1.exportGrid({
        type: 'excel',
        target: 'local',
        fileName: 'LocalCharge.xlsx',
        pagingAllItems: true,
        separateRows: true,
        exportGrids: [
          { grid: gridView1, sheetName: 'Origin(Prepaid)', hideColumns: ['term1'] },
          { grid: gridView2, sheetName: 'Destination(Collect)', hideColumns: ['term2'] }
        ]
      })
    }
  },
  //리스트 데이터 변동에 따른 셋팅
  watch: {
    listData () {
      this.list = this.listData
      if (this.flag === 'Y') {
        provider1.setRows(this.list)
        gridView1.setColumnProperty('term1', 'mergeRule', { criteria: 'row div ' + this.list.length })
        //this.currentPage1 = gridView1.getPage() //현재 페이지 반환
        //this.totalPage1 = gridView1.getPageCount() //전체페이지 개수 반환
      } else {
        provider2.setRows(this.list)
        gridView2.setColumnProperty('term2', 'mergeRule', { criteria: 'row div ' + this.list.length })
        //this.currentPage2 = gridView2.getPage() //현재 페이지 반환
        //this.totalPage2 = gridView2.getPageCount() //전체페이지 개수 반환
      }
    }
  }
}
</script>
