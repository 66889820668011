var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.captchYn
    ? _c("div", {}, [
        _c("div", [
          _c("div", { staticClass: "captcha-left" }, [
            _c("img", {
              staticStyle: { width: "100px" },
              attrs: {
                title: "캡차이미지",
                src: _vm.captchaImg,
                alt: "캡차이미지",
              },
            }),
          ]),
          _c("div", { staticClass: "captcha-left" }, [
            _c("img", {
              staticStyle: { width: "15px", margin: "5px" },
              attrs: {
                src: require("../../assets/images/common/icon-headphone.png"),
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.getAudio.apply(null, arguments)
                },
              },
            }),
            _c("img", {
              staticStyle: { width: "15px", margin: "5px" },
              attrs: {
                src: require("../../assets/images/common/icon_refresh.png"),
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.getImage.apply(null, arguments)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "captcha-left" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.answer,
                  expression: "answer",
                },
              ],
              staticStyle: { width: "100px" },
              attrs: { id: "answer", type: "text", value: "" },
              domProps: { value: _vm.answer },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.answer = $event.target.value
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticStyle: { "align-content": "center", height: "33px" } },
            [
              _c(
                "a",
                {
                  staticClass: "button blue sh",
                  on: { click: _vm.answerCheck },
                },
                [_vm._v("인증")]
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }