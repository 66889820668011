var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content widfix" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("menu.MENU08.050")) + " "),
      ]),
      _c("div", { staticClass: "tab mt15" }, [
        _c("ul", { staticClass: "tab_list" }, [
          _c("li", { class: _vm.tab == "profile" ? "on" : "" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.selectTab("profile")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("menu.MENU08.060")))]
            ),
          ]),
          _c("li", { class: _vm.tab == "sch-subs" ? "on" : "" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.selectTab("sch-subs")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("menu.MENU08.080")))]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "tab_content_area" },
        [
          _vm.tab == "profile"
            ? _c("Profile", {
                attrs: {
                  auth: _vm.auth,
                  "member-detail": _vm.memberDetail,
                  "selected-profile": _vm.selectedProfile,
                },
              })
            : _vm.tab == "sch-subs"
            ? _c("Schedule", {
                attrs: { auth: _vm.auth, "member-detail": _vm.memberDetail },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }