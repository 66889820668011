var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content resize" }, [
    _c("div", { staticClass: "location" }, [
      _c("ul", [
        _vm._m(0),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goHome.apply(null, arguments)
                },
              },
            },
            [_vm._v("Home")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.selectTab("1")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("menu.MENU08.000")))]
          ),
        ]),
        _c("li", [_vm._v(_vm._s(_vm.curTabName))]),
      ]),
    ]),
    _c("h1", { staticClass: "page_title" }, [
      _vm._v(_vm._s(_vm.$t("msg.CMBA100.544"))),
    ]),
    _c("div", { staticClass: "tab" }, [
      _c("ul", [
        _vm.isShowTab.tab1
          ? _c(
              "li",
              {
                staticClass: "on",
                attrs: { id: "tab1" },
                on: {
                  click: function ($event) {
                    return _vm.selectTab("1")
                  },
                },
              },
              [
                _c("a", { attrs: { href: "javascript:void(0)" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA100.547"))),
                ]),
              ]
            )
          : _vm._e(),
        _vm.isShowTab.tab2
          ? _c(
              "li",
              {
                attrs: { id: "tab2" },
                on: {
                  click: function ($event) {
                    return _vm.selectTab("2")
                  },
                },
              },
              [
                _c("a", { attrs: { href: "javascript:void(0)" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA100.548"))),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm.isShowTab.tab1
      ? _c(
          "div",
          { attrs: { id: "cnt1", name: "cnt" } },
          [
            _c("impt-bsnes-agenc-info", {
              attrs: { "param-data": _vm.paramData },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.isShowTab.tab2
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
            attrs: { id: "cnt2", name: "cnt" },
          },
          [
            _c("impt-bsnes-agenc-info-list", {
              attrs: { "param-data": _vm.paramData },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { staticClass: "home", attrs: { href: "#" } }, [_vm._v("home")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }