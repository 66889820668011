var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "location" }, [_c("e-breadcrumbs")], 1),
    _c("h1", { staticClass: "page_title" }, [
      _vm._v(_vm._s(_vm.$t("menu.MENU09.020"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content_box mt10" }, [
      _c("div", { staticClass: "network_menu" }, [
        _c(
          "ul",
          _vm._l(_vm.ctrList, function (item) {
            return _c(
              "li",
              {
                key: item.cdNm,
                staticClass: "list_area",
                attrs: {
                  "v-model": _vm.ctrFlag,
                  value: item.seq,
                  id: item.seq,
                },
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        ;[_vm.getNetworkInfo(item.seq), _vm.btnChgImg(item.seq)]
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.cdNm))]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "map_wrap network_photo mt20" }, [
        _vm.ctrFlag === "01"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab1" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_01.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "02"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab2" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_02.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "03"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab3" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_03.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "04"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab4" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_05_02.png"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "05"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab5" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_06.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "06"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab6" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_07.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "07"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab7" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_08.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "08"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab8" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_09.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "09"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab9" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_10.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "10"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab10" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_11.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "11"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab11" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_12.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "12"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab12" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_13.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "13"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab13" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_14.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "14"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab14" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_15.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "15"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab15" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_16.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "16"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab16" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_17.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "17"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab17" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_18_01.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "18"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab18" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_19.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "19"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab19" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_20.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "20"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab20" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_21.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "21"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab21" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_22.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "22"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab22" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_23.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "23"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab23" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_24.jpg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.ctrFlag === "24"
          ? _c("p", { staticClass: "state_map", attrs: { id: "tab24" } }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/csct/map_25.png"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
      ]),
      _vm.ctrFlag === "08"
        ? _c("table", { staticClass: "tbl_col mt20" }, [
            _vm._m(0),
            _vm._m(1),
            _c(
              "tbody",
              _vm._l(_vm.ctrInfoList, function (vo, idx) {
                return _c(
                  "tr",
                  {
                    key: idx,
                    attrs: {
                      "v-if": vo.area !== null && vo.area !== undefined,
                    },
                  },
                  [
                    vo.area !== null && vo.area !== undefined
                      ? _c(
                          "td",
                          {
                            attrs: {
                              rowspan: [
                                vo.area !== undefined &&
                                vo.area !== null &&
                                vo.area == "UJUNG PANDANG"
                                  ? 2
                                  : vo.cnt,
                              ],
                            },
                          },
                          [_vm._v(" " + _vm._s(vo.area) + " ")]
                        )
                      : _vm._e(),
                    vo.area !== null &&
                    vo.area !== undefined &&
                    vo.area === "PALEMBANG"
                      ? _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$ekmtcCommon.isEmpty(vo.address) &&
                                  !_vm.$ekmtcCommon.isEmpty(vo.team),
                                expression:
                                  "$ekmtcCommon.isEmpty(vo.address) && !$ekmtcCommon.isEmpty(vo.team)",
                              },
                            ],
                            attrs: {
                              colspan: _vm.$ekmtcCommon.isNotEmpty(vo.address)
                                ? 5
                                : false,
                              rowspan: vo.area === "PALEMBANG" ? "10" : "1",
                            },
                          },
                          [_vm._v(" " + _vm._s(vo.team) + " ")]
                        )
                      : _c(
                          "td",
                          {
                            staticClass: "border_left",
                            attrs: {
                              colspan: _vm.$ekmtcCommon.isNotEmpty(vo.address)
                                ? 5
                                : false,
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(vo.team) + " "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$ekmtcCommon.isNotEmpty(
                                    vo.address
                                  ),
                                  expression:
                                    "$ekmtcCommon.isNotEmpty(vo.address)",
                                },
                              ],
                              class: _vm.textAreaHtcontrol(vo.rows),
                              staticStyle: { border: "0" },
                              attrs: { name: "p_descr", readonly: "" },
                              domProps: { value: vo.address },
                            }),
                          ]
                        ),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.$ekmtcCommon.isEmpty(vo.address) &&
                              !_vm.$ekmtcCommon.isEmpty(vo.team),
                            expression:
                              "$ekmtcCommon.isEmpty(vo.address) && !$ekmtcCommon.isEmpty(vo.team)",
                          },
                        ],
                        attrs: {
                          rowspan: vo.area === "PALEMBANG" ? "10" : "1",
                        },
                      },
                      [_vm._v(_vm._s(vo.job))]
                    ),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.$ekmtcCommon.isEmpty(vo.address) &&
                              !_vm.$ekmtcCommon.isEmpty(vo.team),
                            expression:
                              "$ekmtcCommon.isEmpty(vo.address) && !$ekmtcCommon.isEmpty(vo.team)",
                          },
                        ],
                        attrs: {
                          rowspan: vo.area === "PALEMBANG" ? "10" : "1",
                        },
                      },
                      [_vm._v(_vm._s(vo.officeTel))]
                    ),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.$ekmtcCommon.isEmpty(vo.address) &&
                              !_vm.$ekmtcCommon.isEmpty(vo.team),
                            expression:
                              "$ekmtcCommon.isEmpty(vo.address) && !$ekmtcCommon.isEmpty(vo.team)",
                          },
                        ],
                        attrs: {
                          rowspan: vo.area === "PALEMBANG" ? "10" : "1",
                        },
                      },
                      [_vm._v(_vm._s(vo.email))]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _c("table", { staticClass: "tbl_col mt20" }, [
            _vm._m(2),
            _vm._m(3),
            _c(
              "tbody",
              _vm._l(_vm.ctrInfoList, function (vo, idx) {
                return _c(
                  "tr",
                  {
                    key: idx,
                    attrs: {
                      "v-if": vo.area !== null && vo.area !== undefined,
                    },
                  },
                  [
                    vo.area !== null && vo.area !== undefined
                      ? _c(
                          "td",
                          {
                            attrs: {
                              rowspan: [
                                vo.area !== undefined && vo.area !== null
                                  ? vo.cnt
                                  : 1,
                              ],
                            },
                          },
                          [_vm._v(" " + _vm._s(vo.area) + " ")]
                        )
                      : _vm._e(),
                    vo.area !== null && vo.area !== undefined
                      ? _c(
                          "td",
                          {
                            attrs: {
                              colspan: _vm.$ekmtcCommon.isNotEmpty(vo.address)
                                ? 5
                                : false,
                            },
                          },
                          [_vm._v(_vm._s(vo.team))]
                        )
                      : _c(
                          "td",
                          {
                            staticClass: "border_left",
                            attrs: {
                              colspan: _vm.$ekmtcCommon.isNotEmpty(vo.address)
                                ? 5
                                : false,
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(vo.team) + " "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$ekmtcCommon.isNotEmpty(
                                    vo.address
                                  ),
                                  expression:
                                    "$ekmtcCommon.isNotEmpty(vo.address)",
                                },
                              ],
                              class: _vm.textAreaHtcontrol(vo.rows),
                              staticStyle: { border: "0" },
                              attrs: { name: "p_descr", readonly: "" },
                              domProps: { value: vo.address },
                            }),
                          ]
                        ),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$ekmtcCommon.isEmpty(vo.address),
                            expression: "$ekmtcCommon.isEmpty(vo.address)",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(vo.job))]
                    ),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$ekmtcCommon.isEmpty(vo.address),
                            expression: "$ekmtcCommon.isEmpty(vo.address)",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(vo.officeTel))]
                    ),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$ekmtcCommon.isEmpty(vo.address),
                            expression: "$ekmtcCommon.isEmpty(vo.address)",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(vo.email))]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("AREA")]),
        _c("th", [_vm._v("TEAM/NAME")]),
        _c("th", [_vm._v("JOB")]),
        _c("th", [_vm._v("OFFICE TEL.")]),
        _c("th", [_vm._v("EMAIL")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("AREA")]),
        _c("th", [_vm._v("TEAM/NAME")]),
        _c("th", [_vm._v("JOB")]),
        _c("th", [_vm._v("OFFICE TEL.")]),
        _c("th", [_vm._v("EMAIL")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }