var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "550px", height: "650px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _c("span", { staticClass: "color_red" }, [
            _vm._v("[" + _vm._s(_vm.$t("msg.MYIN010G010.017")) + "] "),
          ]),
          _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.018")) + " "),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("h2", { staticClass: "content_title" }),
          _c("p", [_vm._v(" " + _vm._s(_vm.$t("approval.001")) + " ")]),
          _c("p", { staticClass: "tit mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.002")) + " "),
          ]),
          _c("ul", [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.003")) + " ")]),
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.004")) + " ")]),
          ]),
          _c("p", { staticClass: "tit mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.005")) + " "),
          ]),
          _c("ul", [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.006")) + " ")]),
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.007")) + " ")]),
          ]),
          _c("p", { staticClass: "tit mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.008")) + " "),
          ]),
          _c("ul", [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.009")) + " ")]),
          ]),
          _c("p", { staticClass: "tit mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.010")) + " "),
          ]),
          _c("ul", [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.011")) + " ")]),
          ]),
          _c("p", { staticClass: "mt20" }, [
            _vm._v(" " + _vm._s(_vm.$t("approval.032")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.FILE_UPLOAD.011")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }