<template>
  <div class="popup_wrap" style="width:550px;">
    <!-- 팝업사이즈 참고 : 500*235  popup_wrap -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">[{{ $t('msg.ONIM070P020.002') }}] {{ $t('msg.MYIN010G010.021') }}</h1>
      <div class="content_box">
        <!-- content_box -->
        <h2 class="content_title"></h2>
        <!-- <textarea class="hei200" v-model="parentInfo.content"></textarea> -->
        <p class="tit">
          {{ $t('approval.022') }}
          <!-- 1. 마케팅 정보 수신 동의시 아래와 같은 다양한 서비스 및 혜택 정보를 이메일, 문자메시지 등을 통하여 보내드립니다. -->
        </p>
        <ul>
          <li>
            {{ $t('approval.023') }}
            <!-- 고객 관심 구간 등 데이터 기반 맞춤 서비스 제공 -->
          </li>
          <li>
            {{ $t('approval.024') }}
            <!-- 뉴스레터, 특가 운임 정보, 신규 항로, 서비스, 기능 등 소식 제공 -->
          </li>
          <li>
            {{ $t('approval.025') }}
            <!-- 할인, 쿠폰, 추가 적립행사, 프로모션 등 이벤트 안내 -->
          </li>
          <li>
            {{ $t('approval.026') }}
            <!-- 혜택, 각종 이벤트 정보 등 광고성 정보 -->
          </li>
        </ul>
        <p class="tit mt20">
          {{ $t('approval.027') }}
          <!-- 2. 고객님은 마케팅 정보 수신 동의 이후에라도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용할 수 있으나, 특가 운임 정보, 혜택 등 당사의 마케팅 정보를 수신하지 못할 수 있습니다. -->
        </p>
        <p class="mt20">
          {{ $t('approval.030') }}
          <!-- 위 마케팅 정보 수신에 동의합니다. (선택) -->
        </p>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <!-- <a class="button lg" href="#">
          <span></span>동의</a> -->
        <!-- <p class="button lg">
          <input type="checkbox"
                 @change="$emit('agreement', clickAgreement, gubun)"
                 id="checkAgreement" name="checkAgreement"
                 v-model="clickAgreement"
                 true-value="Y"
                 false-value="N"
          />
          <label for="checkAgreement"><span></span>동의</label>
        </p>-->
        <a class="button gray lg"
           @click="$emit('close')"
        >{{ $t('common.FILE_UPLOAD.011') }}</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>

export default {
  name: 'PersonalInfoAgree',
  props: {
    parentInfo: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      param: {
        portCd: ''
      },
      contents: '',
      clickAgreement: this.parentInfo.recvCnstYn,
      gubun: 'MMA'
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted: function () {
    const $vm = this
    //조회
    $vm.searchData()
  },
  methods: {
    searchData: function () {
    }
  }
}
</script>

<style scoped>
</style>
