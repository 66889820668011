<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('msg.MAIN100.172') }}</h1><!-- 고객의 소리 -->
    <!-- 검색 박스 -->
    <div class="content_box mt10">
      <div class="mt5">
        <select
          v-model="param.select"
          class="wid150"
        >
          <option
            v-for="category in categoryList"
            :key="category.cd"
            :value="category.cd"
          >
            {{ category.cdNm }}
          </option>
        </select>
        <input
          v-model="param.searchInput"
          type="text"
          class="wid300"
          @keyup.enter="search()"
        >
        <a
          class="button blue sh ml15"
          @click="search()"
        >{{ $t('msg.CMBA100.528') }}</a> <!-- 검색 -->
      </div>
    </div>

    <div class="content_box mt10">
      <!-- 글 개수 카운트 -->
      <div class="flex_box">
        <span class="tot_num">
          {{ $t('msg.CMBA100.530') }} : <span class="num">{{ listCountNumber }}</span></span>
      </div>

      <!-- 글 리스트 -->
      <table class="tbl_col">
        <colgroup>
          <col width="6%">
          <col width="18%">
          <col width="12%">
          <col width="12%">
          <col width="12%">
          <col width="18%">
          <col width="12%">
          <col width="10%">
        </colgroup>
        <thead>
          <tr>
            <th>No</th> <!-- No -->
            <th>{{ $t('msg.MAIN100.176') }}</th> <!-- 제안 및 불만 -->
            <th>{{ $t('msg.MAIN100.173') }}</th> <!-- 작성자 -->
            <th>{{ $t('msg.MAIN100.174') }}</th> <!-- 작성지역 -->
            <th>{{ $t('msg.MAIN100.175') }}</th> <!-- 전화번호 -->
            <th>{{ $t('msg.MAIN100.204') }}</th> <!-- 이메일 -->
            <th>{{ $t('msg.MAIN100.205') }}</th> <!-- 등록일 -->
            <th>{{ $t('msg.MAIN100.206') }}</th> <!-- 상태 -->
          </tr>
        </thead>
        <tbody v-if="vocList.length > 0">
          <tr v-for="voc in vocList" :key="voc.cstNo + voc.seq">
            <td>{{ voc.num }}</td>
            <td>
              <a href="#"
                 @click.prevent="$router.push(
                   { name: 'VocDetail',
                     params: {
                       cstNo: voc.cstNo,
                       cstUno: voc.cstUno,
                       seq: voc.seq
                     }
                   })"
              >
                {{ voc.contKndCd }}</a>
            </td>
            <td>{{ voc.reqUno }}</td>
            <td>{{ voc.usrCtrCd }}</td>
            <td>{{ voc.picTelOfcNo + '-' + voc.picTelNo }}</td>
            <td>{{ voc.usrEmlAddr }}</td>
            <td>{{ voc.fstEntDtm }}</td>
            <td v-if="voc.repYn !== 'Y'">
              <span class="label red md">{{ $t('msg.MAIN100.207') }}</span>
            </td>
            <td v-else>
              <span class="label green md">{{ $t('msg.MAIN100.208') }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">
              {{ $t('msg.CMBA100.00131') }}
            </td> <!-- 등록된 내용이 없습니다. -->
          </tr>
        </tbody>
      </table>

      <!-- paging -->
      <div
        v-if="vocList.length > 0"
        class="paging"
      >
        <a
          class="btn start"
          @click.prevent="getVocList(1, false)"
        >Start</a>
        <a
          class="btn prev"
          @click.prevent="getVocList(pagination.prevPage, false)"
        >Prev</a>
        <a
          v-for="idx in pageNumberRange"
          :key="idx"
          :class="[idx === param.curPage ? 'on' : '']"
          href="#"
          @click.prevent="getVocList(idx, false)"
        >{{ idx }}</a>
        <a
          class="btn next"
          @click.prevent="getVocList(pagination.nextPage, false)"
        >Next</a>
        <a
          class="btn end"
          @click.prevent="getVocList(pagination.pageCnt, false)"
        >End</a>
      </div>
      <!-- paging // -->
    </div>
    <!-- 목록 버튼 -->
    <div class="flex_box mt10">
      <button
        class="button blue lg ml_auto"
        type="button"
        @click.prevent="$router.push({ name: 'VocReg' })"
      >
        {{ $t('msg.MAIN100.209') }}
      </button><!-- 문의하기 -->
    </div>
  </div>
</template>

<script>
import vocs from '@/api/services/vocs'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'Vocs',
  components: {
    EBreadcrumbs
  },
  data: function () {
    return {
      isInit: false,
      param: {
        curPage: 1, // 현재 페이지
        pageSize: 15, // 한 페이지당 게시글 수
        select: 'all',
        searchInput: '',
        serviceCtrCd: ''
      },

      categoryList: [],
      ctrList: [],

      listCountNumber: 0,
      vocList: [],
      pagination: {}
    }
  },
  computed: {
    pageNumberRange: function () {
        return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    }
  },
  created () {
    this.initCommCodes()
    this.getVocList(1)
  },
  methods: {
    async initCommCodes () {
      // 접속국가 가져오기
      this.param.serviceCtrCd = localStorage.getItem('service_ctrcd')

      const cdId = 'SA023'
      await Promise.all([
        //1. 카테고리 리스트 가져오기
        vocs.getCode({ cdId: cdId }).then(response => {
            this.categoryList = [{ cd: 'all', cdNm: this.$t('msg.PERS010L022.002') }]

            if (response.status === 200) {
            this.categoryList = [...this.categoryList, ...response.data]
          }
        })
        .catch(err => {
          console.log(err)
        }),
        //2. 유저국가리스트 가져오기
        vocs.getCtrCode().then(res => {
          if (res.status === 200) {
            this.ctrList = [...this.ctrList, ...res.data]
          }
        })
      ])
    },
    search () {
      this.getVocList(1, true)
    },
    /**
     * param
     * |_ index : 이동할 페이지
     * |_ searcgFlag : 검색유무
     *          true  => 검색 시
     *          false => 페이지 이동
     */
    getVocList (index, searchFlag) {
      const alertOpt = {
        alertType: 'simple',
        customCloseBtnText: this.$t('msg.CMBA100.546'), // 닫기
        useConfirmBtn: false
      }

      if (this.isInit && index < 1) {
        this.$ekmtcCommon.alert({
          ...alertOpt, message: this.$t('js.common.002') // 처음페이지입니다.
        })
        return
      }
      if (this.isInit && this.pagination && index > this.pagination.pageCnt) {
        this.$ekmtcCommon.alert({
          ...alertOpt, message: this.$t('js.common.001') // 마지막페이지입니다.
        })
        return
      }

      // !searchFlag(페이지 이동 시) 현재 페이지와 이동할 페이지 같다면
      if (!searchFlag && this.isInit && this.param.curPage === index) return

      this.param.curPage = index

      vocs.getVocList(this.param).then((response) => {
        const resp = response.data
        const list = resp.vocList
        const pagination = resp.pagination

        this.vocList = []

        if (list !== undefined) {
          this.pagination = pagination

          //전체 글 개수
          this.listCountNumber = list.length === 0 ? 0 : list[0].listCnt

          list.forEach((item) => {
            item.contKndCd = this.getCategoryName(item.contKndCd)
            item.usrCtrCd = this.getCountryName(item.usrCtrCd)
            item.fstEntDtm = this.changeDatePattern(item.fstEntDtm, '-')
            item.picTelNo = item.picTelNo.substring(0, 3) + '-' + item.picTelNo.substring(3)

            this.vocList.push(item)
          })

          if (!this.isInit) this.isInit = true
        }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          console.log(this.vocList)
        })
    },
    // 카테고리 명 가져오기
    getCategoryName (cd) {
      let catNm = '카테고리 X'
      if (this.$ekmtcCommon.isNotEmpty(cd)) {
          this.categoryList.forEach((item) => {
            if (item.cd === cd) {
                catNm = item.cdNm
            }
        })
      }
      return catNm
    },
    // 국가 명 가져오기
    getCountryName (ctrCd) {
      let ctrEnm = '국가명 X'
      if (this.$ekmtcCommon.isNotEmpty(ctrCd)) {
          this.ctrList.forEach((item) => {
            if (item.ctrCd === ctrCd) {
                ctrEnm = item.ctrEnm
            }
        })
      }
      return ctrEnm
    },
    changeDatePattern (strDate, char) {
      let result = ''
      if (this.$ekmtcCommon.isEmpty(strDate)) {
        return ''
      }

      if (this.$ekmtcCommon.isEmpty(char)) {
        char = '.'
      }

      if (char !== 'KO') {
        if (strDate !== null && strDate.length >= 12) {
          result += strDate.substring(0, 4) + char + strDate.substring(4, 6) + char + strDate.substring(6, 8)
        } else if (strDate !== null && strDate.length >= 8) {
          result += strDate.substring(0, 4) + char + strDate.substring(4, 6) + char + strDate.substring(6, 8)
        } else if (strDate !== null && strDate.length() >= 6) {
          result += strDate.substring(0, 4) + char + strDate.substring(4, 6)
        }
      } else {
        if (strDate !== null && strDate.length >= 12) {
          result += strDate.substring(0, 4) + '년' + strDate.substring(4, 6) + '월' + strDate.substring(6, 8) + '일'
          result += ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
        } else if (strDate !== null && strDate.length >= 8) {
          result += strDate.substring(0, 4) + '년' + strDate.substring(4, 6) + '월' + strDate.substring(6, 8) + '일'
        } else if (strDate !== null && strDate.length() >= 6) {
          result += strDate.substring(0, 4) + '년' + strDate.substring(4, 6) + '월'
        }
      }

      return result
    }
  }
}
</script>

<style scoped>

</style>
