<template>
  <div class="content resize"><!-- content : 화면 리사이징 되는 화면 resize 추가 -->
    <form id="frm">
      <e-breadcrumbs />
      <win-layer-pop class="intergis-cfs-image-detail-pop">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="popupParams"
        />
      </win-layer-pop>
      <h1 class="page_title">{{ $t('msg.CSCT060T090.001') }}</h1><!-- 인터지스 CFS 사진조회 -->
      <div class="content_box mt10"><!-- content_box -->
        <table class="tbl_search">
          <colgroup>
            <col width="110px">
            <col width="150px">
            <col>
            <col width="100px">
            <col width="150px">
            <col>
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSCT060T090.002') }}</th><!-- 작업일 -->
              <td class="pl0" colspan="5">
                <e-date-range-picker
                  id="oprtDt"
                  :sdate="params.fromDt"
                  :edate="params.toDt"
                  :is-debug="true"
                  @change="changeDateRange"
                />
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSCT060T090.003') }}</th><!-- Container No. -->
              <td colspan="2">
                <input
                  v-model="params.cntrNo"
                  type="text"
                >
              </td>
              <td colspan="2">

              </td>
              <td class="text_right">
                <a
                  class="button blue sh"
                  href="#"
                  @click.prevent="searchCFSInterImg(1, true)"
                >{{ $t('msg.CSCT060T090.004') }}</a><!-- 검색 -->
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->

      <div class="content_box mt10"><!-- content_box -->
        <div class="flex_box">
            <span class="tot_num">{{ $t('msg.CSCT060T090.005') }} : <span class="num">{{ listCountNumber }}</span></span>
        </div>

        <table class="tbl_list font_sm gridtype">
          <colgroup>
            <col width="33%">
            <col width="33%">
            <col width="34%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSCT060T090.006') }}</th><!-- 입고일자 -->
              <th>{{ $t('msg.CSCT060T090.007') }}</th><!-- 컨테이너 번호 -->
              <th>{{ $t('msg.CSCT060T090.008') }}</th><!-- 사진 -->
            </tr>
          </thead>
          <tbody v-if="intergisCfsImgList.length > 0">
            <tr
              v-for="intergisCfsImg in intergisCfsImgList"
              :key="intergisCfsImg.oprtDt + '_' + intergisCfsImg.cntrNo"
            >
              <td>{{ $ekmtcCommon.changeDatePattern(intergisCfsImg.oprtDt, '-') }}</td>
              <td>{{ intergisCfsImg.cntrNo }}</td>
              <td>
                <a
                  class="button sm"
                  href="#"
                  @click.prevent="fnCfsImgPop(intergisCfsImg)"
                >{{ $t('msg.CSCT060T090.009') }}</a><!-- 보기 -->
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">{{ $t('msg.CSCT060T090.010') }}</td><!-- 등록된 내용이 없습니다. -->
            </tr>
          </tbody>
        </table>

        <div
          v-if="intergisCfsImgList.length > 0"
          class="paging"
        ><!-- paging -->
          <a
            href="#"
            class="btn start"
            @click.prevent="searchCFSInterImg(1, false)"
          >Start</a>
          <a
            href="#"
            class="btn prev"
            @click.prevent="searchCFSInterImg(pagination.prevPage, false)"
          >Prev</a>
          <a
            v-for="idx in pageNumberRange"
            :key="idx"
            :class="[idx === params.curPage ? 'on' : '']"
            href="#"
            @click.prevent="searchCFSInterImg(idx, false)"
          >{{ idx }}</a>
          <a
            href="#"
            class="btn next"
            @click.prevent="searchCFSInterImg(pagination.nextPage, false)"
          >Next</a>
          <a
            href="#"
            class="btn end"
            @click.prevent="searchCFSInterImg(pagination.pageCnt, false)"
          >End</a>
        </div>
      </div><!-- content_box // -->
    </form>
  </div><!-- content // -->
</template>

<script>

import intergis from '@/api/rest/common/intergis'

import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'IntergisCfsImage',
  components: {
    EBreadcrumbs,
    'e-date-range-picker': () => import('@/components/common/EDateRangePicker'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'intergis-cfs-image-detail-pop': () => import('@/pages/common/popup/IntergisCfsImageDetailPop')
  },
  data: function () {
    return {
      // 인터지스 CFS 입고 등록 팝업 컴포넌트
      customComponent: null,
      // 최초 API(팝업관리 리스트) 호출여부
      isInit: false,

      // 검색조건
      params: {
        // 현재 페이지
        curPage: 1,
        // 한 페이지당 게시글 수
        pageSize: 10,

        // 작업일
        fromDt: '',
        toDt: '',
        // test
        // fromDt: '2020.01.01',
        // toDt: '2020.01.31',
        // Container No.
        cntrNo: '',
        // Booking No.
        bkgNo: ''
      },
      // Total(전체 글 개수)
      listCountNumber: 0,
      // 조회 리스트
      intergisCfsImgList: [],
      // 페이징 모듈
      pagination: {},
      // 등록/수정 팝업에 필요한 파라미터
      popupParams: {}
    }
  },
  computed: {
    pageNumberRange: function () {
      return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    }
  },
  created () {
    this.searchCFSInterImg(1, true)
  },
  methods: {
    changeDateRange (sdt, edt) {
      this.params.fromDt = sdt
      this.params.toDt = edt
      this.fnValidationOprtDt()
    },
    /**
     * param
     * |_ index : 이동할 페이지
     * |_ searcgFlag : 검색유무
     *          true  => 검색 시
     *          false => 페이지 이동
     */
    searchCFSInterImg (index, searchFlag) {
      if (this.fnValidationOprtDt()) {
        if (this.isInit && index < 1) {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.002')) // 처음페이지입니다.
          return
        }
        if (this.isInit && this.pagination && index > this.pagination.pageCnt) {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.001')) // 마지막페이지입니다.
          return
        }

        // !searchFlag(페이지 이동 시) 현재 페이지와 이동할 페이지 같다면
        if (!searchFlag && this.isInit && this.params.curPage === index) return

        this.params.curPage = index

        const formData = JSON.parse(JSON.stringify(this.params))

        if (formData.fromDt !== '') {
          formData.fromDt = formData.fromDt.replaceAll('.', '')
        }
        if (formData.toDt !== '') {
          formData.toDt = formData.toDt.replaceAll('.', '')
        }

        intergis.searchCFSInterImg(formData)
          .then((response) => {
            const resp = response.data

            const list = resp.cfsImgInfoList
            const pagination = resp.pagination

            this.intergisCfsImgList = []
            this.listCountNumber = 0

            if (list !== undefined && list.length > 0) {
              // api response 값 data에 세팅
              this.pagination = pagination

              //전체 글 개수
              this.listCountNumber = list[0].listCnt

              this.intergisCfsImgList = list

              if (!this.isInit) this.isInit = true
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    // fnCfsImgPop (oprtDt, cntrNo) {
    fnCfsImgPop (data) {
      this.popupParams = {
        oprtDt: data.oprtDt,
        cntrNo: data.cntrNo
      }

      this.openPopup('intergis-cfs-image-detail-pop')
    },
    // 미리보기 팝업 열기
    openPopup (cmpNm) {
      this.customComponent = cmpNm
      this.$ekmtcCommon.layerOpen('.intergis-cfs-image-detail-pop')
    },
    // 미리보기 팝업 닫기
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.intergis-cfs-image-detail-pop')
    },
    fnValidationOprtDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elemOprtDt = frm.querySelector('#oprtDt')
      let isOk = true

      if (this.$ekmtcCommon.isEmpty(this.params.fromDt) || this.$ekmtcCommon.isEmpty(this.params.toDt)) {
        let msg = this.$t('msg.CSCT060T070.038') // 작업일자를 선택하세요.
        this.$ekmtcCommon.showErrorTooltip(elemOprtDt, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemOprtDt)
      }

      return isOk
    }
  }
}
</script>
