var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.CSCT060G040.001"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("table", { staticClass: "tbl_search" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G040.002")))]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("e-auto-complete-place", {
                    attrs: { placeholder: "항구, 국가 입력(영어)" },
                    on: { change: _vm.changePol },
                    model: {
                      value: _vm.formData.podCtrCd,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "podCtrCd", $$v)
                      },
                      expression: "formData.podCtrCd",
                    },
                  }),
                ],
                1
              ),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G040.003")))]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("e-auto-complete-place", {
                    attrs: { placeholder: "항구, 국가 입력(영어)" },
                    on: { change: _vm.changePod },
                    model: {
                      value: _vm.formData.podCtrCd,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "podCtrCd", $$v)
                      },
                      expression: "formData.podCtrCd",
                    },
                  }),
                ],
                1
              ),
              _c("td", { staticClass: "text_right" }),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G040.004")))]),
              _vm._v(" "),
              _c(
                "td",
                { attrs: { colspan: "3" } },
                [
                  _vm._l(_vm.cntrTypeList, function (cntrType) {
                    return [
                      _c("input", {
                        key: cntrType.cd,
                        attrs: {
                          type: "checkbox",
                          id: cntrType.cd,
                          name: cntrType.cd,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.fnCntrTypeCheck(cntrType.cd)
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          key: "label_" + cntrType.cd,
                          staticClass: "mr20",
                          attrs: { for: cntrType.cd },
                        },
                        [_c("span"), _vm._v(_vm._s(cntrType.cd))]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c("td", { staticClass: "text_right" }, [
                _c(
                  "a",
                  {
                    staticClass: "button blue sh",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnSearch.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00120")))]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "content_box mt10" },
        [
          _c("div", { staticClass: "text_right mb8" }, [
            _c(
              "a",
              {
                staticClass: "button sm",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.fnExcelDownload.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CSCT060G040.005")))]
            ),
          ]),
          _c("local-charge-sub", {
            ref: "sub",
            attrs: {
              "list-data": _vm.originLocalChargeList,
              "mount-flag": _vm.oFlag,
            },
          }),
          _c("br"),
          _c("local-charge-sub", {
            attrs: {
              "list-data": _vm.destinationLocalChargeList,
              "mount-flag": _vm.dFlag,
            },
          }),
        ],
        1
      ),
      _c("ul", { staticClass: "bul_list_sm lh_short" }, [
        _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G040.006")))]),
        _vm._v(" "),
        _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G040.007")))]),
        _vm._v(" "),
        _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G040.008")))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130px" } }),
      _c("col"),
      _c("col", { attrs: { width: "150px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }