import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  getMrnMsnCallInfo (data) {
    return Send({
      url: '/trans/mrn-msn',
      method: 'get',
      params: {
        blNo: data.blNo,
        ownYn: data.ownYn,
        kind: data.kind,
        dtKnd: data.dtKnd,
        startDt: data.startDt,
        endDt: data.endDt,
        portcond: data.portcond,
        eiCatCd: data.eiCatCd,
        voyNo: data.voyNo,
        vslCd: data.vslCd,
        vslNm: data.vslNm,
        podPortCd: data.podPortCd,
        polPortCd: data.polPortCd
      }
    })
  }
}
