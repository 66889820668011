var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content", attrs: { id: "report_sub_pop" } },
    [
      _c("div", { staticClass: "tab_content_area", attrs: { id: "tab3" } }, [
        _c(
          "div",
          { staticClass: "content_box beforetab" },
          [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.MYIN020T010.066"))),
            ]),
            _c(
              "tableau-viz",
              {
                attrs: {
                  id: "tableauViz",
                  src: _vm.url,
                  token: "",
                  height: "800",
                  width: "1200",
                },
              },
              [
                _c("viz-parameter", {
                  attrs: { name: "CST_NO", value: _vm.cstCd },
                }),
                _c("viz-parameter", {
                  attrs: { name: "POR_CTR_CD_PARAMETER", value: _vm.ctrCd },
                }),
                _c("custom-parameter", {
                  attrs: { name: ":tooltip", value: "no" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }