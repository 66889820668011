var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("menu.MENU09.141"))),
      ]),
      _c(
        "table",
        {
          staticClass: "tbl_col",
          attrs: {
            width: "100%",
            cellpadding: "0",
            cellspacing: "0",
            align: "center",
          },
        },
        [
          _c("tr", { attrs: { height: "15" } }, [
            _c(
              "td",
              {
                staticStyle: {
                  "border-left-width": "0px",
                  "border-right-width": "0px",
                  "text-align": "left",
                },
                attrs: { colspan: "4" },
              },
              [
                _c("p", { attrs: { align: "left" } }, [
                  _c(
                    "b",
                    [
                      _c(
                        "font",
                        {
                          attrs: {
                            face: "맑은고딕",
                            size: "4",
                            color: "#004080",
                          },
                        },
                        [_vm._v("보내고자 하는 카드를 선택하세요.")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _vm._m(0),
          _c("tr", { attrs: { height: "25" } }, [
            _c(
              "td",
              { attrs: { align: "center" } },
              [
                _c(
                  "font",
                  { attrs: { face: "맑은고딕", size: "3", color: "#282828" } },
                  [
                    _c("b", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cardCd,
                            expression: "cardCd",
                          },
                        ],
                        attrs: {
                          id: "pic1",
                          type: "radio",
                          name: "R1",
                          value: "kmtc2024_001",
                          checked: "",
                        },
                        domProps: {
                          checked: _vm._q(_vm.cardCd, "kmtc2024_001"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.cardCd = "kmtc2024_001"
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "pic1" } }, [
                        _vm._v("카드1"),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "td",
              { attrs: { align: "center" } },
              [
                _c(
                  "font",
                  { attrs: { face: "맑은고딕", size: "3", color: "#282828" } },
                  [
                    _c("b", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cardCd,
                            expression: "cardCd",
                          },
                        ],
                        attrs: {
                          id: "pic2",
                          type: "radio",
                          name: "R1",
                          value: "kmtc2024_002",
                        },
                        domProps: {
                          checked: _vm._q(_vm.cardCd, "kmtc2024_002"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.cardCd = "kmtc2024_002"
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "pic2" } }, [
                        _vm._v("카드2"),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "mt10 flex_box" }, [
        _c("span", { staticClass: "ml_auto" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg ml5",
              on: {
                click: function ($event) {
                  return _vm.goNextStep()
                },
              },
            },
            [_vm._v("다음")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { attrs: { valign: "middle" } }, [
      _c(
        "td",
        {
          staticStyle: { "border-bottom-width": "0px" },
          attrs: { width: "50%", align: "center" },
        },
        [
          _c("img", {
            attrs: {
              src: "https://www.ekmtc.com/img/kmtc2024_001.jpg",
              width: "500",
              height: "350",
            },
          }),
        ]
      ),
      _c(
        "td",
        {
          staticStyle: { "border-bottom-width": "0px" },
          attrs: { width: "50%", align: "center" },
        },
        [
          _c("img", {
            attrs: {
              src: "https://www.ekmtc.com/img/kmtc2024_002.jpg",
              width: "500",
              height: "350",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }