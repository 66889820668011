<template>
  <div class="popup_wrap" style="width:500px;">
    <!-- popup_wrap : style="width:500px; height:490px;" 참고용, 개발시 제거 -->
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.CMBA100.0103') }}</h1>

      <div class="content_box mt10">
        <!-- content_box -->
        <table class="tbl_row">
          <colgroup>
            <col style="width:30%">
            <col style="width:auto">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">{{ $t('msg.CMBA100.0105') }}</th>
              <td>
                <input type="text" v-model="params.userPwd">
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.CMBA100.0117') }}</th>
              <td>
                <input type="text" v-model="params.userNewPwd1">
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.CMBA100.0118') }}</th>
              <td>
                <input type="text" v-model="params.userNewPwd">
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button blue lg" @click.prevent="savePwd()">{{ $t('msg.MYIN010G010.005') }}</a>
        <a class="button gray lg" @click.prevent="$emit('close')">{{ $t('msg.MYIN010G010.022') }}</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import member from '@/api/rest/user/member'

export default {
  name: 'MyInfoPasswordPop',
  props: {
    parentInfo: {
      type: Object
      }
  },
  data () {
    return {
      params: {
        userId: '',
        userPwd: '',
        userNewPwd: '',
        userNewPwd1: '',
        checklFlag: 'Y'
      }
    }
  },
  mounted () {
    console.log('mounted!')
    console.log(this.parentInfo.userId)
    this.params.userId = this.parentInfo.userId
  },
  methods: {
    closeFun () {
      this.$Simplert.close()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: '확인',
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    savePwd () {
      console.log(this.params)
      if (this.params.userNewPwd === '' || this.params.userNewPwd1 === '' || this.params.userPwd === '') {
        this.openAlert(this.$t('art.CMATK233')) // 비밀번호를 입력하세요.
      } else if (this.params.userNewPwd !== this.params.userNewPwd1) {
        this.openAlert(this.$t('art.CMATK237')) // 새 비밀번호와 비밀번호 확인이 일치하지 않습니다.
      } else {
        member.passwordChange(this.params)
           .then(res => {
              console.log(res)
              // if (res.status === 200) {
                if (res.data > 0) {
                  this.openAlert(this.$t('art.CMATK239')) // 비밀번호가 성공적으로 변경되었습니다.
                  this.$emit('close')

                  this.params.userPwd = ''
                  this.params.userNewPwd = ''
                  this.params.userNewPwd1 = ''
                } else {
                  this.openAlert(this.$t('art.CMATK240')) // 비밀번호 변경이 실패하였습니다.
                }
              // }
              // } else {
              //   console.log(res.status)
              // }
           })
      }
    }
  }

}
</script>
