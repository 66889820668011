<template>
  <div class="portTerminalCy content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('msg.VOSD100_M5.105') }}</h1> <!-- Port/Terminal/CY -->
    <div class="content_box mt10"><!-- content_box -->
      <table class="tbl_search">
        <colgroup>
          <col width="80px">
          <col>
          <col width="60px">
          <col>
          <col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.VOSD100_M5.100') }}</th> <!-- Area -->
            <td>
              <!-- <e-auto-complete-place @change="changePort" v-model="formData.ctrCd" plaholder="항구, 국가 입력(영어)"/> -->
              <e-auto-complete-place @change="changePort" v-model="formData.ctrCd" :plaholder="$t('msg.VOSD100_M5.108')"/>
            </td>
            <td class="text_right">
              <a class="button blue sh ml_auto" href=" #" @click.prevent="fnSearch">{{ $t('msg.VOSD100_M5.107') }}</a> <!-- 검색 -->
            </td>
          </tr>
        </tbody>
      </table>

    </div><!-- content_box // -->

    <div class="content_box mt10"><!-- content_box -->

      <p>
        {{ currentTimeVO.plcNm }}
        <br v-if="currentTimeVO.plcNm"/>
        {{ $t('msg.VOSD100_M5.064') }} : {{ changeDateTime2Display(currentTimeVO.localDtm, lang) }} ({{ currentTimeVO.tmf }}{{ $t('msg.VOSD100_M5.065') }})
      </p>

      <!-- Terminal Info -->
      <table class="tbl_col mt10" v-if="terminalInfo1VO && terminalInfo1VO.length > 0">
        <colgroup>
          <col width="24%">
          <col width="46%">
          <col width="30%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.VOSD100_M5.102') }}</th> <!-- Terminal Info -->
            <th>{{ $t('msg.VOSD100_M5.066') }}</th> <!-- 주소 -->
            <th>{{ $t('msg.VOSD100_M5.067') }}</th> <!-- 홈페이지 -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(terminal, index) in terminalInfo1VO" :key="index">
            <td class="text_left">{{ terminal.cyEnm }}({{ terminal.cyCd }})</td> <!-- Terminal Info -->
            <td class="text_left"> <!-- 주소 -->
              {{ terminal.cyAddr }}
              <span class="ml5" v-if="terminal.cyAddr">
                <button class="tbl_icon place" @click="openPopup('port-location-pop', terminal)">
                  {{ $t('msg.VOSD100_M5.066') }}
                </button>
              </span>
            </td>
            <td class="text_left"><a class="td_underline" :href="terminal.hmpgUrlAddr" target="_black">{{ terminal.hmpgUrlAddr }}</a></td>
          </tr>
        </tbody>
      </table>
      <!-- 20220325 부산신항 추가 -->
      <table class="tbl_col mt10" v-if="terminalInfo2VO && terminalInfo2VO.length > 0">
        <colgroup>
          <col width="24%">
          <col width="46%">
          <col width="30%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.VOSD100_M5.102') }}</th> <!-- Terminal Info -->
            <th>{{ $t('msg.VOSD100_M5.066') }}</th> <!-- 주소 -->
            <th>{{ $t('msg.VOSD100_M5.067') }}</th> <!-- 홈페이지 -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(terminal, index) in terminalInfo2VO" :key="index">
            <td class="text_left">{{ terminal.cyEnm }}({{ terminal.cyCd }})</td> <!-- Terminal Info -->
            <td class="text_left"> <!-- 주소 -->
              {{ terminal.cyAddr }}
              <span class="ml5" v-if="terminal.cyAddr">
                <button class="tbl_icon place" @click="openPopup('port-location-pop', terminal)">
                  {{ $t('msg.VOSD100_M5.066') }}
                </button>
              </span>
            </td>
            <td class="text_left"><a class="td_underline" :href="terminal.hmpgUrlAddr" target="_black">{{ terminal.hmpgUrlAddr }}</a></td>
          </tr>
        </tbody>
      </table>

      <!-- ICD Info -->
      <table class="tbl_col mt10" v-if="icdInfoVO && icdNm">
        <colgroup>
          <col width="24%">
          <col width="46%">
          <col width="30%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.VOSD100_M5.103') }}</th> <!-- ICD Info -->
            <th>{{ $t('msg.VOSD100_M5.066') }}</th> <!-- 주소 -->
            <th>{{ $t('msg.VOSD100_M5.067') }}</th> <!-- 홈페이지 -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text_left">{{ icdNm }}</td>
            <template v-if="icdInfoVO && icdInfoVO.length > 0">
              <template v-for="(icd, index) in icdInfoVO">
                <td class="text_left" :key="index + '_addr'"> <!-- 주소 -->
                  {{ icd.cyAddr }}
                  <span class="ml5" v-if="icd.cyAddr">
                    <button class="tbl_icon place" @click="openPopup('port-location-pop', icd)">
                      {{ $t('msg.VOSD100_M5.066') }} <!-- 주소 -->
                    </button>
                  </span>
                </td>
                <td class="text_left" :key="index + '_hmpgAddr'"><a class="td_underline" :href="icd.hmpgUrlAddr" target="_black">{{ icd.hmpgUrlAddr }}</a></td>
              </template>
            </template>
            <template v-else>
              <td></td>
              <td></td>
            </template>
          </tr>
        </tbody>
      </table>

      <table class="tbl_col mt10">
        <colgroup>
          <col width="24%">
          <col width="46%">
          <col width="30%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.VOSD100_M5.104') }}</th> <!-- KMTC Agent -->
            <th>{{ $t('msg.VOSD100_M5.066') }}</th> <!-- 주소 -->
            <th>{{ $t('msg.VOSD100_M5.068') }}</th> <!-- 연락처 -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(agent, index) in agentList" :key="index">
            <td class="text_left">{{ agent.orgNm }}</td>
            <td class="text_left">
              {{ agent.subAddr }}
            </td>
            <td class="text_left">
              {{ agent.telNo }}
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
    <win-layer-pop class="popup_dim_normal">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parentInfo="parentInfoPort"
      />
    </win-layer-pop>
  </div><!-- content // -->
</template>

<script>
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'

export default {
  name: 'PortTerminalCy',
  components: {
    EBreadcrumbs,
    EAutoCompletePlace,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'port-location-pop': () => import('@/pages/schedule/popup/PortLocationPop')
  },
  data () {
    return {
      icdNm: '',
      formData: {
        ctrCd: '',
        asPortCd: '',
        asSvcLang: '01',
        icdPlcCd: ''
      },
      currentTimeVO: {},
      regionInfoVO: {},
      terminalInfo1VO: [],
      terminalInfo2VO: [],
      icdInfoVO: {},
      customComponent: null,
      lang: 'KOR',
      parentInfoPort: {},
      icdList: [],
      agentList: []
    }
  },
  created () {
    if (this.auth !== undefined) {
      this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
    }
  },
  methods: {
    //국가,포트 변경시 값 셋팅(자동완성)
    changePort (vo) {
      const def = {
        ctrCd: '',
        plcCd: ''
      }
      const tvo = { ...def, ...vo }

      this.formData.ctrCd = tvo.ctrCd
      this.formData.asPortCd = tvo.plcCd
      this.formData.plcCatCd = tvo.plcCatCd
      this.formData.plcEnm = tvo.plcEnm
      // console.log(vo)
      //해당 국가코드에 매칭되는 ICD 리스트(셀렉트박스) 셋팅
      if (this.formData.ctrCd) {
        vslScheduleInfo.getIcdList(this.formData.ctrCd).then((result) => {
          this.icdList = result.data
        })
      }
      if (this.formData.ctrCd) {
        this.fnSearch()
      }
    },
    //검색
    fnSearch () {
      if (!this.formData.ctrCd) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.VOSD100_M5.123'))
        return
      }

      vslScheduleInfo.getPortTerminalCY(this.formData).then((rtnData) => {
        // console.log('######### [PortTerminalCY] getPortTerminalCY #########')
        // console.log(rtnData)
        this.currentTimeVO = rtnData.data.currentTimeVO
        this.regionInfoVO = rtnData.data.regionInfoVO
        this.icdInfoVO = rtnData.data.icdInfoVO //ICD가 있는 경우 ICD로 활성화
        this.agentList = rtnData.data.agentVO
        //ICD 네임 셋팅
        this.icdNm = this.formData.plcEnm

        // 부산신항 추가
        if (this.formData.asPortCd === 'PUS') {
          this.terminalInfo1VO = rtnData.data.terminalInfo1VO.filter(item => {
            return item.plcCd === 'PUS'
          })
          this.terminalInfo2VO = rtnData.data.terminalInfo1VO.filter(item => {
            return item.plcCd !== 'PUS'
          })
        } else if (this.formData.asPortCd === 'HKG') {
          this.terminalInfo1VO = rtnData.data.terminalInfo1VO.filter(item => {
            return item.plcCd === 'HKG'
          })
          this.terminalInfo2VO = rtnData.data.terminalInfo1VO.filter(item => {
            return item.plcCd !== 'HKG'
          })
        } else {
          this.terminalInfo1VO = rtnData.data.terminalInfo1VO //터미널이 있는 경우 터미널로 활성화
          this.terminalInfo2VO = []
        }
      })
    },
    //팝업 열기
    openPopup (name, terminalInfo) {
      this.parentInfoPort.catCd = 'S'
      this.parentInfoPort.portCd = this.currentTimeVO.plcCd
      this.parentInfoPort.lttd = terminalInfo.lttdNm || 0
      this.parentInfoPort.lngtd = terminalInfo.lngtdNm || 0

      this.customComponent = name
      this.$ekmtcCommon.layerOpen('.portTerminalCy .popup_dim')
    },
    //팝업 닫기
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.portTerminalCy .popup_dim')
    },
    //현지시간 및 시차 셋팅
    changeDateTime2Display (strDate, lang) {
      var rtnStrDate = ''
      if (!strDate) {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          const engCalNm = [
                      ['01', '.Jan.'],
                      ['02', '.Feb.'],
                      ['03', '.Mar.'],
                      ['04', '.Apr.'],
                      ['05', '.May.'],
                      ['06', '.Jun.'],
                      ['07', '.Jul.'],
                      ['08', '.Aug.'],
                      ['09', '.Sep.'],
                      ['10', '.Oct.'],
                      ['11', '.Nov.'],
                      ['12', '.Dec.']
                  ]

          //month에 따른 영문 month명 셋팅
          engCalNm.map((item) => {
            if (strDate.substring(4, 6) === item[0]) {
              rtnStrDate = strDate.substring(0, 4) + item[1] + strDate.substring(6, 8)
            }
          })
        } else {
          if (strDate) {
            rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
          }
        }
        if (rtnStrDate) {
          rtnStrDate = rtnStrDate + ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
        }
      }
      return rtnStrDate
    }
  }
}
</script>
