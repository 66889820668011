import Send from '../../user.client.js'

export default {
    agencyInfoList (params) {
        return Send({
            url: '/user/proxy',
            method: 'get',
            params: params
        })
    },
    getAgencyInfo (params) {
        return Send({
            url: '/user/proxy/detail',
            method: 'get',
            params: params
        })
    },
    eicatList (param) {
        return Send({
            url: '/user/proxy/eicat',
            method: 'get',
            params: {
            }
        })
    },
    saveAgencyInfo (data) {
        return Send({
          url: '/user/proxy',
          method: 'put',
          data: data
        })
    },
    agencySecondTabList (params) {
        return Send({
            url: '/user/agency/list',
            method: 'get',
            params: params
        })
    }
}
