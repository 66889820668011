var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.MAIN100.209"))),
      ]),
      _c("form", { attrs: { id: "frm" } }, [
        _c("div", { staticClass: "content_box mt10" }, [
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.173")))]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.reqUno,
                        expression: "params.reqUno",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "inputUno",
                      name: "inputUno",
                      value: "",
                    },
                    domProps: { value: _vm.params.reqUno },
                    on: {
                      keyup: function ($event) {
                        return _vm.checkValiUser()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "reqUno", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.174")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.usrCtrCd,
                          expression: "params.usrCtrCd",
                        },
                      ],
                      attrs: { name: "selectCtrCd", id: "selectCtrCd" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "usrCtrCd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.checkValiPlac()
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.ctrList, function (ctr) {
                      return _c(
                        "option",
                        { key: ctr.ctrCd, domProps: { value: ctr.ctrCd } },
                        [_vm._v(_vm._s(ctr.ctrEnm))]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.175")))]),
                _c(
                  "td",
                  [
                    _c("e-input-number", {
                      attrs: { id: "inputTel", name: "inputTel" },
                      on: {
                        keyup: function ($event) {
                          return _vm.checkValiTel()
                        },
                      },
                      model: {
                        value: _vm.params.userTelNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "userTelNo", $$v)
                        },
                        expression: "params.userTelNo",
                      },
                    }),
                  ],
                  1
                ),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.202")))]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.usrEmlAddr,
                        expression: "params.usrEmlAddr",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "inputEmail",
                      name: "inputEmail",
                      value: "",
                    },
                    domProps: { value: _vm.params.usrEmlAddr },
                    on: {
                      keyup: function ($event) {
                        return _vm.checkValiEmail()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "usrEmlAddr", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.176")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.contKndCd,
                          expression: "params.contKndCd",
                        },
                      ],
                      attrs: { name: "selectCate", id: "selectCate" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "contKndCd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.checkValiCate()
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.categoryList, function (category) {
                      return _c(
                        "option",
                        { key: category.cd, domProps: { value: category.cd } },
                        [_vm._v(" " + _vm._s(category.cdNm) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("td", {
                  staticClass: "border_left_none",
                  attrs: { colspan: "2" },
                }),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.177")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.rmk,
                        expression: "params.rmk",
                      },
                    ],
                    staticStyle: { height: "200px" },
                    attrs: { id: "inputCont" },
                    domProps: { value: _vm.params.rmk },
                    on: {
                      keyup: function ($event) {
                        return _vm.checkValiCont()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "rmk", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.214")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("div", { staticClass: "agree_box mt8" }, [
                    _c("p", { staticClass: "tit font_medium" }, [
                      _vm._v(_vm._s(_vm.$t("msg.MAIN100.215"))),
                    ]),
                    _c("ul", [
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.216")))]),
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.217")))]),
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.218")))]),
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.219")))]),
                    ]),
                    _c("p", { staticClass: "tit font_medium mt20" }, [
                      _vm._v(_vm._s(_vm.$t("msg.MAIN100.220"))),
                    ]),
                    _c("ul", [
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.221")))]),
                    ]),
                    _c("p", { staticClass: "tit font_medium mt20" }, [
                      _vm._v(_vm._s(_vm.$t("msg.MAIN100.222"))),
                    ]),
                    _c("ul", [
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.223")))]),
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.224")))]),
                    ]),
                  ]),
                  _c("p", { staticClass: "mt10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.acceptYn,
                          expression: "acceptYn",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "agreea1",
                        name: "agree",
                        "true-value": "Y",
                        "false-value": "N",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.acceptYn)
                          ? _vm._i(_vm.acceptYn, null) > -1
                          : _vm._q(_vm.acceptYn, "Y"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.acceptYn,
                              $$el = $event.target,
                              $$c = $$el.checked ? "Y" : "N"
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.acceptYn = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.acceptYn = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.acceptYn = $$c
                            }
                          },
                          function ($event) {
                            return _vm.checkValiCheck()
                          },
                        ],
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "color_black", attrs: { for: "agreea1" } },
                      [_vm._v(" " + _vm._s(_vm.$t("msg.MAIN100.225")) + " ")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex_box mt10" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg ml_auto",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitVoc()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("msg.MAIN100.226")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }